import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Feature } from "geojson";

/**
 * Gets the style value for a map object using the map objects properties if the style value is mapped
 */
export function getStaticStyleValueForMapObject<TStyleValue>(styleValue: StyleValue<TStyleValue>, mapObject: Feature): TStyleValue | null {
    if (styleValue == null || mapObject == null) {
        return null;
    }

    switch (styleValue.extractionMethod) {
        case StyleValueExtractionMethod.Mapped: {
            const featuresSelectedListItem = styleValue.dataFieldId != null ? mapObject?.properties?.[styleValue.dataFieldId] : null;
            return styleValue.mappedValues[featuresSelectedListItem]?.staticValue ?? styleValue.staticValue.staticValue;
        }
        case StyleValueExtractionMethod.Static:
            return styleValue.staticValue.staticValue;
        default:
            throw new Error(`${styleValue.extractionMethod} is not supported`);
    }
}
