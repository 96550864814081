import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { StyledInteractiveArea } from "@iventis/styles/src/components";
import { Header5 } from "@iventis/styles/src/components/texts";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { sanitiseForDOMToken } from "@iventis/utilities";
import { HeaderCellComponentProps, RowModel } from "../types/data-table.types";
import { nextSortBy } from "../lib/grid.helpers";

export enum SortBy {
    ASC = "asc",
    DESC = "desc",
}

const sortByIconNames: { asc: IconName; desc: IconName } = {
    [SortBy.ASC]: "sort-up",
    [SortBy.DESC]: "sort-down",
};

const SortTemplateWithHeader: FunctionComponent<{ sort: () => void; enableSorting: boolean; sortBy: SortBy; displayName: string }> = ({
    sort,
    enableSorting,
    sortBy,
    displayName,
}) => (
    <>
        <StyledInteractiveArea data-testid={sanitiseForDOMToken(`column-header-${displayName}`)} zIndex={0} disabled={!enableSorting} onClick={sort} />
        <div style={{ display: "flex", alignItems: "center" }}>
            <Header5 data-testid={sanitiseForDOMToken(`column-header-name-${displayName}`)}>{displayName}</Header5>
            {sortBy && <FontAwesomeIcon className={`icon ${sortBy}`} icon={["fas", sortByIconNames[sortBy]]} />}
        </div>
    </>
);

/**
 * Sort component for when we are using the client side row model
 * When sorting on the client, we must hold the sortBy in local state and listen to changes via the "sortChanged" event
 */
const SortForClientSideRowModel: FunctionComponent<{ props: HeaderCellComponentProps }> = ({ props }) => {
    const { displayName, column, setSort, enableSorting } = props;
    const [sortBy, setSortBy] = useState(null);

    const onSortChanged = () => {
        setSortBy(column.getSort());
    };

    const sort = () => {
        setSort(nextSortBy(sortBy));
    };

    useEffect(() => {
        column.addEventListener("sortChanged", onSortChanged);
        return () => column.removeEventListener("sortChanged", onSortChanged);
    }, []);

    return <SortTemplateWithHeader sort={sort} enableSorting={enableSorting} sortBy={sortBy} displayName={displayName} />;
};

/**
 * Sort component for when we are using the infinite row model
 * When sorting on the server, all we do is tell ag-grid the new sortBy method via setSort(), and the component needs no private state
 */
const SortForInfiniteRowModel: FunctionComponent<{ props: HeaderCellComponentProps }> = ({ props }) => {
    const { displayName, column, setSort, enableSorting } = props;
    const sortBy = column.getSort() as SortBy;

    const sort = useCallback(() => {
        if (enableSorting) {
            setSort(nextSortBy(sortBy), true);
        }
    }, [enableSorting, sortBy, setSort]);

    return <SortTemplateWithHeader sort={sort} enableSorting={enableSorting} sortBy={sortBy} displayName={displayName} />;
};

export const getSortComponent = (rowModelType: RowModel, props: HeaderCellComponentProps) => {
    switch (rowModelType) {
        case RowModel.ServerSide:
        case RowModel.Infinite: {
            return <SortForInfiniteRowModel props={props} />;
        }
        case RowModel.ClientSide: {
            return <SortForClientSideRowModel props={props} />;
        }
        default:
            throw new Error("Row model not supported");
    }
};
