import { sectionalMargin, TextButton, styled } from "@iventis/styles";
import React, { useMemo, useState } from "react";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { TEMPLATE_3D_TAG, useLayerTemplates } from "@iventis/layer-styles/src/layer-template-query";
import { convertBlobToBase64 } from "@iventis/map-engine/src/utilities/converters";
import { LoadingComponent } from "@iventis/components";
import { LayerThumbnailGenerator } from "@iventis/layer-styles/src/layer-thumbnail-generator";
import { LayerTemplatePreview, LAYER_TEMPLATE_BORDERS_PX, LAYER_TEMPLATE_HEIGHT_PX, LAYER_TEMPLATE_WIDTH_PX } from "@iventis/layer-styles/src/layer-template-preview";
import { Asset } from "@iventis/domain-model/model/asset";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { getBase64FromImageUrl, isValidUuid, replaceItemInArray, useAssetSignatureCache, useDebouncer } from "@iventis/utilities";
import { isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { EditLayerServicesContext, ILayerService } from "@iventis/layer-styles/src/edit-layer-services";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import { DataFieldServicesContext, IDataFieldServices } from "@iventis/datafield-editor";
import { LayerEditModal } from "@iventis/layer-styles";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { assetService, editStyleService, isLayerTemplateDataFieldChanged, isLayerTemplateStyleChanged, preserveDataFieldListItemsOnLayer } from "./layer-template.helpers";
import { AssetsRepoTemplate } from "./assets-repo-template";
import { createAsset, getAsset, getModelThumbnailUrl, updateAsset } from "./assets-api-helpers";
import { getFlatCategories } from "./category-services";

const layerTemplateFilter = [];

type TemplateData = { layer: MapLayer; assetId: string; thumbnailUrl: string; iconUrl: string; tags: string[] };

const getLayerTags = (layer: MapLayer, tags: string[]) => (isModelLayer(layer) && !tags.includes(TEMPLATE_3D_TAG) ? [...tags, TEMPLATE_3D_TAG] : tags);

export const LayerTemplateRepositoryComponent = ({ allowEdit = false }: { allowEdit?: boolean }) => {
    const [modal, setModal] = useState(false);

    const queryClient = useQueryClient();

    const [editAsset, setEditAsset] = useState<TemplateData & { initialLayer: MapLayer; initialThumbnailUrl: string }>(null);
    const [layerThumbnailsBeingGenerated, setLayerThumbnailsBeingGenerated] = useState<TemplateData[]>([]);

    const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
    const handleSelection = (layerId: string) => {
        if (selectedLayers.includes(layerId)) {
            setSelectedLayers(selectedLayers.filter((s) => s !== layerId));
        } else {
            setSelectedLayers([...selectedLayers, layerId]);
        }
    };

    const { assetError, assetLoading, templateLayerQueries, getLayer } = useLayerTemplates(layerTemplateFilter, { assetService, styleService: editStyleService }, true);

    // Get categories
    const { data: categories } = useQuery(
        ["categories", AssetType.LayerTemplate],
        async () => getFlatCategories(AssetType.LayerTemplate).then((res) => res.map(({ name }) => name)),
        {
            refetchOnWindowFocus: false,
        }
    );

    // Create a new template layer
    const { mutateAsync: mutateTemplate, variables: dataUndergoingMutation, isLoading: isMutating } = useMutation(
        async ({
            layer,
            thumbnailUrl,
            iconUrl,
            name,
            tags,
            edit,
            assetId,
        }: {
            layer?: MapLayer;
            thumbnailUrl?: string;
            iconUrl?: string;
            name: string;
            tags: string[];
            edit: boolean;
            assetId?: string;
        }) => {
            const isThumbnailDataUrl = thumbnailUrl?.startsWith("data:"); // The image url can be re-uploaded if starts with data:
            const isIconDataUrl = iconUrl?.startsWith("data:"); // The image url can be re-uploaded if starts with data:
            const blob = new Blob([JSON.stringify(layer)], { type: "application/json" });
            const dataUrl = layer == null ? null : await convertBlobToBase64(blob);
            const asset = {
                dataUrl,
                name,
                tags,
                type: AssetType.LayerTemplate,
                thumbnailDataUrl: isThumbnailDataUrl ? thumbnailUrl : null,
                iconDataUrl: isIconDataUrl ? iconUrl : null,
            };
            return edit ? updateAsset({ ...asset, id: assetId }) : createAsset(asset);
        },
        {
            onSuccess: async (layerTemplateResponse: Asset, request) => {
                if (!request.edit) {
                    // We only need to set the asset data if we are creating a new template
                    queryClient.setQueryData<{ pages: PagedResult<Asset[]>[] }>(["assets", AssetType.LayerTemplate, layerTemplateFilter], (data) => {
                        const { pages } = data;
                        // Add the new template to the end of the last page
                        const lastPage = pages[pages.length - 1];
                        const allOtherPages = data.pages.slice(0, -1);
                        return { ...data, pages: [...allOtherPages, { ...lastPage, results: [...lastPage.results, layerTemplateResponse] }] };
                    });
                }
                const existing = templateLayerQueries.find((templateLayerQuery) => templateLayerQuery?.data?.assetId === request.assetId)?.data;
                queryClient.setQueryData(["template-layer-json", layerTemplateResponse.id], {
                    ...existing,
                    thumbnailUrl: request.thumbnailUrl ?? layerTemplateResponse.thumbnailUrl,
                    assetId: layerTemplateResponse.id,
                    iconUrl: request.iconUrl ?? layerTemplateResponse.iconUrl,
                });
                setSelectedLayers([request.assetId ?? existing?.assetId]);
            },
        }
    );

    const onLayerChange = async (isExistingLayer: boolean, layer: MapLayer, thumbnailUrl: string, tags: string[], assetId?: string, forceSave?: boolean) => {
        // If layer style has not changed then only update the name and tags
        const isLayerStyleChanged =
            !isExistingLayer || (editAsset && (isLayerTemplateStyleChanged(editAsset.layer, layer) || isLayerTemplateDataFieldChanged(editAsset.layer, layer)));

        const request = {
            layer: forceSave || isLayerStyleChanged ? layer : null,
            thumbnailUrl,
            name: layer.name,
            tags: getLayerTags(layer, tags),
            edit: isExistingLayer,
            assetId: assetId ?? editAsset?.assetId,
        };
        const layerTemplateResponse = await mutateTemplate(request);
        setEditAsset((l) => ({ ...l, ...request, layer, assetId: request.assetId ?? layerTemplateResponse.id }));
    };

    const saveDebounceRef = useDebouncer(onLayerChange, 1500);

    const onLayerListItemCreate = async (listItem: DataFieldListItem, datafieldId: string) => {
        setEditAsset((edit) => {
            const dataField = edit.layer.dataFields.find((df) => df.id === datafieldId);
            const newAsset = {
                ...edit,
                layer: { ...edit.layer, dataFields: replaceItemInArray(edit.layer.dataFields, { ...dataField, listValues: [...(dataField.listValues ?? []), listItem] }) },
            };
            saveDebounceRef.current(true, newAsset.layer, editAsset.thumbnailUrl, editAsset.tags, editAsset.assetId, true);
            return newAsset;
        });
    };

    const onLayerListItemUpdate = async (listItem: DataFieldListItem, datafieldId: string) => {
        setEditAsset((edit) => {
            const dataField = edit.layer.dataFields.find((df) => df.id === datafieldId);
            const newAsset = {
                ...edit,
                layer: { ...edit.layer, dataFields: replaceItemInArray(edit.layer.dataFields, { ...dataField, listValues: replaceItemInArray(dataField.listValues, listItem) }) },
            };
            saveDebounceRef.current(true, newAsset.layer, editAsset.thumbnailUrl, editAsset.tags, editAsset.assetId, true);
            return newAsset;
        });
    };

    const onLayerListItemDelete = async (listItemId: string, datafieldId: string) => {
        setEditAsset((edit) => {
            const dataField = edit.layer.dataFields.find((df) => df.id === datafieldId);
            const newAsset = {
                ...edit,
                layer: {
                    ...edit.layer,
                    dataFields: replaceItemInArray(edit.layer.dataFields, { ...dataField, listValues: dataField.listValues.filter(({ id }) => id !== listItemId) }),
                },
            };
            saveDebounceRef.current(true, newAsset.layer, editAsset.thumbnailUrl, editAsset.tags, editAsset.assetId, true);
            return newAsset;
        });
    };

    const postLayer: ILayerService["postLayer"] = (...props) => onLayerChange(false, ...props);
    const putLayer: ILayerService["putLayer"] = (layer, ...props) =>
        onLayerChange(true, editAsset?.layer ? preserveDataFieldListItemsOnLayer(editAsset.layer, layer) : layer, ...props);
    const setLayerThumbnail = async (previewDataUrl: string, templateData: TemplateData) => {
        await mutateTemplate({
            edit: true,
            tags: templateData.tags,
            layer: undefined,
            assetId: templateData.assetId,
            thumbnailUrl: templateData.thumbnailUrl == null ? previewDataUrl : null,
            iconUrl: previewDataUrl,
            name: templateData.layer.name,
        });
    };

    const onEditClicked = (layer: MapLayer, assetId: string, thumbnailUrl: string, iconUrl: string) => {
        setEditAsset({ layer, assetId, thumbnailUrl, iconUrl, tags: [], initialLayer: { ...layer }, initialThumbnailUrl: thumbnailUrl });
        setModal(true);
    };

    const imageGetter = useAssetSignatureCache();

    const dataFieldServices: IDataFieldServices = useMemo(
        () => ({
            dataFieldsService: {
                // The layerService.put() takes care of updating the layer data fields
                postDataField: () => null,
                putDataField: () => null,
                deleteDataField: () => null,
                getDataFieldsForResource: async () => Promise.resolve([]), // Dont need to implement as layer templates dont use relationships so this function isnt needed
                isDataFieldBeingUsed: () => Promise.resolve(false),
            },
            dataFieldListItemsService: {
                // Because the admin dashboard has no list item controller and the data fields are just stored in the layer json,
                // when we update the list items, we update the whole layer
                getDataFieldListItems: async (dataFieldId) => editAsset.layer.dataFields.find((df) => df.id === dataFieldId)?.listValues ?? [],
                getDataFieldListItem: async (dataFieldId, listItemId) =>
                    editAsset.layer.dataFields.find((df) => df.id === dataFieldId)?.listValues.find((lv) => lv.id === listItemId),
                postDataFieldListItem: async (listItem, dataFieldId) => {
                    onLayerListItemCreate(listItem, dataFieldId);
                },
                putDataFieldListItem: async (listItem, dataFieldId) => {
                    onLayerListItemUpdate(listItem, dataFieldId);
                },
                deleteDataFieldListItem: async (listItemId, dataFieldId) => {
                    onLayerListItemDelete(listItemId, dataFieldId);
                },
            },
        }),
        [onLayerChange, editAsset, isMutating]
    );

    return (
        <EditLayerServicesContext.Provider
            value={{
                assetService,
                styleService: editStyleService,
                layerService: {
                    postLayer,
                    putLayer,
                    deleteLayer: () => null,
                },
            }}
        >
            <DataFieldServicesContext.Provider value={dataFieldServices}>
                <AssetsRepoTemplate
                    repoTitle="Layer templates"
                    repoSubtitle="Layer templates repository"
                    buttons={[
                        <TextButton key="upload" type="button" onClick={() => setModal(true)}>
                            Create a layer template
                        </TextButton>,
                    ]}
                    browserComponent={
                        assetLoading ? (
                            <LoadingComponent />
                        ) : assetError ? (
                            <>
                                <p>Error!</p>
                                <p>{JSON.stringify(assetError, null, 4)}</p>
                            </>
                        ) : (
                            <TemplateContainer>
                                {templateLayerQueries
                                    .filter((q) => q.data?.assetId != null)
                                    .map((query) => (
                                        <LayerTemplatePreview
                                            name={query.data?.name}
                                            tags={query.data?.tags}
                                            key={query.data?.assetId}
                                            thumbnailUrl={query.data?.thumbnailUrl}
                                            iconUrl={query.data?.iconUrl}
                                            selected={selectedLayers.includes(query.data?.assetId)}
                                            loading={query.status === "loading" || layerThumbnailsBeingGenerated.some((l) => l.assetId === query.data.assetId)}
                                            error={query.status === "error"}
                                            onClick={() => handleSelection(query.data?.assetId)}
                                            allowEdit={allowEdit}
                                            onEditClick={async () => {
                                                const layer = await getLayer(query.data?.layerUrl, query.data?.name);
                                                // If a layer template has no data fields, we need to set it to an empty array
                                                if (layer.dataFields == null) {
                                                    layer.dataFields = [];
                                                }
                                                onEditClicked({ ...layer, id: layer.id ?? uuid() }, query.data?.assetId, query.data?.thumbnailUrl, query.data?.iconUrl);
                                            }}
                                        />
                                    ))}
                                {dataUndergoingMutation?.edit === false && isMutating && (
                                    <LayerTemplatePreview
                                        key={dataUndergoingMutation?.assetId}
                                        tags={dataUndergoingMutation?.tags}
                                        thumbnailUrl={dataUndergoingMutation?.thumbnailUrl}
                                        iconUrl={dataUndergoingMutation?.iconUrl}
                                        name={dataUndergoingMutation?.name}
                                        selected={false}
                                        loading
                                        error={false}
                                        onClick={() => null}
                                        allowEdit={false}
                                        onEditClick={() => null}
                                    />
                                )}
                            </TemplateContainer>
                        )
                    }
                />
                {layerThumbnailsBeingGenerated.map((templateData) => (
                    <LayerThumbnailGenerator
                        key={templateData.layer.id}
                        layer={templateData.layer}
                        services={{ ...editStyleService, setLayerThumbnail: (url) => setLayerThumbnail(url, templateData) }}
                        onDone={() => setLayerThumbnailsBeingGenerated((layers) => layers.filter((l) => templateData.layer.id !== l.layer.id))}
                    />
                ))}
                {modal && (
                    <LayerEditModal
                        open={modal}
                        layer={editAsset?.layer}
                        close={async () => {
                            setModal(false);
                            if (editAsset == null) {
                                return;
                            }
                            const { thumbnailUrl, layer, tags, initialLayer, initialThumbnailUrl } = editAsset;
                            const isSaved = isValidUuid(layer.id);
                            // If it exists on the server and either the layer or the thumbnail has changed, we need to update the thumbnail
                            if (isSaved && (initialLayer == null || isLayerTemplateStyleChanged(initialLayer, layer) || initialThumbnailUrl !== thumbnailUrl)) {
                                // If it's a model type, we want to get the icon url for it
                                if (editAsset.layer.styleType === StyleType.Model || editAsset.layer.styleType === StyleType.LineModel) {
                                    const style = getLayerStyle(editAsset.layer) as ModelStyle;
                                    const modelId = getStaticStyleValue(style.model);
                                    const assetUrl = await getModelThumbnailUrl(modelId, imageGetter);
                                    const iconUrl = await getBase64FromImageUrl(assetUrl);
                                    // Update with the icon url
                                    await mutateTemplate({ tags, edit: true, name: layer.name, iconUrl, thumbnailUrl: thumbnailUrl ?? iconUrl, assetId: editAsset.assetId });
                                } else {
                                    // Else, generate the thumbnail
                                    setLayerThumbnailsBeingGenerated((layers) => [...layers, { ...editAsset }]);
                                }
                            }
                            // Set the edit asset to null unless the user has already clicked on a new layer to edit
                            setEditAsset((latestEditAsset) => (latestEditAsset?.layer.id === layer.id ? null : latestEditAsset));
                        }}
                        thumbnailImage={editAsset?.thumbnailUrl}
                        mapName="Layer template"
                        categoryOptions={{
                            categories,
                            getExistingCategories: async () => getAsset(editAsset.assetId).then((asset) => asset.tags),
                        }}
                        isTemplateSelector
                        allowImageUpload
                        showTour={false}
                        finishTour={() => null}
                        canUserEdit
                        skipTemplateSelection={false}
                        showMapObjectDataFieldSelectionForTooltip={false}
                    />
                )}
            </DataFieldServicesContext.Provider>
        </EditLayerServicesContext.Provider>
    );
};

const TemplateContainer = styled.div`
    display: flex;
    gap: ${sectionalMargin};
    flex-wrap: wrap;

    button {
        width: 200px;
    }

    .creating-template-loading-spinner {
        width: ${`${LAYER_TEMPLATE_WIDTH_PX}px`};
        height: ${`${LAYER_TEMPLATE_HEIGHT_PX}px`};
        padding: ${`${LAYER_TEMPLATE_BORDERS_PX}px`};
    }
`;
