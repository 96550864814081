import { UserGroup } from "@iventis/domain-model/model/userGroup";
import { assign, createMachine, DoneInvokeEvent, sendParent } from "xstate";
import { v4 as uuid } from "uuid";
import { UserGroupWithUsers } from "@iventis/domain-model/model/userGroupWithUsers";
import { User } from "@iventis/domain-model/model/user";
import { Content } from "@iventis/translations/content/typed-content";
import { emailValidationRegex } from "@iventis/utilities";
import { Toasts } from "@iventis/toasts/src/toast.types";
import { NewTeamUsers, PostUserCreateRequest } from "./people-view.types";
import { PeopleViewMachineEventType } from "./people-view.machine";
import { GetTeams, PostNewUserAndGroups } from "./types";

export const addNewPeopleMachineId = "addNewPeopleMachine";

export const createBasicTeamUser = (user: { email: string; projectAdmin?: boolean; ssoEnabled: boolean }, groupIds: string[]): PostUserCreateRequest => ({
    user,
    groupIds,
    specificPassword: undefined,
    permissions: [],
    isPasswordSalted: false,
    tempPassword: undefined,
    marketingOptIn: false,
    userCreateRequestedEmailAction: null,
});

export interface AddNewPeopleMachineContext {
    newUserGroup: NewTeamUsers[];
    teams: UserGroup[];
}

export enum AddNewPeopleMachineEvents {
    ADD_USER_GROUP = "ADD_USER_GROUP",
    UPDATE_TEAM_IN_USER_GROUP = "UPDATE_TEAM_IN_USER_GROUP",
    UPDATE_EMAILS_IN_USER_GROUP = "UPDATE_EMAILS_IN_USER_GROUP",
    SAVE = "SAVE",
    CANCEL = "CANCEL",
    UPDATE_PROJECT_ADMIN_STATUS = "UPDATE_PROJECT_ADMIN_STATUS",
    UPDATE_USER_SSO = "UPDATE_USER_SSO",
}

type AddNewUserGroupEvent = {
    type: AddNewPeopleMachineEvents.ADD_USER_GROUP;
};

type UpdateTeamInUserGroupEvent = {
    type: AddNewPeopleMachineEvents.UPDATE_TEAM_IN_USER_GROUP;
    payload: { newTeamIds: string[]; userGroupId: string };
};

type UpdateEmailsInUserGroupEvent = {
    type: AddNewPeopleMachineEvents.UPDATE_EMAILS_IN_USER_GROUP;
    payload: { newEmails: string; userGroupId: string };
};

type SaveUserGroupsEvent = {
    type: AddNewPeopleMachineEvents.SAVE;
};

type CancelUserGroupEvent = {
    type: AddNewPeopleMachineEvents.CANCEL;
};

type UpdateProjectAdminStatusEvent = {
    type: AddNewPeopleMachineEvents.UPDATE_PROJECT_ADMIN_STATUS;
    payload: { newUserGroupId: string; projectAdmin: boolean };
};

type UpdateUserSsoEvent = {
    type: AddNewPeopleMachineEvents.UPDATE_USER_SSO;
    payload: { newUserGroupId: string; ssoEnabled: boolean };
};

export type AddNewPeopleEvents =
    | UpdateTeamInUserGroupEvent
    | AddNewUserGroupEvent
    | UpdateEmailsInUserGroupEvent
    | SaveUserGroupsEvent
    | CancelUserGroupEvent
    | UpdateProjectAdminStatusEvent
    | UpdateUserSsoEvent;

export const addNewPeopleMachine = <Translate extends (text: string, options?: Record<string, unknown>, ignoreWarning?: boolean) => string>(params: {
    postNewUserAndGroups: ReturnType<PostNewUserAndGroups>;
    getTeams: GetTeams;
    toast: Toasts;
    translate: Translate;
    isChild: boolean;
}) =>
    createMachine<AddNewPeopleMachineContext, AddNewPeopleEvents>(
        {
            id: addNewPeopleMachineId,
            predictableActionArguments: true,
            context: {
                newUserGroup: [],
                teams: [],
            },
            initial: "teamsLoading",
            states: {
                teamsLoading: {
                    id: "teamsLoading",
                    invoke: {
                        src: params.getTeams,
                        onDone: {
                            actions: assign((context, event: DoneInvokeEvent<UserGroupWithUsers[]>) => ({
                                ...context,
                                teams: event.data,
                            })),
                            target: "idle",
                        },
                        onError: "teamsLoadingError",
                    },
                },
                teamsLoadingError: {
                    id: "teamsLoadingError",
                    type: "final",
                },
                savingAddingNewPeople: {
                    id: "savingAddingNewPeople",
                    invoke: {
                        src: (context) => {
                            const newTeamUsers: PostUserCreateRequest[] = [];
                            context.newUserGroup.forEach((teamUsers) => {
                                const groupIds = teamUsers.teams.map(({ id }) => id);
                                teamUsers.emails.forEach((email) => {
                                    newTeamUsers.push(createBasicTeamUser({ email, projectAdmin: teamUsers.isProjectAdmin, ssoEnabled: teamUsers.ssoEnabled }, groupIds));
                                });
                            });
                            return params.postNewUserAndGroups(newTeamUsers);
                        },
                        onDone: [
                            {
                                cond: () => params.isChild,
                                actions: [
                                    sendParent((_, event: DoneInvokeEvent<User[]>) => ({ type: PeopleViewMachineEventType.ADD_PEOPLE, payload: event.data })),
                                    "notifySuccess",
                                ],
                                target: "finished",
                            },
                            {
                                actions: "notifySuccess",
                                target: "finished",
                            },
                        ],
                        onError: {
                            target: "idle",
                        },
                    },
                },
                finished: {
                    id: "finished",
                    type: "final",
                },
                idle: {
                    id: "idle",
                    on: {
                        [AddNewPeopleMachineEvents.SAVE]: "savingAddingNewPeople",
                        [AddNewPeopleMachineEvents.CANCEL]: "finished",
                    },
                },
            },
            on: {
                [AddNewPeopleMachineEvents.ADD_USER_GROUP]: {
                    actions: assign({
                        newUserGroup: (context) => [...context.newUserGroup, { id: uuid(), emails: [], valid: false, teams: [] }],
                    }),
                },
                [AddNewPeopleMachineEvents.UPDATE_TEAM_IN_USER_GROUP]: {
                    actions: assign({
                        newUserGroup: (context, event: UpdateTeamInUserGroupEvent) => {
                            // get the teams which have been selected
                            const newTeams = context.teams.filter((team) => event.payload.newTeamIds.includes(team.id));
                            // assign new teams to the group that has been changed
                            context.newUserGroup.find((group) => group.id === event.payload.userGroupId).teams = newTeams;
                            return context.newUserGroup;
                        },
                    }),
                },
                [AddNewPeopleMachineEvents.UPDATE_EMAILS_IN_USER_GROUP]: {
                    actions: assign({
                        newUserGroup: (context, event) => {
                            // find the user group being changed
                            const userGroup = context.newUserGroup.find((group) => group.id === event.payload.userGroupId);
                            // spilt email string by comma
                            const spiltEmails = event.payload.newEmails.split(",");
                            // remove all spaces for the emails
                            const removedSpacesEmails = spiltEmails.map((email) => email.replace(/\s/g, ""));
                            // ensure all emails are valid if not set valid to false
                            userGroup.valid = removedSpacesEmails.every((email) => email.match(emailValidationRegex) != null);
                            // assign new emails to context
                            userGroup.emails = removedSpacesEmails;
                            return context.newUserGroup;
                        },
                    }),
                },
                [AddNewPeopleMachineEvents.UPDATE_PROJECT_ADMIN_STATUS]: {
                    actions: assign({
                        newUserGroup: (context, event) => {
                            const userGroup = context.newUserGroup.find((group) => group.id === event.payload.newUserGroupId);
                            userGroup.isProjectAdmin = event.payload.projectAdmin;
                            return context.newUserGroup;
                        },
                    }),
                },
                [AddNewPeopleMachineEvents.UPDATE_USER_SSO]: {
                    actions: assign({
                        newUserGroup: (context, event) => {
                            const userGroup = context.newUserGroup.find((group) => group.id === event.payload.newUserGroupId);
                            userGroup.ssoEnabled = event.payload.ssoEnabled;
                            return context.newUserGroup;
                        },
                    }),
                },
            },
        },
        {
            actions: {
                notifySuccess: () => {
                    params.toast.success(
                        {
                            title: params.translate(Content.common.success),
                            message: params.translate(Content.people.toasts.invite_success),
                        },
                        { autoHideDuration: 5000 }
                    );
                },
            },
        }
    );
