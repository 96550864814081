// GeoJSON must be imported for the sitemapVersionLevel type definition
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { GeoJSON } from "geojson";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { EMPTY_GUID } from "@iventis/utilities";
import { v4 as uuid } from "uuid";
import { Status } from "@iventis/domain-model/model/status";
import { SitemapVersionLevelCreateResponse } from "@iventis/domain-model/model/sitemapVersionLevelCreateResponse";
import { SiteMapNodeTypes, SitemapNode, SitemapVersionNode, SitemapVersionLevelNode, SitemapVersionLevelWithFiles, SitemapVersionWithFiles } from "./sitemap-types";
import { ISitemapContext } from "./sitemap-context";

/**
 * Converts an array of sitemaps into an array of {@link SitemapNode}. To be primarily used inside the tree browser.
 * @param sitemaps - the sitemaps to be converted
 * @returns an array of SitemapNodes
 */
export function convertSitemapsToSitemapNodes<T extends Sitemap>(sitemaps: T[], rootId: string) {
    return sitemaps.map<SitemapNode>((sitemap) => ({
        id: sitemap.id,
        sourceId: sitemap.id,
        name: sitemap.name,
        sitemap,
        type: SiteMapNodeTypes.Sitemap,
        parentId: rootId,
        childCount: sitemap.versions.length,
        childNodes: sitemap.versions.map<SitemapVersionNode>((version) => ({
            id: version.id,
            sourceId: version.id,
            name: version.name,
            version,
            type: SiteMapNodeTypes.Version,
            childCount: version?.sitemapVersionLevels?.length,
            childNodes: sortByLevelIndex(version).map<SitemapVersionLevelNode>((level) => ({
                id: level.id,
                sourceId: level.id,
                name: level.levelName,
                level,
                type: SiteMapNodeTypes.Level,
                childCount: 0,
                childNodes: [],
            })), // Make all levels for this version be ordered by their level index
        })),
    }));
}

export const createEmptySitemapVersion = (sitemapId: string): SitemapVersionWithFiles => ({
    id: EMPTY_GUID,
    date: undefined,
    name: "",
    sitemapVersionLevels: [],
    sitemapId,
    status: Status.Active,
});

export const createEmptySitemapVersionLevel = (versionId: string, index = 0): SitemapVersionLevelWithFiles => ({
    id: uuid(),
    levelIndex: index,
    levelName: "",
    levelAbbreviation: "",
    bounding: undefined,
    sitemapVersionId: versionId,
    status: Status.Pending,
    perimeter: undefined,
});

export const getNextLevelIndex = (sitemapVersionLevels: SitemapVersionLevel[]) => {
    let index = 0;
    sortByLevelIndex({ sitemapVersionLevels }).forEach((level) => {
        if (level.levelIndex - index === 0) {
            index += 1;
        }
    });
    return index;
};

export const hasSitemapChanged = (existingSitemap: Sitemap, sitemap: Sitemap) =>
    existingSitemap == null || existingSitemap.name !== sitemap.name || existingSitemap.colour !== sitemap.colour || existingSitemap.tag !== sitemap.tag;

export const hasSitemapVersionLevelChanged = (oldLevel: SitemapVersionLevel, newLevel: SitemapVersionLevelWithFiles) =>
    oldLevel.levelName !== newLevel.levelName ||
    oldLevel.levelIndex !== newLevel.levelIndex ||
    oldLevel.levelAbbreviation !== newLevel.levelAbbreviation ||
    // If a style exists locally, it means it has been updated
    newLevel.style != null ||
    // If mbtiles exist locally, it means it has been updated
    newLevel.mbtiles != null ||
    JSON.stringify(oldLevel.perimeter) !== JSON.stringify(newLevel.perimeter);

export const isSitemapVersionLevelValid = (sitemapVersionLevel: SitemapVersionLevelWithFiles) =>
    sitemapVersionLevel.levelName?.length > 0 && sitemapVersionLevel.levelAbbreviation?.length > 0 && sitemapVersionLevel.levelIndex != null;

export const isNewSitemapVersionLevelValid = (sitemapVersionLevel: SitemapVersionLevelWithFiles) =>
    isSitemapVersionLevelValid(sitemapVersionLevel) && sitemapVersionLevel.mbtiles != null && sitemapVersionLevel.style != null;

export const hasSitemapVersionChanged = (oldSitemapVersion: SitemapVersion, newSitemapVersion: SitemapVersionWithFiles) =>
    oldSitemapVersion == null || oldSitemapVersion.name !== newSitemapVersion.name;

export const hasSitemapVersionsLevelsChanged = (existingLevels: SitemapVersionLevel[], newLevels: SitemapVersionLevelWithFiles[]) => {
    const existingSorted = [...existingLevels].sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
    const newSorted = [...newLevels].sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
    const allExistingEqual = existingSorted.every((level, index) => level.id === newSorted[index]?.id && !hasSitemapVersionLevelChanged(level, newSorted[index]));
    const allNewEqual = newSorted.every((level, index) => level.id === existingSorted[index]?.id && !hasSitemapVersionLevelChanged(existingSorted[index], level));
    return !(allExistingEqual && allNewEqual);
};

export function hasSitemapVersionsLevelPerimeterChanged(_existingLevels: SitemapVersionLevel[], _newLevels: SitemapVersionLevelWithFiles[]) {
    // Ensure that the existing levels are an empty array if null
    const existingLevels = _existingLevels ?? [];
    const newLevels = _newLevels ?? [];
    return existingLevels.some((level, index) => {
        const existingPerimeter = level?.perimeter;
        const newPerimeter = newLevels[index]?.perimeter;
        return JSON.stringify(existingPerimeter) !== JSON.stringify(newPerimeter);
    });
}

export const sortByLevelIndex = <TVersion extends { sitemapVersionLevels: SitemapVersionLevel[] }>(sitemapVersion: TVersion): TVersion["sitemapVersionLevels"] =>
    sitemapVersion.sitemapVersionLevels?.sort((a, b) => (a.levelIndex < b.levelIndex ? -1 : a.levelIndex > b.levelIndex ? 1 : 0));

/** Checks for duplicate level indexes in a list of levels. Does this by checking lengh of list against length of distinct list */
export const getLevelsWithDuplicateIndexes = (levels: SitemapVersionLevel[]) =>
    levels.reduce(
        (acc, leftLevel, leftIndex) =>
            levels.some((rightLevel, rightIndex) => leftIndex !== rightIndex && leftLevel.levelIndex === rightLevel.levelIndex) ? [...acc, leftLevel] : acc,
        [] as SitemapVersionLevel[]
    );

export const splitExistingAndNewLevels = (existingLevels: SitemapVersionLevelWithFiles[], allLevels: SitemapVersionLevelWithFiles[]) => {
    const upserted = allLevels.reduce(
        (acc, level) => {
            if (existingLevels?.some((l) => l.id === level.id)) {
                acc[0].push(level);
            } else {
                acc[1].push(level);
            }
            return acc;
        },
        [[], []] as [SitemapVersionLevelWithFiles[], SitemapVersionLevelWithFiles[]]
    );
    const deleted = existingLevels.filter((l) => !allLevels.some((nl) => nl.id === l.id));
    return [...upserted, deleted] as const;
};

export const upsertSitemapVersionAndLevels = async (
    existingSitemapVersion: SitemapVersion,
    sitemapVersion: SitemapVersionWithFiles,
    sitemapServices: ISitemapContext,
    onSitemapVersionLevelsUpdated: (sitemapVersion: SitemapVersion, uploadTilesRequests: SitemapVersionLevelCreateResponse[]) => void
) => {
    // Split into levels to create and update
    const [levelsThatAlreadyExisted, levelsToCreate, levelsToDelete] = splitExistingAndNewLevels(existingSitemapVersion?.sitemapVersionLevels, sitemapVersion.sitemapVersionLevels);
    const levelsToPatch = levelsThatAlreadyExisted.filter((level) =>
        hasSitemapVersionLevelChanged(
            existingSitemapVersion.sitemapVersionLevels.find((el) => el.id === level.id),
            level
        )
    );

    // Delte any that have been removed
    if (levelsToDelete?.length > 0) {
        await Promise.all(levelsToDelete.map((l) => sitemapServices.deleteSitemapVersionLevel(l.id, sitemapVersion.id, sitemapVersion.sitemapId)));
    }

    // If we're updating new levels, the backend will return important information required to upload the tiles to s3
    let levelUploadRequests: SitemapVersionLevelCreateResponse[];
    if (levelsToCreate?.length > 0) {
        try {
            levelUploadRequests = await Promise.all(
                levelsToCreate.map((level) => sitemapServices.createSitemapVersionLevel({ ...level, sitemapVersionId: sitemapVersion.id }, sitemapVersion.sitemapId))
            );
            levelsToCreate.forEach((level, index) => {
                // eslint-disable-next-line no-param-reassign
                level.id = levelUploadRequests[index].id;
            });
        } catch {
            return;
        }
    }
    if (levelsToPatch.length > 0) {
        try {
            const update = async (level: SitemapVersionLevelWithFiles): Promise<SitemapVersionLevelCreateResponse> => {
                // If we're uploading a tile as part of the patch, make sure we set the status to pending
                const url = await sitemapServices.updateSitemapVersionLevel({ ...level, status: level.mbtiles == null ? level.status : Status.Pending }, sitemapVersion.sitemapId);
                return { ...level, tileUploadUrl: url };
            };
            const patchLevelUploadRequests = (await Promise.all(levelsToPatch.map((level) => update(level))))?.filter(
                (req) => levelsToPatch.find((l) => l.id === req.id)?.mbtiles != null
            );
            // User may have only updated the style file, which means we don't need to upload a new tile
            if (patchLevelUploadRequests?.length > 0) {
                levelUploadRequests = levelUploadRequests == null ? patchLevelUploadRequests : [...levelUploadRequests, ...patchLevelUploadRequests];
            }
        } catch {
            return;
        }
    }
    const combinedLevels = [...levelsToCreate, ...levelsToPatch];
    // If we have created, updated or deleted any levels, inform the parent
    if (combinedLevels.length > 0 || levelsToDelete?.length > 0) {
        onSitemapVersionLevelsUpdated(
            {
                ...sitemapVersion,
                sitemapVersionLevels: sitemapVersion.sitemapVersionLevels.map((level) => ({
                    ...level,
                    sitemapVersionId: sitemapVersion.id,
                    mbtiles: undefined,
                    style: undefined,
                })),
            },
            levelUploadRequests?.map((req) => ({ ...req, mbtiles: combinedLevels.find((level) => level.id === req.id).mbtiles }))
        );
    }
};
