import React from "react";
import { Select, OutlinedInput, Checkbox } from "@mui/material";
import { Body1, fontSizes, InteractiveElement, muiInputFormsCSS, styled } from "@iventis/styles";

interface MultiSelectDropdownProps<TValue extends { id: string; name: string }> {
    values: TValue[];
    selectedValues: TValue[];
    maximumValueSelected?: number;
    minimumValueSelected?: number;
    selectedValuesJoiningString?: string;
    placeHolderText?: string;
    dataTestId?: string;
    disabled?: boolean;
    onValuesUpdate: (value: TValue[]) => void;
}

/**
 * Combines a dropdown with checkboxes to allow multiple values to be selected
 */
export function MultiSelectDropdownComponent<T extends { id: string; name: string }>({
    values,
    selectedValues,
    selectedValuesJoiningString = ",",
    /** Text shown when not values are selected */
    placeHolderText,
    maximumValueSelected = Infinity,
    minimumValueSelected = 0,
    dataTestId,
    disabled,
    onValuesUpdate,
}: MultiSelectDropdownProps<T>) {
    const handleOptionClicked = (valueId: string) => {
        if (selectedValues.some((selectedValue) => selectedValue.id === valueId)) {
            onValuesUpdate(selectedValues.filter((selectedValue) => selectedValue.id !== valueId));
        } else {
            const foundValue = values.find((value) => value.id === valueId);
            onValuesUpdate([...selectedValues, foundValue]);
        }
    };

    const allowToAddMoreOptions = selectedValues.length < maximumValueSelected;
    const allowToRemoveMoreOptions = selectedValues.length > minimumValueSelected;
    return (
        <MultiSelectDropdownContainer>
            <Select
                sx={{ width: "100%" }}
                multiple
                value={selectedValues}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <MultiSelectSelectedValuesText>{placeHolderText}</MultiSelectSelectedValuesText>;
                    }
                    return <MultiSelectSelectedValuesText>{selected.map((value) => value.name).join(`${selectedValuesJoiningString} `)}</MultiSelectSelectedValuesText>;
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            marginTop: 10,
                            height: "fit-content",
                            maxHeight: 250,
                        },
                    },
                }}
                displayEmpty
                data-testid={dataTestId}
                disabled={disabled}
            >
                <MenuItemsContainer>
                    {values.map((value) => {
                        const isSelected = selectedValues.some((selectedValue) => selectedValue.id === value.id);
                        // Is disabled if max number of options is selected and the current option is not selected or if the number of selected options is equal or less to the minimum value selected
                        const isDisabled = (!allowToAddMoreOptions && !isSelected) || (isSelected && !allowToRemoveMoreOptions);
                        return (
                            <StyledMenuItem key={value.id} disabled={isDisabled} onClick={() => handleOptionClicked(value.id)} data-testid={`${dataTestId}-${value.name}`}>
                                <StyledCheckbox size="small" checked={isSelected} disabled={isDisabled} color="primary" />
                                <Body1>{value.name}</Body1>
                            </StyledMenuItem>
                        );
                    })}
                </MenuItemsContainer>
            </Select>
        </MultiSelectDropdownContainer>
    );
}

const StyledMenuItem = styled(InteractiveElement)`
    box-sizing: border-box;
    width: 100%;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5x;

    padding: 5px 10px;

    :disabled {
        cursor: default;
        // When disabled, decrease opacity of checkbox
        span {
            opacity: 0.3;
        }
    }
`;

const StyledCheckbox = styled(Checkbox)`
    // Overrides the hover colour of the checkbox
    :hover {
        // 10% opacity of the colour
        background-color: ${({ theme }) => `${theme.primaryColors.subdued70}10`};
    }

    // Overrides the ripple colour of the checkbox
    span {
        color: ${({ theme }) => theme.primaryColors.subdued70} !important;
    }

    // Overrides the tick colour of the checkbox
    .MuiSvgIcon-root {
        color: ${({ theme }) => theme.primaryColors.subdued70};
    }
`;

const MultiSelectSelectedValuesText = styled(Body1)`
    margin-top: 10px;
    font-style: italic;
    font-size: ${fontSizes.small};
`;

const MultiSelectDropdownContainer = styled.div`
    ${muiInputFormsCSS}

    width: 100%;

    legend {
        display: none;
    }

    // Overrides the border colour of the dropdown when focused
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.tertiaryColors.headerBackground};
    }
`;

const MenuItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
`;
