import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { css } from "@emotion/react";
import { EngineInterpreter } from "@iventis/map-engine/src/bridge/engine-generic";
import { v4 as uuid } from "uuid";
import { MapboxEngine } from "@iventis/map-engine/src/bridge/mapbox/engine-mapbox";
import { DataField } from "@iventis/domain-model/model/dataField";
import { MAPBOX_MAX_ZOOM, MAPBOX_MIN_ZOOM } from "@iventis/map-engine/src/bridge/constants";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapboxEngineData, MapModuleLayer, MapState, Source } from "@iventis/map-engine/src/types/store-schema";
import { BehaviorSubject, Observable } from "rxjs";
import { borderRadius } from "@iventis/styles/src/atomic-rules";
import { ModeEvents } from "@iventis/map-engine/src/machines/map-machines.types";
import { RefreshAssetEvent, MapEventTypes, MapEvents } from "@iventis/map-engine/src/types/map-stream.types";
import { InvocationEventType } from "@iventis/map-engine/src/types/events-invocation";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { screenSizeBreakpoints, styled } from "@iventis/styles";
import { mapboxKey, useWindowSize, EventStream } from "@iventis/utilities";
import { Model } from "@iventis/domain-model/model/model";
import { dataTestIds } from "@iventis/testing";
import { mapFontStackUrl } from "@iventis/map-engine/src/map-fonts";
import { baseMapStylePreviewSnapshot, initialMapState } from "./preview-map-store-initial-values-mapbox";
import {
    calculateMapPosition,
    createPreviewLayer,
    createPreviewSnapshotLayer,
    createPreviewSourceData,
    getSnapshotBearing,
    getSnapshotPitch,
    getSnapshotZoomLevel,
} from "./preview-helper-functions";

interface PreviewContainerProps {
    layerStyle: LayerStyle;
    datafields: DataField[];
    className?: string;
    modelGetter: (ids: string[]) => Promise<Model[]>;
    assetGetter: (ids: string[]) => Promise<{ id: string; url: string }[]>;
    imageCompressUrlGetter: (id: string, pixelRes: number, skip?: boolean) => Promise<string>;
    isAssetSdf: (id: string) => Promise<boolean>;
    bustCacheIds: (ids: string[]) => void;
    onMapLoaded?: () => void;
    getAttributeListItems: EngineInterpreter["getAttributeListItems"];
    assetRefreshObserver?: Observable<ModeEvents | MapEvents>;
    cypressWindowName?: string;
}

export type PreviewContainerCallbackRefs = {
    takeSnapshot(): Promise<string>;
};

/**
 * The component creates a map and displays an Iventis layer style onto the map
 * @param { number } layerStyle - layer style which wants to be previewed
 * @param { string } className - class to be applied to the preview
 */
const PreviewContainerComponent = forwardRef<PreviewContainerCallbackRefs, PreviewContainerProps>(
    (
        {
            className,
            layerStyle,
            datafields,
            modelGetter,
            assetGetter,
            imageCompressUrlGetter,
            onMapLoaded,
            isAssetSdf,
            getAttributeListItems,
            bustCacheIds,
            assetRefreshObserver,
            cypressWindowName,
        },
        ref
    ) => {
        const mapElement = useRef(null);
        // Remove all default layers from preview as they are not needed
        // create a behaviour subject and pass layer changes to it to change the map engine
        const mapStoreSubject = useRef({ change: new BehaviorSubject<MapState<MapboxEngineData>>(initialMapState) });

        // create the preview layer with the layer style and basic layer data
        const [previewLayer, setPreviewLayer] = useState<MapModuleLayer>(createPreviewLayer(layerStyle, datafields));
        const mapInterpreter = useRef<EngineInterpreter>();
        const previewContainer = useRef<HTMLDivElement>();
        const updatePreviewLayer = () => setPreviewLayer(createPreviewLayer(layerStyle, datafields));
        const [snapShotMode, setSnapShotMode] = useState<boolean>(false);
        const updatePreviewLayerReadyForSnapshot = () => {
            // Return the map back to its orginal position, with snapshot preview style and geom data.
            // We are not using the "PreviewLayer" state to update the preview snapshot layer because it causes the map to be idle before the useEffect has been called sometimes. This way allows for only 1 Store change.
            setSnapShotMode(true);
            previewContainer.current.style.width = "101px";
            previewContainer.current.style.height = "101px";
            mapInterpreter.current.onResize();
            mapStoreSubject.current.change.next({
                ...mapStoreSubject.current.change.value,
                position: {
                    value: {
                        lng: 0,
                        lat: 0,
                        zoom: getSnapshotZoomLevel(layerStyle),
                        bearing: getSnapshotBearing(layerStyle),
                        pitch: getSnapshotPitch(layerStyle),
                        source: Source.HOST,
                    },
                    stamp: uuid(),
                },
                engineSpecificData: {
                    styles: {
                        value: {
                            [AssetType.MapBackground]: baseMapStylePreviewSnapshot,
                            [AssetType.SiteMap]: [],
                        },
                        stamp: uuid(),
                    },
                },
                layers: { value: [{ ...createPreviewSnapshotLayer(layerStyle), stamp: uuid() }], stamp: uuid() },
                geoJSON: { value: createPreviewSourceData(layerStyle, true), stamp: uuid() },
            });
        };

        useImperativeHandle(ref, () => ({
            takeSnapshot() {
                updatePreviewLayerReadyForSnapshot();
                return new Promise<string>((resolve) => {
                    mapInterpreter.current.onMapIdle(() => mapInterpreter.current.getSnapshotDataUrl().then(resolve), { disableMapQuerying: true, listenOnce: true });
                });
            },
        }));
        // if we are on a small screen, set the zoom level to be 14 of the preview window. This makes the preview window zoom out more getting the whole line shape in frame. Otherwise use the default zoom
        const zoomLevel = useWindowSize().screenWidth < screenSizeBreakpoints.extraSmall ? 14 : mapStoreSubject.current.change.value.position.value.zoom;
        useEffect(() => {
            if (mapStoreSubject.current.change.isStopped) {
                mapStoreSubject.current = { change: new BehaviorSubject<MapState<MapboxEngineData>>(initialMapState) };
            }

            const previewEventStream = new EventStream<ModeEvents | MapEvents>();

            const iconSubscription = assetRefreshObserver?.subscribe({
                next(event: RefreshAssetEvent) {
                    previewEventStream.next({ type: MapEventTypes.REFRESH_ASSET, payload: event.payload });
                },
            });

            // create an instance of a mapbox engine
            const mapEngine = new MapboxEngine({
                store: mapStoreSubject.current,
                container: mapElement.current,
                eventStream: previewEventStream,
                mbxAccessToken: mapboxKey,
                preview: true,
                modifierKeys: [],
                assetOptions: {
                    assetUrlGetter: (id, type) => imageCompressUrlGetter(id, 60, type === AssetType.Model),
                    multipleAssetUrlGetter: assetGetter,
                    multipleModelsGetter: modelGetter,
                    isAssetSdf,
                    bustCacheIds,
                },
                maxZoom: MAPBOX_MAX_ZOOM,
                minZoom: MAPBOX_MIN_ZOOM,
                fontStackUrl: mapFontStackUrl,
                getAttributeListItems,
                routeApiFunctions: undefined,
                isExternalUser: false,
                user: { id: "", isMobileUser: false },
                cypressWindowName,
            });

            const mapLoadedEvent = mapEngine.invocationEvents.subscribe((e) => {
                if (e.type === InvocationEventType.MAP_LOADED) {
                    onMapLoaded?.();
                }
            });
            mapInterpreter.current = mapEngine;
            // emit the initial store with the preview layer and source data
            mapStoreSubject.current.change.next({
                ...mapStoreSubject.current.change.value,
                position: {
                    value: {
                        ...mapStoreSubject.current.change.value.position.value,
                        zoom: zoomLevel,
                    },
                    stamp: uuid(),
                },
                layers: {
                    value: [previewLayer],
                    stamp: uuid(),
                },
                geoJSON: {
                    value: createPreviewSourceData(layerStyle),
                    stamp: uuid(),
                },
            });

            // component unmount function (when the component is destoryed)
            return () => {
                mapLoadedEvent.unsubscribe();
                iconSubscription?.unsubscribe();
                // destroy event stream
                previewEventStream.destroy();
                // call the map engine destory function
                mapEngine.destroy();
                // unsubscribe from the map store subject
                mapStoreSubject.current.change.unsubscribe();
            };
        }, []);

        // when the layer style changes
        useEffect(() => {
            updatePreviewLayer();
            mapStoreSubject.current.change.next({
                ...mapStoreSubject.current.change.value,
                position: {
                    value: {
                        ...calculateMapPosition(layerStyle),
                    },
                    stamp: uuid(),
                },
                geoJSON: {
                    value: createPreviewSourceData(layerStyle),
                    stamp: uuid(),
                },
            });
        }, [layerStyle, datafields]);

        // when the previewLayer changes
        useEffect(() => {
            // emit the new preview layer
            mapStoreSubject.current.change.next({
                ...mapStoreSubject.current.change.value,
                layers: { value: [{ ...previewLayer, stamp: uuid() }], stamp: uuid() },
            });
        }, [previewLayer]);

        return (
            <StyledPreviewContainer data-testid="layer-style-preview-map" ref={previewContainer} preview={!snapShotMode} className={className}>
                <StyledMap ref={mapElement} data-testid={dataTestIds.editLayer.preview} />
            </StyledPreviewContainer>
        );
    }
);

export const StyledPreview = css`
    margin-right: 12px;
    border-radius: ${borderRadius.standard};
    flex-shrink: 0;
    flex-grow: 0;
    position: sticky;
    top: 0px;
`;

const StyledPreviewSnapshot = css`
    position: fixed;
    top: 0px;
    left: -300px;
`;

const StyledPreviewContainer = styled.div<{ preview: boolean }>`
    ${(props) => (props.preview ? StyledPreview : StyledPreviewSnapshot)}
`;

const StyledMap = styled.div`
    width: 100%;
    height: 100%;
`;

export default PreviewContainerComponent;
