import { definedValueSelectorComponentCreator, incrementalValueSelectorComponentCreator } from "@iventis/components";
import { Content } from "@iventis/translations";
import React from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { EditStyleItemComponent } from "./edit-style-item";
import { ChangeStyleValue } from "./edit-style.helpers";
import { StylesWithText } from "./text-edit-style";

type IconEditStyleProps = {
    changeStyleValue: ChangeStyleValue<StylesWithText>;
    layerStyle: IconStyle;
};

export const IconTextEditStyle = ({ layerStyle, changeStyleValue }: IconEditStyleProps) => {
    const translate = useIventisTranslate();
    return (
        <>
            <EditStyleItemComponent
                styleProperty="iconTextFit"
                component={definedValueSelectorComponentCreator<boolean>({
                    optionalValues: [
                        { value: true, label: translate(Content.common.yes) },
                        { value: false, label: translate(Content.common.no) },
                    ],
                })}
                value={layerStyle.iconTextFit ?? getDefaultStyleProperty(layerStyle.styleType, "iconTextFit")}
                changeStyleValue={changeStyleValue}
                title={translate(Content.map5.styles.scaleText)}
                disabled={!getStaticStyleValue(layerStyle.text)}
            />
            <EditStyleItemComponent
                styleProperty="iconTextFitMargin"
                value={layerStyle.iconTextFitMargin ?? getDefaultStyleProperty(layerStyle.styleType, "iconTextFitMargin")}
                changeStyleValue={changeStyleValue}
                component={incrementalValueSelectorComponentCreator({
                    minValue: 0,
                    maxValue: 20,
                    increment: 1,
                    units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                    selectedUnitId: "px",
                })}
                title={translate(Content.map5.styles.textMargin)}
                isZoomableValue={false}
                disabled={!getStaticStyleValue(layerStyle.iconTextFit)}
            />
        </>
    );
};
