/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

// IMPORTANT:
//    1) Content can only be created/edited inside the "./content" folder
//    2) If you have added a new js file inside of "./content", add the filename to the below namespaces array and add it to "./content/index.js"
//    3) Run -- npm run trans -- if you change any of the below arrays

import { languages } from "./languages.js";

const namespaces = [
    "common",
    "settings",
    "settings2",
    "settings3",
    "map",
    "map2",
    "map3",
    "map4",
    "map5",
    "map6",
    "map7",
    "map8",
    "map9",
    "map10",
    "library",
    "library2",
    "errors",
    "projects",
    "permissions",
    "login",
    "login2",
    "schedule",
    "schedule2",
    "schedule3",
    "table",
    "people",
    "runsheet",
    "timerules",
    "subscriptionplan",
    "subscriptionplan2",
    "subscriptionplan3",
    "subscriptionplan4",
    "attributes",
    "notifications",
];

const getResources = () => {
    const resources = {};
    languages.forEach((lang) => {
        resources[lang] = {};
        namespaces.forEach(async (ns) => {
            resources[lang][ns] = {};
            const getFile = async () => {
                let file;
                try {
                    // eslint-disable-next-line import/no-dynamic-require
                    file = await import(`./locales/${lang}/${ns}.json`);
                } catch (err) {
                    throw new Error(
                        `${err}.\nTry 'npm run trans' to create this file` // Catches any failed imports and provides instruction
                    );
                }
                return file;
            };
            resources[lang][ns] = await getFile();
        });
    });
    return resources;
};

export { Content } from "./content/typed-content";

export default { namespaces, languages, getResources };

export * from "./helpers";
