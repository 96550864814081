import booleanContains from "@turf/boolean-contains";
import { AnySupportedGeometry } from "@iventis/map-types";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { MapSitemapConfig } from "@iventis/domain-model/model/mapSitemapConfig";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { SitemapVersionLevel } from "@iventis/domain-model/model/sitemapVersionLevel";

/** Checks if a given map object is in the perimeter of a sitemap and returns the sitemap's id */
export function getMapObjectSitemap(mapObjectGeometry: AnySupportedGeometry, mapObjectLevel: number, sitemaps: Sitemap[], _sitemapConfig: MapSitemapConfig[]): string | null {
    // Early exit if any of the required parameters are missing
    if (mapObjectLevel == null || mapObjectGeometry == null || sitemaps == null || sitemaps.length === 0) {
        return null;
    }

    // Ensure sitemapConfig is not null
    const sitemapConfig = _sitemapConfig || [];

    const sitemapMapObjectBelongsTo = sitemaps.find((sitemap) => {
        // Check if we can find the sitemap config
        const foundSitemapConfig = sitemapConfig.find((sitemapInView) => sitemapInView.sitemapId === sitemap.id);
        if (foundSitemapConfig != null) {
            // Get the selected version of the sitemap
            const sitemapVersion = sitemap.versions.find((version) => version.id === foundSitemapConfig.versionId);
            if (sitemapVersion != null) {
                // Get the level of the sitemap that the map object is on
                const sitemapVersionLevelInView = sitemapVersion.sitemapVersionLevels.find((sitemapVersionLevel) => sitemapVersionLevel.levelIndex === mapObjectLevel);
                if (sitemapVersionLevelInView != null && sitemapVersionLevelInView.perimeter != null) {
                    // Is the whole sitemap inside the perimeter of the sitemap
                    return booleanContains(sitemapVersionLevelInView.perimeter, mapObjectGeometry);
                }
            }
        }

        // Can't find sitemap through the sitemap config
        // Get most recent sitemap version
        const mostRecentSitemapVersion = sitemap.versions.reduce<SitemapVersion | undefined>((mostRecentSitemap, sitemap) => {
            if (mostRecentSitemap == null) {
                return sitemap;
            }
            return new Date(mostRecentSitemap.date).getTime() > new Date(sitemap.date).getTime() ? mostRecentSitemap : sitemap;
        }, undefined);

        // Get the closet level to the map object
        const sitemapVersionLevel = mostRecentSitemapVersion?.sitemapVersionLevels.reduce<SitemapVersionLevel | null>((selectedLevel, sitemapVersionLevel) => {
            if (selectedLevel == null) {
                return sitemapVersionLevel;
            }
            const levelDiff = Math.abs(sitemapVersionLevel.levelIndex - mapObjectLevel);
            const selectedSitemapVersionLevelDiff = Math.abs(selectedLevel.levelIndex - mapObjectLevel);
            return selectedSitemapVersionLevelDiff > levelDiff ? sitemapVersionLevel : selectedLevel;
        }, null);

        return sitemapVersionLevel != null && sitemapVersionLevel.perimeter != null ? booleanContains(sitemapVersionLevel.perimeter, mapObjectGeometry) : undefined;
    });

    return sitemapMapObjectBelongsTo?.id ?? null;
}
