import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { PredefinedZoomLevels } from "../bridge/constants";

/** Creates a default style value which is zoomable */
export function createDefaultStyleZoomableValue(zoomLevelValues: { city: number; street: number; max: number }) {
    return {
        extractionMethod: StyleValueExtractionMethod.Static,
        dataFieldId: undefined,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Continuous,
            staticValue: zoomLevelValues.max,
            mappedZoomValues: {
                [PredefinedZoomLevels.max]: { value: zoomLevelValues.max, hidden: zoomLevelValues.max === 0 },
                [PredefinedZoomLevels.street]: { value: zoomLevelValues.street, hidden: zoomLevelValues.street === 0 },
                [PredefinedZoomLevels.city]: { value: zoomLevelValues.city, hidden: zoomLevelValues.city === 0 },
                [PredefinedZoomLevels.country]: { value: 0, hidden: true },
                [PredefinedZoomLevels.world]: { value: 0, hidden: true },
            },
            unitType: Units.None,
        },
        mappedValues: undefined,
        multiValues: [],
    };
}
