/* eslint-disable camelcase */
export const schedule3 = {
    venueEdit: {
        insertFailure: "Failed to create venue",
        editFailure: "Failed to edit venue",
        deleteFailure: "Failed to delete venue",
        creationSuccess: "Venue created succesfully",
        editSuccess: "Venue changed succesfully",
        deleteSuccess: "Venue deleted succesfully",
        topLevel: "Top level",
    },
    resetZoom: "Reset zoom",
    shareOrExport: {
        title: "Share/Export schedule",
        shareLinkOptionTitle: "Share link",
        shareLinkOptionDescription: "Share selected schedule by a read only link (Expiry 7 days)",
        exportOptionTitle: "Export schedule",
        exportOptionDescription: "Export selected schedule",
        shareLinkLabel: "Link",
        exportStarted: "Export has started",
        pickOption: "Select an option",
    },
    eventBlocksImport: "The import has completed successfully",
    shareSchedule: "Shared schedule link has been copied to your clipboard",
    visitSchedule: "Visit schedule",
} as const;
