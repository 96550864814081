import React, { useState } from "react";
import { styled, Body2, StyledFieldLabel, sectionalMargin } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { fileToBase64 } from "@iventis/utilities";

import { useQuery } from "@tanstack/react-query";
import { LinearProgress, TextField } from "@mui/material";
import { Asset } from "@iventis/domain-model/model/asset";
import { FormWizardTemplate } from "./form-wizard-template";
import { StyledFileUploadComponent } from "./upload-icon";
import { DisabledOverlay } from "./disabled-overlay";
import { ImageUploadPreviewResizer } from "./image-preview-resizer";
import { CopyableTextPreview } from "./copyable-text-preview";

export type UploadProjectImageData = {
    id: string;
    image: { fileName: string; imageUrl: string; height?: number; width?: number };
};

const defaultData: UploadProjectImageData = {
    id: undefined,
    image: { fileName: null, imageUrl: null },
};

export const UploadProjectImageForm: React.FunctionComponent<{
    onClose: () => void;
    existingId?: string;
    getExistingData?: (id: string) => Promise<UploadProjectImageData>;
    uploadRemotely: (data: UploadProjectImageData) => Promise<Asset>;
    setSelectedId?: (id: string) => void;
}> = ({ onClose, existingId, getExistingData, uploadRemotely, setSelectedId }) => {
    const translate = useIventisTranslate();

    const [state, setState] = useState<"idle" | "loading" | "error">("idle");

    const [data, setData] = useState<UploadProjectImageData>(defaultData);
    const [existingData, setExistingData] = useState<UploadProjectImageData>(undefined);

    const { isLoading, isFetching } = useQuery(["get-existing-project-image", existingId], () => (existingId ? getExistingData(existingId) : Promise.resolve(defaultData)), {
        onSuccess(data) {
            setData(data);
            if (existingId) {
                setExistingData(data);
            }
        },
    });

    const uploadProjectImage = async () => {
        setState("loading");
        let err = false;
        try {
            const res = await uploadRemotely(data);
            setSelectedId(res?.id);
        } catch (e) {
            err = true;
            setState("error");
        }
        if (!err) {
            onClose();
        }
    };

    const uploadProjectImageLocally = async (file: File) => {
        const image = document.createElement("img");
        const { withHeader } = await fileToBase64(file);
        image.src = withHeader;
        image.addEventListener("load", () => {
            setData({ ...data, image: { fileName: file.name, imageUrl: withHeader, width: image.naturalWidth, height: image.naturalHeight } });
            setState("idle");
            image.remove();
        });
    };

    const setImageDimensionsLocally = (value: { height: number; width: number }) => {
        setData({ ...data, image: { ...data.image, height: value.height, width: value.width } });
    };

    return (
        <FormWizardTemplate
            stages={[
                {
                    isValid: true,
                    primaryButtonText: translate("Confirm"),
                    primaryButtonCallback: uploadProjectImage,
                    secondaryButtons: [{ buttonText: translate("Cancel"), onButtonPressed: onClose }],
                },
            ]}
            isSubmitting={state === "loading"}
            currentStage={0}
            title="Upload a default project image"
        >
            {(isLoading || isFetching) && <LinearProgress style={{ position: "absolute", width: "100%", top: "0px", left: "0px" }} />}
            {(isLoading || isFetching) && <DisabledOverlay />}
            {existingId && (
                <div style={{ marginBottom: sectionalMargin, display: "flex", flexDirection: "column" }}>
                    <CopyableTextPreview fieldLabel="Image Id" text={existingId} />
                </div>
            )}
            {!existingId && (
                <div style={{ marginBottom: sectionalMargin, display: "flex", flexDirection: "column" }}>
                    <StyledFieldLabel id="name">Id</StyledFieldLabel>
                    <TextField type="text" aria-labelledby="name" name="name" variant="outlined" value={data.id || ""} onChange={(e) => setData({ ...data, id: e.target.value })} />
                </div>
            )}
            <StyledFileUploadComponent
                removeRequested={() => null}
                loading={false}
                fileName={data?.image.fileName}
                fileThumbnailUrl={data?.image.imageUrl}
                uploadFile={uploadProjectImageLocally}
                uploadButtonText={translate("Select file")}
                removeImageText={null}
                inputAccept="image/*"
                className="file-upload"
                persistFileSelectInput
                maxFileSize={2}
            />

            <StyledFieldLabel id="name">{translate("Image size")}</StyledFieldLabel>
            <StyledImageResizer>
                <ImageUploadPreviewResizer
                    fileThumbnailUrl={data?.image?.imageUrl}
                    height={data?.image?.height || 1920}
                    width={data?.image?.width || 1080}
                    maxHeight={1920}
                    maxWidth={1080}
                    disabled={data?.image.imageUrl == null || existingData?.image.imageUrl === data?.image.imageUrl}
                    updateDimensions={setImageDimensionsLocally}
                    displayHeight={360}
                    displayWidth={480}
                />
            </StyledImageResizer>

            {state === "error" && (
                <>
                    <div role="presentation" style={{ height: sectionalMargin, margin: "auto" }} />
                    <Body2 style={{ color: "red" }}>{translate("Something went wrong")}</Body2>
                </>
            )}
        </FormWizardTemplate>
    );
};

const StyledImageResizer = styled.div`
    margin-bottom: ${sectionalMargin};
`;
