import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";

export function createStaticStyleValue<T>(styleValue: T): StyleValue<T> {
    return {
        extractionMethod: StyleValueExtractionMethod.Static,
        dataFieldId: undefined,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue: styleValue,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        multiValues: [],
        mappedValues: {},
    };
}

export function createMultiStyleValue<T>(staticValue: T): StyleValue<T> {
    return {
        extractionMethod: StyleValueExtractionMethod.Multi,
        dataFieldId: undefined,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        multiValues: [],
        mappedValues: {},
    };
}

export function createLiteralStyleValue<T>(staticValue: T, dataFieldId: string): StyleValue<T> {
    return {
        extractionMethod: StyleValueExtractionMethod.Literal,
        dataFieldId,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        mappedValues: {},
        multiValues: [],
    };
}

export function getStaticStyleValue<T>(styleValue: StyleValue<T>) {
    if (styleValue == null) {
        return null;
    }

    if (styleValue.extractionMethod === StyleValueExtractionMethod.Static) {
        return styleValue.staticValue.staticValue;
    }

    throw new Error(`Extraction of style value method "${styleValue.extractionMethod}" not yet implemented`);
}

export function getStaticStyleValueFromMapped<T>(styleValue: StyleValue<T>) {
    if (styleValue == null) {
        return null;
    }

    if (styleValue.extractionMethod === StyleValueExtractionMethod.Static) {
        return styleValue.staticValue.staticValue;
    }

    if (styleValue.extractionMethod === StyleValueExtractionMethod.Mapped) {
        return styleValue.staticValue.staticValue;
    }

    throw new Error(`Extraction of style value method "${styleValue.extractionMethod}" not yet implemented`);
}

export function isStaticStyleDefaultValue<T>(styleValue: StyleValue<T>, defaultValue: T) {
    if (styleValue.extractionMethod !== StyleValueExtractionMethod.Static) {
        return false;
    }

    return defaultValue === getStaticStyleValue(styleValue);
}

export function createMappedStyleValue<T>(staticValue: T, mappedValues: Record<string, T>, dataFieldId?: string): StyleValue<T> {
    return {
        extractionMethod: StyleValueExtractionMethod.Mapped,
        dataFieldId,
        staticValue: {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        multiValues: [],
        mappedValues: Object.entries(mappedValues).reduce(
            (cum, [key, value]) => ({
                ...cum,
                [key]: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    staticValue: value,
                    mappedZoomValues: {},
                    unitType: Units.None,
                },
            }),
            {}
        ),
    };
}
