import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import { AnySupportedGeometry, LocalGeoJson } from "@iventis/map-types";
import { createDefaultLocalGeoJsonObject, createPreviewObjectId, createPreviewObjectName } from "./preview-helper-functions";

export function createLinePreviewSourceData(lineStyle: LineStyle, snapshotGeometry: boolean): LocalGeoJson {
    const isDataDriven = lineStyle.colour?.extractionMethod === StyleValueExtractionMethod.Mapped;
    if (isDataDriven) {
        return createLineDataDrivenLocalGeoJson(lineStyle, snapshotGeometry);
    }
    return createLineLocalGeoJson(snapshotGeometry);
}

function createLineDataDrivenLocalGeoJson(lineStyle: LineStyle, snapshot: boolean) {
    const maxNumberOfFeaturesAllowed = 4;

    // Get the possible colour key values
    const colourKeyValues =
        lineStyle.colour.mappedValues != null
            ? Object.entries(lineStyle.colour.mappedValues)
                  .map((keyValuePair) => keyValuePair[0])
                  .slice(0, maxNumberOfFeaturesAllowed) // Slice to the max number of features allowed.
            : [];

    // If the total number of data driven colour key values is less than the max number of features allowed, add 1 to allow for the default colour when no data field is selected.
    const numberOfFeatures = colourKeyValues.length < maxNumberOfFeaturesAllowed ? colourKeyValues.length + 1 : colourKeyValues.length;

    // Create an array of data driven objects with the data driven property assigned
    const dataDrivenLocalObjects = colourKeyValues.map((colourKey, index) => {
        const geometry = snapshot ? createLineDataDrivenSnapshotGeometry(index + 1, numberOfFeatures) : createLineDataDrivenPreviewGeometry(index + 1, numberOfFeatures);
        return createDefaultLocalGeoJsonObject(createPreviewObjectId(index), createPreviewObjectName(index), previewLayerId, 0, geometry, {
            [lineStyle.colour.dataFieldId]: colourKey,
        });
    });

    // If the number of features is more than colour key values, then we include the default colour feature. This just has no data field properties assigned.
    if (numberOfFeatures > colourKeyValues.length) {
        const geometry = snapshot
            ? createLineDataDrivenSnapshotGeometry(numberOfFeatures, numberOfFeatures)
            : createLineDataDrivenPreviewGeometry(numberOfFeatures, numberOfFeatures);
        const localObject = createDefaultLocalGeoJsonObject(createPreviewObjectId(numberOfFeatures), createPreviewObjectName(numberOfFeatures), previewLayerId, 0, geometry);
        dataDrivenLocalObjects.push(localObject);
    }

    return {
        [previewLayerId]: dataDrivenLocalObjects,
    };
}

function createLineLocalGeoJson(snapshot: boolean) {
    const geom = snapshot ? createLinePreviewSnapshotGeometry() : createLinePreviewGeometry();
    const localObject = createDefaultLocalGeoJsonObject("PreviewObject", "Object 1", previewLayerId, 0, geom);
    return {
        [previewLayerId]: [localObject],
    };
}

export function createLinePreviewSnapshotGeometry(): AnySupportedGeometry {
    return {
        type: "LineString",
        coordinates: [
            [-0.003, 0],
            [0.003, 0],
        ],
    };
}

export function createLinePreviewGeometry(): AnySupportedGeometry {
    return {
        type: "LineString",
        coordinates: [
            [0.001, 0.001],
            [-0.001, 0.001],
            [0.0003, 0],
            [-0.001, -0.001],
            [0.001, -0.001],
        ],
    };
}

export function createLineDataDrivenSnapshotGeometry(featureNo: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createLinePreviewSnapshotGeometry();
        case 2:
            return TwoLineDataDrivenPreviewSnapshotGeometries[featureNo - 1];
        case 3:
            return ThreeLineDataDrivenPreviewSnapshotGeometries[featureNo - 1];
        case 4:
            return FourLineDataDrivenPreviewSnapshotGeometries[featureNo - 1];
        default:
            throw new Error("Total Features in snapshot must be 1-4");
    }
}

export function createLineDataDrivenPreviewGeometry(featureNo: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createLinePreviewGeometry();
        case 2:
            return TwoLineDataDrivenPreviewGeometries[featureNo - 1];
        case 3:
            return ThreeLineDataDrivenPreviewGeometries[featureNo - 1];
        case 4:
            return FourLineDataDrivenPreviewGeometries[featureNo - 1];
        default:
            throw new Error("Total Features in preview must be 1-4");
    }
}

const TwoLineDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.0005],
            [0.003, -0.0005],
        ],
    },
    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.0005],
            [0.003, 0.0005],
        ],
    },
];

const ThreeLineDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.00066],
            [0.003, -0.00066],
        ],
    },
    {
        type: "LineString",
        coordinates: [
            [-0.003, 0],
            [0.003, 0],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.00066],
            [0.003, 0.00066],
        ],
    },
];

const FourLineDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.001],
            [0.003, -0.001],
        ],
    },
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.00033],
            [0.003, -0.00033],
        ],
    },
    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.00033],
            [0.003, 0.00033],
        ],
    },
    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.001],
            [0.003, 0.001],
        ],
    },
];

const TwoLineDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.002],
            [0.003, -0.002],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.002],
            [0.003, 0.002],
        ],
    },
];

const ThreeLineDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.003],
            [0.003, -0.003],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0],
            [0.003, 0],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.003],
            [0.003, 0.003],
        ],
    },
];

const FourLineDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.003],
            [0.003, -0.003],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, -0.001],
            [0.003, -0.001],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.001],
            [0.003, 0.001],
        ],
    },

    {
        type: "LineString",
        coordinates: [
            [-0.003, 0.003],
            [0.003, 0.003],
        ],
    },
];
