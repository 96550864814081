import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { OutlineStyle } from "@iventis/domain-model/model/outlineStyle";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { Content } from "@iventis/translations/content/typed-content";
import React from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { colourSelectorComponentCreator, definedValueSelectorComponentCreator, incrementalValueSelectorComponentCreator, sliderWithTextSelectorCreator } from "@iventis/components";

import { EditStyleItemComponent } from "./edit-style-item";
import { EditStyleHeaderIcon } from "./area-edit-style";
import { ChangeStyleValue } from "./edit-style.helpers";
import { StyledStyleItemContainer } from "./line-edit-style";
import StyleContainer from "./style-container";

type StylesWithOutline = AreaStyle | LineStyle;

interface EditOutlineStyleProps {
    layerStyle: OutlineStyle & { styleType: StylesWithOutline["styleType"] };
    changeStyleValue: ChangeStyleValue<StylesWithOutline>;
}

const EditOutlineStyle = ({ layerStyle, changeStyleValue }: EditOutlineStyleProps) => {
    const translate = useIventisTranslate();
    return (
        <StyleContainer
            title={translate(Content.map2.styles.outline)}
            icon={<EditStyleHeaderIcon id="header-icon" icon={["far", "circle"]} />}
            defaultOpen={false}
            testId="outline"
        >
            <StyledStyleItemContainer>
                {/* OUTLINE ON/OFF */}
                <EditStyleItemComponent
                    styleProperty="outline"
                    component={definedValueSelectorComponentCreator<boolean>({
                        optionalValues: [
                            { value: true, label: translate(Content.map2.styles.outline) },
                            { value: false, label: translate(Content.common.none), testId: "outline-none" },
                        ],
                    })}
                    value={layerStyle.outline ?? getDefaultStyleProperty(layerStyle.styleType, "outline")}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.outline)}
                />
                {/* OUTLINE COLOUR */}
                <EditStyleItemComponent
                    styleProperty="outlineColour"
                    component={colourSelectorComponentCreator({ testId: "layer-style-outline-colour-selector" })}
                    value={layerStyle.outlineColour ?? getDefaultStyleProperty(layerStyle.styleType, "outlineColour")}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.outline_colour)}
                    disabled={!getStaticStyleValue(layerStyle.outline)}
                />
                {/* OUTLINE OPACITY */}
                <EditStyleItemComponent
                    styleProperty="outlineOpacity"
                    value={layerStyle.outlineOpacity ?? getDefaultStyleProperty(layerStyle.styleType, "outlineOpacity")}
                    component={sliderWithTextSelectorCreator({
                        minValue: 0,
                        maxValue: 1,
                        increment: 0.05,
                        colour: getStaticStyleValue(layerStyle.outlineColour) ?? getStaticStyleValue(getDefaultStyleProperty(layerStyle.styleType, "outlineColour")),
                    })}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.outline_opacity)}
                    isZoomableValue={false}
                    disabled={!getStaticStyleValue(layerStyle.outline)}
                />
                {/* OUTLINE WIDTH */}
                <EditStyleItemComponent
                    styleProperty="outlineWidth"
                    value={layerStyle.outlineWidth ?? getDefaultStyleProperty(layerStyle.styleType, "outlineWidth")}
                    component={incrementalValueSelectorComponentCreator({
                        minValue: 0,
                        maxValue: 10,
                        increment: 1,
                        units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                        selectedUnitId: "px",
                        testId: "layer-style-outline-width",
                    })}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.outline_width)}
                    disabled={!getStaticStyleValue(layerStyle.outline)}
                />
                {/* OUTLINE BLUR */}
                <EditStyleItemComponent
                    styleProperty="outlineBlur"
                    value={layerStyle.outlineBlur ?? getDefaultStyleProperty(layerStyle.styleType, "outlineBlur")}
                    component={incrementalValueSelectorComponentCreator({
                        minValue: 0,
                        maxValue: 100,
                        increment: 1,
                        units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                        selectedUnitId: "px",
                    })}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.outline_blur)}
                    isZoomableValue={false}
                    disabled={!getStaticStyleValue(layerStyle.outline)}
                />
            </StyledStyleItemContainer>
        </StyleContainer>
    );
};

export default EditOutlineStyle;
