import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";

/** Checks if a style value which is determined by zoom level is being used and if it is valid */
export function isValidZoomableStyleValue<TStyleValue>(styleValue: StyleValue<TStyleValue>): boolean {
    if (styleValue == null) {
        return false;
    }

    if (!isUsingZoomableStyleValue(styleValue)) {
        return false;
    }

    return styleValue.staticValue?.mappedZoomValues != null ? Object.keys(styleValue.staticValue?.mappedZoomValues).length > 0 : false;
}

/** Checks if a style value is determined by zoom level */
export function isUsingZoomableStyleValue<TStyleValue>(styleValue: StyleValue<TStyleValue>): boolean {
    if (styleValue == null) {
        return false;
    }

    if (styleValue.extractionMethod !== StyleValueExtractionMethod.Static) {
        return false;
    }

    return styleValue.staticValue?.extractionMethod !== ZoomableValueExtractionMethod.Static;
}
