/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { inlineTextIconMargin } from "@iventis/styles/src/atomic-rules";
import React, { FunctionComponent, useMemo, useRef, useState, ReactNode, PropsWithChildren } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Content } from "@iventis/translations/content/typed-content";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { AdditionalDataFieldProperties } from "@iventis/types";
import { css, Theme } from "@emotion/react";
import { styled } from "@iventis/styles";
import { StyledChip, ChipType } from "@iventis/components/src/chips";
import { DragAndDropSortable, SortableListType, useDragAndDropSortable, DropZoneFill, SortableDragAndDropProps, useDragAndDropReordering } from "@iventis/drag-and-drop";
import { closestCenter } from "@iventis/drag-and-drop/src/drag-and-drop-helpers";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { Toasts } from "@iventis/toasts/src/toast.types";
import { dataFieldTypes } from "./data-fields-types.constants";
import { DataFieldModal } from "./data-field-modal";
import { AdditionalAttributeDataFormComponent } from "./attribute-editor";
import { useDataFieldConfig, useDataFieldServices } from "./data-fields-services";

export const getProjectDataFieldsQueryKey = "projectDataFields";

export interface DataFieldsChipsProps {
    className?: string;
    dataFields: AdditionalDataFieldProperties[];
    getProjectDataFields: () => Promise<DataField[]>;
    AdditionalAttributeDataForm?: AdditionalAttributeDataFormComponent;
    allowEdit?: boolean;
    allowCreate?: boolean;
    showExistingChips?: boolean;
    showListItems?: boolean;
    nonEditableDataFieldIds?: string[];
    allowDeletion?: boolean;
    isProjectAttributesOnly?: boolean;
    filteredDataFieldTypes?: DataFieldType[];
    navigateToProjectSettings?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
    onDragEnd?: (dataFields: DataField[]) => void;
    layerLoaded?: boolean;
    disableAddButton?: boolean;
    addButtonTestId?: string;
}

export const DataFieldsChips: React.FC<DataFieldsChipsProps> = ({
    dataFields: unorderedDataFields = [],
    className,
    getProjectDataFields,
    AdditionalAttributeDataForm,
    allowEdit = true,
    allowCreate = true,
    showListItems = true,
    nonEditableDataFieldIds = [],
    allowDeletion = false,
    isProjectAttributesOnly = false,
    showExistingChips = true,
    filteredDataFieldTypes,
    navigateToProjectSettings,
    onOpen,
    onClose,
    onDragEnd: onDragEndFinished,
    layerLoaded = true,
    disableAddButton = false,
    addButtonTestId,
}) => {
    const [editDataFieldId, setEditDataFieldId] = useState<string | null>();
    const [editDataFieldModal, setEditDataFieldModal] = useState(false);

    const { data: projectDataFields } = useQuery([getProjectDataFieldsQueryKey], getProjectDataFields);
    const { resourceId } = useDataFieldConfig();
    const { dataFieldsService } = useDataFieldServices();

    const translate = useIventisTranslate();

    const onDataFieldAdded = (dataField: AdditionalDataFieldProperties) => {
        setEditDataFieldId(dataField.id);
    };

    // Sorts the project datafields into which ones can be removed
    const removableProjectDataFields = useMemo(
        () =>
            (projectDataFields ?? []).reduce<DataField[]>((removableDataFields, dataField) => {
                if (dataField.systemDataFieldName == null) {
                    return [...removableDataFields, dataField];
                }
                return [...removableDataFields];
            }, []),
        [projectDataFields]
    );

    const { onDragEvent, sortedItems: dataFields } = useDragAndDropReordering(unorderedDataFields, (df) => df.order);

    const isDataFieldEditable = (id: string) => {
        // If it is non editable or a system datafield, we cannot edit
        if (nonEditableDataFieldIds.includes(id) || dataFields.find((df) => df.id === id)?.systemDataFieldName) {
            return false;
        }
        const existingProjectDataField = projectDataFields?.find((pdf) => pdf.id === id);
        // If it is a project attr and the resourceId is the project, that means we're editing from the project attr editor so we can edit
        if (existingProjectDataField && resourceId === existingProjectDataField.projectId) {
            return true;
        }
        // Otherwise we're opening a linked datafield, so prevent editing
        if (existingProjectDataField) {
            return false;
        }
        return true;
    };

    const { mutateAsync: updateDataFieldsOrder, variables: dataFieldsUpdating, isLoading: isUpdatingDataFields } = useMutation({
        mutationFn: async (newlyOrderedDataFields: DataField[]) => {
            for (let i = 0; i < newlyOrderedDataFields.length; i += 1) {
                const dataField = { ...newlyOrderedDataFields[i], order: i + 1 };
                if (dataField?.id != null && dataFields.find((df) => df.id === dataField.id).order !== i + 1) {
                    await dataFieldsService.putDataField(dataField, resourceId);
                }
            }
        },
    });

    const onDragEnd: SortableDragAndDropProps<DataField>["onDragEnd"] = async (itemsBeingDragged, over) => {
        onDragEvent(itemsBeingDragged, over);
        const itemOverIndex = dataFields.findIndex((item) => item.id === over.id);
        const itemIndex = dataFields.findIndex((item) => itemsBeingDragged[0].id === item.id);

        // Splice the array to move the new item to the correct position
        const newlyOrderedDataFields = [...dataFields];
        newlyOrderedDataFields.splice(itemOverIndex, 0, newlyOrderedDataFields.splice(itemIndex, 1)[0]);
        updateDataFieldsOrder(newlyOrderedDataFields.map((df, index) => (df.order === index + 1 ? undefined : df)));
        onDragEndFinished?.(newlyOrderedDataFields);
    };

    const dropZoneContainerRef = useRef<HTMLDivElement>(undefined);

    return (
        <StyledChipsContainer className={className} ref={dropZoneContainerRef}>
            {showExistingChips && (
                <DragAndDropSortable<DataField>
                    items={dataFields}
                    listType={SortableListType.NORMAL}
                    getItems={(id) => [dataFields.find((df) => df.id === id)]}
                    calculateNewParent={(id: string) => dataFields.find((df) => df.id === id)}
                    findBottomItem={(dataField) => dataField?.id}
                    onDragStart={() => {}}
                    onDragEnd={onDragEnd}
                    idSuffixes={["bottom"]}
                    collisionDetection={closestCenter}
                    ghostModifiers={[]}
                    createOverlayComponent={([df]) => {
                        const icon = dataFieldTypes.find(({ type }) => type === df.type)?.icon;
                        return (
                            <StyledGhostChip
                                label={
                                    <>
                                        {Array.isArray(icon) ? <StyledIcon icon={icon} /> : <StyledCustomIcon>{icon as ReactNode}</StyledCustomIcon>}
                                        {df.name}
                                    </>
                                }
                                chipType={isDataFieldEditable(df.id) ? ChipType.HighlightedExisting : ChipType.BasicExisting}
                                onClick={() => null}
                                data-testid="attribute-chip-ghost"
                            />
                        );
                    }}
                >
                    {dataFields.map((dataField, index) => (
                        <React.Fragment key={`${dataField.id}-${dataField.name}`}>
                            <DataFieldChip
                                dataField={dataField}
                                updating={isUpdatingDataFields && dataFieldsUpdating?.some((df) => dataField.id === df?.id)}
                                onClick={() => {
                                    if (allowEdit && dataField?.systemDataFieldName == null) {
                                        setEditDataFieldId(dataField.id);
                                        setEditDataFieldModal(true);
                                    }
                                }}
                                canEdit={isDataFieldEditable(dataField.id)}
                                canMove={allowEdit}
                            />
                            {index === dataFields.length - 1 && (
                                <StyledDropZone key={`${dataField.id}bottom`} className="drop-zone-fill" id={`${dataField.id}bottom`} containerElementRef={dropZoneContainerRef}>
                                    <OverIndicator $placement="before" />
                                </StyledDropZone>
                            )}
                        </React.Fragment>
                    ))}
                </DragAndDropSortable>
            )}
            {allowCreate && (
                <StyledChip
                    label={
                        <>
                            <AddAttributeIcon icon={["far", "plus"]} /> {translate(Content.map.data_fields.add)}
                        </>
                    }
                    chipType={ChipType.PrimaryButton}
                    onClick={() => {
                        setEditDataFieldId(null);
                        setEditDataFieldModal(true);
                        onOpen?.();
                    }}
                    className="add-attribute-button"
                    disabled={disableAddButton}
                    dataTestId={addButtonTestId ?? "add-attribute-button"}
                />
            )}
            <DataFieldModal
                open={allowCreate && editDataFieldModal && layerLoaded}
                onClose={() => {
                    setEditDataFieldModal(false);
                    onClose?.();
                }}
                existingDataField={dataFields.find((d) => d.id === editDataFieldId) ?? ({} as DataField)}
                onDataFieldAdded={onDataFieldAdded}
                close={() => {
                    setEditDataFieldModal(false);
                    onClose?.();
                }}
                AdditionalAttributeDataForm={AdditionalAttributeDataForm}
                showListItems={showListItems}
                allowDeletion={allowDeletion}
                createableProjectDataFields={removableProjectDataFields}
                isProjectAttributesOnly={isProjectAttributesOnly}
                navigateToProjectSettings={navigateToProjectSettings}
                allowEditing={allowEdit && isDataFieldEditable(editDataFieldId)}
                filterDataFieldTypes={filteredDataFieldTypes}
            />
        </StyledChipsContainer>
    );
};

const DataFieldChip: FunctionComponent<{ dataField: DataField; updating?: boolean; canEdit: boolean; canMove: boolean; className?: string; onClick: () => void }> = ({
    dataField,
    canEdit,
    canMove,
    onClick,
    updating,
    className,
}) => {
    const { setRefs, attributes, listeners, isOver, placement } = useDragAndDropSortable(dataField.id, canMove);
    const icon = dataFieldTypes.find(({ type }) => type === dataField.type)?.icon;
    return (
        <StyledChipContainer
            className={className}
            $updating={updating}
            data-testid={`attribute-chip-container-${dataField.name}`.replaceAll(" ", "")}
            key={dataField.id}
            ref={setRefs}
            {...attributes}
            {...listeners}
        >
            {isOver && placement !== "none" && <OverIndicator data-testid="datafield-placement-indicator" $placement={placement} />}
            <StyledChip
                dataTestId={`data-field-chip-${dataField.name}`.replaceAll(" ", "")}
                label={
                    <>
                        {Array.isArray(icon) ? <StyledIcon icon={icon} /> : <StyledCustomIcon>{icon as ReactNode}</StyledCustomIcon>}
                        {dataField.name}
                    </>
                }
                onClick={onClick}
                chipType={!canEdit ? ChipType.BasicExisting : ChipType.HighlightedExisting}
                className="attribute-chip"
            />
        </StyledChipContainer>
    );
};

const StyledIcon = styled(FontAwesomeIcon)`
    margin-right: ${inlineTextIconMargin};
`;

const AddAttributeIcon = styled(FontAwesomeIcon)`
    margin-right: 5px;
`;

const StyledCustomIcon = styled.div`
    margin-right: ${inlineTextIconMargin};
`;

const StyledGhostChip = styled(StyledChip)`
    opacity: 0.5;
`;

const StyledChipContainer = styled.div<{ $updating: boolean }>`
    position: relative;
    opacity: ${(props) => (props.$updating ? 0.8 : 1)};
`;

const OverIndicator = styled.div<{ $placement: ReturnType<typeof useDragAndDropSortable>["placement"] }>`
    width: 2px;
    background-color: ${(props: { theme: Theme }) => props.theme.tertiaryColors.primary};
    height: 100%;
    position: absolute;
    ${(props) =>
        props.$placement === "before"
            ? css`
                  left: -3.5px;
              `
            : props.$placement === "after"
            ? css`
                  right: -3.5px;
              `
            : ""}
`;

const StyledChipsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .MuiChip-labelMedium {
        display: flex;
        align-items: center;
    }
`;

const StyledDropZone = styled(DropZoneFill)`
    width: 2px;
    height: 32px;
    position: relative;
`;

// Mimics the add attribute button
export const ChipsErrorBoundary: React.FC<PropsWithChildren<{ toast: Toasts }>> = ({ children, toast }) => (
    <StyledErrorBoundary toast={toast} size="overlay">
        {children}
    </StyledErrorBoundary>
);

export const StyledErrorBoundary = styled(IventisErrorBoundaryWithToast)`
    width: 115px;
    height: 32px;
    border-radius: 16px;
`;
