import { PointPitchAlignment } from "@iventis/domain-model/model/pointPitchAlignment";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { Content } from "@iventis/translations/content/typed-content";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { createStaticStyleValue, getStaticStyleValue } from "@iventis/layer-style-helpers";
import React, { FunctionComponent } from "react";
import {
    colourSelectorComponentCreator,
    ColourSelectorFormulaPreview,
    definedValueSelectorComponentCreator,
    incrementalValueSelectorComponentCreator,
    sliderWithTextSelectorCreator,
} from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { StyleType } from "@iventis/domain-model/model/styleType";
import CollapsedStyleItemComponent from "./collapsed-style-item";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { EditStyleHeaderIcon } from "./area-edit-style";
import { StyledStyleItemContainer } from "./line-edit-style";
import { EditStyleProps } from "./style.types";
import EditTextStyle from "./text-edit-style";
import { PitchAlignmentInfo } from "./pitch-alignment-info";

export const PointEditStyleComponent: FunctionComponent<EditStyleProps<PointStyle>> = ({ layerStyle, changeStyleValue, dataFields }) => {
    const translate = useIventisTranslate();

    const enableBlur = (enable: boolean) => {
        if (enable) {
            changeStyleValue("blur", createStaticStyleValue(0.5));
        } else {
            changeStyleValue("blur", createStaticStyleValue(0));
        }
    };

    const outlineIsEnabled = getStaticStyleValue(layerStyle.outline);

    const blurIsEnabled = () => getStaticStyleValue(layerStyle.blur) !== 0;

    return (
        <>
            <StyleContainer title={translate(Content.map2.styles.point_fill)} icon={<EditStyleHeaderIcon id="header-icon" icon={["fas", "circle"]} />}>
                <StyledStyleItemContainer>
                    {/* COLOUR */}
                    <EditStyleItemComponent
                        styleProperty="colour"
                        value={layerStyle.colour || getDefaultStyleProperty(layerStyle.styleType, "colour")}
                        changeStyleValue={changeStyleValue}
                        component={colourSelectorComponentCreator()}
                        PreviewComponent={ColourSelectorFormulaPreview}
                        title={translate(Content.map2.styles.colour)}
                        showDataDriven
                        dataFields={dataFields}
                    />
                    {/* OPACITY SLIDER */}
                    <EditStyleItemComponent
                        styleProperty="opacity"
                        value={layerStyle.opacity || getDefaultStyleProperty(layerStyle.styleType, "opacity")}
                        component={sliderWithTextSelectorCreator({
                            minValue: 0,
                            maxValue: 1,
                            increment: 0.05,
                            colour: layerStyle.colour.staticValue.staticValue,
                        })}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.opacity)}
                        isZoomableValue={false}
                    />
                    {/* WIDTH INCREMENTOR */}
                    <EditStyleItemComponent
                        styleProperty="radius"
                        value={layerStyle.radius || getDefaultStyleProperty(layerStyle.styleType, "radius")}
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 1,
                            maxValue: 100,
                            increment: 1,
                            units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                            selectedUnitId: "px",
                            dataCy: "circle-size",
                        })}
                        title={translate(Content.map2.styles.radius)}
                        showHiddenOptions
                    />
                    {blurIsEnabled() && (
                        <EditStyleItemComponent
                            styleProperty="blur"
                            component={incrementalValueSelectorComponentCreator({
                                minValue: 0.05,
                                maxValue: 1,
                                increment: 0.05,
                                units: [{ id: "scale", name: "x" }],
                                selectedUnitId: "scale",
                                decimals: 2,
                            })}
                            value={layerStyle.blur || getDefaultStyleProperty(layerStyle.styleType, "blur")}
                            changeStyleValue={changeStyleValue}
                            title={translate(Content.map2.styles.blur)}
                            emitToggleChange={() => enableBlur(false)}
                            showToggle
                            isZoomableValue={false}
                        />
                    )}
                    {/* UNTOGGLED STYLE ITEMS */}
                    <div style={{ display: "flex" }}>
                        {!blurIsEnabled() && <CollapsedStyleItemComponent emitCheckboxChange={() => enableBlur(true)} name={translate(Content.map2.styles.blur)} />}
                    </div>
                </StyledStyleItemContainer>
            </StyleContainer>
            <StyleContainer title={translate(Content.map2.styles.outline)} icon={<EditStyleHeaderIcon id="header-icon" icon={["far", "circle"]} />} defaultOpen={false}>
                <StyledStyleItemContainer>
                    {/* OUTLINE COLOUR AND TOGGLE */}
                    <EditStyleItemComponent
                        styleProperty="outline"
                        component={definedValueSelectorComponentCreator<boolean>({
                            optionalValues: [
                                { value: true, label: translate(Content.map2.styles.outline) },
                                { value: false, label: translate(Content.common.none) },
                            ],
                        })}
                        value={layerStyle.outline ?? getDefaultStyleProperty(layerStyle.styleType, "outline")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.outline)}
                    />
                    <EditStyleItemComponent
                        styleProperty="outlineColour"
                        value={layerStyle.outlineColour || getDefaultStyleProperty(layerStyle.styleType, "outlineColour")}
                        changeStyleValue={changeStyleValue}
                        component={colourSelectorComponentCreator()}
                        title={translate(Content.map2.styles.colour)}
                        disabled={!outlineIsEnabled}
                    />
                    {/* WIDTH INCREMENTOR */}
                    <EditStyleItemComponent
                        styleProperty="outlineWidth"
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 1,
                            maxValue: 20,
                            increment: 1,
                            units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                            selectedUnitId: "px",
                        })}
                        value={layerStyle.outlineWidth || getDefaultStyleProperty(layerStyle.styleType, "outlineWidth")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.width)}
                        disabled={!outlineIsEnabled}
                    />
                    {/* OPACITY SLIDER */}
                    <EditStyleItemComponent
                        styleProperty="outlineOpacity"
                        value={layerStyle.outlineOpacity || getDefaultStyleProperty(layerStyle.styleType, "outlineOpacity")}
                        changeStyleValue={changeStyleValue}
                        component={sliderWithTextSelectorCreator({
                            minValue: 0,
                            maxValue: 1,
                            increment: 0.05,
                            colour: layerStyle.outlineColour.staticValue.staticValue, // ToDo: evaluate this based on data field
                        })}
                        title={translate(Content.map2.styles.opacity)}
                        isZoomableValue={false}
                        disabled={!outlineIsEnabled}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <StyleContainer title={translate(Content.map2.styles.pitch_alignment)} icon={<EditStyleHeaderIcon id="header-icon" icon={["fas", "database"]} />} defaultOpen={false}>
                <StyledStyleItemContainer>
                    {/* Pitch Alignment */}
                    <EditStyleItemComponent
                        title={translate(Content.map2.styles.placement)}
                        infoTitle={translate(Content.map2.styles.pitch_alignment)}
                        infoContent={<PitchAlignmentInfo styleType={StyleType.Point} />}
                        styleProperty="pitchAlignment"
                        value={layerStyle.pitchAlignment || getDefaultStyleProperty(layerStyle.styleType, "pitchAlignment")}
                        changeStyleValue={changeStyleValue}
                        component={definedValueSelectorComponentCreator({
                            optionalValues: [
                                { value: PointPitchAlignment.Map, label: translate(Content.map2.styles.pitch_alignment_map) },
                                { value: PointPitchAlignment.Viewport, label: translate(Content.map2.styles.pitch_alignment_viewport) },
                            ],
                        })}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <EditTextStyle layerStyle={layerStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
        </>
    );
};
