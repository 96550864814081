import React, { BaseSyntheticEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ICellEditor } from "@ag-grid-community/core";
import { Input } from "@mui/material";
import { narrowPadding, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { sanitiseForDOMToken } from "@iventis/utilities";

import { IventisCellEditorParams, IventisTableData } from "../types/data-table.types";
import { createInitialState } from "../lib/grid.helpers";

/**
 * Used as cellEditor for data table column of type "text"
 */
export const TextCellEditor = forwardRef(_TextCellEditor);

// eslint-disable-next-line no-underscore-dangle
function _TextCellEditor<TData extends IventisTableData = IventisTableData>(props: IventisCellEditorParams<TData>, ref: React.ForwardedRef<ICellEditor>) {
    const { node, column } = props;
    const initialState = createInitialState(props);
    const [value, setValue] = useState<string>(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
    const refInput = useRef(null);

    // Why is this field always mandatory??
    const isInvalid = value?.length === 0;

    // focus on the input
    useEffect(() => {
        // get ref from React component
        const eInput = refInput.current;
        eInput.focus();
        if (eInput.value?.length > 0) {
            if (highlightAllOnFocus) {
                eInput.select?.();

                setHighlightAllOnFocus(false);
            } else {
                // when we started editing, we want the caret at the end, not the start.
                // this comes into play in two scenarios:
                //   a) when user hits F2
                //   b) when user hits a printable character
                const { length } = eInput.value;
                if (length > 0) {
                    eInput.setSelectionRange(length, length);
                }
            }
        }
    }, []);

    useImperativeHandle(ref, () => ({
        getValue() {
            props.api.forEachNode((n) => {
                if (n.data.id === node.data.id && node.id !== n.id) {
                    n.setDataValue(props.column.getColId(), value);
                }
            });
            return value;
        },
        isCancelBeforeStart() {
            return !node.data.canEdit;
        },
        isCancelAfterEnd() {
            return isInvalid || value === props.value;
        },
    }));

    const columnHeader = column.getColDef().headerName;

    return (
        <StyledInput
            type="text"
            inputProps={{ "data-testid": sanitiseForDOMToken(`data-table-Text-cell-editor-row-${node.data?.name ?? ""}-column-${columnHeader}`) }}
            ref={refInput}
            autoFocus
            value={value || ""}
            onChange={(event: BaseSyntheticEvent) => setValue(event.target.value)}
            error={isInvalid}
        />
    );
}

const StyledInput = styled(Input)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }: { theme: Theme }) => theme.dataTableTheme.background};
    padding-left: ${narrowPadding};
`;
