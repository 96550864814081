/* eslint-disable no-param-reassign */
import { DataField } from "@iventis/domain-model/model/dataField";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { createMapFromArray } from "@iventis/utilities";
import { LocalGeoJson, SelectedMapObject } from "@iventis/map-types";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { getStaticStyleValueForMapObject } from "@iventis/layer-style-helpers";
import { Feature } from "geojson";
import length from "@turf/length";
import { getMapObjectQuantityOfModelsOnLineSystemDataField } from "./system-datafield-helpers";

/**
 * Updates the quantity value for line model layers which have data-driven spacing style value
 */
export function updateMapObjectsQuantityValue(layers: MapLayer[], mapObjects: MapObject[], projectDataFields: DataField[]) {
    const layersMap = createMapFromArray(layers, "id");
    // Get the data field for the quantity value
    const quantityDataField = getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields);

    if (quantityDataField == null) {
        return mapObjects;
    }

    return mapObjects.map((mapObject) => {
        const layer = layersMap.get(mapObject.layerId);
        // Check if the layer is a line model layer and the spacing is data-driven
        if (layer != null && layer.styleType === StyleType.LineModel && layer.lineModelStyle.spacing.extractionMethod === StyleValueExtractionMethod.Mapped) {
            // Update the quantity value
            const updatedQuantityValue = getQuantityOfModelsOnALine(
                { type: "Feature", properties: mapObject.dataFieldValues, geometry: mapObject.geoJsonFeature.geometry },
                layer.lineModelStyle
            );
            return {
                ...mapObject,
                dataFieldValues: { ...mapObject.dataFieldValues, [quantityDataField.id]: updatedQuantityValue },
                geoJsonFeature: { ...mapObject.geoJsonFeature, properties: { ...mapObject.geoJsonFeature.properties, [quantityDataField.id]: updatedQuantityValue } },
            };
        }
        return mapObject;
    });
}

/**
 * Checks if the quantity value is null for line model layers and if so updates it
 */
export function updatedQuantityDataFieldValueIfNull(objects: SelectedMapObject[], layers: MapLayer[], localGeojson: LocalGeoJson, projectDataFields: DataField[]) {
    const layerMap = createMapFromArray(layers, "id");
    const quantityDataField = getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields);

    if (quantityDataField == null) {
        return [];
    }

    const objectsToUpdate: { layerId: string; object: { mapObjectId: string; dataFieldValues: { [dataFieldId: string]: number } } }[] = [];

    objects.forEach((object) => {
        // Get layer map object belongs to
        const layer = layerMap.get(object.layerId);
        // Ensure it is a line model layer
        if (layer && layer.styleType === StyleType.LineModel) {
            // Get the geojson for the map object
            const foundGeojson = localGeojson[object.layerId]?.find((f) => f.objectId === object.objectId);
            if (foundGeojson != null && foundGeojson.feature.properties[quantityDataField.id] == null) {
                objectsToUpdate.push({
                    layerId: object.layerId,
                    object: { mapObjectId: object.objectId, dataFieldValues: { [quantityDataField.id]: getQuantityOfModelsOnALine(foundGeojson.feature, layer.lineModelStyle) } },
                });
            }
        }
    });
    return objectsToUpdate;
}

/** Works out the amount of models on a line */
export function getQuantityOfModelsOnALine(feature: Feature, lineModelStyle: LineModelStyle): number {
    const spacingValue = getStaticStyleValueForMapObject(lineModelStyle?.spacing, feature) ?? 0;
    const offsetValue = getStaticStyleValueForMapObject(lineModelStyle?.modelOffset, feature) ?? 0;

    const lengthOfMapObject = length(feature, { units: "meters" });
    const amountOfModels = Math.floor(lengthOfMapObject / spacingValue);

    if (offsetValue === 0) {
        // Add one for the model at the start of the line
        return amountOfModels + 1;
    }

    const spacingRemainder = lengthOfMapObject % spacingValue;
    // Only show last model if the last line chunk if half the length of spacing
    return spacingRemainder > spacingValue * 0.5 ? amountOfModels + 1 : amountOfModels;
}

/**
 * Checks if a datafield is used for the offset or spacing of a line model layer (style properties which can affect the quantity value)
 */
export function isQuantityDataFieldValueAffectedByDataField(layer: MapLayer, dataFieldId: string): boolean {
    if (layer.styleType !== StyleType.LineModel || dataFieldId == null) {
        return false;
    }

    const isOffsetAffectedByDataField =
        layer.lineModelStyle.modelOffset.extractionMethod === StyleValueExtractionMethod.Mapped && layer.lineModelStyle.modelOffset.dataFieldId === dataFieldId;
    const isSpacingAffectedByDataField =
        layer.lineModelStyle.spacing.extractionMethod === StyleValueExtractionMethod.Mapped && layer.lineModelStyle.spacing.dataFieldId === dataFieldId;

    return isOffsetAffectedByDataField || isSpacingAffectedByDataField;
}
