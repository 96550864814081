import { TitleWithIcon, inlineTextIconMargin, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent, useState } from "react";
import { getImageDimensions, megaBytesToBytes, resizeFileImage, resizeImageGivenMaxWidthAndHeight } from "@iventis/utilities";
import { DragDropFileUpload } from "./drag-drop-file-upload";
import { AspectRatio, UploadImageWithPreview } from "./upload-image-with-preview";
import { FormWizardTemplate, Stage } from "./form-wizard-template";

export interface UploadAndPreivewImageProps {
    imageUrl?: string;
    imageName?: string;
    onFileUpload: (file: File) => void;
    onClose: () => void;
    onTooLargeFile?: () => void;
    saving?: boolean;
    topText: string;
    maxFileSize?: number;
    aspectRatioLimit?: AspectRatio;
    onSave?: () => void;
    canSave?: boolean;
}

/** Maximum size of image after it has been resized */
const MAX_FILE_SIZE_AFTER_RESIZING = 4;

/** Maximum width of image, once it has been resized */
const MAX_WIDTH_AFTER_RESIZING = 1920;

/** Maximum height of image, once it has been resized */
const MAX_HEIGHT_AFTER_RESIZING = 1080;

const VALID_FILE_EXTENSIONS = ["jpg", "jpeg", "png"];

/**
 * Shows the modals for an image which are different depending on if the image is already existing or not
 */
export const UploadAndPreviewImageComponent: FunctionComponent<UploadAndPreivewImageProps> = ({
    imageUrl,
    imageName,
    onFileUpload,
    onClose,
    onSave,
    onTooLargeFile,
    topText,
    maxFileSize = 15,
    aspectRatioLimit,
    canSave = false,
    saving,
}) => {
    const translate = useIventisTranslate();

    const [stage, setState] = useState<number>(imageUrl == null ? 0 : 1);
    const [imageSaving, setImageSaving] = useState<boolean>(false);

    /**
     * Uploads a given image file
     * Will mimic the resizing of the image that is done in the backend
     */
    const handleFileUpload = async (file: File, stage: number | null = null) => {
        setImageSaving(true);
        let processedFile = file;

        // File attempted to upload is too large to even compress
        if (file.size > megaBytesToBytes(maxFileSize)) {
            onTooLargeFile();
            setImageSaving(false);
            return;
        }

        // Image is too large to send, we are going to resize it to compress it
        if (file.size > megaBytesToBytes(MAX_FILE_SIZE_AFTER_RESIZING)) {
            const dimensions = await getImageDimensions(file);
            // Get new dimensions for the image, maintaining aspect ratio
            const { height, width } = resizeImageGivenMaxWidthAndHeight(dimensions.width, dimensions.height, MAX_WIDTH_AFTER_RESIZING, MAX_HEIGHT_AFTER_RESIZING);
            processedFile = await resizeFileImage(processedFile, width, height);
        }

        // File is still too large after resizing, don't upload the image and show a notification
        if (processedFile.size > megaBytesToBytes(MAX_FILE_SIZE_AFTER_RESIZING)) {
            onTooLargeFile();
            setImageSaving(false);
            return;
        }

        // File is now small enough to upload
        onFileUpload(processedFile);
        if (stage !== null) {
            setState(stage);
        }
        setImageSaving(false);
    };

    /** Stage is used when no image has been uploaded yet */
    const noImageStage: Stage = {
        replaceTemplateWithComponent: true,
        Component: (
            <DragDropFileUpload onFileUpload={(file) => handleFileUpload(file, 1)} supportedExtensions={VALID_FILE_EXTENSIONS} maxFileSize={maxFileSize} loading={imageSaving} />
        ),
        secondaryButtons: [],
    };

    /** Stage is used when image is already existing  */
    const existingImageStage: Stage = {
        primaryButtonText: translate(Content.common.buttons.save),
        primaryButtonCallback: onSave,
        submitButtonDataCy: "save-image-button",
        showLoadingSpinner: imageSaving || saving,
        isValid: canSave && (!imageSaving || !saving),
        secondaryButtons: [
            {
                buttonText: translate(Content.common.buttons.cancel),
                onButtonPressed: onClose,
                dataCy: "image-cancel",
            },
        ],
        Component: (
            <UploadImageWithPreview
                imageUrl={imageUrl}
                imageName={imageName}
                onTempImageUpload={(file) => handleFileUpload(file)}
                topText={topText}
                fileExtensions={VALID_FILE_EXTENSIONS}
                maxFileSize={maxFileSize}
                uploadButtonText={translate(Content.common.buttons.upload)}
                inputAccept="image/*"
                containerDataTestId="existing-image-upload"
                onFileUploadTooLarge={onTooLargeFile}
                aspectRatioLimit={aspectRatioLimit}
            />
        ),
    };

    return <FormWizardTemplate currentStage={stage} stages={[noImageStage, existingImageStage]} />;
};

export const UploadAndPreviewImageTitle = ({ title }: { title: string }) => (
    <TitleContainer>
        <TitleWithIcon title={title} icon={["fas", "download"]} />
    </TitleContainer>
);

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${inlineTextIconMargin};
`;
