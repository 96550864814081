import React from "react";
import "@iventis/styles/src/fonts/index.css";
import { GlobalStyle, main, muiTheme } from "@iventis/styles";
import { Global } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BehaviorSubject } from "rxjs";
import { allFunctionality, checkForKeyboardUser, FunctionalityContext, useConstant } from "@iventis/utilities";
import { GlobalToastRef, toast } from "@iventis/toasts/src/toast";
import { ToastProvider } from "@iventis/toasts/src/toast-provider";
import { RemoteUpload, RemoteUploadContext } from "@iventis/remote-upload";
import { ErrorBoundaryToastContext } from "@iventis/error-boundaries";
import Router from "./components/router";
import "./font-awesome";

// Create a client
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

/** Called inside axios interceptor to warn user they are not logged in (triggered when a 401 is received) */
export const isAuthenticated$ = new BehaviorSubject(true);

export const App: React.FunctionComponent = () => {
    checkForKeyboardUser();
    const remoteUploadContext = useConstant(() => new RemoteUpload());
    // DO NOT ALLOW list item properties in the admin dashboard because they do not have an external reference in the database
    const functionality = useConstant(() => ({ ...allFunctionality, listItemProperties: false }));
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={muiTheme(main, "light", "ltr")}>
                    <ToastProvider>
                        <ErrorBoundaryToastContext.Provider value={{ toast }}>
                            <FunctionalityContext.Provider value={functionality}>
                                <RemoteUploadContext.Provider value={remoteUploadContext}>
                                    <Router />
                                    <Global styles={GlobalStyle} />
                                    <GlobalToastRef />
                                </RemoteUploadContext.Provider>
                            </FunctionalityContext.Provider>
                        </ErrorBoundaryToastContext.Provider>
                    </ToastProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </>
    );
};
