import React, { FunctionComponent } from "react";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { Content } from "@iventis/translations/content/typed-content";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { incrementalValueSelectorComponentCreator } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { EditStyleItemComponent } from "./edit-style-item";
import { EditStyleProps } from "./style.types";
import ModelEditStyleComponent from "./model-edit-style";

export const LineModelEditStyleComponent: FunctionComponent<EditStyleProps<LineModelStyle>> = ({ layerStyle, changeStyleValue, dataFields }) => {
    const translate = useIventisTranslate();
    return (
        <ModelEditStyleComponent layerStyle={layerStyle} dataFields={dataFields} changeStyleValue={changeStyleValue}>
            {/* ROTATION INCREMENTOR */}
            <EditStyleItemComponent
                styleProperty="rotation"
                value={layerStyle.rotation ?? getDefaultStyleProperty(layerStyle.styleType, "rotation")}
                changeStyleValue={changeStyleValue}
                component={incrementalValueSelectorComponentCreator({
                    minValue: 0,
                    maxValue: 359,
                    increment: 10,
                    units: [{ id: "degrees", name: "degrees" }],
                    selectedUnitId: "degrees",
                    testId: "line-model-rotation",
                })}
                title={translate(Content.map2.styles.rotation)}
                isZoomableValue={false}
            />
            {/* MODEL SPACING */}
            <EditStyleItemComponent
                styleProperty="spacing"
                value={layerStyle.spacing || getDefaultStyleProperty(layerStyle.styleType, "spacing")}
                changeStyleValue={changeStyleValue}
                component={incrementalValueSelectorComponentCreator({
                    minValue: 0.01,
                    maxValue: Infinity,
                    increment: 0.1,
                    units: [{ id: "m", name: "metres" }],
                    selectedUnitId: "m",
                    decimals: 2,
                    testId: "line-model-spacing",
                })}
                title={translate(Content.map2.styles2.spacing)}
                isZoomableValue={false}
                dataFields={dataFields}
                showDataDriven
            />
            {/* MODEL OFFSET */}
            <EditStyleItemComponent
                styleProperty="modelOffset"
                value={layerStyle.modelOffset || getDefaultStyleProperty(layerStyle.styleType, "modelOffset")}
                changeStyleValue={changeStyleValue}
                component={incrementalValueSelectorComponentCreator({
                    minValue: -Infinity,
                    maxValue: Infinity,
                    increment: 0.1,
                    units: [{ id: "m", name: "metres" }],
                    selectedUnitId: "m",
                    decimals: 2,
                    testId: "line-model-offset",
                })}
                title={translate(Content.map7.styles.modelOffset)}
                isZoomableValue={false}
                dataFields={dataFields}
                showDataDriven
            />
        </ModelEditStyleComponent>
    );
};
