/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-dupe-class-members */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint no-console: ["warn", { allow: ["warn"] }] */
/* eslint-disable import/no-extraneous-dependencies */
import "mapbox-gl/dist/mapbox-gl.css";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import mapboxgl, { Map, Layer, LngLat, MapMouseEvent, StyleSpecification, RequestTransformFunction, GeolocateControl, MapOptions, FilterSpecification, GeoJSONSource, LayerSpecification, PaintSpecification, LayoutSpecification, ExpressionSpecification } from "mapbox-gl";
import GeoJSON, { Feature, Geometry } from "geojson";
import centerOfMass from "@turf/center-of-mass";
import bbox from "@turf/bbox";
import { featureCollection, polygon, BBox, point } from "@turf/helpers";
import booleanContains from "@turf/boolean-contains";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapboxLayer } from "@deck.gl/mapbox";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { isValidUuid, Optional } from "@iventis/utilities";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { LineType } from "@iventis/domain-model/model/lineType";
import { TextPosition } from "@iventis/domain-model/model/textPosition";
import throttle from "lodash.throttle";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { AnySupportedGeometry, LocalGeoJsonObject, MapObjectProperties } from "@iventis/map-types";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import { getLayerStyleTextProperties, isTextContentDeterminedByListDataField } from "@iventis/layer-style-helpers/src/text-style-helpers";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import { groupCompositionMapObjectByLayer } from "../../utilities/converters";
import { MapModuleLayer, MapboxEngineData, MapState, Source, LayerStorageScope, DrawingObject, StoredBounds, PitchOptions, UnionOfStyles } from "../../types/store-schema";
import {
    highlightInfix,
    coordinateDeleteLayerId,
    coordinateDeleteSourceId,
    coordinateDeleteSpriteName,
    coordinateHandleLayerId,
    coordinateHandleSourceId,
    coordinateHandleSpriteName,
    internalMapLayerIds,
    localSuffix,
    rotateHandleLayerId,
    rotateHandleSourceId,
    rotateHandleSpriteName,
    traceLineLayerId,
    traceLineSourceId,
    midpointHandleLayerId,
    midpointHandleSourceId,
    continueDrawingLayerId,
    continueDrawingSourceId,
    continueDrawingSpriteName,
    textboxSpriteName,
    analysisLayerIds,
    analysisSourceIds,
    measurementSourceId,
    MAP_FRAME_TIME,
    systemLayerIds,
    sdfDisabledSuffix,
    waypointLayerId,
    waypointSourceId,
    waypointSpriteName,
    analysisMeasuringLabelLayerName,
    building3dLayerIds,
    buildingLayerIds,
    MAPBOX_MAX_ZOOM,
    MAPBOX_MIN_ZOOM,
    COMMENT_ICON_DATA_URL,
    COMMENT_VECTOR_SOURCE_ID,
} from "../constants";
import { AggregateLayers, LayerSourceLocation, MapboxlayerWithSublayerType, SubLayerType } from "./sublayer-types";
import { EngineInterpreter, removeLayerIdSuffixes, getCentroidLayerID, getRemoteLayerId } from "../engine-generic";
import { ClickEvent, MoveEvent, MapCursor, StylePropertyToValueMap, Listener, CompositionMapObject, ReflectLocalObjectsOptions } from "../../types/internal";
import {
    boldFontStack,
    MapboxEngineOptions,
    maxDefaultPitch,
    minDefaultPitch,
    regularFontStack,
    TileCacheBuster,
    ExportOptions,
    locationHashString,
    ApplyListItemTextFunction,
    googleMapBackgroundAssetTag,
} from "./engine-mapbox-types-and-constants";
import {
    createArrowsSublayer,
    createTextSublayer,
    iventisLayerToHighlightBaseLayer,
    combineMapboxBaseStyles,
    iventisSourceToMapboxSource,
    styleValueParser,
    createOutlineSubLayer,
    calculateOffsetForPolygonOutline,
    styleValueToMapboxStyleValue,
    calculateLineWidthForLineOutline,
    textContentValueToMapboxStyleValue,
    zoomableValueToMinMaxZoomLevels,
    iventisAreaToBaseSublayer,
    iventis3DAreaToBaseSublayer,
    setMapboxTextPropertiesOnIcon,
    localGeoJsonToFeatureCollection,
    createEphemeralObjectsFor3DLayers,
    getInitialMapPosition,
    containsSdfDefaultIcons,
    replaceIconIdsForSdf,
    getAllLayerIconsIds,
    sortSubLayers,
    amountOfRemoteLayers,
    getSourceBounds,
    iventisAreaToAggregateLayer,
    iventisIconToAggregateLayer,
    iventisLineModelToAggregateLayer,
    iventisLineToAggregateLayer,
    iventisModelToAggregateLayer,
    iventisPointToAggregateLayer,
    getSiteMapLayerIds,
    applyConfigurationToBackground,
} from "./engine-mapbox-helpers";
import { getDefaultStyleProperty, getHighlightCircleRadius, getStaticAndMappedValues, modifyStyleValueFundamentalValues } from "../../utilities/style-helpers";
import { getModelLayers, isAnalysisLayer, isAreaSelectLayer, isModelLayer, removeModelLayers } from "../../utilities/state-helpers";
import { getLocalLayerID, tileLayerToLocalLayer } from "../engine-generic-helpers";
import { SitemapStyle } from "../../types/sitemap-style";
import { getModelLayerStyle } from "../../utilities/layer.helpers";
import { DeckglEngine } from "./3d-engine/deckgl/engine-deckgl";
import { layerScaleModifier } from "./export-scale-helpers";
import { getAboveLayerId, getBottomMostInternalLayerId, getBottomMostRelatedLayer, isSubLayerAboveOrBelow } from "./layer-ordering/layer-ordering-mapbox-helpers";
import { GoogleMapboxAttribution } from "./attribution/engine-mapbox-google-attribution";
import { GoogleLogoMapboxAttribution } from "./attribution/engine-mapbox-google-logo-attribution";
import { MapboxAttribution } from "./attribution/engine-mapbox-attribution";
import { MapboxTestHelpers } from "./test-helpers/mapbox-test-helpers";
import { DeckGlTestHelpers } from "./test-helpers/deckgl-test-helpers";
import { createDateFilterExpression } from "./filters/engine-mapbox-date-filter-helpers";
import { getLayerFilterByType, removeLayerFilterByType, updateLayerFilters } from "./filters/engine-mapbox-filter-helpers";
import { FilterType } from "./filters/engine-mapbox-filter-types";
import { createMapboxLevelFilter } from "./filters/engine-mapbox-level-filter-helpers";
import { SubLayerPlacement } from "./layer-ordering/layer-ordering-types-and-constants";
import { getLowestMapOrderValueLayer, orderDomainLayers } from "./layer-ordering/layer-ordering-generic-helpers";
import { orderQueryRenderFeatures } from "./layer-ordering/layer-ordering-query-rendered-features";
import { MapObject3D } from "./3d-engine/engine-3d-types";
import { MapboxMaskLayer } from "../mapbox-layers/mapbox-mask-layer";
import { MapboxSnapIndicatorLayer } from "../mapbox-layers/mapbox-snap-indicator-layer";
import { isGoogleMapsMapboxSource } from "./attribution/engine-mapbox-attribution-types";
import { isIventisTestLayer, IventisMapboxTestLayer } from "./test-helpers/mapbox-test-helper-types";

declare global {
    interface Window {
        Cypress: {
            [key: string]: {
                getLayerByName: (name: string, type?: StyleType) => Partial<MapModuleLayer>;
                getLayerById: (id: string, type?: StyleType) => Partial<MapModuleLayer>;
                zoomToBounds: (bounds: [number, number, number, number]) => void;
                canvasXYToCoordinates: (x: number, y: number) => LngLat;
                coordinatesToCanvasXY: (lat: number, lng: number) => { x: number; y: number };
                viewportContainsCoordinate: (coordinate: [number, number]) => Promise<boolean>;
                getLayerGeoJsonFeatures: (layerName: string, type?: StyleType) => { geometry: GeoJSON.Geometry; properties: MapObjectProperties }[];
                getLayerGeoJsonFeaturesViaId: (layerId: string, type?: StyleType) => { geometry: GeoJSON.Geometry; properties: MapObjectProperties }[];
                getLayerTileRenderedFeatures: (layerName: string) => { geometry: GeoJSON.Geometry; properties: MapObjectProperties }[];
                getLayerGeoJsonRenderedFeatures: (layerName: string) => { geometry: GeoJSON.Geometry; properties: MapObjectProperties }[];
                getListItemIdFromName: (layerName: string, dataFieldName: string, listItemName: string) => Promise<string>;
                isSitemapOnMap: (sitemapName: string) => boolean;
                getCurrentSitemapTileUrl: (sitemapName: string) => string;
                getConstants: () => { [key: string]: unknown };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getDrawingLayersGeoJsonFeatures: () => { coordinateHandles: any; rotationHandle: any; midPointHandles: any };
                getMapCentre: () => number[];
                getLayerTopAndBottomMapIndex: (layerName: string) => { top: number; bottom: number };
                waitForMapIdle: (callback: () => void) => void;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getModelDataByLayerName: (layerName: string) => Promise<any>;
                getDeckGlLayersForLayerByName: (layerName: string) => MapboxLayer<MapObject3D>[];
                setBearing: (bearing: number) => void;
                getPitch: () => number;
                getZoom:() => number;
            };
        };
    }
}

/**
 * Here lies the lower-level abstractions
 * Specific implementation of the engine interpreter in the mapbox flavour
 */
export class MapboxEngine extends EngineInterpreter {
    private mbxAccessToken: string;

    private maxZoom: number;

    private minZoom: number;

    protected modifierKeys: KeyboardEvent["key"][] = [];

    protected bustTileCache: TileCacheBuster = (tileUrls: string[]) => tileUrls;

    private transformRequest?: RequestTransformFunction;

    private map: Map;

    private models3D: DeckglEngine;

    private aggregateLayers: AggregateLayers = {};

    /** History of ids from remote objects that have switched to local.  */
    private hiddenRemoteObjects: { [layerId: string]: string[] } = {};

    /*
        Retained functions to be used with map.off in preventing memory leaks
    */

    private mouseDownFunction: (event: MapMouseEvent) => void;

    private onFirstRenderFunction: () => void;

    private mapInitialisedInterval: NodeJS.Timer;

    private layerTextHasLoadedInterval: NodeJS.Timer;

    private fontStackUrl: string;

    private disabledSDFIconMapping: { [key: string]: string };

    private layerMap: { [key: string]: MapModuleLayer } = {};

    private maskLayer: MapboxMaskLayer;

    private snapIndicator: MapboxSnapIndicatorLayer;

    private customAttribution: MapboxAttribution[] = [];

    private layerIdsWithTextContentDeterminedByListDataField: string[] = [];

    public onResize() {
        this.map.resize();
    }

    constructor({
        store,
        container,
        eventStream,
        mbxAccessToken,
        preview,
        modifierKeys = [],
        assetOptions,
        bustTileCache = (tileUrls: string[]) => tileUrls,
        transformRequest,
        devTools,
        maxZoom,
        minZoom,
        preserveDrawingBuffer,
        fontStackUrl,
        pitchOptions,
        exportOptions,
        useMapObjectBounds,
        getAttributeListItems,
        routeApiFunctions,
        user,
        isExternalUser,
        cypressWindowName,
    }: MapboxEngineOptions) {
        super({ store, container, eventStream, preview, modifierKeys, assetOptions, bustTileCache, devTools, getAttributeListItems, routeApiFunctions, user, isExternalUser });
        this._attachTestingFunctionsToWindow(cypressWindowName);
        this.fontStackUrl = fontStackUrl;
        this.mbxAccessToken = mbxAccessToken;
        this.modifierKeys = modifierKeys;
        this.maxZoom = maxZoom;
        this.minZoom = minZoom;
        this.disabledSDFIconMapping = {};
        this.bustTileCache = bustTileCache;
        this.transformRequest = transformRequest;
        this.getAttributeListItems = getAttributeListItems.bind(this);
        this.initialise({ preserveDrawingBuffer, pitchOptions, exportOptions, useMapObjectBounds });
        this.onWindowFocusOut();
    }

    public initialise(options?: { preserveDrawingBuffer?: boolean; pitchOptions?: PitchOptions; exportOptions?: ExportOptions; useMapObjectBounds: boolean }) {
        const state = this.getCurrentState();

        if (typeof this.mbxAccessToken !== "string") {
            throw new Error("A Mapbox access token must be provided. Pass it into the constructor!");
        }

        this.setLayersWithTextContentBasedOnListDataField(state.layers.value);

        // Here
        mapboxgl.accessToken = this.mbxAccessToken;

        // Create the mapbox map
        this.map = new Map(this.createMbxMapOptions(state, options));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.map.on("error", (e: any) => {
            // Bug fix: 13274. Suppress missing mapbox vector tiles (eg sitemaps 403 due to cloud front) to a warning to prevent map exports from being failing.
            // We accept some tiles not being available when close to CAD boundaries, but we don't want to fail the export.
            if (e?.source?.type === "vector") {
                return;
            }
            this.onMapError(e);
        });

        if (options?.exportOptions != null) {
            this.loadAllIconImages(state.layers.value);
        }
        // Add the 3D model handler
        this.models3D = new DeckglEngine(this.map, this.assetOptions, state.currentLevel, state.models.value, state.datesFilter.value.filter);
        this.map.once("styledata", async () => {
            await this.checkSdfIcons(state.layers.value);
            if (options.exportOptions?.bearing) {
                this.map.setBearing(options.exportOptions.bearing);
            }
            if (options.exportOptions?.pitch) {
                this.map.setPitch(options.exportOptions.pitch);
            }
            await this.addModelLayers();
            this.reflectLocalObjects({ allowTextOnSelectedObjects: true });
            clearInterval(this.mapInitialisedInterval);
            clearInterval(this.layerTextHasLoadedInterval);
            this.mapReadyChecks();
        });

        this.map.once("render", async () => {
            await this.checkSdfIcons(state.layers.value);
            this.map.triggerRepaint();
        });

        this.initialiseListeners();

        state.layers.value.forEach((layer) => {
            this.layerMap[layer.id] = layer;
        });

        this.saveLastState(state);
        this.setMapLock(state.isCameraMovementLocked.value);

        const mapBackground = state.engineSpecificData.styles.value.MapBackground;
        if (mapBackground != null) {
            this.addMapAttribution(mapBackground);
        }
        this.map.addControl(
            new GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true,
            }),
            "top-right",
        );
    }

    private mapReadyChecks() {
        // Checks when then style and all map images are loaded
        const styleLoadedPromise = new Promise<void>((res) => {
            this.mapInitialisedInterval = setInterval(() => {
                if (this.map.isStyleLoaded() && this.imagesLoading.length === 0) {
                    clearInterval(this.mapInitialisedInterval);
                    res();
                }
            }, 500);
        });

        // Checks when the map is idle
        const mapIdlePromise = new Promise<void>((res) => {
            this.map.once("idle", () => {
                res();
            });
        });

        const layerTextHasLoaded = new Promise<void>((res) => {
            this.layerTextHasLoadedInterval = setInterval(() => {
                if (this.layerIdsWithTextContentDeterminedByListDataField.length === 0) {
                    clearInterval(this.layerTextHasLoadedInterval);
                    res();
                }
            }, 500);
        });

        Promise.all([styleLoadedPromise, mapIdlePromise, layerTextHasLoaded]).then(() => {
            this.onLoadComplete();
        });
    }

    private createMbxMapOptions(
        state: MapState<MapboxEngineData>,
        options?: { preserveDrawingBuffer?: boolean; pitchOptions?: PitchOptions; exportOptions?: ExportOptions; useMapObjectBounds: boolean },
    ): MapOptions {
        // creates normal options used for all instances of the mapbox map
        const mbxMapOptions: MapOptions = {
            container: this.container,
            style: this.createMbxStyle(state.engineSpecificData.styles.value, amountOfRemoteLayers(state.layers.value) > 0, options?.exportOptions?.scale),
            maxZoom: this.maxZoom,
            minZoom: this.minZoom,
            minPitch: options.pitchOptions?.min ?? minDefaultPitch,
            maxPitch: options.pitchOptions?.max ?? maxDefaultPitch,
            antialias: true,
            preserveDrawingBuffer: options.preserveDrawingBuffer,
            projection: { name: state.globe.value ? "globe" : "mercator" },
            logoPosition: "bottom-right",
            fadeDuration: 0,
            ...getInitialMapPosition(state, options.useMapObjectBounds, options.exportOptions),
            ...(this.transformRequest
                ? {
                      transformRequest: this.transformRequest,
                  }
                : {}),
        };

        // if the map is not being used for preview then add location hashing
        if (options.exportOptions == null && !this.preview) {
            mbxMapOptions.hash = locationHashString;
        }

        return mbxMapOptions;
    }

    public getCanvas(): HTMLCanvasElement {
        return this.map.getCanvas();
    }

    protected async onStoreChanged(state: MapState<MapboxEngineData>, previousState: MapState<MapboxEngineData>) {
        if (state.layers.stamp !== previousState.layers.stamp) {
            await this.checkSdfIcons(state.layers.value);

            state.layers.value.forEach((layer) => {
                this.layerMap[layer.id] = layer;
            });
        }

        super.onStoreChanged(state, previousState);

        // Basemap style changed
        if (state.engineSpecificData.styles.stamp !== previousState.engineSpecificData.styles.stamp) {
            const mapBackground = state.engineSpecificData.styles.value.MapBackground;
            // Remove all attribution, each map background has it's own
            this.removeAttribution();
            // If "Google Maps" map background is selected we need to add their attribution
            if (isGoogleMapsMapboxSource(mapBackground) && mapBackground.metadata?.tags?.includes(googleMapBackgroundAssetTag)) {
                this.addMapAttribution(mapBackground);
            }

            // Remove models so when they are re-added they are added above the other layers
            this.models3D.deleteAllLayers();

            this.map.setStyle(await this.createMbxStyle(state.engineSpecificData.styles.value, amountOfRemoteLayers(state.layers.value) > 0));

            // Setting the style completely wipes the old style out of mapbox. Since we have no way of adding 3D model layers in this operation, we have to do it after that set style has finished.
            this.map.once("styledata", async () => {
                if (this.maskLayer != null) {
                    this.maskLayer.remove();
                    this.maskLayer = null;
                }

                if (state.currentLevel !== 0) {
                    this.maskLayer = new MapboxMaskLayer(
                        this.map,
                        state.engineSpecificData.styles.value.SiteMap.map(({ perimeter }) => perimeter),
                        this.store.change,
                    );
                }
                // Ensure the dates filter is applied to the local map objects
                this.reflectLocalObjects({ allowTextOnSelectedObjects: true });
                this.addInternalImages();
                this.addModelLayers();

                // Readd the snap indicator if we change basemap
                if (state.drawingModifier === "snapping") {
                    this.snapIndicator = new MapboxSnapIndicatorLayer(this.map);
                }

                // Changing style removed 3d terrain properties on the map, lets reinitialise this
                this.configureTerrain3D(state.terrain3D.value.enabled, state.terrain3D.value.exaggeration);
            });
        }

        // when level changes we also need to tell the deckgl engine it has changed
        if (state.currentLevel !== previousState.currentLevel) {
            this.models3D.setCurrentLevel(
                state.currentLevel,
                state.geoJSON.value,
                state.layers.value.filter((layer) => this.models3D.doesLayerExist(layer.id)),
            );
            if (state.currentLevel === 0 && this.maskLayer != null) {
                this.maskLayer.remove();
                this.maskLayer = null;
            }
        }

        if (state.buildings3D.stamp !== previousState.buildings3D.stamp) {
            this.configure3dBuildings(state.buildings3D.value, state.engineSpecificData.styles.value[AssetType.MapBackground]);
        }

        if (state.datesFilter.stamp !== previousState.datesFilter.stamp) {
            // Set filter in deckgl engine
            this.models3D.dateFilterEnabled = state.datesFilter.value.filter;
            const layers = getModelLayers(state.layers.value);
            // Check if layer can have date filtering and update the map objects
            layers.forEach((layer) => {
                if (layer.dataFields?.some((df) => df.type === DataFieldType.RepeatedTimeRanges)) {
                    const featureCollection = localGeoJsonToFeatureCollection<GeoJSON.LineString>(this.getLocalLayerGeometry(layer.id));
                    this.models3D.updateMapObjects(layer, featureCollection);
                }
            });
        }
    }

    private async checkSdfIcons(layers: MapModuleLayer[]) {
        await Promise.all(
            layers.map(async (layer) => {
                if (layer.styleType === StyleType.Icon && layer.iconStyle.iconImage.extractionMethod === StyleValueExtractionMethod.Mapped) {
                    // Check all icons to see if they're SDF enabled
                    const [containsSdfIcons, containsNormalIcons, sdfIconIds] = await containsSdfDefaultIcons(layer, this.assetOptions);
                    // We only need to add an sdf disabled version if the layer contains both sdf enabled and disabled images
                    if (containsSdfIcons && containsNormalIcons) {
                        // Create non-sdf enabled icons in mapbox
                        sdfIconIds.forEach(async (sdfIconId) => {
                            // The id of the asset with the sdf disabled suffix
                            const idWithSuffix = `${sdfIconId}-${sdfDisabledSuffix}`;
                            this.disabledSDFIconMapping[sdfIconId] = idWithSuffix;
                        });
                    }
                }
            }),
        );
    }

    private async addModelLayers() {
        const state = this.getCurrentState();
        const modelLayers = getModelLayers(state.layers.value);
        modelLayers
            .filter((layer) => layer.styleType === StyleType.Model || layer.styleType === StyleType.LineModel)
            .forEach(async (layer) => {
                this.addLayer(layer, false);
                const featureCollection = localGeoJsonToFeatureCollection<GeoJSON.LineString | GeoJSON.Point>(this.getLocalLayerGeometry(layer.id));
                this.models3D.updateMapObjects(layer, featureCollection);
            });
    }

    private createMbxStyle(
        baseStyle: {
            [AssetType.MapBackground]: StyleSpecification;
            [AssetType.SiteMap]: SitemapStyle[];
        } = {
            MapBackground: null,
            SiteMap: [],
        },
        areUserLayersPresent: boolean,
        scaleModifier?: number,
    ) {
        const state = this.getCurrentState();
        const tileSources = state.tileSources.value;

        const backgroundStyle = applyConfigurationToBackground(baseStyle[AssetType.MapBackground], state);

        // Get all of the sitemap styles
        const siteMaps: StyleSpecification[] = baseStyle[AssetType.SiteMap].map((style) => ({
            ...style.style,
            // Append the site map version to each layer id so they are always unique
            layers: style.style.layers.map((layer) => ({ ...layer, id: `${layer.id}-${style.sitemapVersionLevelId}` })),
        }));

        const style = combineMapboxBaseStyles(JSON.parse(JSON.stringify(backgroundStyle)) as StyleSpecification, baseStyle[AssetType.SiteMap]);

        // Add each tile source from the store
        Object.values(tileSources).forEach((value) => {
            value.tiles.forEach((source) => {
                // If bounds is null for comments we know that there are no comment tiles to serve
                // Set bounds to empty and therefore we won't request any tiles
                if (source.name === COMMENT_VECTOR_SOURCE_ID && value.bounds == null) {
                    style.sources[source.name] = iventisSourceToMapboxSource(source, [0, 0, 0, 0]);
                } else {
                    const emptySource = source.name !== COMMENT_VECTOR_SOURCE_ID && !areUserLayersPresent;
                    style.sources[source.name] = iventisSourceToMapboxSource(source, getSourceBounds(value.bounds, emptySource));
                }
            });
        });

        // Add selected area source
        style.sources.selectedArea = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        };
        // Add analysis sources
        analysisSourceIds.forEach((id) => {
            style.sources[id] = {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            };
        });

        const localObjectFilters: Record<string, FilterSpecification> = {};

        Object.entries(state.geoJSON.value).forEach(([layerId, objects]) => {
            const { storageScope, styleType, areaStyle } = state.layers.value.find(({ id }) => id === layerId);
            const localLayerId = getLocalLayerID(layerId);
            style.sources[storageScope === LayerStorageScope.LocalOnly ? layerId : localLayerId] = {
                type: "geojson",
                data: { type: "FeatureCollection", features: objects.map((object) => object.feature) },
            };

            // Area text has it's own source. If the area layer is local also add in a local text source
            if (styleType === StyleType.Area && areaStyle?.text && objects.length > 0) {
                style.sources[getCentroidLayerID(localLayerId)] = {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: objects.map((object) => ({
                            type: "Feature",
                            geometry: centerOfMass(object.feature).geometry,
                            properties: object.feature.properties,
                        })),
                    },
                };
            }

            // Generate filters to ensure remote objects of the same id as the local objects are not rendered on the map. Save these filters with a key of the layerId so we can access them later
            localObjectFilters[layerId] = objects.map((object) => ["!=", ["get", "id"], this.getMapObjectRemoteId(object.objectId)]).filter(([eq, prop, id]) => id !== undefined);
        });

        // Cannot include Models yet as we must create the map before initialising our 3D model handler
        const domainLayers = removeModelLayers(this.store.change.value.layers.value);

        const orderedLayers = orderDomainLayers(domainLayers);
        const levelFilter = createMapboxLevelFilter(this.getCurrentState().currentLevel);

        // Convert each domain layer into an aggregate layer, resulting in an array of aggregate layers
        const aggregateLayers = orderedLayers.flatMap((layer) => {
            // Filter needs to be applied and the layer has a repeated time range data field
            const layerNeedDateFilter = state.datesFilter.value.filter && layer.dataFields?.some((df) => df.type === DataFieldType.RepeatedTimeRanges);
            const dateFilterExpression = layerNeedDateFilter ? createDateFilterExpression(layer, state.datesFilter.value.day, state.datesFilter.value.time, 15) : null;

            const layers: { layers: MapboxlayerWithSublayerType[]; layerId }[] = [];
            const layerFilter: Layer["filter"] = this.addDateFilterToIventisLayer(layer, levelFilter);
            let aggregateLayers = this.iventisLayerToAggregateLayer(layer, layerFilter);

            // Go through each layer and check if an object filter exists for it. This filter will hide any remote objects
            // if local objects exist for it thus leaving local objects to be rendered and no duplicate objects are rendered (remote and local)
            aggregateLayers = aggregateLayers.reduce((layers, layer) => {
                // Models layers are already removed but need to do this for type safety
                if (layer.layer.type !== "custom") {
                    // Ensure that the base layer filter is applied to all the sub layers
                    const baseLayerId = aggregateLayers.find((l) => l.type === SubLayerType.BASE || l.type === SubLayerType.EXTRUSION)?.id;

                    const layerFilter: FilterSpecification = ["all"];

                    // Add the local object filter
                    if (localObjectFilters[baseLayerId] !== undefined) {
                        const filter = localObjectFilters[baseLayerId];
                        layerFilter.push(...filter);
                    }

                    // Add the date filter
                    if (dateFilterExpression != null) {
                        layerFilter.push(dateFilterExpression);
                    }

                    // Add the level filter
                    layerFilter.push(levelFilter);

                    layers.push({
                        ...layer,
                        layer: {
                            ...layer.layer,
                            filter: layerFilter,
                        },
                    });
                }
                return layers;
            }, []);
            // Push our remote layer with any objects to our layers array
            layers.push({ layers: aggregateLayers, layerId: layer.id });

            // Add local layer to aggregate layer

            // If we have a source for the local version of the layer id then we have local objects
            if (style.sources[getLocalLayerID(layer.id)] !== undefined && layer.storageScope !== LayerStorageScope.LocalOnly) {
                const localLayer = {
                    ...layer,
                    id: getLocalLayerID(layer.id),
                    source: getLocalLayerID(layer.id),
                };
                // Push our local layer converted to an aggregate layer to the layers array
                layers.push({
                    layers: this.iventisLayerToAggregateLayer(localLayer, layerFilter),
                    layerId: localLayer.id,
                });
            }
            return layers;
        });

        // Convert this array into object format
        this.aggregateLayers = aggregateLayers.reduce((cum, { layerId, layers }) => ({ ...cum, [layerId]: layers }), {});
        const layers = aggregateLayers.flatMap(({ layers }) => layers).map(({ layer }) => layer);

        style.layers.push(...(layers as LayerSpecification[]));

        style.fog = {};

        style.glyphs = this.fontStackUrl;
        if (scaleModifier) {
            layerScaleModifier(style.layers, scaleModifier, getSiteMapLayerIds(baseStyle[AssetType.SiteMap]));
        }

        return style;
    }

    public setPosition(lat: number, lng: number, bearing: number, pitch: number, zoom: number, smooth = false) {
        if (smooth) {
            this.map.easeTo({ center: [lng, lat], zoom, pitch, bearing });
        } else {
            this.map.jumpTo({ center: [lng, lat], zoom, pitch, bearing });
        }
    }

    public setBounds(bounds: number[][][], duration = 5000): void {
        // Get bounding box from the polygon geometry
        const polygonBbox = bbox({ type: "Feature", geometry: { coordinates: bounds, type: "Polygon" } }) as [number, number, number, number];
        this.map.fitBounds(polygonBbox, { maxZoom: 19, padding: 100, duration });
    }

    public setBearing(bearing: number): void {
        this.map.setBearing(bearing);
    }

    public setBoundsWithBounds(bounds: [number, number, number, number], duration = 5000, maxZoom = 19, padding = 100): void {
        this.map.fitBounds(bounds, { maxZoom, padding, duration });
    }

    protected configureTerrain3D(enabled: boolean, exaggeration: number) {
        const terrainSourceId = "mapbox-dem";
        const skyLayerId = "sky";

        if (enabled) {
            this.map.addSource(terrainSourceId, {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxzoom: 14,
            });
            // add the DEM source as a terrain layer with exaggerated height
            this.map.setTerrain({ source: terrainSourceId, exaggeration });
        } else {
            this.map.setTerrain();
            this.map.removeLayer(skyLayerId);
            this.map.getSource(terrainSourceId) && this.map.removeSource(terrainSourceId);
        }
    }

    protected configure3dBuildings(enabled: boolean, originalStyle: StyleSpecification) {
        // When the 3D buildings are hidden, we want to show the normal buildings at the 3D zoom level
        // When show the 3D buildings, we need to make sure we have the original max zoom level and so we use the layers from the original style (not from mapbox)
        const rawBackgroundBuildingLayers = originalStyle.layers.filter((l) => buildingLayerIds.includes(l.id));
        rawBackgroundBuildingLayers.forEach((layer: Layer) =>
            this.map.setLayerZoomRange(layer.id, layer.minzoom ?? MAPBOX_MIN_ZOOM, enabled ? (layer.maxzoom ?? MAPBOX_MAX_ZOOM) : MAPBOX_MAX_ZOOM),
        );
        // Hide/show the 3D buildings
        building3dLayerIds.forEach((id) => this.map.setLayoutProperty(id, "visibility", enabled ? "visible" : "none"));
    }

    protected configureGlobeProjection(enabled: boolean) {
        // For some reason, the mapbox type package does not have setProjection
        if (enabled) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.map as any).setProjection("globe");
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.map as any).setProjection("mercator");
        }
    }

    protected onImageRequired(callback: (e: { id: string }) => void): Listener {
        return this.map.on("styleimagemissing", callback);
    }

    protected addImage(image: string | HTMLImageElement, id: string, options?: { sdf?: boolean }) {
        return new Promise<void>((resolve) => {
            // If the image is a string, we need to load it first and then add
            if (typeof image === "string") {
                this.map.loadImage(image, (error: Error | null, image: ImageBitmap) => {
                    if (error instanceof Error) {
                        throw error;
                    }

                    if (!this.map.hasImage(id)) {
                        this.map.addImage(id, image, { sdf: options?.sdf || false });
                    }
                    resolve(undefined);
                });
            } else {
                // If image is an HTMLImageElement, we can add it directly and not need to load it
                if (!this.map.hasImage(id)) {
                    this.map.addImage(id, image, { sdf: false });
                }
                resolve(undefined);
            }
        });
    }

    protected removeImage(id: string) {
        this.map.removeImage(id);
        const state = this.getCurrentState();
        this.assetOptions.bustCacheIds([id]);
        this.loadAllIconImages(state.layers.value.filter((x) => x.styleType === StyleType.Icon));
    }

    protected async reloadLayersByAssetId(id: string) {
        const state = this.getCurrentState();

        // If the asset is in the map, we need to remove the lod model asset from the cache. Otherwise, remove the asset regularly
        const assetId = this.models3D.getModelLodIdFromModelId(id);
        this.assetOptions.bustCacheIds([assetId ?? id]);

        const modelLayersToReload = state.layers.value.filter((layer) => {
            if (layer.styleType === StyleType.Model || layer.styleType === StyleType.LineModel) {
                const style = getModelLayerStyle(layer);
                return getStaticAndMappedValues(style.model).includes(id);
            }
            return false;
        });
        modelLayersToReload.forEach((layer) => {
            this.removeLayer(layer.id);
            this.models3D.removeModelFromLoadedModels(id);
            this.addLayer(layer, false);
        });
    }

    protected isImageAdded(id: string) {
        return this.map.listImages().some((imageId) => imageId === id);
    }

    protected addContinueDrawingLayer() {
        if (this.map.getLayer(continueDrawingLayerId)) {
            return;
        }
        this.map.addLayer({
            id: continueDrawingLayerId,
            type: "symbol",
            source: continueDrawingSourceId,
            layout: {
                "icon-image": continueDrawingSpriteName,
                "icon-allow-overlap": true,
            },
            metadata: {
                name: "continueDrawingHandle"
            }
        });
    }

    protected setContinueDrawingButtonRotation(angle: number) {
        this.map.setLayoutProperty(continueDrawingLayerId, "icon-rotate", angle);
    }

    protected addCoordinateHandleLayer() {
        if (this.map.getLayer(coordinateHandleLayerId)) {
            return;
        }
        this.map.addLayer({
            id: coordinateHandleLayerId,
            type: "symbol",
            source: coordinateHandleSourceId,
            layout: {
                "icon-image": coordinateHandleSpriteName,
                "icon-allow-overlap": true,
            },
            metadata: { name: "coordinateHandle" },
        });
    }

    protected removeCoordinateHandleLayer() {
        if (this.map.getLayer(coordinateHandleLayerId)) {
            this.map.removeLayer(coordinateHandleLayerId);
        }
    }

    protected addWaypointsLayer() {
        if (this.map.getLayer(waypointLayerId)) {
            return;
        }
        this.map.addLayer({
            id: waypointLayerId,
            type: "symbol",
            source: waypointSourceId,
            layout: {
                "icon-image": waypointSpriteName,
                "icon-allow-overlap": true,
                "icon-anchor": "bottom",
                "text-field": ["get", "waypointLetter"],
                "text-anchor": "bottom",
                "text-offset": [0, -1.5],
                "text-font": boldFontStack,
                "icon-ignore-placement": true,
            },
            paint: {
                "text-color": "#ffffff",
            },
            metadata: { name: "waypoint", type: "base" },
        });
    }

    protected removeWaypointLayer() {
        if (this.map.getLayer(waypointLayerId)) {
            this.map.removeLayer(waypointLayerId);
        }
    }

    protected addMidpointHandleLayer() {
        if (this.map.getLayer(midpointHandleLayerId)) {
            return;
        }
        this.map.addLayer({
            id: midpointHandleLayerId,
            type: "circle",
            source: midpointHandleSourceId,
            metadata: { name: "midpointHandle" },
        });
    }

    protected removeMidpointHandleLayer() {
        if (this.map.getLayer(midpointHandleLayerId)) {
            this.map.removeLayer(midpointHandleLayerId);
        }
    }

    protected addRotateHandleLayer() {
        if (this.map.getLayer(rotateHandleLayerId)) {
            return;
        }

        this.map.addLayer({
            id: rotateHandleLayerId,
            type: "symbol",
            source: rotateHandleSourceId,
            layout: {
                "icon-image": rotateHandleSpriteName,
                "icon-allow-overlap": true,
                "icon-size": 1,
            },
            metadata: { name: "rotationHandle" },
        });
    }

    protected addCoordinateDeleteLayer() {
        if (this.map.getLayer(coordinateDeleteLayerId)) {
            return;
        }

        this.map.addLayer({
            id: coordinateDeleteLayerId,
            type: "symbol",
            source: coordinateDeleteSourceId,
            layout: {
                "icon-image": coordinateDeleteSpriteName,
                "icon-allow-overlap": true,
                "icon-size": 0.7,
            },
            metadata: {
                name: "deleteHandle"
            },
        });
    }

    protected addTraceLineLayer() {
        if (this.map.getLayer(traceLineLayerId)) {
            return;
        }
        this.map.addLayer({
            id: traceLineLayerId,
            type: "line",
            source: traceLineSourceId,
            paint: {
                "line-width": 4,
                "line-color": ["get", "traceLineColour"], // to dynamically match the colour of the fill layer
            },
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
        });
    }

    protected addTextboxLayer(layerId: string, sourceId = layerId, removeOtherTextBoxes = true, name = layerId): void {
        if (removeOtherTextBoxes || !this.map.getSource(sourceId)) {
            this.setEmptySource(sourceId);
        }
        if (this.map.getLayer(layerId)) {
            return;
        }
        this.map.addLayer({
            id: layerId,
            type: "symbol",
            source: sourceId,
            layout: {
                "icon-image": textboxSpriteName,
                "text-field": ["get", "text"],
                "text-font": boldFontStack,
                "text-size": 14,
                "icon-text-fit": "both",
                "icon-text-fit-padding": [5, 5, 5, 5],
            },
            metadata: {
                name,
                type: "base",
            },
        });
    }

    protected removeCoordinateDeleteLayer() {
        this.map.removeLayer(coordinateDeleteLayerId);
    }

    protected disableDragPan() {
        this.map.dragPan.disable();
    }

    public enableDragPan() {
        if (!this.isCameraMovementLocked()) {
            this.map.dragPan.enable();
        }
    }

    protected disableDoubleClickZoom() {
        this.map.doubleClickZoom.disable();
    }

    public enableDoubleClickZoom() {
        this.map.doubleClickZoom.enable();
    }

    protected disableMapRotation() {
        this.map.dragRotate.disable();
    }

    protected enableMapRotation() {
        if (!this.isCameraMovementLocked()) {
            this.map.dragRotate.enable();
        }
    }

    public async getPosition() {
        const { lng, lat } = this.map.getCenter();
        const zoom = this.map.getZoom();
        const bearing = this.map.getBearing();
        const pitch = this.map.getPitch();

        return {
            lng,
            lat,
            zoom,
            bearing,
            pitch,
            source: Source.MAP,
        };
    }

    public getRotation() {
        return this.map.getBearing();
    }

    protected async getBounds(): Promise<StoredBounds> {
        const canvas = this.map.getCanvas();
        const { width } = canvas;
        const { height } = canvas;
        const coordinatesUpperLeft = this.map.unproject([0, 0]).toArray();
        const coordinatesUpperRight = this.map.unproject([width, 0]).toArray();
        const coordinatesLowerRight = this.map.unproject([width, height]).toArray();
        const coordinateLowerLeft = this.map.unproject([0, height]).toArray();
        const coordinates = [coordinatesUpperLeft, coordinatesUpperRight, coordinatesLowerRight, coordinateLowerLeft, coordinatesUpperLeft];
        const poly = polygon([coordinates]);
        return { bounds: poly.geometry.coordinates, source: Source.MAP };
    }

    public project(coordinate: GeoJSON.Position) {
        const { x, y } = this.map.project(coordinate as [number, number]);
        return [x, y] as [number, number];
    }

    protected unproject(xy: [number, number]) {
        const position = this.map.unproject(xy);
        return [position.lng, position.lat];
    }

    /** Adds unique objects to "hiddenRemoteObjects" which determines which objects have switched to local for the given layer. Returns the resulting array */
    private addToHiddenRemoteObjects(layerId: string, objects: LocalGeoJsonObject[]): string[] {
        const uniqueObjectIds = objects
            .reduce(
                (cum, object) => (cum.includes(this.getMapObjectRemoteId(object.objectId)) ? cum : [...cum, this.getMapObjectRemoteId(object.objectId)]),
                this.hiddenRemoteObjects[layerId] ?? [],
            )
            // Undefined remote IDs occur if the object has not yet been created on the server
            .filter((o) => o !== undefined);
        this.hiddenRemoteObjects[layerId] = uniqueObjectIds;
        return uniqueObjectIds;
    }

    /**
     * Hide the remote for objects for which local geometry exists
     */
    protected hideRemoteObjects() {
        const state = this.getCurrentState();
        Object.entries(state.geoJSON.value).forEach(([layerId, objects]) => {
            const layer = state.layers.value.find((layer) => layer.id === layerId);
            if (layer.storageScope === LayerStorageScope.LocalOnly) {
                return;
            }
            // if layer is preview layer then early exit as you can never have a remote preview layer
            if (this.preview) {
                return;
            }
            const uniqueHiddenObjectIds = this.addToHiddenRemoteObjects(layerId, objects);
            // Create filters
            const objectFilters = uniqueHiddenObjectIds.map((objectId) => ["!=", ["get", "id"], objectId]).filter(([eq, prop, id]) => id !== undefined);
            const levelFilter = createMapboxLevelFilter(state.currentLevel);
            const filter: FilterSpecification = ["all", ...objectFilters, levelFilter];

            // Find if there is an existing date filter
            const existingFilter = this.map.getFilter(layerId);
            const dateFilter = getLayerFilterByType(existingFilter, FilterType.Date);
            if (dateFilter != null) {
                filter.push(dateFilter);
            }

            const sublayerIds = this.aggregateLayers[layerId].map(({ id }) => id);
            sublayerIds.forEach((sublayerId) => {
                this.map.setFilter(sublayerId, filter);
            });
        });
    }

    protected hideLocalObjects(layerId: string, objectIds: string[]) {
        const layer = this.getStoredLayer(layerId);
        const localLayerId = layer.storageScope === LayerStorageScope.LocalOnly ? layerId : getLocalLayerID(layerId);
        const localLayer = this.map.getLayer(localLayerId);
        if (localLayer) {
            const objectFilters = objectIds.map((objectId) => ["!=", ["get", "id"], objectId]);
            const filter = ["all", ...objectFilters, createMapboxLevelFilter(this.getCurrentState().currentLevel)];
            this.map.setFilter(localLayerId, filter);
        }
    }

    protected unhideLocalObjects(layerId: string, objectIds: string[]) {
        const layer = this.getStoredLayer(layerId);
        const localLayerId = layer.storageScope === LayerStorageScope.LocalOnly ? layerId : getLocalLayerID(layerId);
        const localLayer = this.map.getLayer(localLayerId);
        if (localLayer) {
            const existingFilter = this.map.getFilter(localLayerId);
            const newFilter = existingFilter.filter((item) => !objectIds.includes(item?.[2]));
            this.map.setFilter(localLayerId, newFilter);
        }
    }

    /**
     * Get the map objects present at coordinates
     * @param x coordinate
     * @param y coordinate
     * relative to the map canvas
     */
    protected queryMapObjects(x: number, y: number, radius?: number) {
        let queryArgument: [[number, number], [number, number]] | [number, number];

        if (typeof radius === "number") {
            queryArgument = [
                [x - radius, y - radius],
                [x + radius, y + radius],
            ];
        } else {
            queryArgument = [x, y];
        }

        const queriedFeatures: GeoJSON.Feature[] = this.map.queryRenderedFeatures(queryArgument).map((queried) => {
            const queriedObject = {
                type: queried.type,
                id: queried.properties.id as string,
                geometry: queried.geometry,
                properties: queried.properties,
            } as GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;

            // If the queried feature belongs to the map internals then assign the layer id to the properties id
            if (internalMapLayerIds.includes(queried.layer.id)) {
                queriedObject.properties.layerid = queried.layer.id;
            }
            return queriedObject;
        });

        // Get 3d models under the cursor
        const threeDimensionalFeatures = this.models3D.queryRenderedFeatures();
        if (threeDimensionalFeatures.length !== 0) {
            return orderQueryRenderFeatures(queriedFeatures, threeDimensionalFeatures, this.layerMap);
        }
        return queriedFeatures;
    }

    protected queryMapObjectsByLayer(position: [number, number], layerIds: string[], radius: number): GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>[] {
        const [x, y] = position;
        const lowerRadius: [number, number] = [x - radius, y - radius];
        const upperRadius: [number, number] = [x + radius, y + radius];
        const features = this.map.queryRenderedFeatures([lowerRadius, upperRadius], { layers: layerIds });
        return features.map((queried) => ({
                type: queried.type,
                id: queried.properties?.id as string,
                geometry: queried.geometry,
                properties: queried.properties,
            } as GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>));
    }

    protected queryMapObjectsForSnapping(position: [number, number], radius: number) {
        const layerIds = [];
        Object.entries(this.aggregateLayers).forEach(([layerId, subLayers]) => {
            // If layer is remote, nothing will happen to it. If layer is local "_local" will be removed
            const remoteLayerId = getRemoteLayerId(layerId);
            // If valid then we know it is a user layer
            if (isValidUuid(remoteLayerId) || isAnalysisLayer(layerId)) {
                const hasBase = subLayers.some((subLayer) => subLayer.type === SubLayerType.BASE);
                subLayers.forEach((subLayer) => {
                    // Some areas don't have a base layer
                    if ((hasBase && subLayer.type === SubLayerType.BASE) || (!hasBase && (subLayer.type === SubLayerType.EXTRUSION || subLayer.type === SubLayerType.POLYGON_OUTLINE))) {
                        layerIds.push(subLayer.id);
                    }
                });
            }
        });
        return this.queryMapObjectsByLayer(position, layerIds, radius);
    }

    /**
     * Display the objects we have locally in the map
     */
    protected reflectLocalObjects(options: ReflectLocalObjectsOptions) {
        // TODO: This should have smarter diffing to only update what has changed
        const { geoJSON, datesFilter } = this.getCurrentState();

        Object.entries(geoJSON.value).forEach(([layerId, objects]) => {
            const layer = this.getStoredLayer(layerId);
            // Ensure that the layer can be found
            if (layer == null) {
                return;
            }

            let features = [];
            // If date filter is applied to the map, check if the object has been filtered out
            if (datesFilter.value.filter) {
                objects.forEach((object) => {
                    if (!object.feature.properties.dateFiltered) {
                        features.push(object.feature);
                    }
                });
            } else {
                features = objects.map((object) => object.feature);
            }

            const reflectSourceId = layer.storageScope === LayerStorageScope.LocalOnly ? layerId : getLocalLayerID(layerId);
            this.reflectSource(reflectSourceId, featureCollection(features));

            this.reflectAdditionalSources(layer, features, options.allowTextOnSelectedObjects);

            // TODO: Manage local layer names in a single-source way
            // Put the layer for local objects in the map
            this.ensureLayerInMap(tileLayerToLocalLayer(layer));
        });
    }

    /**
     * Apply the additional sources for each object
     */
    protected reflectAdditionalSources(layer: MapModuleLayer, features: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>[], allowTextOnSelectedObjects: boolean) {
        // Add the centroids to sources if Area style
        if (layer.styleType === StyleType.Area) {
            const centroidId = getCentroidLayerID(layer.id);
            const centroidFeatures = features
                .filter((v) => {
                    const isDrawingObject = !!this.getCurrentState().mapObjectsSelected.value.find((o) => o.objectId === v.properties.id);
                    // While drawing, we do not want to render the text label
                    if (!allowTextOnSelectedObjects && isDrawingObject) {
                        return false;
                    }
                    return true;
                })
                .map((val) => centerOfMass(val, { properties: { ...val.properties } }));
            const localCentroidLayerId = layer.storageScope === LayerStorageScope.LocalOnly ? centroidId : getLocalLayerID(centroidId);
            this.reflectSource(localCentroidLayerId, featureCollection(centroidFeatures));
        }
    }

    protected removeTextLabels(removals: { objectId: string; layerId: string }[]) {
        const groupedByLayer = groupCompositionMapObjectByLayer(removals);

        Object.entries(groupedByLayer).forEach(([layerId]) => {
            const layer = this.getStoredLayer(layerId);
            const centroidId = layer.storageScope === LayerStorageScope.LocalOnly ? getCentroidLayerID(layerId) : getLocalLayerID(getCentroidLayerID(layerId));
            const localCentroidSource = this.map.getStyle().sources[centroidId];
            if (localCentroidSource.type === "geojson") {
                const centroidsWithOmissions = (localCentroidSource.data as GeoJSON.FeatureCollection).features.filter(
                    (feature) => !removals.some((removal) => removal.objectId === feature.properties.id),
                );

                this.reflectSource(centroidId, featureCollection(centroidsWithOmissions));
            }
        });
    }

    protected reflectSource(sourceId: string, value: GeoJSON.FeatureCollection<GeoJSON.Geometry>) {
        const layer = this.getStoredLayer(sourceId);
        const isModel = this.models3D.doesLayerExist(sourceId);
        const modelSubLayer = this.getModelSubLayer(sourceId);
        const isLineOfModels = isModel && modelSubLayer?.type === SubLayerType.LINE_MODEL;

        if (isModel || isLineOfModels) {
            this.models3D.updateMapObjects(layer, value as GeoJSON.FeatureCollection<GeoJSON.Point, MapObjectProperties>);
        }

        if (!isModel || isLineOfModels) {
            const source = this.map.getSource(sourceId);
            if (source !== undefined) {
                (source as GeoJSONSource).setData(value);
            } else {
                this.map.addSource(sourceId, { type: "geojson", data: value });
            }
        }
        this.queryMapObjectsCached.clear();
    }

    protected createEphemeralObjects(objects: (CompositionMapObject | Optional<CompositionMapObject, "geojson">)[]): void {
        const compObjects = this.getCompositionForMapObjects(objects);
        const objectsFor3DLayers = createEphemeralObjectsFor3DLayers(compObjects, (layerId) => {
            const layer = this.getStoredLayer(layerId);
            return isModelLayer(layer);
        });
        Object.keys(objectsFor3DLayers).forEach((layerId) => {
            const value = objectsFor3DLayers[layerId];
            const layer = this.getStoredLayer(layerId);
            this.models3D.updateMapObjects(layer, featureCollection(value));
        });
        super.createEphemeralObjects(objects, compObjects);
    }

    protected removeDrawingControls(): void {
        super.removeDrawingControls();
    }

    /**
     * Put the provided layer in the map if it is not already there, otherwise do nothing
     * @param layer The style for the layer to put in the map
     */
    protected ensureLayerInMap(layer?: MapModuleLayer) {
        const aggregated = this.aggregateLayers[layer.id];
        if (aggregated !== undefined) {
            const subLayerIds = aggregated.map(({ id }) => id);

            const layersNotInMap = subLayerIds.filter((id) => !this.map.getLayer(id));
            if (layersNotInMap.length === 0) {
                return;
            }

            throw new Error(`Sublayers: ${layersNotInMap.join(" ")} were present in this.aggregateLayers, but were not present in the map. Something has gone wrong.`);
        }

        const layerFilter = this.addDateFilterToIventisLayer(layer, createMapboxLevelFilter(this.getCurrentState().currentLevel));
        const aggregateLayers = this.iventisLayerToAggregateLayer(layer, layerFilter);
        this.aggregateLayers[layer.id] = aggregateLayers.map(({ type, id }) => ({ type, id }));
        sortSubLayers(aggregateLayers).forEach(async ({ layer: aggregateLayer }) => {
            if (!this.map.getLayer(aggregateLayer.id) || !this.models3D.doesLayerExist(aggregateLayer.id)) {
                const mapModuleLayer = this.getStoredLayer(aggregateLayer.id);
                // Where we add the layer depends on the style type
                const aboveLayerId = getAboveLayerId(aggregateLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), layer.id);
                if (isModelLayer(mapModuleLayer)) {
                    this.addLayer(mapModuleLayer, false);
                } else {
                    if (aggregateLayer.type !== "custom" && !this.map.getSource(aggregateLayer.source.toString())) {
                        this.setEmptySource(aggregateLayer.source.toString());
                    }
                    this.map.addLayer(aggregateLayer as LayerSpecification, aboveLayerId);
                }
            }
        });
    }

    /** Gets the centre of the map (screen projection) */
    protected getMapCentre(cb: (position: GeoJSON.Position) => void, isMoving = false) {
        if (isMoving) {
            this.map.once("idle", () => {
                cb(this.map.getCenter().toArray());
            });
        } else {
            cb(this.map.getCenter().toArray());
        }
    }

    /**
     * Begin composition (geometry editing) for the provided
     * @param object object id and layer id to edit
     */
    public beginCompositionBehaviour(objects: CompositionMapObject[], duplication?: boolean) {
        super.beginCompositionBehaviour(objects, duplication);
        // Ensures that disabling map zoom is done after adding the read behaviour
        this.map.once("idle", () => {
            this.map.doubleClickZoom.disable();
        });
    }

    public addReadBehaviour(enableDoubleClickZoom = true) {
        super.addReadBehaviour(enableDoubleClickZoom);
        if (enableDoubleClickZoom) {
            // Ensures that the finish composition behaviour has been ran before double click zooming enabled
            this.map.once("idle", () => {
                this.map.doubleClickZoom.enable();
            });
        }
    }

    protected onMoveEnd(callback) {
        let removed = false;
        const onMoveEndFunction = () => {
            if (removed) {
                return;
            }
            callback();
        };
        this.map.on("moveend", onMoveEndFunction);

        return {
            remove: () => {
                removed = true;
                this.map.off("moveend", onMoveEndFunction);
            },
        };
    }

    protected onMouseMove(callback: (event: MoveEvent) => void, options?: { queryRadius?: number; layerId: string; disableQuerying?: boolean }) {
        let removed = false;

        const mouseMoveFunction = throttle(
            (event: MapMouseEvent) => {
                if (removed) {
                    return;
                }

                let queriedObjects: GeoJSON.Feature[];
                if (!options?.disableQuerying) {
                    queriedObjects = this.queryMapObjectsWithCaching(event.point.x, event.point.y, options?.queryRadius);
                }

                if (!removed) {
                    callback({
                        objects: queriedObjects,
                        lng: event.lngLat.lng,
                        lat: event.lngLat.lat,
                    });
                }
            },
            MAP_FRAME_TIME, // Throttles updates the map frame rate (60fps / 16.6ms frame-time)
            { trailing: false, leading: true },
        );

        if (options?.layerId !== undefined) {
            this.map.on("mousemove", options.layerId, mouseMoveFunction);

            return {
                remove: () => {
                    removed = true;
                    this.map.off("mousemove", options.layerId, mouseMoveFunction);
                },
            };
        }
        this.map.on("mousemove", mouseMoveFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("mousemove", mouseMoveFunction);
            },
        };
    }

    protected onMouseUp(callback: (event: MoveEvent) => void) {
        let removed = false;

        const mouseUpFunction = (event: MapMouseEvent) => {
            if (removed) {
                return;
            }

            callback({
                objects: [],
                lng: event.lngLat.lng,
                lat: event.lngLat.lat,
            });
        };
        this.map.on("mouseup", mouseUpFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("mouseup", mouseUpFunction);
            },
        };
    }

    private cancelClickEventIfDrag(onClick: (event: MapMouseEvent) => void, clickWhenDownAbsent: boolean) {
        let startPosition = [];
        const travelThreshold = 0;

        return {
            onMouseDown: (event: MapMouseEvent) => {
                // When comment is open original event is null
                if (event.originalEvent) {
                    startPosition = [event.originalEvent.clientX, event.originalEvent.clientY];
                }
            },
            onMouseUp: (event: MapMouseEvent) => {
                if (startPosition.length === 0 && clickWhenDownAbsent) {
                    onClick(event);
                    return;
                }
                const endPosition = [event.originalEvent.clientX, event.originalEvent.clientY];
                if (Math.abs(startPosition[0] - endPosition[0]) > travelThreshold || Math.abs(startPosition[1] - endPosition[1]) > travelThreshold) {
                    return;
                }
                onClick(event);
            },
        };
    }

    protected onClick(callback: (event: ClickEvent) => void, layerId?: string) {
        let removed = false;

        const { onMouseDown, onMouseUp } = this.cancelClickEventIfDrag((event) => {
            if (removed) return;
            if (event.originalEvent.button === 2) return;
            const queriedObjects = this.queryMapObjectsWithCaching(event.point.x, event.point.y);
            callback({
                objects: queriedObjects
                    .filter((ob) => isValidUuid(ob.id) || [...internalMapLayerIds, ...systemLayerIds, ...analysisLayerIds].some((id) => id === ob.properties.layerid))
                    .map((ob) => ({
                        layerId: this.getMainLayerId(ob.properties.layerid),
                        objectId: (ob.properties.baseObjectId ?? ob.id) as string,
                        properties: ob.properties,
                        geometry: ob.geometry,
                    })),
                lng: event.lngLat.lng,
                lat: event.lngLat.lat,
            });
        }, false);

        const cleanUp = () => {
            removed = true;
            this.map.off("mousedown", layerId ?? [], onMouseDown);
            this.map.off("mouseup", layerId ?? [], onMouseUp);
        };

        if (layerId !== undefined) {
            this.map.on("mousedown", layerId, onMouseDown);
            this.map.on("mouseup", layerId, onMouseUp);
            return {
                remove: cleanUp,
            };
        }

        this.map.on("mousedown", onMouseDown);
        this.map.on("mouseup", onMouseUp);
        return {
            remove: cleanUp,
        };
    }

    protected onMouseDown(callback: (event: ClickEvent) => void, layerIds?: string[]) {
        let removed = false;
        const mouseDownFunction = (mouseEvent) => {
            if (removed) {
                removed = true;
            }
            const queriedObjects = this.queryMapObjectsWithCaching(mouseEvent.point.x, mouseEvent.point.y);
            callback({
                objects:
                    queriedObjects.map((queriedObject) => ({
                        layerId: this.getMainLayerId(queriedObject.properties.layerid),
                        objectId: (queriedObject.properties.baseObjectId ?? queriedObject.id) as string,
                        properties: queriedObject.properties,
                        geometry: queriedObject.geometry,
                    })) || [],
                lng: mouseEvent.lngLat.lng,
                lat: mouseEvent.lngLat.lat,
            });
        };

        if (layerIds != null && layerIds?.length > 0) {
            this.map.on("mousedown", layerIds, mouseDownFunction);
            return {
                remove: () => {
                    removed = true;
                    this.map.off("mousedown", layerIds, mouseDownFunction);
                },
            };
        }

        this.map.on("mousedown", mouseDownFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("mousedown", mouseDownFunction);
            },
        };
    }

    protected onFocus(callback: (event: FocusEvent) => void): Listener {
        let removed = false;
        const focusFunction = (focusEvent) => {
            if (removed) {
                removed = true;
            }

            callback(focusEvent);
        };

        this.map.getCanvas().addEventListener("focus", focusFunction);
        return {
            remove: () => {
                removed = true;
                document.removeEventListener("focus", focusFunction);
            },
        };
    }

    protected onFocusOut(callback: (event: FocusEvent) => void): Listener {
        let removed = false;
        const focusOutFunction = (focusEvent) => {
            if (removed) {
                removed = true;
            }

            callback(focusEvent);
        };

        this.map.getCanvas().addEventListener("focusout", focusOutFunction);
        return {
            remove: () => {
                removed = true;
                document.removeEventListener("focusout", focusOutFunction);
            },
        };
    }

    public onMapIdle(callback: (event: MoveEvent) => void, options: { disableMapQuerying: boolean; listenOnce: boolean }): Listener {
        let removed = false;
        const mapIdleFunction = () => {
            if (removed) {
                return;
            }

            if (options.listenOnce) {
                removed = true;
                this.map.off("idle", mapIdleFunction);
            }

            if (!this.lastMouse || options.disableMapQuerying) {
                callback({
                    objects: [],
                    lng: undefined,
                    lat: undefined,
                });
                return;
            }

            const queriedObjects = this.queryMapObjectsWithCaching(this.lastMouse.x, this.lastMouse.y);

            callback({
                objects: queriedObjects,
                lng: this.lastMouse.lng,
                lat: this.lastMouse.lat,
            });
        };

        this.map.once("idle", mapIdleFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("idle", mapIdleFunction);
            },
        };
    }

    protected OnMapMoveStart(callback: () => void): Listener {
        let removed = false;
        const mapMoveStartFunction = () => {
            if (removed) {
                return;
            }
            callback();
        };

        this.map.on("movestart", mapMoveStartFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("movestart", mapMoveStartFunction);
            },
        };
    }

    protected OnMapMoveEnd(callback: () => void): Listener {
        let removed = false;
        const mapMoveEndFunction = () => {
            if (removed) {
                return;
            }
            callback();
        };

        this.map.on("moveend", mapMoveEndFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("moveend", mapMoveEndFunction);
            },
        };
    }

    protected removeMouseDownListener(layerId?: string) {
        if (layerId !== undefined) {
            // eslint-disable-next-line no-console
            console.error("Removing a listener for a specific layer is not yet implemented");
        }
        if (this.mouseDownFunction !== undefined) {
            this.map.off("mousedown", this.mouseDownFunction);
            this.mouseDownFunction = undefined;
        }
    }

    protected onFirstRender(callback: () => void) {
        this.onFirstRenderFunction = () => {
            callback();
            this.map.off("styledata", this.onFirstRenderFunction);
        };
        this.map.on("styledata", this.onFirstRenderFunction);
    }

    protected removeOnMapLoadedListener() {
        if (this.onFirstRenderFunction !== undefined) {
            this.map.off("styledata", this.onFirstRenderFunction);
            this.onFirstRenderFunction = undefined;
        }
    }

    protected onDoubleClick(callback: (event: ClickEvent) => void) {
        let removed = false;
        const doubleClickFunction = (event: MapMouseEvent) => {
            if (removed) {
                return;
            }
            callback({
                objects: [],
                lng: event.lngLat.lng,
                lat: event.lngLat.lat,
            });
        };

        this.map.on("dblclick", doubleClickFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("dblclick", doubleClickFunction);
            },
        };
    }

    protected getUserLocation() {
        const btn: HTMLButtonElement = document.querySelector(".mapboxgl-ctrl-geolocate");
        btn.click();
    }

    protected onRightClick(callback: (event: ClickEvent) => void) {
        let removed = false;
        const { onMouseDown, onMouseUp } = this.cancelClickEventIfDrag((event) => {
            if (removed) return;
            // Ignore left mouse button
            if (event.originalEvent.button === 0) return;
            const queriedObjects = this.queryMapObjectsWithCaching(event.point.x, event.point.y);

            callback({
                objects: queriedObjects.map((ob) => ({
                    layerId: this.getMainLayerId(ob.properties.layerid),
                    objectId: (ob.properties.baseObjectId ?? ob.id) as string,
                    properties: ob.properties,
                    geometry: ob.geometry,
                })),
                lng: event.lngLat.lng,
                lat: event.lngLat.lat,
            });
        }, true);

        // Rotate start captures the mouse event if the user begins dragging
        this.map.on("rotatestart", onMouseDown);
        // Mouse down captures the mouse event if the user does not drag
        this.map.on("mousedown", onMouseDown);
        // Context menu is always called for right clicks
        this.map.on("contextmenu", onMouseUp);
        return {
            remove: () => {
                removed = true;
                this.map.off("rotatestart", onMouseDown);
                this.map.off("mousedown", onMouseDown);
                this.map.off("contextmenu", onMouseUp);
            },
        };
    }

    protected onMouseOut(callback: (event: MoveEvent) => void) {
        let removed = false;
        const mouseOutFunction = (event: MapMouseEvent) => {
            this.setEmptySource(measurementSourceId);
            if (removed) {
                return;
            }
            callback({ objects: [], lat: event.lngLat.lat, lng: event.lngLat.lng });
        };
        this.map.on("mouseout", mouseOutFunction);
        return {
            remove: () => {
                this.map.off("mouseout", mouseOutFunction);
                removed = true;
            },
        };
    }

    protected onMouseOver(callback: (event: MoveEvent) => void) {
        let removed = false;
        const mouseOverFunction = (event: MapMouseEvent) => {
            if (removed) {
                return;
            }
            callback({ objects: [], lat: event.lngLat.lat, lng: event.lngLat.lng });
        };
        this.map.on("mouseover", mouseOverFunction);
        return {
            remove: () => {
                this.map.off("mouseover", mouseOverFunction);
                removed = true;
            },
        };
    }

    protected onEnterKeyPress(callback: (event: KeyboardEvent) => void) {
        const removed = false;
        const onEnterFunction = (event: KeyboardEvent) => {
            if (removed) {
                return;
            }
            if (event.key !== "Enter") return;
            callback(event);
        };

        this.map.getCanvas().addEventListener("keyup", onEnterFunction);

        return {
            remove: () => document.removeEventListener("keyup", onEnterFunction),
        };
    }

    protected onPanStart(callback: () => void) {
        let removed = false;
        const panStartFunction = () => !removed && callback();
        this.map.on("dragstart", panStartFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("dragstart", panStartFunction);
            },
        };
    }

    protected onPanEnd(callback: () => void) {
        let removed = false;
        const panEndFunction = () => !removed && callback();
        this.map.on("dragend", panEndFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("dragend", panEndFunction);
            },
        };
    }

    protected onZoomStart(callback: () => void) {
        let removed = false;
        const zoomStartFunction = () => !removed && callback();
        this.map.on("zoomstart", zoomStartFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("zoomstart", zoomStartFunction);
            },
        };
    }

    protected onZoomEnd(callback: () => void) {
        let removed = false;
        const zoomEndFunction = () => !removed && callback();
        this.map.on("zoomend", zoomEndFunction);
        return {
            remove: () => {
                removed = true;
                this.map.off("zoomend", zoomEndFunction);
            },
        };
    }

    protected onMapRotateStart(callback: () => void): Listener {
        let removed = false;
        const rotateStartFuncion = () => !removed && callback();
        this.map.on("rotatestart", rotateStartFuncion);
        return {
            remove: () => {
                removed = true;
                this.map.off("rotatestart", rotateStartFuncion);
            },
        };
    }

    protected onMapRotateEnd(callback: (bearing: number) => void): Listener {
        let removed = false;
        const rotateEndFuncion = () => !removed && callback(this.map.getBearing());
        this.map.on("rotateend", rotateEndFuncion);
        return {
            remove: () => {
                removed = true;
                this.map.off("rotateend", rotateEndFuncion);
            },
        };
    }

    protected removeSublayer(originalLayer: MapModuleLayer, type: SubLayerType, sourceLocation: LayerSourceLocation) {
        const layer = sourceLocation === LayerSourceLocation.Local ? tileLayerToLocalLayer(originalLayer) : originalLayer;
        const aggregateLayer = this.aggregateLayers[layer.id];
        const subLayerIndex = aggregateLayer.findIndex(({ type: aggregateLayerType }) => aggregateLayerType === type);
        if (subLayerIndex === -1) {
            // Sublayer not present in aggregate layers
            return;
        }
        const { id } = aggregateLayer[subLayerIndex];
        this.map.removeLayer(id);
        this.aggregateLayers[layer.id] = aggregateLayer.filter((_, index) => index !== subLayerIndex);
    }

    protected addSublayer(originalLayer: MapModuleLayer, type: SubLayerType, sourceLocation: LayerSourceLocation) {
        const layer = sourceLocation === LayerSourceLocation.Local ? tileLayerToLocalLayer(originalLayer) : originalLayer;
        const levelFilter = createMapboxLevelFilter(this.getCurrentState().currentLevel);
        // IMPORTANT: Area layers can not have a base layer, in the case of where their fill is removed. This has consequences for ordering (not implemented at this time).
        switch (type) {
            case SubLayerType.BASE: {
                // Only work for areas
                if (originalLayer.styleType !== StyleType.Area) {
                    throw new Error(`Removing base layer for type '${originalLayer.styleType}' is not implemented`);
                }
                const baseLayer = iventisAreaToBaseSublayer(layer, layer.id, levelFilter);
                this.aggregateLayers[layer.id].push({ id: baseLayer.id, type: SubLayerType.BASE });
                const aboveLayerId = getAboveLayerId(baseLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), originalLayer.id);
                this.map.addLayer(baseLayer, aboveLayerId);
                this.hideRemoteObjects();
                break;
            }
            case SubLayerType.EXTRUSION: {
                // Only work for areas
                if (originalLayer.styleType !== StyleType.Area) {
                    throw new Error(`Removing base layer for type '${originalLayer.styleType}' is not implemented`);
                }
                const extrusionLayer = iventis3DAreaToBaseSublayer(layer, layer.id, levelFilter);
                this.aggregateLayers[layer.id].push({ id: extrusionLayer.id, type: SubLayerType.EXTRUSION });
                const aboveLayerId = getAboveLayerId(extrusionLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), originalLayer.id);
                this.map.addLayer(extrusionLayer, aboveLayerId);
                this.hideRemoteObjects();
                break;
            }
            case SubLayerType.LINE_ICON:
                {
                    const subLayer = createArrowsSublayer(layer, levelFilter);
                    this.aggregateLayers[layer.id].push({ id: subLayer.id, type: SubLayerType.LINE_ICON });
                    const aboveLayerId = getAboveLayerId(subLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), originalLayer.id);
                    this.map.addLayer(subLayer, aboveLayerId);
                }
                break;
            case SubLayerType.LINE_OUTLINE:
            case SubLayerType.POLYGON_OUTLINE:
                {
                    const style = getLayerStyle(layer) as LineStyle | AreaStyle;
                    const subLayer = createOutlineSubLayer(layer, layer.source, layer.storageScope, style, layer.visible, levelFilter);
                    this.aggregateLayers[layer.id].push({ id: subLayer.id, type });
                    const aboveLayerId = getAboveLayerId(subLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), originalLayer.id);
                    this.map.addLayer(subLayer, aboveLayerId);
                    this.hideRemoteObjects();
                }
                break;
            case SubLayerType.POLYGON_TEXT:
            case SubLayerType.LINE_TEXT:
            case SubLayerType.POINT_TEXT:
                {
                    const style = getLayerStyle(layer) as LineStyle | PointStyle | AreaStyle;
                    const subLayer = createTextSublayer(
                        layer,
                        style,
                        this.preview,
                        {
                            projectDataFields: this.getProjectDataFields(),
                            unitOfMeasurement: this.getUnitOfMeasurement(),
                        },
                        levelFilter,
                        this.applyTextFieldExpression.bind(this),
                        this.getAttributeListItems,
                    );
                    this.aggregateLayers[layer.id].push({ id: subLayer.id, type });
                    const aboveLayerId = getAboveLayerId(subLayer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), originalLayer.id);
                    this.map.addLayer(subLayer, aboveLayerId);
                    this.hideRemoteObjects();
                }
                break;
            default:
                throw new Error(`Type '${type}' does not have a case`);
        }
    }

    protected updateStyle<TStyle extends UnionOfStyles = UnionOfStyles>(layerId: string, styleType: StyleType, styleChanges: StylePropertyToValueMap<TStyle>[]) {
        this.changeLayerStyle(styleChanges, styleType, this.getStoredLayer(layerId));
    }

    private async changeLayerStyle<TStyle extends UnionOfStyles = UnionOfStyles>(styleChanges: StylePropertyToValueMap<TStyle>[], styleType: StyleType, layer: MapModuleLayer) {
        const localLayer = tileLayerToLocalLayer(layer);
        const localSourceIsAdded = this.localSourceIsAdded(layer.id, layer.storageScope === LayerStorageScope.LocalOnly);

        if (layer.storageScope === LayerStorageScope.LocalAndTiles) {
            // Modify remote layers
            if (styleChanges.some((change) => change.styleProperty === "styleType")) {
                this.removeLayer(layer.id);
                this.addLayer(layer, false);
            } else {
                this.changeStyle(styleChanges, layer, LayerSourceLocation.Remote);
            }
        }

        if (localSourceIsAdded || layer.storageScope === LayerStorageScope.LocalOnly) {
            // Modify local layers
            if (styleChanges.some((change) => change.styleProperty === "styleType")) {
                this.removeLayer(localLayer.id);
                this.addLayer(localLayer, false);
            } else {
                this.changeStyle(styleChanges, layer, LayerSourceLocation.Local);
            }
        }

        const highlightLayerId = `${removeLayerIdSuffixes(localLayer.source)}_${highlightInfix}`;
        this.updateHighlightStyles(highlightLayerId, layer);
    }

    private updateHighlightStyles(highlightLayerId: string, layer: MapModuleLayer) {
        const updatePointHighlights = (highlightLayerId: string) => {
            if (layer.styleType !== StyleType.Point || !this.checkIfLayerExists(highlightLayerId)) {
                return;
            }
            const expression = styleValueToMapboxStyleValue(
                modifyStyleValueFundamentalValues<number>(layer.pointStyle.radius, (value) => {
                    const outlineOffset = getStaticStyleValue(layer.pointStyle.outline) ? getStaticStyleValue(layer.pointStyle.outlineWidth) : 0;
                    return getHighlightCircleRadius(value + outlineOffset);
                }),
            );
            this.map.setPaintProperty(highlightLayerId, "circle-radius", expression);
        };

        /** When layer's outline changes the highlights offset value needs to be changed accordingly */
        const updateLayersWithOutlines = (highlightLayerId: string) => {
            if (layer.styleType === StyleType.Area) {
                const newOffset = layer.areaStyle.outline ? this.calculateHighlightLayerOffset(layer) : 0;
                if (this.map.getLayer(highlightLayerId)) {
                    this.map.setPaintProperty(highlightLayerId, "line-offset", newOffset);
                }
            }

            if (layer.styleType === StyleType.Line) {
                const newWidth = layer.lineStyle.outline ? this.calculateHighlightWidth(layer) : 0;
                if (this.map.getLayer(highlightLayerId)) {
                    this.map.setPaintProperty(highlightLayerId, "line-width", newWidth);
                }
            }
        };

        const localSourceIsAdded = this.localSourceIsAdded(layer.id, layer.storageScope === LayerStorageScope.LocalOnly);

        if (this.preview) {
            // Preview has no highlights
            return;
        }

        // Update remote highlight layer
        updatePointHighlights(highlightLayerId);
        updateLayersWithOutlines(highlightLayerId);

        if (localSourceIsAdded) {
            // Update local highlight layer
            updatePointHighlights(`${highlightLayerId}_${localSuffix}`);
            updateLayersWithOutlines(`${highlightLayerId}_${localSuffix}`);
        }
    }

    private changeStyle<TStyle extends UnionOfStyles = UnionOfStyles>(styleChanges: StylePropertyToValueMap<TStyle>[], layer: MapModuleLayer, sourceLocation: LayerSourceLocation) {
        const localLayer = tileLayerToLocalLayer(layer);
        const localSourceIsAdded = this.localSourceIsAdded(layer.id, layer.storageScope === LayerStorageScope.LocalOnly);
        const mapboxBaseLayerId = {
            [LayerSourceLocation.Local]: localSourceIsAdded ? localLayer.id : undefined,
            [LayerSourceLocation.Remote]: layer.id,
        }[sourceLocation];

        const { styleType } = layer;

        if ((styleType === StyleType.Model && styleChanges.length > 0) || styleType === StyleType.LineModel) {
            if (this.models3D.doesLayerNeedRecreating(layer)) {
                this.removeLayer(layer.id);
                this.addLayer(layer, false);
                const featureCollection = localGeoJsonToFeatureCollection<GeoJSON.LineString | GeoJSON.Point>(this.getLocalLayerGeometry(layer.id));
                this.models3D.updateMapObjects(layer, featureCollection);
            } else {
                this.models3D.updateStyle(layer.id, getModelLayerStyle(layer), styleChanges);
                if (
                    styleChanges.some(
                        ({ styleProperty }) =>
                            styleProperty === "rotation" ||
                            styleProperty === "spacing" ||
                            styleProperty === "width" ||
                            styleProperty === "height" ||
                            styleProperty === "length" ||
                            styleProperty === "scale" ||
                            styleProperty === "modelOffset" ||
                            styleProperty === "colour" ||
                            styleProperty === "customColour",
                    )
                ) {
                    const featureCollection = localGeoJsonToFeatureCollection<GeoJSON.LineString>(this.getLocalLayerGeometry(layer.id));
                    this.models3D.updateMapObjects(layer, featureCollection);
                }
            }
        } else {
            styleChanges.forEach(({ styleProperty, value: _value }) => {
                // In the absence of strict mode, we have to cast to any here
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const value = _value as StyleValue<any>;
                switch (styleType) {
                    case StyleType.Area:
                        const dimension = getStaticStyleValue<AreaDimension>(layer.areaStyle.dimension);
                        switch (styleProperty) {
                            case "colour":
                                this.map.setPaintProperty(
                                    mapboxBaseLayerId,
                                    dimension === AreaDimension.Two ? "fill-color" : "fill-extrusion-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "opacity":
                                this.map.setPaintProperty(
                                    mapboxBaseLayerId,
                                    dimension === AreaDimension.Two ? "fill-opacity" : "fill-extrusion-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "outline":
                                if (getStaticStyleValue(value)) {
                                    this.addSublayer(layer, SubLayerType.POLYGON_OUTLINE, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.POLYGON_OUTLINE, sourceLocation);
                                }
                                break;
                            case "outlineColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_OUTLINE,
                                    "line-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "outlineOpacity":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_OUTLINE,
                                    "line-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "outlineWidth":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_OUTLINE,
                                    "line-width",
                                    styleValueToMapboxStyleValue(value),

                                );
                                // Ensure the outline is not overlapping the polygon
                                const offset = calculateOffsetForPolygonOutline(getStaticStyleValue(value));
                                const subLayer = this.aggregateLayers[layer.id].find((al) => al.type === SubLayerType.POLYGON_OUTLINE);
                                this.map.setPaintProperty(subLayer.id, "line-offset", offset);
                                break;
                            case "outlineBlur":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_OUTLINE,
                                    "line-blur",
                                    styleValueToMapboxStyleValue(value),

                                );
                                break;
                            case "dimension":
                                if (getStaticStyleValue(value) === AreaDimension.Three) {
                                    this.removeSublayer(layer, SubLayerType.BASE, sourceLocation);
                                    this.addSublayer(layer, SubLayerType.EXTRUSION, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.EXTRUSION, sourceLocation);
                                    this.addSublayer(layer, SubLayerType.BASE, sourceLocation);
                                }
                                break;
                            case "height":
                                this.map.setPaintProperty(mapboxBaseLayerId, "fill-extrusion-height", styleValueToMapboxStyleValue(value));
                                break;
                            case "fill":
                                if (getStaticStyleValue(value)) {
                                    this.addSublayer(layer, SubLayerType.BASE, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.BASE, sourceLocation);
                                }
                                break;
                            case "text":
                                if (getStaticStyleValue(value)) {
                                    // Add text
                                    this.addSublayer(layer, SubLayerType.POLYGON_TEXT, sourceLocation);
                                } else {
                                    // Remove text
                                    this.removeSublayer(layer, SubLayerType.POLYGON_TEXT, sourceLocation);
                                }
                                break;
                            case "textColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textSize":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-size",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOverlap":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-allow-overlap",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-halo-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineWidth":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-halo-width",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOpacity":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textBold":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-font",
                                    styleValueToMapboxStyleValue(value) ? boldFontStack : regularFontStack,
                                );
                                break;
                            case "textContent": {
                                const textField = textContentValueToMapboxStyleValue(
                                    value,
                                    {
                                        projectDataFields: this.getProjectDataFields(),
                                        unitOfMeasurement: this.getUnitOfMeasurement(),
                                    },
                                    this.getAggregateSubLayerId(mapboxBaseLayerId, SubLayerType.POLYGON_TEXT),
                                    layer.id,
                                    this.applyTextFieldExpression,
                                    this.getAttributeListItems,
                                );
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POLYGON_TEXT,
                                    "text-field",
                                    textField,
                                );
                            }
                                break;
                            case "objectOrder":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "fill-sort-key", styleValueToMapboxStyleValue(value));
                                break;
                            default:
                                // eslint-disable-next-line no-console
                                console.error(`Area property '${styleProperty?.toString()}' does not have a case`);
                        }
                        break;
                    case StyleType.Line:
                        switch (styleProperty) {
                            case "arrows":
                                if (getStaticStyleValue(value)) {
                                    // Add arrows
                                    this.addSublayer(layer, SubLayerType.LINE_ICON, sourceLocation);
                                } else {
                                    // Remove arrows
                                    this.removeSublayer(layer, SubLayerType.LINE_ICON, sourceLocation);
                                }
                                break;
                            case "arrowSize": {
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_ICON,
                                    "icon-size",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            }
                            case "arrowOpacity": {
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_ICON,
                                    "icon-opacity",
                                    styleValueToMapboxStyleValue(value)
                             );
                                break;
                            }
                            case "arrowColour": {
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_ICON,
                                    "icon-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            }
                            case "arrowColourMatchesLine":
                                // handled by the changing arrow colour property
                                break;
                            case "arrowSpacing": {
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_ICON,
                                    "symbol-spacing",
                                    styleValueParser.symbolSpacing(styleValueToMapboxStyleValue(value)),
                                );
                                break;
                            }
                            case "iconPlacement": {
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_ICON,
                                    "symbol-placement",
                                    styleValueParser.iconPlacement(getStaticStyleValue(value)),
                                );
                                break;
                            }
                            case "colour":
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-color", styleValueToMapboxStyleValue(value));
                                // If arrow colour is meant to sync, change arrow colour as well
                                if (getStaticStyleValue(layer.lineStyle.arrows) && getStaticStyleValue(layer.lineStyle.arrowColourMatchesLine)) {
                                    this.editAggregateLayerPaintProperty(
                                        mapboxBaseLayerId,
                                        SubLayerType.LINE_ICON,
                                        "icon-color",
                                        styleValueToMapboxStyleValue(value),
                                    );
                                    // For some reason will in preview mode mapbox is not updating the arrow colour when the line colour changes
                                    // We need to update the local geojson and then the arrow colour will change
                                    if (this.preview) {
                                        this.reflectLocalObjects({ allowTextOnSelectedObjects: true });
                                    }
                                }
                                break;
                            case "opacity":
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-opacity", styleValueToMapboxStyleValue(value));
                                break;
                            case "blur":
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-blur", styleValueToMapboxStyleValue(value));
                                break;
                            case "type":
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-dasharray", styleValueParser.lineType(getStaticStyleValue<LineType>(value)));
                                break;
                            case "width":
                                const { minZoom, maxZoom } = zoomableValueToMinMaxZoomLevels(value.staticValue);
                                this.map.setLayerZoomRange(mapboxBaseLayerId, minZoom, maxZoom);
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-width", styleValueToMapboxStyleValue(value));
                                if (layer.lineStyle.outline) {
                                    // If the line has an outline need to also change the width of it
                                    const outlineWidth = calculateLineWidthForLineOutline(value, layer.lineStyle.outlineWidth);
                                    this.editAggregateLayerPaintProperty(mapboxBaseLayerId, SubLayerType.LINE_OUTLINE, "line-width", outlineWidth);
                                }
                                break;
                            case "offset":
                                const offsetValue = getStaticStyleValue(value);
                                this.map.setPaintProperty(mapboxBaseLayerId, "line-offset", styleValueToMapboxStyleValue(value));

                                // When the offset of a line changes we need to apply the same offset to all the sublayers
                                if (layer.lineStyle.outline) {
                                    this.editAggregateLayerPaintProperty(mapboxBaseLayerId, SubLayerType.LINE_OUTLINE, "line-offset", offsetValue);
                                }

                                if (layer.lineStyle.text) {
                                    this.editAggregateLayerPaintProperty(mapboxBaseLayerId, SubLayerType.LINE_TEXT, "text-translate", [offsetValue, 0]);
                                }

                                if (layer.lineStyle.arrows) {
                                    this.editAggregateLayerLayoutProperty(mapboxBaseLayerId, SubLayerType.LINE_ICON, "icon-offset", [offsetValue, 0]);
                                }

                                break;
                            case "join":
                                const joinStyleValue = styleValueToMapboxStyleValue(value);
                                this.map.setLayoutProperty(mapboxBaseLayerId, "line-join", joinStyleValue.toLowerCase());
                                break;
                            case "end":
                                const capStyleValue = styleValueToMapboxStyleValue(value);
                                this.map.setLayoutProperty(mapboxBaseLayerId, "line-cap", capStyleValue.toLowerCase());
                                break;
                            case "text":
                                if (getStaticStyleValue(value)) {
                                    this.addSublayer(layer, SubLayerType.LINE_TEXT, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.LINE_TEXT, sourceLocation);
                                }
                                break;
                            case "textColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textSize":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-size",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOverlap":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-allow-overlap",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-halo-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineWidth":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-halo-width",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOpacity":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textBold":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-font",
                                    styleValueToMapboxStyleValue(value) ? boldFontStack : regularFontStack,
                                );
                                break;
                            case "textContent":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_TEXT,
                                    "text-field",
                                    textContentValueToMapboxStyleValue(
                                        value,
                                        {
                                            projectDataFields: this.getProjectDataFields(),
                                            unitOfMeasurement: this.getUnitOfMeasurement(),
                                        },
                                        this.getAggregateSubLayerId(mapboxBaseLayerId, SubLayerType.LINE_TEXT),
                                        layer.id,
                                        this.applyTextFieldExpression.bind(this),
                                        this.getAttributeListItems,
                                    ),
                                );
                                break;
                            case "outline":
                                if (getStaticStyleValue(value)) {
                                    this.addSublayer(layer, SubLayerType.LINE_OUTLINE, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.LINE_OUTLINE, sourceLocation);
                                }
                                break;
                            case "outlineColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_OUTLINE,
                                    "line-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "outlineOpacity":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_OUTLINE,
                                    "line-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "outlineWidth":
                                const outlineWidth = calculateLineWidthForLineOutline(layer.lineStyle.width, value);
                                this.editAggregateLayerPaintProperty(mapboxBaseLayerId, SubLayerType.LINE_OUTLINE, "line-width", outlineWidth);
                                break;
                            case "outlineBlur":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.LINE_OUTLINE,
                                    "line-blur",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "objectOrder":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "line-sort-key", styleValueToMapboxStyleValue(value));
                                break;
                            default:
                                // eslint-disable-next-line no-console
                                console.error(`Line property '${styleProperty?.toString()}' does not have a case`);
                        }
                        break;
                    case StyleType.Point:
                        switch (styleProperty as StylePropertyToValueMap<PointStyle>["styleProperty"]) {
                            case "colour":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-color", styleValueToMapboxStyleValue(value));
                                break;
                            case "opacity":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-opacity", styleValueToMapboxStyleValue(value));
                                break;
                            case "radius": {
                                const { minZoom, maxZoom } = zoomableValueToMinMaxZoomLevels(value.staticValue);
                                this.map.setLayerZoomRange(mapboxBaseLayerId, minZoom, maxZoom);
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-radius", styleValueToMapboxStyleValue(value));
                                break;
                            }
                            case "blur":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-blur", styleValueToMapboxStyleValue(value));
                                break;
                            case "outlineWidth":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-stroke-width", styleValueToMapboxStyleValue(value));
                                break;
                            case "outlineColour":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-stroke-color", styleValueToMapboxStyleValue(value));
                                break;
                            case "outlineOpacity":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-stroke-opacity", styleValueToMapboxStyleValue(value));
                                break;
                            case "pitchAlignment":
                                this.map.setPaintProperty(mapboxBaseLayerId, "circle-pitch-alignment", styleValueToMapboxStyleValue(value).toString().toLowerCase());
                                break;
                            case "outline":
                                if (styleValueToMapboxStyleValue(value)) {
                                    // Outline is enabled
                                    this.map.setPaintProperty(mapboxBaseLayerId, "circle-stroke-width", styleValueToMapboxStyleValue(layer.pointStyle.outlineWidth));
                                } else {
                                    // Outline is disabled, set the width to 0
                                    this.map.setPaintProperty(mapboxBaseLayerId, "circle-stroke-width", 0);
                                }
                                break;
                            case "text":
                                if (getStaticStyleValue(value)) {
                                    this.addSublayer(layer, SubLayerType.POINT_TEXT, sourceLocation);
                                } else {
                                    this.removeSublayer(layer, SubLayerType.POINT_TEXT, sourceLocation);
                                }
                                break;
                            case "textColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textSize":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-size",
                                    styleValueToMapboxStyleValue(value),
                                );
                                // Text size is correlated to text offset therefore offset to be changed when size changes
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-radial-offset",
                                    styleValueParser.textOffset(getStaticStyleValue<number>(layer.pointStyle.textOffset), value),
                                );
                                break;
                            case "textOverlap":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-allow-overlap",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineColour":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-halo-color",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOutlineWidth":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-halo-width",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textOpacity":
                                this.editAggregateLayerPaintProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-opacity",
                                    styleValueToMapboxStyleValue(value),
                                );
                                break;
                            case "textBold":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-font",
                                    styleValueToMapboxStyleValue(value) ? boldFontStack : regularFontStack,
                                );
                                break;
                            case "textContent":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-field",
                                    textContentValueToMapboxStyleValue(
                                        value,
                                        {
                                            projectDataFields: this.getProjectDataFields(),
                                            unitOfMeasurement: this.getUnitOfMeasurement(),
                                        },
                                        this.getAggregateSubLayerId(mapboxBaseLayerId, SubLayerType.POINT_TEXT),
                                        layer.id,
                                        this.applyTextFieldExpression.bind(this),
                                        this.getAttributeListItems
                                    ),
                                );
                                break;
                            case "textPosition":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-variable-anchor",
                                    styleValueParser.textPosition(getStaticStyleValue<TextPosition>(value), this.preview),
                                );
                                break;
                            case "textOffset":
                                this.editAggregateLayerLayoutProperty(
                                    mapboxBaseLayerId,
                                    SubLayerType.POINT_TEXT,
                                    "text-radial-offset",
                                    styleValueParser.textOffset(getStaticStyleValue<number>(value), layer.pointStyle.textSize),
                                );
                                break;
                            case "objectOrder":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "circle-sort-key", styleValueToMapboxStyleValue(value));
                                break;
                            default:
                                // eslint-disable-next-line no-console
                                console.error(`Point property '${styleProperty?.toString()}' does not have a case`);
                        }
                        break;
                    case StyleType.Icon:
                        switch (styleProperty as StylePropertyToValueMap<IconStyle>["styleProperty"]) {
                            case "customColour":
                            case "colour":
                                this.map.setPaintProperty(
                                    mapboxBaseLayerId,
                                    "icon-color",
                                    layer.iconStyle.customColour.staticValue.staticValue ? styleValueToMapboxStyleValue(layer.iconStyle.colour) : undefined,
                                );
                                break;
                            case "opacity":
                                this.map.setPaintProperty(mapboxBaseLayerId, "icon-opacity", styleValueToMapboxStyleValue(value));
                                break;
                            case "iconImage":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-image", styleValueToMapboxStyleValue(replaceIconIdsForSdf(value, this.disabledSDFIconMapping)));
                                break;
                            case "size":
                                const { minZoom, maxZoom } = zoomableValueToMinMaxZoomLevels(value.staticValue);
                                this.map.setLayerZoomRange(mapboxBaseLayerId, minZoom, maxZoom);
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-size", styleValueToMapboxStyleValue(value));
                                break;
                            case "rotation":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-rotate", styleValueToMapboxStyleValue(value));
                                break;
                            case "orientation":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-pitch-alignment", styleValueParser.iconOrientation(getStaticStyleValue(value)));
                                break;
                            case "allowOverlap":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-allow-overlap", styleValueToMapboxStyleValue(value));
                                break;
                            case "iconTextFit":
                                {
                                    const iconTextFitValue = getStaticStyleValue(value) as boolean;
                                    this.map.setLayoutProperty(mapboxBaseLayerId, "icon-text-fit", styleValueParser.iconTextFit(iconTextFitValue));
                                    // If iconTextFitMargin is null on the style get the default value
                                    const iconTextFitMargin =
                                        layer.iconStyle?.iconTextFitMargin != null
                                            ? getStaticStyleValue(layer.iconStyle.iconTextFitMargin)
                                            : getStaticStyleValue(getDefaultStyleProperty(StyleType.Icon, "iconTextFitMargin"));
                                    this.map.setLayoutProperty(mapboxBaseLayerId, "icon-text-fit-padding", styleValueParser.iconTextFitMargin(iconTextFitMargin));
                                }
                                break;
                            case "iconTextFitMargin":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-text-fit-padding", styleValueParser.iconTextFitMargin(getStaticStyleValue(value)));
                                break;
                            case "iconAlignment":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "icon-rotation-alignment", styleValueParser.iconAlignment(getStaticStyleValue(value)));
                                break;
                            case "text":
                            case "textColour":
                            case "textSize":
                            case "textOverlap":
                            case "textOutlineColour":
                            case "textOutlineWidth":
                            case "textOpacity":
                            case "textBold":
                            case "textContent":
                            case "textPosition":
                            case "textOffset":
                                setMapboxTextPropertiesOnIcon(
                                    mapboxBaseLayerId,
                                    layer,
                                    styleChanges as unknown as StylePropertyToValueMap<IconStyle>[],
                                    this.preview,
                                    this.map,
                                    {
                                        projectDataFields: this.getProjectDataFields(),
                                        unitOfMeasurement: this.getUnitOfMeasurement(),
                                    },
                                    this.applyTextFieldExpression.bind(this),
                                    this.getAttributeListItems,
                                );
                                break;
                            case "objectOrder":
                                this.map.setLayoutProperty(mapboxBaseLayerId, "symbol-sort-key", styleValueToMapboxStyleValue(value));
                                break;
                            default:
                                // eslint-disable-next-line no-console
                                console.error(`Icon property '${styleProperty?.toString()}' does not have a case`);
                        }
                        break;
                    default:
                        throw new Error(`Style type '${styleType}' does not have a case`);
                }
            });
        }
    }

    private editAggregateLayerPaintProperty<T extends keyof PaintSpecification>(layerId: string, subLayerType: SubLayerType, styleProperty: T, value: PaintSpecification[T]) {
        const subLayer = this.aggregateLayers[layerId].find((al) => al.type === subLayerType);
        if (!subLayer) {
            return;
        }

        this.map.setPaintProperty(subLayer.id, styleProperty, value);
    }

    private editAggregateLayerLayoutProperty<T extends keyof LayoutSpecification>(layerId: string, subLayerType: SubLayerType, styleProperty: T, value: LayoutSpecification[T]) {
        const subLayer = this.aggregateLayers[layerId].find((al) => al.type === subLayerType);
        if (!subLayer) {
            return;
        }

        this.map.setLayoutProperty(subLayer.id, styleProperty, value);
    }

    private getAggregateSubLayerId(layerId: string, subLayerType: SubLayerType) {
        return this.aggregateLayers[layerId].find((al) => al.type === subLayerType)?.id;
    }

    localSourceIsAdded(sourceId: string, localOnlyLayer: boolean): boolean {
        if (localOnlyLayer) {
            return this.map.getSource(sourceId) !== undefined;
        }
        return this.map.getSource(`${sourceId}_${localSuffix}`) !== undefined;
    }

    setCursor(newMapCursorState: MapCursor) {
        const newCursor = this.getCurrentState().overrideCursor.value ?? newMapCursorState;

        if (this.mapCursorState === newCursor) {
            return;
        }

        this.mapCursorState = newCursor;

        this.map.getCanvas().style.cursor = this.getCursor(newCursor);
        this.models3D.setCursor();
    }

    // eslint-disable-next-line prettier/prettier
    private getCursor(cursorState: MapCursor | `url(${string})`) {
        switch (cursorState) {
            case MapCursor.COMPOSITION:
                return "crosshair";
            case MapCursor.READ:
                return "grab";
            case MapCursor.MOVE:
                return "move";
            case MapCursor.POINTING:
                return "pointer";
            case MapCursor.LOADING:
                return "wait";
            case MapCursor.NS_RESIZE:
                return "ns-resize";
            case MapCursor.NESW_RESIZE:
                return "nesw-resize";
            case MapCursor.NWSE_RESIZE:
                return "nwse-resize";
            case MapCursor.EW_RESIZE:
                return "ew-resize";
            case MapCursor.DEFAULT:
                return "default";
            case MapCursor.COMMENT:
                return `url(${COMMENT_ICON_DATA_URL}) 24 24, crosshair`;
            default:
                return cursorState;
        }
    }

    checkIfLayerExists(layerId: string) {
        return !!this.map.getLayer(layerId);
    }

    protected addHighlightLayer(layer: MapModuleLayer): void {
        const layerId = removeLayerIdSuffixes(layer.id);
        const levelFilter = createMapboxLevelFilter(this.getCurrentState().currentLevel);
        const mapboxHighlightLayer = iventisLayerToHighlightBaseLayer(layer, null, this.preview, `${layerId}_${highlightInfix}`, levelFilter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);

        // check if highlight layer does not exist
        if (!this.checkIfLayerExists(`${layerId}_${highlightInfix}`)) {
            const layerToInsertBehindId = getBottomMostRelatedLayer(this.aggregateLayers, layerId, this.map.getStyle().layers);
            this.map.addLayer(mapboxHighlightLayer, layerToInsertBehindId);
        }

        if (layer.storageScope === LayerStorageScope.LocalAndTiles) {
            const localLayerId = getLocalLayerID(layerId);
            const localBottommostId = getBottomMostRelatedLayer(this.aggregateLayers, localLayerId, this.map.getStyle().layers);

            // check if local layer exists and if the local highlight layer does not exist
            if (localBottommostId && this.checkIfLayerExists(localBottommostId) && !this.checkIfLayerExists(`${mapboxHighlightLayer.id}_${localSuffix}`)) {
                const localMapboxHighlightLayer = iventisLayerToHighlightBaseLayer(layer, null, this.preview, localLayerId, levelFilter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);
                delete localMapboxHighlightLayer["source-layer"];
                // For typesafety remove the custom layer type
                if (localMapboxHighlightLayer.type !== "custom") {
                    const layerToAdd = { ...localMapboxHighlightLayer, id: `${mapboxHighlightLayer.id}_${localSuffix}`, source: `${layerId}_${localSuffix}` };
                    this.map.addLayer(layerToAdd, localBottommostId);
                }
            }
        }
    }

    protected removeHighlightLayer(mapLayerIds: string[]): void {
        // for each map layer
        mapLayerIds.forEach((layerId) => {
            // check if highlight layer exists
            if (this.checkIfLayerExists(`${layerId}_${highlightInfix}`)) {
                // make sure it does not show
                this.map.setFilter(`${layerId}_${highlightInfix}`, ["in", "id"]);
            }

            if (this.checkIfLayerExists(`${layerId}_${highlightInfix}_${localSuffix}`)) {
                // make sure it does not show
                this.map.setFilter(`${layerId}_${highlightInfix}_${localSuffix}`, ["in", "id"]);
            }
        });
    }

    protected highlightMapObjects(remoteMapObjectIds: string[], localMapObjectIds: string[], selectedLayerIds: string[]) {
        // for each selected layer
        selectedLayerIds.forEach((layerId) => {
            // check if highlight layer exists for remote layer
            if (this.checkIfLayerExists(`${layerId}_${highlightInfix}`)) {
                // add remote map objects to show highlight for
                this.map.setFilter(`${layerId}_${highlightInfix}`, ["in", "id", ...remoteMapObjectIds]);
            }

            // check if highlight layer exists locally
            if (this.checkIfLayerExists(`${layerId}_${highlightInfix}_${localSuffix}`)) {
                // add local map objects to show highlight for
                this.map.setFilter(`${layerId}_${highlightInfix}_${localSuffix}`, ["in", "id", ...localMapObjectIds]);
            }
        });
    }

    protected addAggregateLayerToMap(layerId: string, subLayers: MapboxlayerWithSublayerType[]) {
        const baseLayer = this.getStoredLayer(layerId);
        this.aggregateLayers[layerId] = subLayers;
        sortSubLayers(subLayers).forEach(({ layer, type }) => {
            // When a line model is imported we need to set a source for the guide lines
            if (baseLayer.styleType === StyleType.LineModel && type === SubLayerType.BASE) {
                if (!this.map.getSource(baseLayer.id)) {
                    this.setEmptySource(baseLayer.id);
                }
            }
            const aboveLayerId = getAboveLayerId(layer.id, this.aggregateLayers, this.map.getStyle().layers, this.getMapLayers(), layerId);
            this.map.addLayer(layer as LayerSpecification, aboveLayerId);
        });
    }

    protected async addLayer(layer: MapModuleLayer, refreshMap: boolean) {
        const layerFilter = this.addDateFilterToIventisLayer(layer, createMapboxLevelFilter(this.getCurrentState().currentLevel));
        const aggregatedLayers = this.iventisLayerToAggregateLayer(layer, layerFilter);
        this.addAggregateLayerToMap(layer.id, aggregatedLayers);
        if (layer.storageScope !== LayerStorageScope.LocalOnly && refreshMap) {
            const layerSources = aggregatedLayers.reduce<string[]>((layerSources, { layer }) => {
                if (layer?.type !== "custom" && typeof layer?.source === "string") {
                    layerSources.push(layer.source);
                }
                return layerSources;
            }, []);
            this.refreshTileSources(layerSources);
        }
        if (isModelLayer(layer)) {
            const geoJson = this.getLocalLayerGeometry(layer.id);
            if (geoJson?.length > 0) {
                this.models3D.updateMapObjects(layer, {
                    type: "FeatureCollection",
                    features: geoJson.map(({ feature }) => feature as Feature<GeoJSON.Point, MapObjectProperties>),
                });
            }
        }
    }

    protected hideLayer(layerId: string): void {
        const remoteSublayers = this.aggregateLayers[layerId]?.map(({ id }) => id) || [];
        const localSublayers = this.aggregateLayers[getLocalLayerID(layerId)]?.map(({ id }) => id) || [layerId];
        const layerIds = [...remoteSublayers, ...localSublayers, `${layerId}_${highlightInfix}`, `${layerId}_${highlightInfix}_${localSuffix}`];
        layerIds.forEach((id) => {
            if (this.models3D.doesLayerExist(id)) {
                this.models3D.updateLayerVisibility(id, false);
                return;
            }
            if (this.checkIfLayerExists(id)) {
                this.map.setLayoutProperty(id, "visibility", "none");
            }
        });
    }

    protected showLayer(layerId: string): void {
        const remoteSublayers = this.aggregateLayers[layerId]?.map(({ id }) => id) || [];
        const localSublayers = this.aggregateLayers[getLocalLayerID(layerId)]?.map(({ id }) => id) || [layerId];
        const layerIds = [...remoteSublayers, ...localSublayers, `${layerId}_${highlightInfix}`, `${layerId}_${highlightInfix}_${localSuffix}`];
        layerIds.forEach((id) => {
            if (this.models3D.doesLayerExist(id)) {
                this.models3D.updateLayerVisibility(id, true);
                return;
            }
            if (this.checkIfLayerExists(id)) {
                this.map.setLayoutProperty(id, "visibility", "visible");
            }
        });
    }

    protected removeLayer(layerId: string): void {
        const remoteSublayers = this.aggregateLayers[layerId]?.map(({ id }) => id) || [];
        const localSublayers = this.aggregateLayers[getLocalLayerID(layerId)]?.map(({ id }) => id) || [];
        const layerIds = [...remoteSublayers, ...localSublayers, `${layerId}_${highlightInfix}`, `${layerId}_${highlightInfix}_${localSuffix}`];
        layerIds.forEach((id) => {
            if (this.checkIfLayerExists(id)) {
                this.map.removeLayer(id);
                delete this.aggregateLayers[layerId];
                if (this.models3D.doesLayerExist(layerId)) {
                    this.models3D.deleteLayer(layerId);
                }
            }
        });
    }

    protected refreshTileSources(tileSourceNames: string[]) {
        tileSourceNames.forEach((sourceName) => {
            const source = this.map.getSource(sourceName);
            if (source != null && source.type === "vector" && source.tiles != null) {
                const newTileUrls = this.bustTileCache(source.tiles);
                source.setTiles(newTileUrls);
            }
        });
    }

    /** CADs bounds */

    public areCompositionObjectsOutsideCadBounds(objects: CompositionMapObject[]): boolean {
        // If the current level is 0 then the user can draw outside the bounds
        const { currentLevel } = this.getCurrentState();
        if (currentLevel === 0 || this.maskLayer == null) {
            return false;
        }

        const features = objects.map((object) => object.geojson);
        return this.maskLayer?.areObjectsOverlappingMaskLayer(features);
    }

    public areGeometriesOutsideCadBounds(objects: Geometry[]): boolean {
        // If the current level is 0 then the user can draw outside the bounds
        const { currentLevel } = this.getCurrentState();
        if (currentLevel === 0 || this.maskLayer == null) {
            return false;
        }

        const features: Feature[] = objects.map((object) => ({ type: "Feature", geometry: object, properties: {} }) as Feature);
        return this.maskLayer?.areObjectsOverlappingMaskLayer(features);
    }

    public async getSnapshotDataUrl(): Promise<string> {
        return new Promise((resolve) => {
            // Snapshot must happen upon a re-render as this is when the drawing is in the buffer.
            this.map.once("render", () => {
                const imageData = this.getCanvas().toDataURL();
                resolve(imageData);
            });
            // cause re-render
            this.map.triggerRepaint();
        });
    }

    public getExportPosition(): ExportOptions {
        const bearingBefore = this.map.getBearing();
        const pitchBefore = this.map.getPitch();
        // Set bearing to 0 to get bounding box position without rotation
        this.map.setBearing(0);
        // Set pitch to 0 to get bounding box position without pitch
        this.map.setPitch(0);
        // Get bounds of viewport and then re-apply the rotation
        const bounds = this.map.getBounds().toArray();
        this.map.setBearing(bearingBefore);
        this.map.setPitch(pitchBefore);
        return { bounds, bearing: bearingBefore, scale: undefined, pitch: pitchBefore };
    }

    protected addGuideLinesForLineModel(layerId: string, mapObjectIdsToShow: string[]): void {
        const baseLayer = this.aggregateLayers[layerId].find(({ type }) => type === SubLayerType.BASE);
        const currentFilter = this.map.getFilter(baseLayer.id);
        const updatedFilter = updateLayerFilters(currentFilter, ["in", "id", ...mapObjectIdsToShow]);
        this.map.setFilter(baseLayer.id, updatedFilter);
    }

    protected removeGuideLinesForLineModel(): void {
        this.getMapLayers().forEach((layer) => {
            if (layer.styleType === StyleType.LineModel && this.aggregateLayers[layer.id]) {
                const baseLayer = this.aggregateLayers[layer.id].find((l) => l.type === SubLayerType.BASE);
                const currentFilter = this.map.getFilter(baseLayer.id);
                const updatedFilter = updateLayerFilters(currentFilter, ["in", "id"]);
                this.map.setFilter(baseLayer.id, updatedFilter);
            }
        });
    }

    public getStoredLayer(layerId: string) {
        const layer = super.getStoredLayer(layerId);
        return layer ?? super.getStoredLayer(this.models3D.getIventisLayerId(layerId));
    }

    public setMapLock(locked: boolean): void {
        if (locked) {
            this.disableMapCameraMovement();
            this.map.scrollZoom.disable();
            this.map.keyboard.disable();
            this.map.doubleClickZoom.disable();
        } else {
            this.enableMapCameraMovement();
            this.map.scrollZoom.enable();
            this.map.keyboard.enable();
            this.map.doubleClickZoom.enable();
        }
    }

    private getModelSubLayer(layerId: string) {
        return this.aggregateLayers[layerId]?.find(({ type }) => type === SubLayerType.LINE_MODEL);
    }

    /** If the given layer id is a sub layer, return it's main layer id */
    private getMainLayerId(inputLayerId: string) {
        return Object.keys(this.aggregateLayers).find((layerId) => this.aggregateLayers[layerId].find((l) => l.id === inputLayerId)) ?? inputLayerId ?? null;
    }

    /** Loads all the icons in the user layers ahead of time
     *
     * Note: Only used for export
     */
    private async loadAllIconImages(layers: MapModuleLayer[]) {
        // Get all asset ids and then their urls
        const allAssetIds = getAllLayerIconsIds(layers);
        const assetRequests = await this.assetOptions.multipleAssetUrlGetter(allAssetIds);
        assetRequests.forEach(({ id, url, metaData }) => {
            this.map.loadImage(url, (err, image) => {
                if (err) throw new Error(err.message);
                // Get the asset's id and url and add it to mapbox
                this.map.addImage(id, image, { sdf: metaData?.sdf });
            });
        });
    }

    /** Updates bounds for given source
     *
     * Note - has to remove all layers and readd them so source will apply
     */
    public updateBoundsForSource(sourceId: string, bounds: BBox2d) {
        const state = this.getCurrentState();

        // Need to remove all the layers when we remove the source
        this.removeAllLayers();

        const source = Object.values(state.tileSources.value)
            .flatMap((s) => s.tiles)
            .find((source) => source.name === sourceId);

        // If the source exists remove it as there is no way to update it
        if (this.map.getSource(source.name)) {
            this.map.removeSource(source.name);
        }

        // Readd the source with the updated bounds
        this.map.addSource(source.name, iventisSourceToMapboxSource(source, bounds));

        // Readd all the layers so they use the new source
        this.addAllLayers();

        // When the map is idle bust the cache
        this.map.once("idle", () => {
            this.bustTileCache([sourceId]);
        });
    }

    /** Waits for the style to load before executing the given callback */
    private waitForStyleToLoad = async (callback: (...params: unknown[]) => void) => {
        let count = 0;
        let isLoaded = false;
        while (count < 10 && !isLoaded) {
            if (!this.map.isStyleLoaded()) {
                await new Promise<void>((res) => setTimeout(() => res(), 300));
                count += 1;
            } else {
                isLoaded = true;
                callback();
            }
        }
    };

    private readonly applyTextFieldExpression =
        async (expression: ExpressionSpecification, mapboxTextLayerId: string, iventisLayerId: string) => {
            await this.waitForStyleToLoad(() => this.map.setLayoutProperty(mapboxTextLayerId, "text-field", expression));
            this.layerIdsWithTextContentDeterminedByListDataField = this.layerIdsWithTextContentDeterminedByListDataField.filter((layerId) => layerId !== iventisLayerId);
        };

    private iventisLayerToAggregateLayer = (iventisLayer: MapModuleLayer, filter: Layer["filter"]): MapboxlayerWithSublayerType[] => {
        const lengthAndArea = {
            projectDataFields: this.getProjectDataFields(),
            unitOfMeasurement: this.getUnitOfMeasurement(),
        };
        switch (iventisLayer.styleType) {
            case StyleType.Line:
                return iventisLineToAggregateLayer(iventisLayer, this.preview, lengthAndArea, filter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);
            case StyleType.Area:
                return iventisAreaToAggregateLayer(iventisLayer, this.preview, lengthAndArea, filter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);
            case StyleType.Point:
                return iventisPointToAggregateLayer(iventisLayer, this.preview, lengthAndArea, filter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);
            case StyleType.Icon:
                return iventisIconToAggregateLayer(iventisLayer, this.preview, this.disabledSDFIconMapping, lengthAndArea, filter, this.applyTextFieldExpression.bind(this), this.getAttributeListItems);
            case StyleType.Model:
                return iventisModelToAggregateLayer(iventisLayer, this.models3D.createLayer.bind(this.models3D));
            case StyleType.LineModel:
                return iventisLineModelToAggregateLayer(iventisLayer, this.models3D.createLayer.bind(this.models3D));
            default:
                throw new Error("Layer type is not implemented");
        }
    };

    public removeAllLayers(): void {
        super.removeAllLayers();
        this.aggregateLayers = {};
    }

    private onWindowFocusOut() {
        window.addEventListener("focusout", () => {
            this.keysPressed = [];
        });
    }

    /** Remove all custom attribution */
    private removeAttribution() {
        this.customAttribution.forEach((attribution) => attribution.destroy());
        this.customAttribution = [];
    }

    private addMapAttribution(mapBackground: StyleSpecification) {
        if (isGoogleMapsMapboxSource(mapBackground) && mapBackground.metadata.tags.includes(googleMapBackgroundAssetTag)) {
            const locationAttribution = new GoogleMapboxAttribution(this.map, "bottom-right", mapBackground.metadata.apiKey, mapBackground.metadata.sessionToken);
            const logoAttribution = new GoogleLogoMapboxAttribution(this.map, "bottom-left");
            this.customAttribution = [locationAttribution, logoAttribution];
        }
    }

    public addDateFilterToLayer(layer: MapModuleLayer, dateFilter: { day: number; time: number }): void {
        // Get all the layers the filter needs to be applied to
        const aggregatedLayers = this.aggregateLayers[layer.id];
        // Create the filter expression for the date filter
        const mapboxDateFilter = createDateFilterExpression(layer, dateFilter.day, dateFilter.time, 15);
        aggregatedLayers.forEach((mapboxLayer) => {
            // For each layer get their existing filter and add/update the date filter
            const existingLayerFilter = this.map.getFilter(mapboxLayer.id);
            const updatedFilter = updateLayerFilters(existingLayerFilter, mapboxDateFilter);
            this.map.setFilter(mapboxLayer.id, updatedFilter);
        });
    }

    public removeDateFilterFromLayer(layerId: string): void {
        // Get all the layers the filter needs to be applied to
        const aggregatedLayers = this.aggregateLayers[layerId];
        aggregatedLayers.forEach((mapboxLayer) => {
            // Get existing filters
            const layerFilter = this.map.getFilter(mapboxLayer.id);
            // Update the layer filter by removing the date filter
            const updatedFilter = removeLayerFilterByType(layerFilter, FilterType.Date);
            this.map.setFilter(mapboxLayer.id, updatedFilter);
        });
    }

    private addDateFilterToIventisLayer(layer: MapModuleLayer, levelFilter: Layer["filter"]): Layer["filter"] {
        const state = this.getCurrentState();
        if (state.datesFilter.value.filter && layer.dataFields?.some((df) => df.type === DataFieldType.RepeatedTimeRanges)) {
            const datesFilter = createDateFilterExpression(layer, state.datesFilter.value.day, state.datesFilter.value.time, 15);
            return ["all", levelFilter, datesFilter];
        }
        return ["all", levelFilter];
    }

    /** Moves a layer to the top of the map (below the internal layers such as coordinate handles, rotation handles etc.) */
    protected moveLayerToTopOfMap(layerId: string): void {
        const internalLayer = getBottomMostInternalLayerId(this.map.getStyle().layers);
        this.changeBaseLayerMapOrder(internalLayer, layerId);
        this.changeSubLayerMapOrder(internalLayer, layerId);
        this.changeHighlightLayerMapOrder(layerId);
    }

    /** Moves a layer to the bottom of the map (above map background and CAD layers) */
    protected moveLayerToBottomOfMap(layerId: string): void {
        // Remove all analysis layers
        const filteredLayers = this.getMapLayers().filter(({ id, remote }) => id !== layerId && remote);
        const layerToMoveBelow = getLowestMapOrderValueLayer(filteredLayers);

        // When a lowest map layer can't be found, it means the layer is already at the bottom of the map
        if (layerToMoveBelow === null) {
            return;
        }

        // Find the mapbox layer which is currently bottom of the map
        const mapboxLayerIdToMoveBelow = getBottomMostRelatedLayer(this.aggregateLayers, layerToMoveBelow.id, this.map.getStyle().layers, this.getMapLayers());

        this.changeBaseLayerMapOrder(mapboxLayerIdToMoveBelow, layerId);
        this.changeSubLayerMapOrder(mapboxLayerIdToMoveBelow, layerId);
        this.changeHighlightLayerMapOrder(layerId);
    }

    protected addSnapIndicatorLayer(): void {
        this.snapIndicator = new MapboxSnapIndicatorLayer(this.map);
    }

    protected setSnapIndicatorSource(position: GeoJSON.Position | undefined): void {
        this.snapIndicator?.updateSource(position);
    }

    protected removeSnapIndicatorLayer(): void {
        this.snapIndicator?.remove();
        this.snapIndicator = null;
    }

    /** Moves the base layer behind a given mapbox layer */
    private changeBaseLayerMapOrder(layerIdToMoveBelow: string, layerId: string) {
        const layer = this.layerMap[layerId];

        if (isModelLayer(layer)) {
            this.models3D.updateModelMapOrder(layerId, layerIdToMoveBelow);
        } else {
            const aggregateLayer = this.aggregateLayers[layerId];
            const localAggregateLayer = this.aggregateLayers[getLocalLayerID(layerId)];

            // Get remote and local base layer, 3d areas do not have a base layer so get extrusion sub layer
            const baseLayer = aggregateLayer.find((layer) => layer.type === SubLayerType.BASE || layer.type === SubLayerType.EXTRUSION);

            if (!baseLayer) {
                return;
            }

            this.map.moveLayer(baseLayer.id, layerIdToMoveBelow);
            // If local layer exists then move below the remote version of the layer
            if (localAggregateLayer) {
                const localBaseLayer = localAggregateLayer?.find((layer) => layer.type === SubLayerType.BASE || layer.type === SubLayerType.EXTRUSION);
                this.map.moveLayer(localBaseLayer?.id, baseLayer.id);
            }
        }
    }

    /** Moves the sub layers behind a given mapbox layer */
    private changeSubLayerMapOrder(layerIdToMoveBelow: string, layerId: string) {
        const aggregateLayer = this.aggregateLayers[layerId];
        const localAggregateLayer = this.aggregateLayers[getLocalLayerID(layerId)];

        // Get remote and local base layer
        const baseLayer = aggregateLayer.find((layer) => layer.type === SubLayerType.BASE);
        const localBaseLayer = localAggregateLayer?.find((layer) => layer.type === SubLayerType.BASE);

        aggregateLayer.forEach((layer) => {
            // Base layer has already been moved
            if (layer.type !== SubLayerType.BASE && layer.type !== SubLayerType.EXTRUSION) {
                const placement = isSubLayerAboveOrBelow(layer.type);

                // Layers below the base layer such as "border"
                if (placement === SubLayerPlacement.Below) {
                    this.map.moveLayer(layer.id, baseLayer.id);
                    // If local layer exists then move below the remote version of the layer
                    if (localAggregateLayer) {
                        const localLayer = localAggregateLayer.find((l) => l.type === layer.type);
                        this.map.moveLayer(localLayer.id, localBaseLayer.id);
                    }
                }

                // Layers above the base layer such as "text" and "arrows"
                if (placement === SubLayerPlacement.Above) {
                    this.map.moveLayer(layer.id, layerIdToMoveBelow);
                    if (localAggregateLayer) {
                        const localLayer = localAggregateLayer.find((l) => l.type === layer.type);
                        this.map.moveLayer(localLayer.id, layerIdToMoveBelow);
                    }
                }
            }
        });
    }

    /** Moves the highlight layers to the where the base layer is */
    private changeHighlightLayerMapOrder(layerId: string) {
        const localAggregateLayer = this.aggregateLayers[getLocalLayerID(layerId)];
        const bottomMostLayer = getBottomMostRelatedLayer(this.aggregateLayers, layerId, this.map.getStyle().layers);
        this.map.moveLayer(`${layerId}_${highlightInfix}`, bottomMostLayer);
        // If local layer exists then move below the remote version of the layer
        if (localAggregateLayer) {
            const localBaseLayer = localAggregateLayer.find((layer) => layer.type === SubLayerType.BASE || layer.type === SubLayerType.EXTRUSION);
            const bottomMostLocalLayer = getBottomMostRelatedLayer(this.aggregateLayers, localBaseLayer.id, this.map.getStyle().layers);
            this.map.moveLayer(`${layerId}_${highlightInfix}_${localSuffix}`, bottomMostLocalLayer);
        }
    }

    /** Sets a global variable with the layers which have text content set by a list datafield */
    private setLayersWithTextContentBasedOnListDataField(layers: MapModuleLayer[]) {
        layers.forEach((layer) => {
            const textStyle = getLayerStyleTextProperties(layer);
            // Checks if text style exists and if the text content is determined by a list datafield
            if (textStyle != null && isTextContentDeterminedByListDataField(textStyle, layer.dataFields)) {
                this.layerIdsWithTextContentDeterminedByListDataField.push(layer.id);
            }
        });
    }

    public destroy() {
        this.models3D.destroy();
        super.destroy();
        clearInterval(this.mapInitialisedInterval);
        clearInterval(this.layerTextHasLoadedInterval);
        const url: URL = new URL(window.location.href);
        // If the default hash location is being used remove it as we don't set it
        if (url.hash === "" || url.hash === `#${locationHashString}=0/0/0`) {
            window.history.replaceState(null, null, " ");
        }
        if (this.map.loaded()) {
            this.map.remove();
        }
    }

    /** TESTING FUNCTIONS */

    private _attachTestingFunctionsToWindow(cypressWindowName?: string) {
        // Only attach testing functions when cypress is running
        if (window.Cypress != null && cypressWindowName != null) {
            window.Cypress[cypressWindowName] = this.getFunctionData();
        }
    }

    private getFunctionData() {
        const mapboxTestHelpers = new MapboxTestHelpers();
        const deckglTestHelpers = new DeckGlTestHelpers();

        /** Gets the geojson source for a given layer */
        const getGeoJsonSource = (sourceId: string) => {
            const source = this.map.getSource(sourceId);
            if (source != null && source.type === "geojson") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return source._data.features;
            }
            return [];
        };

        /** Gets the rendered features for map viewport */
        const getFeaturesUsingQueryRenderedFeatures = (layer: Layer) => {
            if (layer == null) {
                return [];
            }
            // Check map using the found layer and the filter it uses
            const features = this.map.queryRenderedFeatures(null, { layers: [layer.id], filter: layer.filter });
            const uniqueFeatures: { geometry: GeoJSON.Geometry; properties: MapObjectProperties }[] = [];
            // Features from tiles will show more than once, so consolidate them
            features.forEach((feature) => {
                if (!uniqueFeatures.some((f) => f.properties.id === feature.properties.id)) {
                    uniqueFeatures.push({ geometry: feature.geometry, properties: feature.properties as MapObjectProperties });
                }
            });
            return uniqueFeatures;
        };

        const testingFunctions: (typeof window.Cypress)[string] = {
            coordinatesToCanvasXY: (lat: number, lng: number) => this.map.project({ lat, lng }),
            canvasXYToCoordinates: (x: number, y: number) => this.map.unproject([x, y]),
            zoomToBounds: (bounds: [number, number, number, number]) => {
                this.map.fitBounds(bounds, { duration: 0 });
            },
            getDrawingLayersGeoJsonFeatures: () => {
                const { layers } = this.map.getStyle();
                const midPointLayer = layers.find((layer) => isIventisTestLayer(layer) && layer.type === "circle" && layer.metadata?.name === "midpointHandle");
                const rotationHandleLayer = layers.find((layer) => isIventisTestLayer(layer) && layer.type === "symbol" && layer.metadata?.name === "rotationHandle");
                const coordinateHandleLayer = layers.find((layer) => isIventisTestLayer(layer) && layer.type === "symbol" && layer.metadata?.name === "coordinateHandle");
                const deleteCoordinateLayer = layers.find((layer) => isIventisTestLayer(layer) && layer.type === "symbol" && layer.metadata?.name === "deleteHandle");
                const continueDrawingHandleLayer = layers.find((layer) => isIventisTestLayer(layer) && layer.type === "symbol" && layer.metadata?.name === "continueDrawingHandle");
                return {
                    midPointHandles: getGeoJsonSource(midPointLayer?.id),
                    rotationHandle: getGeoJsonSource(rotationHandleLayer?.id),
                    coordinateHandles: getGeoJsonSource(coordinateHandleLayer?.id),
                    deleteCoordinateHandles: getGeoJsonSource(deleteCoordinateLayer?.id),
                    continueDrawingHandles: getGeoJsonSource(continueDrawingHandleLayer?.id)
                };
            },
            getLayerByName: (name: string, type?: StyleType) => {
                // If layer is a 3d one get it from deckgl engine
                if (type === StyleType.Model) {
                    const layer = this.models3D._testFunctions.getLayerByName(name);
                    return deckglTestHelpers.toIventisLayer(layer);
                }
                if (type === StyleType.LineModel) {
                    const layer = this.models3D._testFunctions.getLayerByName(name);
                    return deckglTestHelpers.toIventisLayer(layer);
                }

                const { layers } = this.map.getStyle();
                const mapboxLayers = layers.filter(
                    (layer) => isIventisTestLayer(layer) && layer.metadata?.name === name && layer.source !== "iventis" && layer.source !== "iventis-comments",
                );
                return mapboxTestHelpers.toIventisLayer(mapboxLayers as IventisMapboxTestLayer[]);
            },
            getDeckGlLayersForLayerByName: (name: string) => this.models3D._testFunctions.getLayerByName(name),
            getLayerById: (id: string, type?: StyleType) => {
                if (type === StyleType.Model) {
                    throw new Error("Getting model layer by id not implemented, please use getLayerByName!");
                }
                const { layers } = this.map.getStyle();
                const mapboxLayers = layers.filter((layer) => layer.id === id);
                return mapboxTestHelpers.toIventisLayer(mapboxLayers as IventisMapboxTestLayer[]);
            },
            getLayerGeoJsonFeatures: (name: string, type?: StyleType) => {
                switch (type) {
                    case StyleType.Model:
                        return this.models3D._testFunctions.getLayerGeoJsonFeatures(name);
                    case StyleType.LineModel: {
                        const models = this.models3D._testFunctions.getLayerGeoJsonFeatures(name);
                        const layer = testingFunctions.getLayerByName(name);
                        const layers = getGeoJsonSource(layer.source);
                        return [...layers, ...models];
                    }
                    default: {
                        const layer = testingFunctions.getLayerByName(name, type);
                        return layer != null ? getGeoJsonSource(layer.source) : [];
                    }
                }
            },
            getLayerGeoJsonFeaturesViaId: (id: string, type?: StyleType) => {
                const layer = testingFunctions.getLayerById(id, type);
                if (layer == null) {
                    return [];
                }
                return getGeoJsonSource(layer.source);
            },
            getLayerGeoJsonRenderedFeatures: (layerName: string) => {
                const { layers } = this.map.getStyle();
                const mapboxBaserLayer: LayerSpecification = layers.find(
                    (layer) => isIventisTestLayer(layer) && layer.source !== "iventis" && layer.metadata?.name === layerName && layer.metadata?.type === "base",
                );
                if (mapboxBaserLayer != null) {
                    return getFeaturesUsingQueryRenderedFeatures(mapboxBaserLayer);
                }

                // If layer can't be found we can assume it is a model layer
                const modelLayer = this.models3D._testFunctions.getLayerByName(layerName);
                if (modelLayer == null || modelLayer.length === 0) {
                    return [];
                }

                const iventisModelLayer = deckglTestHelpers.toIventisLayer(modelLayer);
                const modelFeatures = this.models3D._testFunctions.getLayerGeoJsonFeatures(layerName);

                // For line models we also need to get the line features
                if (iventisModelLayer.styleType === StyleType.LineModel) {
                    const layer = testingFunctions.getLayerByName(layerName);
                    const features = getGeoJsonSource(layer.source);
                    return [...features, ...modelFeatures];
                }

                return modelFeatures;
            },
            getModelDataByLayerName: async (id) => {
                const model = await this.models3D._testFunctions.getModelDataByLayerName(id);
                return model;
            },
            getLayerTileRenderedFeatures: (layerName: string) => {
                const { layers } = this.map.getStyle();
                const baseMapboxLayer = layers.find(
                    (layer) => isIventisTestLayer(layer) && layer.source === "iventis" && layer.metadata?.name === layerName && layer.metadata?.type === "base",
                );
                return getFeaturesUsingQueryRenderedFeatures(baseMapboxLayer);
            },
            viewportContainsCoordinate: async (coordinate: [number, number]) => {
                const { bounds: viewportBounds } = await this.getBounds();
                return booleanContains(polygon(viewportBounds), point(coordinate));
            },
            getListItemIdFromName: async (layerName: string, dataFieldName: string, listItemName: string) => {
                // Want to get the Iventis layer (instead of Mapbox) as we want the datafields
                const state = this.getCurrentState();
                const layer = state.layers.value.find((layer) => layer.name === layerName);
                const dataField = layer.dataFields?.find((df) => df.name === dataFieldName);
                const listItems = await this.getAttributeListItems(dataField.id);
                const listItem = listItems.find((lv) => lv.name === listItemName);
                return listItem?.id;
            },
            isSitemapOnMap: (sitemapName: string) => {
                const { layers } = this.map.getStyle();
                return layers.some((layer) => layer?.source?.toString().toLowerCase().includes(sitemapName.toLocaleLowerCase()));
            },
            getCurrentSitemapTileUrl: (sitemapName: string) => {
                const { sources } = this.map.getStyle();
                const key = Object.keys(sources).find((source) => source?.toString().toLowerCase().includes(sitemapName.toLocaleLowerCase()));
                const src = sources[key];
                if (src?.type === "vector") {
                    return src.tiles[0];
                }
                return "";
            },
            getConstants: () => ({
                highlightInfix,
                analysisMeasuringLabelLayerName,
            }),
            getMapCentre: () => {
                const { lng, lat } = this.map.getCenter();
                return this.project([lng, lat]);
            },
            getLayerTopAndBottomMapIndex: (layerName: string) => {
                const relatedLayerIndexes = [];
                this.map.getStyle().layers.forEach((layer, index) => {
                    if (isIventisTestLayer(layer) && layer.metadata?.name === layerName) {
                        relatedLayerIndexes.push(index);
                    }
                });
                const maxIndex = Math.max(...relatedLayerIndexes);
                const minIndex = Math.min(...relatedLayerIndexes);
                return { top: maxIndex, bottom: minIndex };
            },
            waitForMapIdle: (callback: () => void) => {
                this.onMapIdle(() => callback(), { disableMapQuerying: false, listenOnce: true });
            },
            setBearing: (value: number) => {
                this.map.setBearing(value);
            },
            getPitch: () => this.map.getPitch(),
            getZoom: () => this.map.getZoom(),
        };
        return testingFunctions;
    }
}
