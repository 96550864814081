import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Button, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SearchBarComponent } from "@iventis/search/components/search-bar";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FilterFormat } from "@iventis/types";
import { Theme } from "@emotion/react";
import {
    Body2,
    customMediaQueryMax,
    formGap,
    Header5,
    Header6,
    inlineTextIcon,
    inputHeight,
    muiInputFormsCSS,
    screenSizeBreakpoints,
    sectionalMargin,
    styled,
} from "@iventis/styles";
import { ErrorLarge } from "@iventis/components/src/error-large";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { capitalise, useDebounce, useFunctionality, useMaxElementCount } from "@iventis/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dataTestIds } from "@iventis/testing";
import { ExtractRefFromComponent } from "@iventis/types/useful.types";
import { useQuery } from "@tanstack/react-query";
import { getTemplateFilters, useLayerTemplates } from "./layer-template-query";
import { LAYER_TEMPLATE_BORDERS_PX, LAYER_TEMPLATE_HEIGHT_PX, LAYER_TEMPLATE_WIDTH_PX, LayerTemplatePreview, LayerTemplatePreviewSkeleton } from "./layer-template-preview";
import { useEditLayerServices } from "./edit-layer-services";
import { CategoryNode, LayerTemplateCategoryTreeBrowser } from "./layer-template-categories";
import { getRecommendedCategories } from "./layer-template-recommended-helpers";

export type TemplateLayerWithPreviewUrl = MapLayer & { previewUrl: string; assetId: string; iconUrl: string };

export type TemplateLayerSelectedPayload = { assetUrl: string };

export type StaticCategory = "all" | "recommended" | null;

type LayerTemplateData = {
    assetId: string;
    thumbnailUrl: string;
    iconUrl: string;
    name: string;
    layerUrl: string;
    tags: string[];
};

export const LayerTemplateSelector: React.FC<{
    onStartFromScratchSelected: () => void;
    onTemplateSelected: (template: TemplateLayerWithPreviewUrl) => void;
    selectedTemplateLayer: TemplateLayerWithPreviewUrl;
}> = ({ onStartFromScratchSelected, onTemplateSelected, selectedTemplateLayer }) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();
    const functionality = useFunctionality();

    const { data: recommendedCategories, isLoading: isLoadingRecommendedCategories } = useQuery(
        ["recommendedCategories", AssetType.LayerTemplate],
        async () => {
            const [categories, recommended] = await Promise.all([
                assetService.getAssetTags(AssetType.LayerTemplate),
                assetService.getRecommendedCategories(AssetType.LayerTemplate),
            ]);
            // If recommended categories are available, set them as selected
            if (recommended.length > 0) {
                setStaticCategorySelected("recommended");
                // Ensures we get all the child categories as well
                const recommendedNodeCategories = getRecommendedCategories(categories, recommended);
                setCategories(recommendedNodeCategories);
                return recommendedNodeCategories;
            }
            setStaticCategorySelected("all");
            return [];
        },
        {
            // Don't refetch on mount as data is already available
            refetchOnMount: false,
        }
    );

    const [categories, setCategories] = useState<CategoryNode[]>(recommendedCategories?.length > 0 ? recommendedCategories : undefined);

    const [staticCategorySelected, setStaticCategorySelected] = useState<StaticCategory>(
        recommendedCategories != null ? (recommendedCategories.length > 0 ? "recommended" : "all") : null
    );

    const categoriesComponentRef = React.useRef<ExtractRefFromComponent<typeof LayerTemplateCategoryTreeBrowser>>(null);

    const { assetService, styleService } = useEditLayerServices();

    const { value: searchInputValue, debouncedValue: debouncedSearchValue, setValue: setSearchValue, isTyping: isTypingInSearchBar } = useDebounce<string | undefined>(
        undefined,
        300
    );

    const [templateContainerRef, maxNodeCount] = useMaxElementCount<HTMLDivElement>(
        LAYER_TEMPLATE_WIDTH_PX + LAYER_TEMPLATE_BORDERS_PX,
        LAYER_TEMPLATE_HEIGHT_PX + LAYER_TEMPLATE_BORDERS_PX,
        { roundUp: true }
    );
    const filter: FilterFormat[] = getTemplateFilters(categories, debouncedSearchValue, templateContainerRef);

    const { assetError, assetLoading, assetFetching, templateLayerQueries, getLayer } = useLayerTemplates(
        filter,
        { assetService, styleService },
        false,
        // If we're loading recommended categories, we don't want to request templates yet, as we don't know what the filter will be
        !isLoadingRecommendedCategories
    );

    // eslint-disable-next-line react/no-array-index-key
    const skeletons = useMemo(() => new Array(maxNodeCount).fill(true).map((_, i) => <LayerTemplatePreviewSkeleton key={i} sequence={i} totalElementCount={maxNodeCount} />), [
        maxNodeCount,
    ]);

    const handleCategorySelected = (category: CategoryNode) => {
        setCategories([category]);
        setStaticCategorySelected(null);
        setSearchValue("");
    };

    const handleStaticCategorySelected = (staticCategory: StaticCategory) => {
        categoriesComponentRef.current?.clearSelection();
        setStaticCategorySelected(staticCategory);
        if (staticCategory === "recommended") {
            setCategories(recommendedCategories);
        } else {
            setCategories(null);
        }
    };

    // Keeps track of scrollPosition so Template List Container does not scroll back to top when a LayerTemplate Preview is clicked
    const scrollPosition = useRef(0);

    const handleTemplateSelect = async (layerTemplateData: LayerTemplateData) => {
        const layer = await getLayer(layerTemplateData.layerUrl, layerTemplateData.name);

        if (selectedTemplateLayer?.assetId === layerTemplateData.assetId) {
            onTemplateSelected(undefined);
        } else {
            onTemplateSelected({ ...layer, previewUrl: layerTemplateData.thumbnailUrl, assetId: layerTemplateData.assetId, iconUrl: layerTemplateData.iconUrl });
        }
    };

    // Capture scroll position before state changes
    const handleTemplateClick = (layerTemplateData: LayerTemplateData) => {
        if (templateContainerRef.current) {
            scrollPosition.current = templateContainerRef.current.scrollTop;
        }
        handleTemplateSelect(layerTemplateData);
    };

    // Restore scroll position after rerender
    useEffect(() => {
        if (templateContainerRef.current) {
            templateContainerRef.current.scrollTop = scrollPosition.current;
        }
    }, [selectedTemplateLayer]);
    return (
        <>
            {assetError ? (
                <ErrorLarge text={translate(Content.map3.layerTemplates.failedToLoadLayer)} subdued />
            ) : (
                <StyledContent>
                    <div className="sidebar">
                        <Button
                            sx={{ minHeight: inputHeight, height: "auto" }}
                            data-testid={dataTestIds.modals.createCustomLayerButton}
                            color="primary"
                            variant="outlined"
                            onClick={onStartFromScratchSelected}
                        >
                            <span>{translate(Content.map3.layerTemplates.createCustomLayer)}</span>
                            {!functionality.customLayer && <FontAwesomeIcon icon={["far", "circle-up"]} className={`${inlineTextIcon} icon`} />}
                        </Button>
                        <LayerTemplateCategoryTreeBrowser
                            ref={categoriesComponentRef}
                            assetType={AssetType.LayerTemplate}
                            getTags={assetService.getAssetTags}
                            onCategorySelected={handleCategorySelected}
                            handleStaticCategorySelected={handleStaticCategorySelected}
                            staticCategorySelected={staticCategorySelected}
                            showRecommendedCategories={recommendedCategories?.length !== 0}
                        />
                    </div>
                    <SearchBarContainer>
                        <div className="search-label-container">
                            <Header5>{translate(Content.map3.layerTemplates.selectTemplateText)}</Header5>
                            {(assetFetching || isTypingInSearchBar) && templateLayerQueries?.length > 0 && (
                                <FontAwesomeIcon icon="circle-notch" size="sm" color={theme.typographyColors.subdued} spin />
                            )}
                        </div>

                        <SearchBarComponent
                            className="search-bar"
                            value={searchInputValue}
                            onValueChange={(s) => {
                                setSearchValue(s);
                                categoriesComponentRef.current?.clearSelection();
                            }}
                            placeHolderText={translate(Content.map3.layerTemplates.searchPlaceholder)}
                        />
                    </SearchBarContainer>
                    <Header6 className="subheader">
                        {debouncedSearchValue?.length > 0 ? (
                            <span>{translate(Content.map3.layerTemplates.searchResults)}</span>
                        ) : staticCategorySelected === "all" ? (
                            <span>{translate(Content.map3.layerTemplates.allTemplates)}</span>
                        ) : staticCategorySelected === "recommended" ? (
                            <span>{translate(Content.map7.layerTemplates.chosenForYou)}</span>
                        ) : (
                            <span>{categories?.[0]?.name}</span>
                        )}
                    </Header6>
                    <TemplateListContainer className="template-list" ref={templateContainerRef}>
                        {assetLoading ? (
                            <TemplateList data-testid="layer-template-loading-skeleton">{skeletons}</TemplateList>
                        ) : templateLayerQueries.length > 0 ? (
                            <TemplateList>
                                {templateLayerQueries.map((query, index) => (
                                    <LayerTemplatePreview
                                        name={query.data?.name}
                                        tags={query.data?.tags}
                                        key={query.data?.assetId ?? index}
                                        thumbnailUrl={query.data?.thumbnailUrl}
                                        iconUrl={query.data?.iconUrl}
                                        loading={query.status === "loading"}
                                        error={query.status === "error"}
                                        selected={selectedTemplateLayer?.assetId === query.data?.assetId && selectedTemplateLayer !== undefined}
                                        onClick={() => handleTemplateClick(query.data)}
                                        testId={`${index === templateLayerQueries.length - 1 ? "last-layer-template" : `layer-template-${index}`}`}
                                        dataCy={`layer-template-${query.data?.name}`}
                                        firstLayerTemplateNameTestId={index === 0 ? "first-layer-template-name" : null}
                                    />
                                ))}
                            </TemplateList>
                        ) : (
                            <>
                                <Body2 style={{ margin: sectionalMargin }}>{translate(Content.map3.layerTemplates.noLayerTemplates)}</Body2>
                                <Button style={{ marginLeft: sectionalMargin }} variant="outlined" color="primary" onClick={() => onStartFromScratchSelected()}>
                                    {capitalise(translate(Content.map3.layerTemplates.startFromScratch))}
                                </Button>
                            </>
                        )}
                    </TemplateListContainer>
                </StyledContent>
            )}
        </>
    );
};

const StyledContent = styled.div`
    ${muiInputFormsCSS}
    display: grid;
    grid-template-areas:
        "search search"
        "sidebar subheader"
        "sidebar list";
    grid-template-columns: 200px auto;
    grid-template-rows: 107px 44px 1fr;
    max-height: 100%;

    .sidebar {
        grid-area: sidebar;
        margin-right: 15px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-right: 0.5px solid ${({ theme }) => theme.shades.lightBorder};
    }

    .subheader {
        grid-area: subheader;
        margin: 12px 0;
        font-weight: 500;
    }
`;

const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: ${formGap};
    grid-area: search;
    height: 78px;
    gap: 10px;

    .search-label-container {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .search-bar {
        width: 100%;
    }
`;

const TemplateListContainer = styled.div`
    // Gives the container a height, so the contents don't jump. Uses heights of contents for two rows.
    padding-top: ${sectionalMargin};
    flex: 1 1;
    grid-area: list;
    min-height: 0;
    overflow-y: auto;
`;

const TemplateList = styled.div`
    display: grid;
    width: 100%;
    justify-items: center;

    grid-template-columns: repeat(3, 1fr);
    ${customMediaQueryMax(screenSizeBreakpoints.large - 25)} {
        grid-template-columns: repeat(2, 1fr);
    }
    ${customMediaQueryMax(screenSizeBreakpoints.medium - 25)} {
        grid-template-columns: repeat(1, 1fr);
    }
    ${customMediaQueryMax(screenSizeBreakpoints.extraExtraSmall - 25)} {
        grid-template-columns: repeat(1, 1fr);
    }
    gap: 15px;
    padding-bottom: 15px;
`;
