import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { AnySupportedGeometry, LocalGeoJson } from "@iventis/map-types";
import { previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { createDefaultLocalGeoJsonObject, createPreviewObjectId, createPreviewObjectName } from "./preview-helper-functions";

export function createAreaPreviewSourceData(areaStyle: AreaStyle, snapshotGeometry: boolean): LocalGeoJson {
    const isDataDriven = areaStyle.colour?.extractionMethod === StyleValueExtractionMethod.Mapped;
    if (isDataDriven) {
        return createAreaDataDrivenLocalGeoJson(areaStyle, snapshotGeometry);
    }
    // If not data driven create normal area local geo json
    return createAreaLocalGeoJson(snapshotGeometry);
}

function createAreaDataDrivenLocalGeoJson(areaStyle: AreaStyle, snapshotGeometry: boolean) {
    const maxNumberOfFeaturesAllowed = snapshotGeometry ? 2 : 4; // we can show up to 2 features in a snapshot and 4 in the preview screen.

    // Get the possible colour key values
    const colourKeyValues =
        areaStyle.colour.mappedValues != null
            ? Object.entries(areaStyle.colour.mappedValues)
                  .map((keyValuePair) => keyValuePair[0])
                  .slice(0, maxNumberOfFeaturesAllowed) // Slice to the max number of features allowed.
            : [];

    // If the total number of data driven colour key values is less than the max number of features allowed, add 1 to allow for the default colour when no data field is selected.
    const numberOfFeatures = colourKeyValues.length < maxNumberOfFeaturesAllowed ? colourKeyValues.length + 1 : colourKeyValues.length;

    // Create an array of data driven objects with the data driven property assigned
    const dataDrivenlocalObjects = colourKeyValues.map((colourKey, index) => {
        const geometry = snapshotGeometry
            ? createAreaDataDrivenPreviewSnapshotGeometry(index + 1, numberOfFeatures)
            : createAreaDataDrivenPreviewGeometry(index + 1, numberOfFeatures);
        return createDefaultLocalGeoJsonObject(createPreviewObjectId(index), createPreviewObjectName(index), previewLayerId, 0, geometry, {
            [areaStyle.colour.dataFieldId]: colourKey,
        });
    });

    // If the number of features is more than colour key values, then we include the default colour feature. This just has no data field properties assigned.
    if (numberOfFeatures > colourKeyValues.length) {
        const geometry = snapshotGeometry
            ? createAreaDataDrivenPreviewSnapshotGeometry(numberOfFeatures, numberOfFeatures)
            : createAreaDataDrivenPreviewGeometry(numberOfFeatures, numberOfFeatures);
        const localObject = createDefaultLocalGeoJsonObject(createPreviewObjectId(numberOfFeatures), createPreviewObjectName(numberOfFeatures), previewLayerId, 0, geometry);
        dataDrivenlocalObjects.push(localObject);
    }
    return {
        [previewLayerId]: dataDrivenlocalObjects,
    };
}

function createAreaLocalGeoJson(snapshotGeometry: boolean): LocalGeoJson {
    const geom = snapshotGeometry ? createAreaPreviewSnapshotGeometry() : createAreaPreviewGeometry();
    const localObject = createDefaultLocalGeoJsonObject("PreviewObject", "Object 1", previewLayerId, 0, geom);
    return {
        [previewLayerId]: [localObject],
    };
}

// Creates a single dimond
export function createAreaPreviewSnapshotGeometry(): AnySupportedGeometry {
    return {
        type: "Polygon",
        coordinates: [
            [
                [-0.0013, 0],
                [0, 0.0013],
                [0.0013, 0],
                [0, -0.0013],
                [-0.0013, 0],
            ],
        ],
    };
}

/**
 * Get a data driven preview geometry
 * @param {number} featureNumber The current feature number (starts at 1).
 * @param {number} totalFeaturesInPreview total number of features that will be in the preview
 * @returns { AnySupportedGeometry } the feature geom for the feature number provided
 */
export function createAreaDataDrivenPreviewGeometry(featureNumber: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createAreaPreviewGeometry();
        case 2:
            return TwoAreaDataDrivenPreviewGeometries[featureNumber - 1];
        case 3:
            return ThreeAreaDataDrivenPreviewGeometries[featureNumber - 1];
        case 4:
            return FourAreaDataDrivenPreviewGeometries[featureNumber - 1];
        default:
            throw new Error("Total Features in preview must be 1-4");
    }
}

/**
 * Get a data driven preview snapshot geometry
 * @param {number} featureNumber The current feature number (starts at 1).
 * @param {number} totalFeaturesInPreview total number of features that will be in the preview
 * @returns { AnySupportedGeometry } the feature geom for the feature number provided
 */
export function createAreaDataDrivenPreviewSnapshotGeometry(featureNumber: number, totalFeaturesInPreview: number): AnySupportedGeometry {
    switch (totalFeaturesInPreview) {
        case 1:
            return createAreaPreviewSnapshotGeometry();
        case 2:
            return TwoAreaDataDrivenPreviewSnapshotGeometries[featureNumber - 1];
        default:
            throw new Error("Total Features in snapshot must be 1-2");
    }
}

// Creates a single dimond
export function createAreaPreviewGeometry(): AnySupportedGeometry {
    return {
        type: "Polygon",
        coordinates: [
            [
                [-0.0012, 0],
                [0, 0.0012],
                [0.0012, 0],
                [0, -0.0012],
                [-0.0012, 0],
            ],
        ],
    };
}
// Creates a 2 traingles in the shape of a diamond
const TwoAreaDataDrivenPreviewSnapshotGeometries: AnySupportedGeometry[] = [
    {
        type: "Polygon",
        coordinates: [
            [
                [0, 0.0012],
                [0.0012, 0],
                [0, -0.0012],
                [0, 0.0012],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [0, 0.0012],
                [-0.0012, 0],
                [0, -0.0012],
                [0, 0.0012],
            ],
        ],
    },
];
// Creates 2 virtical rectangles
const TwoAreaDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Polygon",
        coordinates: [
            [
                [-0.001, 0.001],
                [-0.0001, 0.001],
                [-0.0001, -0.001],
                [-0.001, -0.001],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [0.0001, 0.001],
                [0.001, 0.001],
                [0.001, -0.001],
                [0.0001, -0.001],
            ],
        ],
    },
];
// Creates 3 virtical rectangles
const ThreeAreaDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Polygon",
        coordinates: [
            [
                [-0.001, 0.001],
                [-0.0004, 0.001],
                [-0.0004, -0.001],
                [-0.001, -0.001],
                [-0.001, 0.001],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [-0.0003, 0.001],
                [0.0003, 0.001],
                [0.0003, -0.001],
                [-0.0003, -0.001],
                [-0.0003, 0.001],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [0.001, 0.001],
                [0.0004, 0.001],
                [0.0004, -0.001],
                [0.001, -0.001],
                [0.001, 0.001],
            ],
        ],
    },
];

// Creates 4 sqaures
const FourAreaDataDrivenPreviewGeometries: AnySupportedGeometry[] = [
    {
        type: "Polygon",
        coordinates: [
            [
                [-0.0011, 0.0011],
                [-0.00005, 0.0011],
                [-0.00005, 0.00005],
                [-0.0011, 0.00005],
                [-0.0011, 0.0011],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [0.00005, 0.0011],
                [0.0011, 0.0011],
                [0.0011, 0.00005],
                [0.00005, 0.00005],
                [0.00005, 0.0011],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [0.00005, -0.00005],
                [0.0011, -0.00005],
                [0.0011, -0.0011],
                [0.00005, -0.0011],
                [0.00005, -0.00005],
            ],
        ],
    },
    {
        type: "Polygon",
        coordinates: [
            [
                [-0.00005, -0.00005],
                [-0.0011, -0.00005],
                [-0.0011, -0.0011],
                [-0.00005, -0.0011],
                [-0.00005, -0.00005],
            ],
        ],
    },
];
