/* eslint-disable no-case-declarations */
import { assign, createMachine, InvokeCallback } from "xstate";
import { send } from "xstate/lib/actions";
import { isValidUuid } from "@iventis/utilities";
import { TreeBrowserMachineContext, TreeBrowserMachineEvent, TreeBrowserMachineEventType } from "./tree-browser.machine.types";
import {
    findNode,
    isAllNodesSelected,
    findAllParentIds,
    addNodes,
    updateNodeProperties,
    deleteNodes,
    updateNode,
    updateNodesProperties,
    moveNodes,
    updateNodesLastUpdatedAt,
    findNodesThatAreDescendants,
    toggleFavourite,
    listIdsOfAllDescendants,
    flattenNodeTree,
} from "../../tree-operations";
import { TreeBrowserNode, NodeServices } from "../../types/data-types";
import { TreeBrowserSelection } from "../tree-browser-selection";

export const nodeServicesId = "node-services";
export const treeBrowserWebSocketsId = "web-sockets";

// eslint-disable-next-line no-undef
type MachineTypegen = import("./tree-browser.machine.typegen").Typegen0;
type InternalEvents = Omit<MachineTypegen["internalEvents"][keyof MachineTypegen["internalEvents"]], "__tip">;

/** Use when sending an event from this machine to the node services child */
const sendToNodeServices = <
    TNode extends TreeBrowserNode,
    TEventReceived extends TreeBrowserMachineEvent<TNode> | InternalEvents,
    TEventToSend extends TreeBrowserMachineEvent<TNode>
>(
    sendEvent: (context: TreeBrowserMachineContext<TNode>, event?: TEventReceived) => TEventToSend
) => send(sendEvent, { to: nodeServicesId });

/** Use when sending an event from this machine to the web sockets child */
const sendToWebSockets = <
    TNode extends TreeBrowserNode,
    TEventReceived extends TreeBrowserMachineEvent<TNode> | InternalEvents,
    TEventToSend extends TreeBrowserMachineEvent<TNode>
>(
    sendEvent: (context: TreeBrowserMachineContext<TNode>, event?: TEventReceived) => TEventToSend
) => send(sendEvent, { to: treeBrowserWebSocketsId });

export function createTreeBrowserMachine<TNode extends TreeBrowserNode>(
    services: NodeServices<TNode>,
    initialState: TreeBrowserMachineContext<TNode>,
    treeBrowserSelection: TreeBrowserSelection<TNode>,
    websockets?: (services: NodeServices<TNode>) => (context: TreeBrowserMachineContext<TNode>) => InvokeCallback<TreeBrowserMachineEvent<TNode>, TreeBrowserMachineEvent<TNode>>
) {
    return createMachine(
        {
            id: "tree-browser",
            predictableActionArguments: true,
            schema: {
                context: {} as TreeBrowserMachineContext<TNode>,
                events: {} as TreeBrowserMachineEvent<TNode>,
                services: {} as { getTree: { data: TNode }; getNode: { data: TNode } },
            },
            // eslint-disable-next-line no-undef
            tsTypes: {} as import("./tree-browser.machine.typegen").Typegen0,
            context: initialState,
            initial: "loadingTree",
            invoke: {
                id: treeBrowserWebSocketsId,
                src: "webSockets",
            },
            exit: "treeResourceClosed",
            states: {
                loadingTree: {
                    id: "loadingTree",
                    exit: "",
                    invoke: {
                        src: "getTree",
                        onDone: {
                            actions: "onTreeLoaded",
                            target: "idle",
                        },
                        onError: {
                            target: "loadingTreeFailed",
                        },
                    },
                },
                loadingDisplayTree: {
                    id: "loadingDisplayTree",
                    invoke: {
                        src: "getNode",
                        onDone: {
                            actions: "replaceNode",
                            target: "idle",
                        },
                        onError: {
                            target: "loadingTreeFailed",
                        },
                    },
                    on: { SEARCH: "loadingTree" },
                },
                loadingTreeFailed: {
                    id: "loadingTreeFailed",
                    always: [
                        {
                            cond: (context) => context.treeId !== context.mainNodeId || context.expandedNodeIds.length > 0,
                            actions: "loadingTreeFailed",
                            target: "loadingTree",
                        },
                        {
                            target: "loadingTreeError",
                        },
                    ],
                    on: { SEARCH: "loadingTree" },
                },
                loadingTreeError: {
                    id: "loadingTreeError",
                    on: { SEARCH: "loadingTree" },
                },
                idle: {
                    id: "idle",
                    invoke: {
                        id: nodeServicesId,
                        src: "nodeServices",
                    },
                    entry: "updateWsContext",
                    on: {
                        SEARCH: "loadingTree",
                        ADD_NODES: [
                            {
                                // If node is added from web sockets, we dont need to update the node if it exists. Web sockets arrive one at a time, so we know the array length is 1
                                cond: (context, event) => findNode([context.tree], event.payload[0].id) == null,
                                actions: ["addNode", "updateWsContext"],
                            },
                        ],
                        DELETE_NODES: {
                            actions: ["deleteNodes", "updateWsContext"],
                        },
                        UPDATE_NODE: {
                            actions: ["updateNode", "updateNodesLastUpdatedAt", "updateWsContext"],
                        },
                        UPDATE_NODES: {
                            actions: ["updateNodes", "updateNodesLastUpdatedAt", "updateWsContext"],
                        },
                        MOVE_NODES: {
                            actions: ["moveNodes", "updateNodesLastUpdatedAt", "updateWsContext"],
                        },
                        TOGGLE_EXPAND_NODE: [
                            {
                                cond: (context, event) => context.expandedNodeIds.includes(event.payload.node.id),
                                actions: ["collapseNode"],
                            },
                            {
                                cond: (_, event) => event.payload.skipGet,
                                actions: ["expandNode", "resourceOpened"],
                            },
                            {
                                cond: (_, event) => event.payload.forceGet,
                                actions: ["getNodeRequested", "expandNode", "resourceOpened"],
                            },
                            {
                                cond: (_, event) => event.payload.node.childNodes.length === event.payload.node.childCount,
                                actions: ["expandNode", "resourceOpened"],
                            },
                            {
                                actions: ["getNodeRequested", "expandNode", "resourceOpened"],
                            },
                        ],
                        COLLAPSE_NODE: {
                            actions: ["collapseNode"],
                        },
                        EXPAND_NODE: [
                            {
                                cond: (_, event) => event.payload.skipGet,
                                actions: ["expandNode", "resourceOpened"],
                            },
                            {
                                cond: "isNodeMissingData",
                                actions: ["getNodeRequested", "expandNode", "resourceOpened"],
                            },
                            {
                                actions: ["expandNode", "resourceOpened"],
                            },
                        ],
                        REPLACE_NODE: {
                            actions: ["replaceNode", "updateWsContext"],
                        },
                        TOGGLE_SELECT_NODES: {
                            actions: "toggleSelectedNodes",
                        },
                        SET_MAIN_NODE: [
                            {
                                cond: "isNodeMissingData",
                                actions: ["setMainNode", "resourceOpened", "updateWsContext"],
                                target: "loadingDisplayTree",
                            },
                            {
                                actions: ["setMainNode", "resourceOpened", "updateWsContext"],
                            },
                        ],
                        SET_TREE: [
                            {
                                actions: ["treeResourceClosed", "setTree", "resourceOpened", "updateWsContext"],
                                target: "loadingTree",
                            },
                        ],
                        SORT_NODES: {
                            actions: ["sortNodes", "updateWsContext"],
                        },
                        GET_THUMBNAILS: {
                            actions: ["getThumbnails", "updateWsContext"],
                        },
                        APPEND_LOADING_NODE: {
                            actions: "appendLoadingNodes",
                        },
                        REMOVE_LOADING_NODE: {
                            actions: "removeLoadingNode",
                        },
                        TOGGLE_SELECT_ALL: [
                            {
                                cond: (context) => isAllNodesSelected(findNode([context.tree], context.mainNodeId), context.selectedNodeIds),
                                actions: ["deselectAll", "updateWsContext"],
                            },
                            {
                                actions: ["selectAll", "updateWsContext"],
                            },
                        ],
                        APPEND_REQUEST_ID: { actions: "appendRequestIds" },
                        REMOVE_REQUEST_ID: { actions: "removeRequestIds" },
                        ADD_FAVOURITE: {
                            actions: "addFavourite",
                        },
                        DELETE_FAVOURITE: {
                            actions: "deleteFavourite",
                        },
                        SET_WITH_EXISTING_TREE: {
                            actions: ["setWithExistingTree", "updateWsContext"],
                        },
                    },
                },
            },
        },
        {
            guards: {
                isNodeMissingData: (_, event) => {
                    if (event.type === "SET_MAIN_NODE") return event.payload?.childNodes.length !== event.payload.childCount;
                    return event.payload?.node.childNodes.length !== event.payload.node.childCount;
                },
            },
            services: {
                getTree: async (context, event) => {
                    let searchString = "";
                    let id = context.expandedNodeIds?.[0] ?? context.mainNodeId ?? context.treeId;
                    if (event.type === TreeBrowserMachineEventType.SEARCH) {
                        searchString = event.payload;
                        // We should use the context tree id when using search.
                        // This is because when using search the tree will be repopulated from the root so we should use the root id.
                        // It prevents bugs where it un-expectactly searches using the id of an expanded node.
                        id = context.treeId;
                    }

                    const tree = await services.getTree(id, context.sort, searchString);
                    return tree;
                },
                getNode: async (context) => {
                    const node = await services.getNode(context.mainNodeId, context, context.sort);
                    return node;
                },
                webSockets: websockets ? websockets(services) : () => () => undefined,
                nodeServices: () => createNodeServices(services),
            },
            actions: {
                onTreeLoaded: assign((context, event) => {
                    context.tree = event.data;
                    if (context.mainNodeId == null) {
                        context.mainNodeId = context.tree.id;
                    }
                    context.expandedNodeIds = context.expandedNodeIds.length > 0 ? [...findAllParentIds(event.data, context.expandedNodeIds[0])] : [];
                    if (context.singleSelectionOnly) context.selectedNodeIds = [event.data.id];
                    // If expand all on initial load is set to true, then append all node ids that exist in the tree into the expanded nodes array
                    if (context.expandAllNodesOnLoad) context.expandedNodeIds = flattenNodeTree(context.tree).map(({ id }) => id);
                    return context;
                }),
                loadingTreeFailed: assign((context) => {
                    // Tree failed to load with the current mainNodeId and expandedNodeIds
                    // Set them to the default value and try again
                    context.mainNodeId = context.treeId;
                    context.expandedNodeIds = [];
                    return context;
                }),
                addNode: assign({
                    tree: (context, event) => {
                        const { tree, sort } = context;
                        return addNodes(tree, event.payload, sort?.sort);
                    },
                    expandedNodeIds: (context, event) =>
                        context.expandAllNodesOnLoad ? [...context.expandedNodeIds, ...event.payload?.map(({ id }) => id)] : context.expandedNodeIds,
                }),
                deleteNodes: assign((context, event) => ({
                    tree: deleteNodes(context.tree, event.payload),
                    // Selection can change while we are deleting or websockets could call this event and delete a node we've not selected, so we filter instead of emptying
                    selectedNodeIds: treeBrowserSelection.fromDeleteNodes({
                        tree: context.tree,
                        nodes: [],
                        mainNodeId: context.mainNodeId,
                        selectedNodeIds: context.selectedNodeIds,
                        deletedNodeIds: event.payload,
                        from: event.options?.from,
                    }),
                    // Remove all deleted nodes from expanded nodes
                    expandedNodeIds: context.expandedNodeIds.filter((id) => !event.payload.includes(id)),
                })),
                updateNode: assign({
                    tree: (context, event) =>
                        updateNode(
                            context.tree,
                            event.payload.node,
                            context.expandedNodeIds,
                            event.payload.previousNodeParentId,
                            context.sort?.sort,
                            event.payload.skipExpandedCheck,
                            event.payload.replaceChildren
                        ),
                }),
                updateNodes: assign({
                    tree: (context, event) => updateNodesProperties(context.tree, event.payload.nodes, context.sort?.sort),
                }),
                moveNodes: assign((context, event) => {
                    const tree = moveNodes(event.payload.nodes, context.tree, event.payload.newParentId, context.sort.sort);
                    const selectedNodeIds = treeBrowserSelection.fromMoveNodes({
                        tree,
                        selectedNodeIds: context.selectedNodeIds,
                        mainNodeId: context.mainNodeId,
                        nodes: event.payload.nodes,
                    });
                    return {
                        tree,
                        selectedNodeIds,
                    };
                }),
                addFavourite: assign({
                    tree: (context, event) => toggleFavourite(context.tree, event.payload.node.id, true),
                }),
                deleteFavourite: assign({
                    tree: (context, event) => toggleFavourite(context.tree, event.payload.node.id, false),
                }),
                updateNodesLastUpdatedAt: assign({
                    tree: (context, event) => {
                        if (event.type === "MOVE_NODES" || event.type === "UPDATE_NODES") {
                            return updateNodesLastUpdatedAt(event.payload.nodes, context.tree, event.type === "MOVE_NODES" && event.payload.newParentId);
                        }
                        if (event.type === "UPDATE_NODE") {
                            return updateNodesLastUpdatedAt([event.payload.node], context.tree, null);
                        }
                        return context.tree;
                    },
                }),
                collapseNode: assign({
                    expandedNodeIds: (context, event) => {
                        const nodeId = event.type === "COLLAPSE_NODE" ? event.payload.id : event.payload.node.id;
                        const nodesToCollapse = findNodesThatAreDescendants(context.tree, nodeId, context.expandedNodeIds);
                        return context.expandedNodeIds.filter((nodeId) => !nodesToCollapse.includes(nodeId));
                    },
                    selectedNodeIds: (context, event) => {
                        const { selectedNodeIds, tree, mainNodeId, singleSelectionOnly } = context;
                        const nodeId = event.type === "COLLAPSE_NODE" ? event.payload.id : event.payload.node.id;
                        if (context.selectedNodeIds.includes(nodeId)) {
                            return context.selectedNodeIds;
                        }
                        return treeBrowserSelection.fromCollapseNode({ tree, mainNodeId, nodes: [nodeId], selectedNodeIds, singleSelectionOnly });
                    },
                }),
                expandNode: assign({
                    expandedNodeIds: (context, event) => [...context.expandedNodeIds, event.payload.node.id],
                    selectedNodeIds: ({ tree, selectedNodeIds, singleSelectionOnly, mainNodeId }, event) =>
                        treeBrowserSelection.fromExpandNode({ tree, selectedNodeIds, singleSelectionOnly, mainNodeId, nodes: [event.payload.node] }),
                }),
                getNodeRequested: sendToNodeServices((context, event) => ({
                    type: TreeBrowserMachineEventType.GET_NODE_REQUESTED,
                    payload: { nodeId: event.payload.node.id, context, sort: context.sort },
                })),
                replaceNode: assign({
                    tree: (context, event) => updateNodeProperties(context.tree, event.data),
                }),
                toggleSelectedNodes: assign({
                    selectedNodeIds: (context, event) => {
                        const { selectedNodeIds, singleSelectionOnly, tree, mainNodeId } = context;
                        const { nodes, ctrlHeld, ignoreExistingSelection, from, affectedNodeTypes } = event.payload;
                        return treeBrowserSelection.fromSelectNodes({
                            nodes,
                            selectedNodeIds,
                            singleSelectionOnly,
                            ctrlHeld,
                            ignoreExistingSelection,
                            tree,
                            mainNodeId,
                            from,
                            affectedNodeTypes,
                        });
                    },
                }),
                setMainNode: assign({
                    mainNodeId: (_, event) => event.payload.id,
                    // Clear selection when you change your display
                    selectedNodeIds: () => [],
                }),
                setTree: assign((_, event) => ({
                    treeId: event.payload.id,
                    selectedNodeIds: [],
                    expandedNodeIds: [],
                    mainNodeId: event.payload.id,
                })),
                sortNodes: assign((_, event) => ({
                    tree: event.payload.sortedTree,
                    sort: event.payload.sort,
                })),
                getThumbnails: assign({
                    nodeThumbnails: (context, event) => {
                        Object.keys(event.payload).forEach((key) => {
                            context.nodeThumbnails[key] = event.payload[key];
                        });
                        return context.nodeThumbnails;
                    },
                }),
                appendLoadingNodes: assign({
                    loadingNodeIds: (context, event) => [...context.loadingNodeIds, event.payload],
                }),
                removeLoadingNode: assign({
                    loadingNodeIds: (context, event) => context.loadingNodeIds.filter((nodeId) => nodeId !== event.payload),
                }),
                deselectAll: assign({
                    selectedNodeIds: () => [],
                }),
                selectAll: assign({
                    selectedNodeIds: (context) => {
                        const mainNode = findNode([context.tree], context.mainNodeId);
                        return listIdsOfAllDescendants(mainNode);
                    },
                }),
                resourceOpened: sendToWebSockets((_, event) => ({
                    type: TreeBrowserMachineEventType.RESOURCE_OPENED,
                    payload:
                        event.type === "SET_TREE" || event.type === "SET_MAIN_NODE"
                            ? { id: event.payload.id, sourceId: event.payload.sourceId }
                            : { id: event.payload.node.id, sourceId: event.payload.node.sourceId },
                })),
                treeResourceClosed: sendToWebSockets((context) => ({ type: TreeBrowserMachineEventType.RESOURCE_CLOSED, payload: { id: context.treeId } })),
                appendRequestIds: assign({ pendingRequests: (context, event) => [...context.pendingRequests, ...event.payload] }),
                removeRequestIds: assign({
                    pendingRequests: (context, event) => context.pendingRequests.filter((req) => !event.payload.some((p) => p.requestId === req.requestId)),
                }),
                setWithExistingTree: assign((_, { payload: { tree, mainNodeId } }) => ({
                    tree,
                    mainNodeId,
                    treeId: tree.id,
                })),
                updateWsContext: sendToWebSockets((context) => ({
                    type: TreeBrowserMachineEventType.UPDATE_CONTEXT,
                    payload: context,
                })),
            },
        }
    );
}

const createNodeServices = <TNode extends TreeBrowserNode>(services: NodeServices<TNode>): InvokeCallback<TreeBrowserMachineEvent<TNode>, TreeBrowserMachineEvent<TNode>> => (
    send,
    onReceive
) => {
    onReceive((event) => {
        switch (event.type) {
            case TreeBrowserMachineEventType.ADD_NODE_REQUESTED:
                // If the node is already has a valid uuid id, then we do not need to wait for the request before adding the node to the tree.
                const waitForRequest = !isValidUuid(event.payload.node.id);
                const addToTree = (node) => send({ type: TreeBrowserMachineEventType.ADD_NODES, payload: [node] });
                const addToBackend = () => services.addNode(event.payload.node, event.payload.context.tree);
                if (waitForRequest) {
                    addToBackend().then((res) => addToTree(res));
                } else {
                    addToTree(event.payload.node);
                    addToBackend();
                }
                break;
            case TreeBrowserMachineEventType.DELETE_NODES_REQUESTED:
                services.deleteNodes(event.payload.nodes, event.payload.context.tree).then((res) => {
                    send({ type: TreeBrowserMachineEventType.DELETE_NODES, payload: res });
                    if (event.payload.onDone) event.payload.onDone(event.payload.nodes);
                });
                break;
            case TreeBrowserMachineEventType.UPDATE_NODE_REQUESTED:
                send({
                    type: TreeBrowserMachineEventType.UPDATE_NODE,
                    payload: { node: event.payload.node, previousNodeParentId: event.payload.previousNodeParentId, replaceChildren: event.payload.replaceChildren },
                });
                services.updateNode(event.payload.node, event.payload.context.tree);
                break;
            case TreeBrowserMachineEventType.MOVE_NODES_REQUESTED:
                send({ type: TreeBrowserMachineEventType.MOVE_NODES, payload: { nodes: event.payload.nodes, newParentId: event.payload.newParentId } });
                services
                    .moveNodes(
                        event.payload.nodes.map((node) => ({ ...node, parentId: event.payload.newParentId })),
                        event.payload.context.tree
                    )
                    .then(() => {
                        if (event.payload.onDone) event.payload.onDone(event.payload.nodes);
                    });
                break;
            case TreeBrowserMachineEventType.GET_NODE_REQUESTED:
                send({ type: TreeBrowserMachineEventType.APPEND_LOADING_NODE, payload: event.payload.nodeId });

                services
                    .getNode(event.payload.nodeId, event.payload.context, event.payload.sort)
                    .then((res) => {
                        send({ type: TreeBrowserMachineEventType.REPLACE_NODE, data: res });
                        send({ type: TreeBrowserMachineEventType.EXPAND_NODE, payload: { node: res, skipGet: true } });
                        send({ type: TreeBrowserMachineEventType.REMOVE_LOADING_NODE, payload: res.id });
                    })
                    .catch(() => {
                        send({ type: TreeBrowserMachineEventType.REMOVE_LOADING_NODE, payload: event.payload.nodeId });
                        send({ type: TreeBrowserMachineEventType.COLLAPSE_NODE, payload: { id: event.payload.nodeId } });
                    });
                break;
            case TreeBrowserMachineEventType.SORT_NODES_REQUESTED:
                // Need to sort the tree and all of the nodes which are the main node and expanded
                const { sort, mainNodeId, expandedNodeIds, treeId, context } = event.payload;
                const sortedTreeRequest = services.getTree(treeId, sort, null);
                // Remove the treeId from the other nodes request otherwise we are getting the same node multiple times
                const sortedNodesRequest = [...expandedNodeIds, mainNodeId].filter((id) => id !== treeId).map((nodeId) => services.getNode(nodeId, context, sort));
                Promise.all([sortedTreeRequest, ...sortedNodesRequest]).then((values) => {
                    const [tree, ...sortedNodes] = values;
                    const sortedTree = updateNodesProperties(tree, sortedNodes);
                    send({ type: TreeBrowserMachineEventType.SORT_NODES, payload: { sort: event.payload.sort, sortedTree } });
                });
                break;
            case TreeBrowserMachineEventType.GET_THUMBNAILS_REQUESTED:
                const thumbnailGetter = services.getNodeThumbnails[event.payload.node.type];
                if (thumbnailGetter == null) {
                    throw new Error(`There is not getter for ${event.payload.node.type} node type`);
                }
                thumbnailGetter(event.payload.node, event.payload.tree).then((res) => {
                    send({ type: TreeBrowserMachineEventType.GET_THUMBNAILS, payload: res });
                });
                break;
            case TreeBrowserMachineEventType.ADD_FAVOURITE_REQUESTED:
                services.addFavourite(event.payload.node.id);
                send({ type: TreeBrowserMachineEventType.ADD_FAVOURITE, payload: event.payload });
                break;
            case TreeBrowserMachineEventType.DELETE_FAVOURITE_REQUESTED:
                services.deleteFavourite(event.payload.node.id);
                send({ type: TreeBrowserMachineEventType.DELETE_FAVOURITE, payload: event.payload });
                break;
            default:
                throw new Error(`Event type is not handled`);
        }
    });
};
