import { SnackbarProvider } from "notistack";
import { styled, zIndexes } from "@iventis/styles";
import React from "react";
import { SlideTransition } from "@iventis/toasts/src/toast-component";
import { SuccessToast, ErrorToast, InfoToast, WarningToast } from "@iventis/toasts/src/toast-wrapper";

export const ToastProvider = ({ children }) => (
    <ToastProviderWrapper>
        <SnackbarProvider
            hideIconVariant
            maxSnack={3}
            Components={{ success: SuccessToast, info: InfoToast, error: ErrorToast, default: SuccessToast, warning: WarningToast }}
            TransitionComponent={SlideTransition}
        >
            {children}
        </SnackbarProvider>
    </ToastProviderWrapper>
);

const ToastProviderWrapper = styled.div`
    height: 100%;

    .notistack-SnackbarContainer {
        z-index: ${zIndexes.toast} !important;
        top: 65px;
        gap: 15px;
    }

    .notistack-CollapseWrapper {
        padding: 0 !important;
    }
`;
