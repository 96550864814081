import React, { FunctionComponent, ReactNode, useState } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { styled, formGap, iconButtonSize, StyledFieldLabel, StyledIconButton } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { AccordionSummary, TextField, useTheme } from "@mui/material";
import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import { FileUploadComponent } from "@iventis/components/src/upload-file";
import { ErrorText } from "@iventis/components/src/selectors/incremental-value-input";
import { validateAreaGeometry } from "@iventis/map-export-import-mapper/src/layer-geojson-validation";
import { ValidationError } from "@iventis/map-export-import-mapper/src/layer-geojson-validation-types";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { SitemapVersionLevelWithFiles } from "./sitemap-types";
import { isSitemapVersionLevelValid } from "./sitemap-helpers";
/** Used to wrap a sitemap level form in an accordion (expandable content) */
export const SitemapLevelAccordion: FunctionComponent<{
    isNew: boolean;
    children: ReactNode;
    sitemapVersionLevel: SitemapVersionLevelWithFiles;
    isLevelIndexUnique: boolean;
    onDelete: (id: string) => void;
}> = ({ isNew, children, sitemapVersionLevel, isLevelIndexUnique, onDelete }) => {
    const translate = useIventisTranslate();
    const theme = useTheme<Theme>();

    const [expanded, setExpanded] = useState(isNew);

    function validateAreaGeometryForSitemapVersionLevel() {
        try {
            return validateAreaGeometry(sitemapVersionLevel?.perimeter, StyleType.Area) === ValidationError.None;
        } catch (e) {
            return false;
        }
    }

    const isValid =
        isSitemapVersionLevelValid(sitemapVersionLevel) &&
        (!isNew || (sitemapVersionLevel.mbtiles != null && sitemapVersionLevel.style != null)) &&
        isLevelIndexUnique &&
        validateAreaGeometryForSitemapVersionLevel();

    return (
        <StyledAccordion expanded={expanded} onChange={(_, expanded) => setExpanded(expanded)}>
            <AccordionSummary>
                <p>
                    {sitemapVersionLevel.levelName?.length > 0 ? (
                        <>
                            <span>{sitemapVersionLevel.levelName}</span>{" "}
                            {sitemapVersionLevel.levelAbbreviation?.length > 0 && <span>({sitemapVersionLevel.levelAbbreviation})</span>}
                        </>
                    ) : (
                        <span>[{translate(Content.map6.sitemapVersion.enter_level_name)}]</span>
                    )}
                </p>
                <>
                    <div className="fill-space" />
                    <StyledIconButton
                        className="delete-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(sitemapVersionLevel.id);
                        }}
                    >
                        <span className="hidden">
                            {translate(Content.common.buttons.remove)} - {sitemapVersionLevel.levelName}
                        </span>
                        <FontAwesomeIcon icon={["far", "trash"]} size="xs" />
                    </StyledIconButton>
                </>
                {isValid ? (
                    <FontAwesomeIcon color={theme.secondaryColors.accept} icon={["fas", "circle-check"]} />
                ) : (
                    <FontAwesomeIcon color={theme.secondaryColors.deny} icon={["fas", "circle-xmark"]} />
                )}
            </AccordionSummary>
            {children}
        </StyledAccordion>
    );
};

export const SitemapLevelForm: FunctionComponent<{
    sitemapVersionLevel: SitemapVersionLevelWithFiles;
    setSitemapVersionLevel: (sitemapVersionLevel: SitemapVersionLevelWithFiles) => void;
    isLevelIndexUnique: boolean;
}> = ({ sitemapVersionLevel, setSitemapVersionLevel, isLevelIndexUnique }) => {
    const translate = useIventisTranslate();

    const [perimeter, setPerimeter] = useState(JSON.stringify(sitemapVersionLevel.perimeter));

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setPerimeter(newValue);

        try {
            const parsedValue = JSON.parse(newValue);
            const updatedSitemapVersionLevel = {
                ...sitemapVersionLevel,
                perimeter: parsedValue,
            };
            setSitemapVersionLevel(updatedSitemapVersionLevel);
        } catch (error) {
            const updatedSitemapVersionLevel = {
                ...sitemapVersionLevel,
                perimeter: null,
            };
            setSitemapVersionLevel(updatedSitemapVersionLevel);
        }
    };
    return (
        <StyledForm>
            <div className="level-details">
                <div>
                    <StyledFieldLabel>{translate(Content.map6.sitemapVersion.name)}</StyledFieldLabel>
                    <TextField
                        variant="outlined"
                        value={sitemapVersionLevel.levelName}
                        onChange={(event) => setSitemapVersionLevel({ ...sitemapVersionLevel, levelName: event.target.value })}
                    />
                </div>
                <div>
                    <StyledFieldLabel>{translate(Content.map6.sitemapVersion.level_index)}</StyledFieldLabel>
                    <TextField
                        sx={{ maxWidth: "3rem" }}
                        value={sitemapVersionLevel.levelIndex ?? null}
                        onChange={(event) => setSitemapVersionLevel({ ...sitemapVersionLevel, levelIndex: event.target.value?.length > 0 ? Number(event.target.value) : null })}
                        type="number"
                        error={!isLevelIndexUnique}
                    />
                    {!isLevelIndexUnique && <ErrorText>{translate(Content.map6.sitemapVersion.level_index_must_be_unique)}</ErrorText>}
                </div>
                <div>
                    <StyledFieldLabel>{translate(Content.map6.sitemapVersion.level_abbreviation)}</StyledFieldLabel>
                    <TextField
                        variant="outlined"
                        sx={{ maxWidth: "4rem" }}
                        value={sitemapVersionLevel.levelAbbreviation}
                        onChange={({ target: { value } }) => value?.length <= 3 && setSitemapVersionLevel({ ...sitemapVersionLevel, levelAbbreviation: value })}
                    />
                </div>
            </div>
            <div>
                <StyledFieldLabel>{translate(Content.map9.sitemapVersion.perimeter)}</StyledFieldLabel>
                <TextField variant="outlined" className="perimeter-input" value={perimeter} onChange={handleValueChange} />
            </div>
            <div>
                <StyledFieldLabel>{translate(Content.map6.sitemapVersion.tiles)}</StyledFieldLabel>
                <FileUploadComponent
                    uploadFile={(file: File) => {
                        setSitemapVersionLevel({ ...sitemapVersionLevel, mbtiles: file });
                    }}
                    uploadButtonText={translate(Content.map6.sitemapVersion.upload_tiles)}
                    removeRequested={() => setSitemapVersionLevel({ ...sitemapVersionLevel, mbtiles: undefined })}
                    loading={false}
                    removeImageText={translate(Content.map6.sitemapVersion.remove_tiles)}
                    persistFileSelectInput
                    fileName={sitemapVersionLevel.mbtiles?.name}
                />
            </div>
            <div>
                <StyledFieldLabel>{translate(Content.map6.sitemapVersion.style)}</StyledFieldLabel>
                <FileUploadComponent
                    uploadFile={(file: File) => {
                        setSitemapVersionLevel({ ...sitemapVersionLevel, style: file });
                    }}
                    uploadButtonText={translate(Content.map6.sitemapVersion.upload_style)}
                    removeRequested={() => setSitemapVersionLevel({ ...sitemapVersionLevel, style: undefined })}
                    loading={false}
                    removeImageText={translate(Content.map6.sitemapVersion.remove_tiles)}
                    persistFileSelectInput
                    fileName={sitemapVersionLevel.style?.name}
                />
            </div>
        </StyledForm>
    );
};

const StyledForm = styled.div`
    display: flex;
    gap: ${formGap};
    flex-direction: column;
    position: relative;

    .level-details {
        display: flex;
        gap: ${formGap};
        align-items: end;
        flex-wrap: wrap;
        width: calc(100% - ${iconButtonSize});
    }

    .perimeter-input {
        width: 100%;
    }
`;

const StyledAccordion = styled(Accordion)`
    .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        margin: 4px 0;
    }
    .MuiCollapse-root {
        padding: 0 16px;
    }
    .MuiCollapse-entered {
        padding-bottom: 16px;
    }
    .fill-space {
        flex-grow: 1;
    }
`;
