import { borderRadius, EmphasisText, zIndexes, styled } from "@iventis/styles";
import { Theme, css } from "@emotion/react";
import { ExtractRefFromComponent, OptionalExceptFor } from "@iventis/types/useful.types";
import { Status } from "@iventis/domain-model/model/status";
import React, { useState, useEffect, PropsWithChildren, forwardRef, useImperativeHandle } from "react";
import { useInView } from "react-intersection-observer";

import { Asset } from "@iventis/domain-model/model/asset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFunctionality } from "@iventis/utilities";
import { LoadingComponent } from "./loading";
import { SelectableImageComponent } from "./selectable-image";

// Redecalare forwardRef
declare module "react" {
    function forwardRef<T, P = {}>(render: (props: P, ref: React.Ref<T>) => React.ReactElement | null): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

// eslint-disable-next-line no-underscore-dangle
function _AssetBrowserComponent<TAsset extends OptionalExceptFor<Asset, "id" | "name">>(
    {
        assets,
        imageUrlGetter,
        fetchNextPage,
        isFetchingNextPage,
        cardWidth,
        cardHeight,
        className,
        initialSelection,
        cardMinWidth,
        multiSelect = true,
        translate = (text) => text,
        onSelectionChange,
        onEditClicked,
    }: PropsWithChildren<{
        assets: TAsset[];
        imageUrlGetter: (asset: TAsset) => string;
        fetchNextPage: () => void;
        isFetchingNextPage: boolean;
        cardWidth: string;
        cardHeight: string;
        cardMinWidth?: string;
        className?: string;
        initialSelection?: string;
        multiSelect?: boolean;
        translate?: (text: string) => string;
        onSelectionChange?: (selection: string[]) => void;
        onEditClicked?: (id: string) => void;
    }>,
    ref: React.ForwardedRef<{ getSelection: () => string[] }>
) {
    const { ref: infiniteFetchRef, inView } = useInView();
    const [selection, setSelection] = useState<string[]>(initialSelection !== undefined ? [initialSelection] : []);
    const select = (id: string) => setSelection((state) => (multiSelect ? [...state, id] : [id]));
    const deselect = (id: string) => setSelection((state) => state.filter((x) => x !== id));

    const functionality = useFunctionality();

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [inView]);

    // Sync selection
    useEffect(() => onSelectionChange?.(selection), [selection]);

    useImperativeHandle(ref, () => ({
        getSelection() {
            return selection;
        },
    }));

    return (
        <StyledContainer className={className} cardWidth={cardWidth} cardMinWidth={cardMinWidth}>
            {assets.length === 0 && (
                <EmphasisText size="small" className="no-results">
                    {translate("No results")}
                </EmphasisText>
            )}
            {assets
                .filter((asset) => asset.status === Status.Active)
                .map((asset) => (
                    <div className="image-container" key={asset.id}>
                        <SelectableImageComponent
                            id={asset.id}
                            name={asset.name}
                            selected={selection.includes(asset.id)}
                            select={select}
                            deselect={deselect}
                            image={imageUrlGetter(asset)}
                            width={cardWidth}
                            height={cardHeight}
                            onEditClicked={onEditClicked}
                        >
                            <div className="icon-container">
                                {(asset.metaData?.sdf || asset.metaData?.customColour) && <FontAwesomeIcon icon={["fas", "palette"]} className="metadata-icon" />}
                                {asset.metaData?.customImage && functionality?.imagesOnModels && <FontAwesomeIcon icon={["fas", "image"]} className="metadata-icon" />}
                            </div>
                        </SelectableImageComponent>
                    </div>
                ))}
            <div ref={infiniteFetchRef}>{isFetchingNextPage ? <LoadingComponent /> : null}</div>
        </StyledContainer>
    );
}

const StyledContainer = styled.div<{ cardWidth: string; cardMinWidth?: string }>`
    display: grid;
    ${(props) =>
        props.cardWidth === "100%"
            ? css`
                  grid-template-columns: repeat(auto-fill, minmax(${props.cardMinWidth ?? props.cardWidth}, 1fr));
              `
            : css`
                  grid-template-columns: repeat(auto-fill, ${props.cardMinWidth ?? props.cardWidth});
              `}

    grid-auto-rows: min-content;
    height: 100%;
    gap: 10px;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadius.standard};
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;

    .no-results {
        position: absolute;
        margin: 10px;
    }

    .image-container {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .icon-container {
        position: absolute;
        bottom: 2px;
        right: 2px;
        display: flex;
        gap: 5px;
    }

    .metadata-icon {
        z-index: ${zIndexes.editLayer.sdfIcon};
        color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued50};
    }
`;

export type AssetBrowserRef = ExtractRefFromComponent<typeof AssetBrowserComponent>;

export const AssetBrowserComponent = forwardRef(_AssetBrowserComponent);
