/* eslint-disable no-underscore-dangle */
import { createElement, FunctionComponent } from "react";
import { OptionsObject, useSnackbar, VariantType } from "notistack";
import { v4 as uuid } from "uuid";

import { ToastComponent } from "./toast-component";
import { ToastProps, Toasts } from "./toast.types";

let toastRef: ReturnType<typeof useSnackbar>;

export const GlobalToastRef: FunctionComponent = () => {
    // Set toastRef to this hook so it can be accessed outside of react components, for example an api interceptor
    toastRef = useSnackbar();
    return null;
};

export const toast = {
    _activeToasts: new Set<string>(),

    success(toastProps: ToastProps, options: OptionsObject = {}) {
        this._create(toastProps, "success", options);
    },
    warning(toastProps: ToastProps, options: OptionsObject = {}) {
        this._create(toastProps, "warning", options);
    },
    info(toastProps: ToastProps, options: OptionsObject = {}) {
        this._create(toastProps, "info", options);
    },
    error(toastProps: ToastProps, options: OptionsObject = {}) {
        this._create(toastProps, "error", options);
    },
    // Function should not be called directly
    _create(toastProps: ToastProps, variant: VariantType = "default", options: OptionsObject) {
        // Generate a unique identifier for the toast based on its content
        const identifier = this._getToastIdentifier(toastProps);

        if (this._activeToasts.has(identifier)) return;

        this._activeToasts.add(identifier);
        // If a key is not provided create a key
        const key = options.key || uuid();

        // Create a toast using the toast type passed into the function
        toastRef.enqueueSnackbar(createElement(ToastComponent, { variant, close: () => this.remove(key, toastProps), ...toastProps }), {
            variant,
            anchorOrigin: { vertical: "top", horizontal: "right" },
            key,
            ...options,
            onClose: (event, reason) => {
                // Remove the identifier from active toasts when the toast is closed
                if (reason !== "instructed") {
                    this._activeToasts.delete(identifier);
                }
            },
        });
    },
    // Given a toast key you can close a toast
    remove(key: string, toastProps: ToastProps) {
        toastRef.closeSnackbar(key);
        this._activeToasts.delete(this._getToastIdentifier(toastProps));
    },
    _getToastIdentifier(toastProps: ToastProps): string {
        // Generate a unique identifier based on the toast's content
        const { title = "", message = "" } = toastProps;
        return `${title}-${message}`;
    },
} as const;

// Test to ensure that the toast object matches the Toasts interface (Replace with satisfies once we upgrade to TS 4.9)
type ExpectTrue = typeof toast extends Toasts ? true : false;
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const _test: ExpectTrue = true;
