/**
 * Manipulates the given number with the given math operation
 * @param type the operation to perform on the number
 * @param value the value to perform the operation on
 * @param exp the exponent
 * @returns the new value
 */
export const decimalAdjust = (type: "round" | "floor" | "ceil", value: number, exp: number) => {
    const stringType = String(type);
    if (exp % 1 !== 0 || Number.isNaN(value)) {
        return NaN;
    }
    if (exp === 0) {
        return Math[stringType](value);
    }
    const [magnitude, exponent = 0] = value.toString().split("e");
    const adjustedValue = Math[type](Number(`${magnitude}e${+exponent - exp}`));
    // Shift back
    const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
    return Number(`${newMagnitude}e${+newExponent + exp}`);
};

export const numberWithCommas = (num: number | string) => (typeof num === "string" ? num : num.toString()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
