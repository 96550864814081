import { StyleSpecification } from "mapbox-gl";

export interface GoogleMapsSourceMetadata {
    apiKey: string;
    sessionToken: string;
    tags: string[];
}

/** Checks if the given source is a google maps source */
export function isGoogleMapsMapboxSource(style: StyleSpecification): style is StyleSpecification & { metadata: GoogleMapsSourceMetadata } {
    if (style == null || style.metadata == null || typeof style.metadata !== "object") {
        return false;
    }
    const metadata = style.metadata as Record<string, unknown>;

    const hasApiKey = typeof metadata.apiKey === "string";
    const hasSessionToken = typeof metadata.sessionToken === "string";

    let hasTags = false;
    if (Array.isArray(metadata.tags)) {
        hasTags = metadata.tags.every((tag) => typeof tag === "string");
    }

    return hasApiKey && hasSessionToken && hasTags;
}
