import { Position } from "geojson";
import centroid from "@turf/centroid";
import distance from "@turf/distance";
import { AnyFeature } from "@iventis/map-types";
import { featureCollection } from "@turf/helpers";
import explode from "@turf/explode";
import bearing from "@turf/bearing";
import transformTranslate from "@turf/transform-translate";
import { isValidUuid } from "@iventis/utilities";

const DEFAULT_SNAPPING_DISTANCE_PIXELS = 20;

export type ObjectSnappingFunctions<TFeature extends AnyFeature = AnyFeature> = {
    /** Queries the canvas for map objects */
    queryRenderedFeatures: (point: [number, number], radius: number) => TFeature[];
    /** Gets local geometry for a given map object, will return undefined if it is not present */
    getGeometry: (layerId: string, objectId: string) => TFeature | undefined;
    /** Will request geometry for all given objects */
    requestMapObjectGeometries: (objects: TFeature[]) => void;
    /** Given real world coordinates, will return the corresponding canvas coordinates */
    getCanvasCoordinates: (point: Position) => [number, number];
    /** Checks if the geometry for a given object has already been requested */
    hasFeatureAlreadyBeenRequested: (layerId: string, objectId: string) => boolean;
    /** Called when a coordinate has been snapped to */
    onSnapToCoordinate: (snappedToCoordinate: Position | undefined) => void;
};

export class ObjectSnapping<TFeature extends AnyFeature> {
    public latestTranslation: TFeature[];

    private lastSnappedToCoordinate: Position | undefined;

    private readonly queryRenderedFeatures: ObjectSnappingFunctions<TFeature>["queryRenderedFeatures"];

    private readonly requestMapObjectGeometries: ObjectSnappingFunctions<TFeature>["requestMapObjectGeometries"];

    private readonly getGeometry: ObjectSnappingFunctions<TFeature>["getGeometry"];

    private readonly getCanvasCoordinates: ObjectSnappingFunctions<TFeature>["getCanvasCoordinates"];

    private readonly hasFeatureAlreadyBeenRequested: ObjectSnappingFunctions<TFeature>["hasFeatureAlreadyBeenRequested"];

    private readonly onSnapToCoordinate: ObjectSnappingFunctions<TFeature>["onSnapToCoordinate"];

    constructor(objects: TFeature[] | undefined, externalFunctions: ObjectSnappingFunctions<TFeature>, private snappingRadius = DEFAULT_SNAPPING_DISTANCE_PIXELS) {
        this.latestTranslation = objects;
        this.queryRenderedFeatures = externalFunctions.queryRenderedFeatures;
        this.getGeometry = externalFunctions.getGeometry;
        this.requestMapObjectGeometries = externalFunctions.requestMapObjectGeometries;
        this.getCanvasCoordinates = externalFunctions.getCanvasCoordinates;
        this.hasFeatureAlreadyBeenRequested = externalFunctions.hasFeatureAlreadyBeenRequested;
        this.onSnapToCoordinate = externalFunctions.onSnapToCoordinate;
    }

    /**
     * Modifies the given features by snapping the closest coordinates against other objects in the map.
     * If no objects are close enough to snap to, the original features are returned.
     */
    public modify(newTranslation: TFeature[]) {
        this.latestTranslation = newTranslation;
        this.lastSnappedToCoordinate = undefined;
        if (!newTranslation?.length) {
            this.onSnapToCoordinate(this.lastSnappedToCoordinate);
            return this.latestTranslation;
        }
        const { centre, radius } = this.getCentreAndRadius();
        const canvasPosition = this.getCanvasCoordinates(centre.geometry.coordinates);
        // Get all map objects within double the snapping distance to ensure we get all the objects we can snap to
        const mapObjects = this.queryRenderedFeatures(canvasPosition, radius + DEFAULT_SNAPPING_DISTANCE_PIXELS * 2);
        if (mapObjects?.length === 0) {
            this.onSnapToCoordinate(this.lastSnappedToCoordinate);
            return this.latestTranslation;
        }
        // Remove the object we are editing and any duplicates (from query rendered features)
        const filteredMapObjects = this.removeSelfAndDuplicates(mapObjects);

        const { localMapObjects, mapObjectsGeometriesToRequest } = this.getLocalMapObjectsAndMapObjectsToRequestGeometryFor(filteredMapObjects);

        if (mapObjectsGeometriesToRequest.length > 0) {
            // Request the geometry for the objects we need to request the geometry for
            this.requestMapObjectGeometries(mapObjectsGeometriesToRequest);
        }

        if (localMapObjects.length === 0) {
            this.onSnapToCoordinate(this.lastSnappedToCoordinate);
            return this.latestTranslation;
        }

        // Find new translation to snap to
        this.latestTranslation = this.getSnapToTranslation(localMapObjects);

        this.onSnapToCoordinate(this.lastSnappedToCoordinate);
        return this.latestTranslation;
    }

    /**
     * Snap the two coordinates from the latest features translation (this.latestTranslation) and the snap to features
     * @param snapToFeatures the features that we can snap to
     * @returns The resulting translation of all objects if they uniformly translate the direction and distance the snapping coordinate travels
     */
    private getSnapToTranslation(snapToFeatures: TFeature[]): TFeature[] {
        let nearestDistance = Infinity;
        let snappingCoordinate: Position;
        let snapToCoordinate: Position;
        this.latestTranslation.forEach((object) => {
            explode(object).features.forEach(({ geometry }) => {
                snapToFeatures.forEach((snapToFeature) => {
                    explode(snapToFeature).features.forEach((snapToPoint) => {
                        const distanceToCoordinate = distance(geometry.coordinates, snapToPoint.geometry.coordinates);
                        if (distanceToCoordinate < nearestDistance) {
                            nearestDistance = distanceToCoordinate;
                            snappingCoordinate = geometry.coordinates;
                            snapToCoordinate = snapToPoint.geometry.coordinates;
                        }
                    });
                });
            });
        });
        // If none of the objects are close enough to snap to, return the original objects
        if (this.getPixelDistance(snappingCoordinate, snapToCoordinate) > this.snappingRadius) {
            this.onSnapToCoordinate(undefined);
            return this.latestTranslation;
        }

        this.lastSnappedToCoordinate = snapToCoordinate;
        const distanceMoved = distance(snappingCoordinate, snapToCoordinate);
        const bearingMoved = bearing(snappingCoordinate, snapToCoordinate);
        const newFeatureCollection = transformTranslate(featureCollection(this.latestTranslation), distanceMoved, bearingMoved);
        // Cast here because geojson is unable to infer collection type from array of features
        return newFeatureCollection.features as TFeature[];
    }

    /** Separates the local map objects and the map objects which we need to request the geometry for  */
    private getLocalMapObjectsAndMapObjectsToRequestGeometryFor(mapObjects: TFeature[]) {
        const localMapObjects: TFeature[] = [];
        const mapObjectsGeometriesToRequest: TFeature[] = [];

        mapObjects.forEach((feature) => {
            const geometry = this.getGeometry(feature.properties.layerid, feature.properties.id);
            if (geometry != null) {
                localMapObjects.push(geometry);
            } else if (isValidUuid(feature.properties.layerid) && !this.hasFeatureAlreadyBeenRequested(feature.properties.layerid, feature.properties.id)) {
                mapObjectsGeometriesToRequest.push(feature);
            }
        });
        return { localMapObjects, mapObjectsGeometriesToRequest };
    }

    /** Consolidates the features so only one of each filter shows and removes the objects which are being edited */
    private removeSelfAndDuplicates(features: TFeature[]) {
        const uniqueFeatures = features.reduce<TFeature[]>((uniqueFeatures, feature) => {
            if (feature.properties.id == null || uniqueFeatures.some((uniqueFeature) => uniqueFeature.properties.id === feature.properties.id)) {
                return uniqueFeatures;
            }
            if (this.latestTranslation.some((object) => object.properties.id === feature.properties.id)) {
                return uniqueFeatures;
            }
            uniqueFeatures.push(feature);
            return uniqueFeatures;
        }, []);
        return uniqueFeatures;
    }

    /** Takes two real world coordinates and their distance on the canvas in pixels */
    private getPixelDistance(coordinateA: Position, coordinateB: Position) {
        const canvasCoordinatesA = this.getCanvasCoordinates(coordinateA);
        const canvasCoordinatesB = this.getCanvasCoordinates(coordinateB);
        const x = canvasCoordinatesA[0] - canvasCoordinatesB[0];
        const y = canvasCoordinatesA[1] - canvasCoordinatesB[1];
        return Math.sqrt(x * x + y * y);
    }

    private getCentreAndRadius() {
        const collection = featureCollection(this.latestTranslation);
        // Calculate centroid of FeatureCollection
        const centre = centroid(collection);

        // Start at maximum one because the point might be at the centre
        let max = -1;
        let coords: Position | undefined;

        // Determine the maximum radius (distance to the furthest point)
        collection.features
            .flatMap((feature) => explode(feature).features)
            .forEach((feature) => {
                const dist = distance(centre, feature);
                if (dist > max) {
                    max = dist;
                    coords = feature.geometry.coordinates;
                }
            });

        const radius = this.getPixelDistance(coords, centre.geometry.coordinates);

        return { centre, radius };
    }
}
