import React from "react";
import Slide from "@mui/material/Slide";
import { styled, borderRadius, sidebarPadding, fontSizes, StyledInteractiveArea, StyledIconButton, InteractiveElement } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VariantType } from "notistack";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { ToastVariantProps } from "./toast.types";

export const ToastComponent = React.forwardRef<HTMLDivElement, ToastVariantProps>(
    ({ onClick, button, title, message, testId, variant, overridingToastIcon, hideToastIcon = false, close }, ref) => {
        const translate = useIventisTranslate();
        return (
            <StyledToast ref={ref} $variant={variant} data-cy={testId}>
                <StyledInteractiveArea onClick={() => close?.()}>
                    <span className="hidden">{translate(Content.common.buttons.close)}</span>
                </StyledInteractiveArea>
                <div>
                    <StyledToastTitleContainer>
                        <div>
                            {overridingToastIcon && !hideToastIcon ? (
                                <StyledIconContainer>
                                    <FontAwesomeIcon icon={overridingToastIcon} />
                                </StyledIconContainer>
                            ) : !hideToastIcon ? (
                                <StyledIconContainer>{getToastIcon(variant)}</StyledIconContainer>
                            ) : null}
                            <StyledToastTitle>{title}</StyledToastTitle>
                        </div>

                        {button && "icon" in button && (
                            <StyledIconButton onClick={onClick}>
                                <FontAwesomeIcon icon={button.icon} />
                                <span className="hidden">{button.iconLabel}</span>
                            </StyledIconButton>
                        )}
                        {button && "label" in button && (
                            <InteractiveElement className="action-label" onClick={onClick}>
                                {button.label}
                            </InteractiveElement>
                        )}
                    </StyledToastTitleContainer>
                    {message && <StyledToastDescription $icon={hideToastIcon}>{message}</StyledToastDescription>}
                </div>
            </StyledToast>
        );
    }
);

const getToastIcon = (variant: VariantType) => {
    switch (variant) {
        case "success":
            return <FontAwesomeIcon icon="check-circle" />;
        case "info":
            return <FontAwesomeIcon icon="circle-info" />;
        case "error":
            return <FontAwesomeIcon icon="circle-exclamation" />;
        case "warning":
            return <FontAwesomeIcon icon="exclamation-triangle" />;
        default:
            return <FontAwesomeIcon icon="circle-info" />;
    }
};

const getBorderColor = (variant: VariantType, theme: Theme) => {
    switch (variant) {
        case "success":
            return theme.toastColours.successBorder;
        case "info":
            return theme.toastColours.infoBorder;
        case "error":
            return theme.toastColours.errorBorder;
        case "warning":
            return theme.toastColours.warningBorder;
        default:
            return theme.toastColours.infoBorder;
    }
};

export function SlideTransition(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide {...props} direction="down" />;
}

const StyledToast = styled.div<{ $variant: VariantType }>`
    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.light20};
    min-width: 300px;
    max-width: 300px;
    box-sizing: border-box;
    border-radius: ${borderRadius.input};
    padding: ${sidebarPadding};
    border-left: 5px solid ${({ $variant, theme }) => getBorderColor($variant, theme)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .action-label {
        color: ${({ theme }) => theme.primaryColors.focus};
        text-decoration: underline;
        font-weight: 500;
        z-index: 1;
    }
`;
const StyledIconContainer = styled.span`
    margin-right: 8px;
    color: ${(props) => `${props.theme.typographyColors.lessSubdued} `};
`;

const StyledToastTitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const StyledToastTitle = styled.span`
    font-size: ${fontSizes.medium};
    line-height: 24px;
    font-weight: 600;
    color: ${(props) => `${props.theme.typographyColors.core} `};
`;

const StyledToastDescription = styled.div<{ $icon?: boolean }>`
    font-size: ${fontSizes.small};
    font-weight: 400;
    color: ${(props) => `${props.theme.typographyColors.core} `};
    margin-left: ${({ $icon }) => ($icon ? "0" : "1.5rem")};
    line-height: 20px;
    margin-top: 4px;
`;
