import { library } from "@fortawesome/fontawesome-svg-core";

// This import situation isn't ideal but reduces bundle size
// Possible solution: https://github.com/FortAwesome/react-fontawesome/issues/70#issuecomment-411165298

// pro-regular-svg-icons
import { faClouds as farClouds } from "@fortawesome/pro-regular-svg-icons/faClouds";
import { faCloud as farCloud } from "@fortawesome/pro-regular-svg-icons/faCloud";
import { faChartNetwork as farChartNetwork } from "@fortawesome/pro-regular-svg-icons/faChartNetwork";
import { faCaretRight as farCaretRight } from "@fortawesome/pro-regular-svg-icons/faCaretRight";
import { faFileLines as farFileLines } from "@fortawesome/pro-regular-svg-icons/faFileLines";
import { faAperture as farAperture } from "@fortawesome/pro-regular-svg-icons/faAperture";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faLayerGroup as farLayerGroup } from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import { faPlus as farPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faFunction as farFunction } from "@fortawesome/pro-regular-svg-icons/faFunction";
import { faMinus as farMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faCircle as farCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faCircleInfo as farCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faMagnifyingGlass as farMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { faTimesCircle as farTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import { faUsers as farUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faTag as farTag } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faTrash as farTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faEdit as farEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faList as farList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faXmark as farXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faPencil as farPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faClipboardListCheck as farClipboardListCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardListCheck";
import { faFilter as farFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faFileArrowUp as farFileArrowUp } from "@fortawesome/pro-regular-svg-icons/faFileArrowUp";
import { faFileArrowDown as farFileArrowDown } from "@fortawesome/pro-regular-svg-icons/faFileArrowDown";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { faUp } from "@fortawesome/pro-regular-svg-icons/faUp";
import { faDown } from "@fortawesome/pro-regular-svg-icons/faDown";
import { faLeft } from "@fortawesome/pro-regular-svg-icons/faLeft";
import { faRight } from "@fortawesome/pro-regular-svg-icons/faRight";
import { faUpDownLeftRight } from "@fortawesome/pro-regular-svg-icons/faUpDownLeftRight";
import { faCircleDot } from "@fortawesome/pro-regular-svg-icons/faCircleDot";
import { faFolders } from "@fortawesome/pro-regular-svg-icons/faFolders";
import { faMap as farMap } from "@fortawesome/pro-regular-svg-icons/faMap";
import { faDownload as farDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faDatabase as farDatabase } from "@fortawesome/pro-regular-svg-icons/faDatabase";
import { faCode as farCode } from "@fortawesome/pro-regular-svg-icons/faCode";

// pro-solid-svg-icons
import { faClouds as fasClouds } from "@fortawesome/pro-solid-svg-icons/faClouds";
import { faCloud as fasCloud } from "@fortawesome/pro-solid-svg-icons/faCloud";
import { faChartNetwork as fasChartNetwork } from "@fortawesome/pro-solid-svg-icons/faChartNetwork";
import { faUsers as fasUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faCaretRight as fasCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretDown as fasCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faSort as fasSort } from "@fortawesome/pro-solid-svg-icons/faSort";
import { faCircleNotch as fasCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { faFileLines as fasFileLines } from "@fortawesome/pro-solid-svg-icons/faFileLines";
import { faAperture as fasAperture } from "@fortawesome/pro-solid-svg-icons/faAperture";
import { faHexagonImage } from "@fortawesome/pro-solid-svg-icons/faHexagonImage";
import { faFileUpload as fasFileUpload } from "@fortawesome/pro-solid-svg-icons/faFileUpload";
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil";
import { faLayerGroup as fasLayerGroup } from "@fortawesome/pro-solid-svg-icons/faLayerGroup";
import { faTag as fasTag } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faChevronUp as fasChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown as fasChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faDiamond as fasDiamond } from "@fortawesome/pro-solid-svg-icons/faDiamond";
import { faPalette as fasPalette } from "@fortawesome/pro-solid-svg-icons/faPalette";
import { faText as fasText } from "@fortawesome/pro-solid-svg-icons/faText";
import { faDatabase as fasDatabase } from "@fortawesome/pro-solid-svg-icons/faDatabase";
import { faCircle as fasCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faImage as fasImage } from "@fortawesome/pro-solid-svg-icons/faImage";
import { faCompass as fasCompass } from "@fortawesome/pro-solid-svg-icons/faCompass";
import { faCube as fasCube } from "@fortawesome/pro-solid-svg-icons/faCube";
import { faEllipsis as farEllipsis } from "@fortawesome/pro-solid-svg-icons/faEllipsis";
import { faPenToSquare as farPenToSquare } from "@fortawesome/pro-solid-svg-icons/faPenToSquare";
import { faTriangleExclamation as fasTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { faUser as fasUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faList as fasList } from "@fortawesome/pro-solid-svg-icons/faList";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faClipboardListCheck as fasClipboardListCheck } from "@fortawesome/pro-solid-svg-icons";
import { faCheck as fasCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faGear as farGear } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faCopy as fasCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faMap as fasMap } from "@fortawesome/pro-solid-svg-icons/faMap";
import { faCirclePlus as farCirclePlus } from "@fortawesome/pro-regular-svg-icons/faCirclePlus";
import { faFolder as fasFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faInfoCircle as fasCircleInfo } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faExclamationCircle as fasExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faFileArrowDown as falFileArrowDown } from "@fortawesome/pro-solid-svg-icons/faFileArrowDown";
import { faUpload as fasUpload } from "@fortawesome/pro-solid-svg-icons/faUpload";
import { faCode as fasCode } from "@fortawesome/pro-solid-svg-icons/faCode";

// pro-light-svg-icons
import { faText as falText } from "@fortawesome/pro-light-svg-icons/faText";
import { faList as falList } from "@fortawesome/pro-light-svg-icons/faList";
import { faHashtag as falHashtag } from "@fortawesome/pro-light-svg-icons/faHashtag";
import { faCalendar as falCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar";
import { faCheck as falCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faUsers as falUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
import { faTag } from "@fortawesome/pro-light-svg-icons/faTag";
import { faTrash as falTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faTrashUndo as falTrashUndo } from "@fortawesome/pro-light-svg-icons/faTrashUndo";
import { faTimer as falTimer } from "@fortawesome/pro-light-svg-icons/faTimer";
import { faClock as falClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faFile as falFile } from "@fortawesome/pro-light-svg-icons/faFile";

library.add(
    faTag,
    faTriangleExclamation,
    fasTriangleExclamation,
    farClouds,
    fasClouds,
    farCloud,
    fasCloud,
    farChartNetwork,
    fasChartNetwork,
    farCaretRight,
    fasCaretRight,
    fasCaretDown,
    fasSort,
    fasCircleNotch,
    farFileLines,
    fasFileLines,
    faHexagonImage,
    fasFileUpload,
    faCheck,
    faPencil,
    farPencil,
    fasLayerGroup,
    farLayerGroup,
    fasTag,
    farPlus,
    farFunction,
    farMinus,
    fasChevronUp,
    fasChevronDown,
    farCircle,
    farList,
    fasDiamond,
    fasPalette,
    fasText,
    farCircleInfo,
    fasDatabase,
    fasCircle,
    fasImage,
    fasCompass,
    farMagnifyingGlass,
    fasCube,
    falText,
    falList,
    falHashtag,
    falCalendar,
    falCheck,
    farTimesCircle,
    falUsers,
    fasUser,
    farPlus,
    farUsers,
    farUsers,
    farEllipsis,
    falUsers,
    farTag,
    farEllipsis,
    farPenToSquare,
    faUserPlus,
    faArrowsRotate,
    faCircleCheck,
    faCircleXmark,
    farTrash,
    farEdit,
    falTrash,
    falTrashUndo,
    fasList,
    faXmark,
    farXmark,
    farClipboardListCheck,
    fasClipboardListCheck,
    farGear,
    fasCheck,
    fasMap,
    fasCopy,
    fasUsers,
    farFilter,
    farFileArrowUp,
    farFileArrowDown,
    faCirclePlus,
    farCirclePlus,
    faUp,
    faDown,
    faLeft,
    faRight,
    faUpDownLeftRight,
    faCircleDot,
    faFolders,
    fasFolder,
    farMap,
    fasCircleInfo,
    farAperture,
    fasAperture,
    falTimer,
    falClock,
    fasExclamationCircle,
    farDownload,
    falFile,
    falFileArrowDown,
    fasUpload,
    farCode,
    fasCode,
    farDatabase
);
