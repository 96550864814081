/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { MapboxLayer } from "@deck.gl/mapbox";
import { Deck } from "deck.gl";
import Colour from "color";
import { Map } from "mapbox-gl";
import { Point, LineString, FeatureCollection, Feature } from "geojson";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { featureCollection } from "@turf/helpers";
import { v4 as uuid } from "uuid";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import { Model } from "@iventis/domain-model/model/model";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { MapObjectProperties, LocalGeoJson, AnySupportedGeometry } from "@iventis/map-types";
import { GLTFLoader, GLTFPostprocessed, postProcessGLTF } from "@loaders.gl/gltf";
import { CUSTOM_COLOUR_MATERIAL_SLOT_NAME, CUSTOM_IMAGE_MATERIAL_SLOT_NAME } from "@iventis/model-utilities";
import { load } from "@loaders.gl/core";
import { ImageLoader } from "@loaders.gl/images";
import { StylePropertyToValueMap } from "../../../../types/internal";
import { AttributeDrivenStyleMetaData, LayerMetaData, MapModuleLayer, ModelData, UnionOfStyles } from "../../../../types/store-schema";
import { Engine3D } from "../engine-3d-interface";
import { MapObject3D, ModelGeometry, UniqueModelLayerStyleProperties } from "../engine-3d-types";
import {
    DEFAULT_LIST_ITEM_ID,
    getAttributeBasedScaleValue,
    parseGeoJsonToMapObject3D,
    getCustomImageDataFromStyle,
    isStyleAttributeBased,
    getFundamentalValueAndListItemId,
    get3DMapObjectKey,
} from "./engine-deckgl-helpers";
import { createFeaturesOnLine } from "../3d-engine-helpers";
import { MapObject3DScale } from "../engine-3d-scale-types";
import {
    createScaleValueForListItems,
    createScaleValueForNonVariableScaleModel,
    createScaleValueForStatic,
    defaultScale,
    isModelValidForVariableScale,
    updateScaleValueForListItems,
} from "../3d-engine-scale-helpers";
import { ModelLayerStyle } from "../../../../types/models";
import { getModelLayerStyle } from "../../../../utilities/layer.helpers";
import { getDefaultStyleProperty } from "../../../../utilities/style-helpers";
import { MapboxEngine } from "../../engine-mapbox";

export class DeckglEngine extends Engine3D<MapboxLayer<MapObject3D>> {
    private mapObjectsMouseIsOver: MapObject3D;

    public readonly map: Map & { __deck: Deck };

    /** Ensure models are facing the same way as Threebox */
    private readonly rotationModifier = 180;

    /** Holds the precalculated scale values for each layer */
    private layerToScaleValue: { [layerId: string]: MapObject3DScale } = {};

    /** Deckgl layer id to the model it is using */
    private deckglLayerIdToModel: { [deckglLayerId: string]: Model } = {};

    /** Model Asset Id (main/thumbnail) to model LOD asset id */
    private modelIdToModelLodId: { [assetId: string]: string } = {};

    /** Key value pair of the models which have already been or are being loaded */
    private loadedOrRequestedModels: Record<string, ModelData> = {};

    constructor(map: Map, assetOptions: MapboxEngine["assetOptions"], currentLevel: number, models: ModelData[], dateFilterEnabled: boolean) {
        super(map, assetOptions, currentLevel, dateFilterEnabled);
        models.forEach((model) => {
            this.loadedOrRequestedModels[model.id] = { ...model };
        });
    }

    /**
     *  Creates an array of deckgl layers, one for each different model used for the layer.
     *
     *  For example an Iventis layer which has attribute based styling with 3 list values would have 4 deckgl layers (3 list items and default value)
     */
    public createLayer(id: string, style: ModelLayerStyle, visible: boolean, layerName: string): MapboxLayer<MapObject3D>[] {
        if (this.doesLayerExist(id)) {
            return this.getDeckglLayersFromIventisLayer(id);
        }

        const layers = getFundamentalValueAndListItemId(style.model).flatMap(({ listItemId: modelListItemId, value: modelId }) =>
            getFundamentalValueAndListItemId(style.colour).map(({ listItemId: colourListItemId, value: colour }) =>
                this.createDeckglLayer(
                    id,
                    { value: modelId, listItemId: modelListItemId, attributeId: style.model?.dataFieldId },
                    { value: colour, listItemId: colourListItemId, attributeId: style.colour?.dataFieldId },
                    visible,
                    style,
                    layerName
                )
            )
        );
        this.layers[id] = layers;
        layers.forEach((layer) =>
            this.createLayerScaleValues(this.loadedOrRequestedModels[layer.props.metaData.model.value], style, layer.props.metaData.layerId, layer.props.metaData.model.listItemId)
        );
        return layers;
    }

    private createDeckglLayer(
        layerId: string,
        model: AttributeDrivenStyleMetaData,
        colour: AttributeDrivenStyleMetaData,
        visible: boolean,
        style: ModelLayerStyle,
        layerName: string
    ) {
        const deckglLayerId = uuid();
        return new MapboxLayer({
            id: deckglLayerId,
            type: ScenegraphLayer,
            pickable: true,
            data: [],
            scenegraph: this.loadModel({ modelId: model.value, colour: colour.value }, deckglLayerId, style),
            getPosition: (d: MapObject3D) => d.position,
            getOrientation: (d: MapObject3D) => [0, d.rotation.z + this.rotationModifier, 90],
            getScale: (d: MapObject3D) => d.scale,
            visible,
            _lighting: "pbr",
            onHover: (event) => this.onLayerHover(event.object),
            metaData: {
                layerName,
                layerId,
                styleType: style.styleType,
                model,
                colour,
            },
        });
    }

    /** Deletes the layer and the associated scale value (does not remove the layer from the map) */
    public deleteLayer(iventisLayerId: string): void {
        delete this.layers[iventisLayerId];
        delete this.layerToScaleValue[iventisLayerId];
    }

    /** Deletes all model layers and removes them from the map */
    public deleteAllLayers(): void {
        Object.entries(this.layers).forEach(([iventisLayerId, layers]) => {
            this.deleteLayer(iventisLayerId);
            layers.forEach((layer) => {
                if (this.map.getLayer(layer.id)) {
                    this.map.removeLayer(layer.id);
                }
            });
        });
    }

    public doesLayerExist(layerId: string): boolean {
        return this.layers[layerId] != null;
    }

    /**
     * Updates all deckgl layers associated to the layerId passed in
     *
     * Note: Rotation and spacing require the updateMapObjects to be called
     */
    public updateStyle<TStyle extends UnionOfStyles = UnionOfStyles>(iventisLayerId: string, style: ModelLayerStyle, styleChanges: StylePropertyToValueMap<TStyle>[]): void {
        if (!(style.styleType === StyleType.Model || style.styleType === StyleType.LineModel)) {
            throw new Error("Style type is not supported");
        }

        // Sort style changes so that model is updated last
        styleChanges.sort(({ styleProperty: a }, { styleProperty: b }) => (a === "model" ? 1 : b === "model" ? -1 : 0));

        /**
         * Runs the callback for every deckgl layer that styleProperty affects.
         * For example, if the style property is model and model is attribute driven,
         * the callback will be ran on every deckgl layer that is created for each model
         */
        const processStylePropertyValue = <
            TStyleProperty extends Exclude<Extract<keyof LayerMetaData, keyof ModelLayerStyle>, "styleType">,
            TFundamentalType extends ModelLayerStyle extends { [Key in TStyleProperty]: infer TStyleValue } ? (TStyleValue extends StyleValue<infer T> ? T : never) : never
        >(
            styleProperty: TStyleProperty,
            modelListItemId: string,
            callback: (layer: MapboxLayer<MapObject3D>, listItemValuePair: { listItemId: string; attributeValue: TFundamentalType }) => void
        ) => {
            if (isStyleAttributeBased(style[styleProperty])) {
                getFundamentalValueAndListItemId<TFundamentalType>(style[styleProperty] as StyleValue<TFundamentalType>).forEach(({ listItemId, value }) => {
                    this.getDeckglLayersFromIventisLayer(iventisLayerId)
                        .filter((layer) => {
                            if (styleProperty === "model") {
                                return layer.props.metaData[styleProperty].listItemId === listItemId;
                            }
                            return layer.props.metaData[styleProperty].listItemId === listItemId && layer.props.metaData.model.listItemId === modelListItemId;
                        })
                        .forEach((layer) => callback(layer, { listItemId, attributeValue: value }));
                });
            } else {
                const styleValue = getStaticStyleValue(style[styleProperty] as StyleValue<TFundamentalType>);
                this.getDeckglLayersFromIventisLayer(iventisLayerId)
                    .filter((layer) => layer.props.metaData.model.listItemId === modelListItemId)
                    .forEach((layer) => callback(layer, { listItemId: DEFAULT_LIST_ITEM_ID, attributeValue: styleValue }));
            }
        };

        /** Reloads the model for the given layer and creates layer scale values */
        const loadModelAndCreateScaleValues = (layer: MapboxLayer<MapObject3D>, modelId: string, colour: string) => {
            layer.setProps({
                scenegraph: this.loadModel({ modelId, colour }, layer.id, style),
            });
            this.createLayerScaleValues(this.loadedOrRequestedModels[layer.props.metaData.model.value], style, layer.props.metaData.layerId, layer.props.metaData.model.listItemId);
        };

        styleChanges.forEach(({ styleProperty }) => {
            switch (styleProperty) {
                case "model": {
                    processStylePropertyValue("model", DEFAULT_LIST_ITEM_ID, (layer, { listItemId: modelListItem, attributeValue: modelId }) => {
                        const loadingModel = this.loadModel({ modelId, colour: layer.props.metaData.colour.value }, layer.id, style);

                        // Update the model being used for that layer
                        layer.setProps({
                            scenegraph: loadingModel,
                            metaData: { ...layer.props.metaData, model: { ...layer.props.metaData.model, value: modelId, listItemId: modelListItem } },
                        });

                        // If the model isnt loaded yet then we cannot create the scale values. Do it after we have loaded the model
                        if (this.loadedOrRequestedModels[layer.props.metaData.model.value] != null) {
                            this.createLayerScaleValues(
                                this.loadedOrRequestedModels[layer.props.metaData.model.value],
                                style,
                                layer.props.metaData.layerId,
                                layer.props.metaData.model.listItemId
                            );
                        } else {
                            loadingModel.then(() => {
                                this.createLayerScaleValues(
                                    this.loadedOrRequestedModels[layer.props.metaData.model.value],
                                    style,
                                    layer.props.metaData.layerId,
                                    layer.props.metaData.model.listItemId
                                );
                            });
                        }
                    });
                    break;
                }
                case "rotation":
                case "spacing":
                case "modelOffset":
                case "objectOrder":
                    // Above values need to update the properties of the 3d map objects instead of changing something on the layer
                    break;
                case "scale":
                case "length":
                case "width":
                case "height":
                    // For each deckgl layer which represents the Iventis layer, change it's dimensions
                    this.getDeckglLayersFromIventisLayer(iventisLayerId).forEach((layer) => {
                        this.createLayerScaleValues(this.deckglLayerIdToModel[layer.id], style, iventisLayerId, layer.props.metaData.model.listItemId);
                        this.updateLayerScaleValue(iventisLayerId);
                    });
                    break;
                case "customColour":
                case "colour":
                    processStylePropertyValue("model", DEFAULT_LIST_ITEM_ID, (_, { listItemId: modelListItemId, attributeValue: modelId }) => {
                        processStylePropertyValue("colour", modelListItemId, (layer, { listItemId: colourListItemId, attributeValue: colour }) => {
                            layer.setProps({
                                metaData: { ...layer.props.metaData, colour: { ...layer.props.metaData.colour, value: colour, listItemId: colourListItemId } },
                            });

                            // If we are also changing the model, defer model loading nad scale value generation for the model to be changed
                            if (!styleChanges.some(({ styleProperty }) => styleProperty === "model")) {
                                loadModelAndCreateScaleValues(layer, modelId, layer.props.metaData.colour.value);
                            }
                        });
                    });
                    break;
                case "customImage":
                    processStylePropertyValue("model", DEFAULT_LIST_ITEM_ID, (layer, { attributeValue: modelId }) => {
                        // If we are also changing the model, defer model loading nad scale value generation for the model to be changed
                        if (!styleChanges.some(({ styleProperty }) => styleProperty === "model")) {
                            loadModelAndCreateScaleValues(layer, modelId, layer.props.metaData.colour.value);
                        }
                    });
                    break;
                default:
                    throw new Error(`Style property "${String(styleProperty)}" not supported for style type "${style.styleType}"`);
            }
        });
    }

    public updateLayerVisibility(id: string, visible: boolean) {
        const layers = this.getDeckglLayersFromIventisLayer(id);
        layers.forEach((layer) => layer.setProps({ visible }));
    }

    /** Updates the map object model positions on the map for the given layer and features */
    public updateMapObjects(iventisLayer: MapModuleLayer, collection: FeatureCollection<ModelGeometry, MapObjectProperties>): void {
        // Ensure deckgl is initialised before updating the map objects
        if (!this.map?.__deck?.isInitialized) {
            return;
        }

        const deckglLayers = this.getDeckglLayersFromIventisLayer(iventisLayer.id);
        // Get attribute and list items which are used to display the model
        const keys = deckglLayers.map((layer) => get3DMapObjectKey(layer.props.metaData.model.listItemId, layer.props.metaData.colour.listItemId));
        const { attributeId: modelAttributeId } = deckglLayers[0].props.metaData.model;
        const { attributeId: colourAttributeId } = deckglLayers[0].props.metaData.colour;

        // Scale value is null due to model not being loaded. Use default scale.
        // Once model has loaded scale will be calculated and applied to the layer
        if (this.layerToScaleValue[iventisLayer.id] == null) {
            this.layerToScaleValue[iventisLayer.id] = defaultScale;
        }

        // Create a key value pair of list item id to an array of map objects
        const updatedMapObjects =
            iventisLayer.styleType === StyleType.Model
                ? this.updateMapObjectForModel(iventisLayer, collection, keys, modelAttributeId, colourAttributeId)
                : this.updateMapObjectsForLineModel(iventisLayer, collection.features, keys, modelAttributeId, colourAttributeId);

        // For each layer which represents the Iventis layer get it's update map objects and set the updated data
        deckglLayers.forEach((layer) => {
            const { listItemId: modelListItemId } = layer.props.metaData.model;
            const { listItemId: colourListItemId } = layer.props.metaData.colour;
            const features = updatedMapObjects[get3DMapObjectKey(modelListItemId ?? DEFAULT_LIST_ITEM_ID, colourListItemId ?? DEFAULT_LIST_ITEM_ID)];
            layer.setProps({ data: features });
        });
    }

    /** Creates key value pair of model list item id to an array of map object models for a line model */
    private updateMapObjectsForLineModel(
        iventisLayer: MapModuleLayer,
        features: Feature<ModelGeometry, MapObjectProperties>[],
        keys: string[],
        modelAttributeId: string,
        colourAttributeId: string
    ) {
        const lineFeatures = createFeaturesOnLine(
            iventisLayer.id,
            features as Feature<LineString, MapObjectProperties>[],
            iventisLayer.lineModelStyle.spacing ?? getDefaultStyleProperty(StyleType.LineModel, "spacing"),
            iventisLayer.lineModelStyle.rotation ?? getDefaultStyleProperty(StyleType.LineModel, "rotation"),
            iventisLayer.lineModelStyle.modelOffset ?? getDefaultStyleProperty(StyleType.LineModel, "modelOffset")
        );
        return parseGeoJsonToMapObject3D(
            lineFeatures,
            iventisLayer.id,
            this.layerToScaleValue[iventisLayer.id],
            this.currentLevel,
            keys,
            iventisLayer.name,
            this.dateFilterEnabled,
            modelAttributeId,
            colourAttributeId
        );
    }

    /** Creates key value pair of model list item id to an array of map object models for a model */
    private updateMapObjectForModel(
        layer: MapModuleLayer,
        collection: FeatureCollection<ModelGeometry, MapObjectProperties>,
        keys: string[],
        modelAttributeId: string,
        colourAttributeId: string
    ) {
        return parseGeoJsonToMapObject3D(
            collection as FeatureCollection<Point, MapObjectProperties>,
            layer.id,
            this.layerToScaleValue[layer.id],
            this.currentLevel,
            keys,
            layer.name,
            this.dateFilterEnabled,
            modelAttributeId,
            colourAttributeId
        );
    }

    public setCursor(): void {
        if (this.map.__deck) {
            this.map.__deck.props.getCursor = () => this.map.getCanvas().style.cursor;
        }
    }

    /** Returns the last model map object that the mouse as over */
    public queryRenderedFeatures(): Feature<AnySupportedGeometry, MapObjectProperties>[] {
        if (this.mapObjectsMouseIsOver == null) {
            return [];
        }
        const returnValue: Feature<AnySupportedGeometry, MapObjectProperties>[] = [
            {
                id: this.mapObjectsMouseIsOver.id,
                properties: this.mapObjectsMouseIsOver.properties,
                geometry: { coordinates: this.mapObjectsMouseIsOver.position, type: "Point" },
                type: "Feature",
            },
        ];
        return returnValue;
    }

    private onLayerHover(mapObject: MapObject3D) {
        this.mapObjectsMouseIsOver = mapObject;
    }

    /** When a model is loaded for a layer need to create scale values which are based upon the layer styles (height, width and length) and the model dimensions */
    private createLayerScaleValues(model: Model, style: ModelLayerStyle, layerId: string, listItemId?: string) {
        switch (true) {
            // Model style value are set by attribute
            case isStyleAttributeBased(style.model) && this.layerToScaleValue[layerId] != null:
                this.layerToScaleValue[layerId] = updateScaleValueForListItems(style, this.layerToScaleValue[layerId], listItemId, model);
                break;
            // Length, width and height are set by attribute
            case isStyleAttributeBased(style.height):
                this.layerToScaleValue[layerId] = createScaleValueForListItems(model, style);
                break;
            // Model being used by layer can have variable dimensions
            case isModelValidForVariableScale(model):
                this.layerToScaleValue[layerId] = createScaleValueForStatic(model, style);
                break;
            // Normal model layer with no attribute based styling
            default:
                this.layerToScaleValue[layerId] = createScaleValueForNonVariableScaleModel(style);
                break;
        }
        this.updateLayerScaleValue(layerId);
    }

    /** Gets a layer and updates all the map objects belonging to it with the latest scale values */
    private updateLayerScaleValue(layerId: string) {
        const updatedScaleValue = this.layerToScaleValue[layerId];
        const layers = this.getDeckglLayersFromIventisLayer(layerId);
        layers.forEach((layer) => {
            const updatedData = layer.props.data.map((d) => ({
                ...d,
                scale: updatedScaleValue.type === "static" ? updatedScaleValue.value : getAttributeBasedScaleValue(updatedScaleValue, d.properties),
            }));
            layer.setProps({
                data: updatedData,
            });
        });
    }

    /** Checks if a model has been loaded, is being loaded or has not been requested yet */
    public async loadModel(styleData: UniqueModelLayerStyleProperties, deckglLayerId: string, style: ModelLayerStyle) {
        const model = this.loadedOrRequestedModels[styleData.modelId];
        if (model == null) {
            return this.requestModel(styleData, deckglLayerId, style);
        }
        return this.getLoadedModel(model, styleData, deckglLayerId, style);
    }

    /** If a model is currently loading or has previously been loaded */
    private async getLoadedModel(model: ModelData, styleData: UniqueModelLayerStyleProperties, deckglLayerId: string, style: ModelLayerStyle) {
        this.deckglLayerIdToModel[deckglLayerId] = model;
        this.modelIdToModelLodId[styleData.modelId] = model.lods[0].files[0].assetId;

        // Get the asset related to the model and check the metadata
        const [modelAsset] = await this.assetOptions.multipleAssetUrlGetter([model.thumbnailAssetId]);

        let image: Blob;
        let colour: string;

        if (modelAsset.metaData?.customImage) {
            image = await getCustomImageDataFromStyle(style, this.assetOptions.assetUrlGetter);
        }

        if (modelAsset.metaData?.customColour && (await getStaticStyleValue(style.customColour))) {
            colour = styleData.colour;
        }
        return this.addModelToMap(model.modelRequest, image, colour);
    }

    /** If a model has not been requested yet */
    private async requestModel(styleData: UniqueModelLayerStyleProperties, deckglLayerId: string, style: ModelLayerStyle) {
        // Get the asset which contains the model asset url
        const [model] = await this.assetOptions.multipleModelsGetter([styleData.modelId]);

        this.deckglLayerIdToModel[deckglLayerId] = model;
        const { assetId } = model.lods[0].files[0];
        this.modelIdToModelLodId[styleData.modelId] = assetId;

        // Get the model asset url and then load the model
        const getModel = async () => {
            const modelUrl = await this.assetOptions.assetUrlGetter(assetId, AssetType.Model);
            const response = await fetch(modelUrl);
            const modelGlb = await response.arrayBuffer();
            return modelGlb;
        };

        // Added
        const loadingModel = { ...model, modelRequest: getModel() };
        this.loadedOrRequestedModels[styleData.modelId] = loadingModel;

        // Get the asset related to the model and check the metadata
        const [modelAsset] = await this.assetOptions.multipleAssetUrlGetter([model.thumbnailAssetId]);

        let image: Blob;
        let colour: string;

        if (modelAsset.metaData?.customImage) {
            image = await getCustomImageDataFromStyle(style, this.assetOptions.assetUrlGetter);
        }

        if (modelAsset.metaData?.customColour && (await getStaticStyleValue(style.customColour))) {
            colour = styleData.colour;
        }

        return this.addModelToMap(loadingModel.modelRequest, image, colour);
    }

    /** Waits for a model to load, if it hasn't already loaded and then adds it to the map */
    private async addModelToMap(model: Promise<ArrayBuffer> | ArrayBuffer, image?: Blob, colour?: string) {
        // If the model is still loading wait until it has resolved
        if (model instanceof Promise) {
            return model.then((response) => this.loadModelWithCustomisations(response, image, colour));
        }
        // Model has finished loading so add to the deckgl layer
        return this.loadModelWithCustomisations(model, image, colour);
    }

    /** Filters out map object models which do not have the currentLevel value */
    public setCurrentLevel(currentLevel: number, allModelMapObjects: LocalGeoJson, layers: MapModuleLayer[]): void {
        this.currentLevel = currentLevel;
        layers.forEach((layer) => {
            const features = allModelMapObjects[layer.id];
            const layerFeatureCollection = featureCollection(features.map((feature) => feature.feature as Feature<Point, MapObjectProperties>));
            this.updateMapObjects(layer, layerFeatureCollection);
        });
    }

    /** Checks if a layer model is styled by attributes and if all list items are represented by a deckgl layer */
    public doesLayerNeedRecreating(layer: MapModuleLayer) {
        const style = getModelLayerStyle(layer);
        // Only need to recreate layers where the model is attribute based on the model
        if (style.model.extractionMethod !== StyleValueExtractionMethod.Mapped && style.colour?.extractionMethod !== StyleValueExtractionMethod.Mapped) {
            return false;
        }
        // Get all of the deckgl layers and list value ids for that layer
        // Ensure that there is one layer per list item and the default value
        const deckglLayers = this.getDeckglLayersFromIventisLayer(layer.id);
        const listItemIds = [...Object.keys(style.model?.mappedValues ?? {}), ...Object.keys(style.colour?.mappedValues ?? {}), DEFAULT_LIST_ITEM_ID];
        const output =
            listItemIds.length === deckglLayers.length &&
            deckglLayers.every(
                (deckglLayer) => listItemIds.includes(deckglLayer.props.metaData.model.listItemId) && listItemIds.includes(deckglLayer.props.metaData.colour.listItemId)
            );
        return !output;
    }

    /** Getters */

    /** Get the Iventis layer from the deckgl layer Id */
    public getIventisLayerId(layerId: string) {
        return Object.keys(this.layers).find((deckglLayerId) => this.layers[deckglLayerId].some(({ id }) => layerId === id));
    }

    /** Return an array of deckgl layers which represent the Iventis layer */
    private getDeckglLayersFromIventisLayer(layerId: string): MapboxLayer<MapObject3D>[] {
        const layer = this.layers[layerId];
        if (layer == null) {
            throw Error(`${layerId} is not a layer in deckgl engine`);
        }
        return layer;
    }

    /** Get the LOD model asset ID from the model assetId */
    public getModelLodIdFromModelId(assetId: string) {
        return this.modelIdToModelLodId[assetId];
    }

    public removeModelFromLoadedModels(modelId: string) {
        delete this.loadedOrRequestedModels[modelId];
    }

    public updateModelMapOrder(id: string, aboveLayerId: string): void {
        this.layers[id].forEach((layer) => {
            this.map.moveLayer(layer.id, aboveLayerId);
        });
    }

    /**
     * Applies customisations to the model. This can include custom images and colours.
     * @param model The model to load with customisations
     * @param customImage The custom image to apply to the model
     * @param customColour The custom colour to apply to the model
     * @returns The processed model with the customisations applied
     */
    private loadModelWithCustomisations = async (model: ArrayBuffer | string, customImage?: Blob, customColour?: string): Promise<GLTFPostprocessed | any> => {
        const rawModel = await load(model, GLTFLoader);
        if (!rawModel?.json) {
            // Do JSON property is required to post process the model, if it is not present something has gone horribly wrong, an outdated loaders.gl version might be the cause
            console.warn("Model json is missing, cannot post process model. Returning raw model.");
            return rawModel;
        }

        let editedModel: any = rawModel;

        if (customImage) {
            editedModel = await this.loadModelWithImage(editedModel, customImage);
        }
        if (customColour) {
            editedModel = await this.loadModelWithColour(editedModel, customColour);
        }

        return postProcessGLTF(editedModel);
    };

    /**
     * Applies the custom colour to an already loaded model
     * @param model The loaded model to apply the custom colour to
     * @param customColour The hex colour to apply to the model
     * @returns the raw model with the custom colour applied
     */
    private loadModelWithColour = async (model: any, customColour?: string): Promise<any> => {
        if (!customColour) {
            return model;
        }

        try {
            const newModel = model;

            const colorConverted: number[] = Colour(customColour)
                .rgb()
                .array()
                .map((value) => value / 255);

            newModel.json.materials = model.json.materials.map((material) => {
                if (material.name.includes(CUSTOM_COLOUR_MATERIAL_SLOT_NAME)) {
                    return {
                        ...material,
                        pbrMetallicRoughness: {
                            ...material.pbrMetallicRoughness,
                            baseColorFactor: [...colorConverted, 1],
                        },
                    };
                }
                return material;
            });

            return newModel;
        } catch (e) {
            console.error("Failed to load custom colour for model, returning model without colour: ", e);
            return model;
        }
    };

    /**
     * Loads a model and optionally replaces the texture with a custom image
     * @param model The loaded model to apply the custom image to
     * @param customImage The custom image to replace the texture with
     * @returns The loaded model with the custom image applied
     * */
    private loadModelWithImage = async (model: any, customImage?: Blob): Promise<any> => {
        if (!customImage) {
            return model;
        }
        try {
            const rawImage = await load(customImage, ImageLoader, { image: { type: "data", decode: true } });
            const processed = postProcessGLTF(model);

            // Next we can derive the index of the image we want to replace based on the processed model
            // Go through materials and find the texture which has a name which includes the material name in CUSTOM_IMAGE_MATERIAL_SLOT_NAME
            // Go to pbrMetallicRoughness -> BaseColourTexture -> Texutre -> source -> id
            // This id is the id of the image we want to replace
            const materialWithImageSlot = processed.materials.find(
                (material) => material.name.includes(CUSTOM_IMAGE_MATERIAL_SLOT_NAME) && material?.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.id
            );
            const id = materialWithImageSlot?.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.id ?? "";

            if (!id) {
                throw new Error(`No material slot matching "${CUSTOM_IMAGE_MATERIAL_SLOT_NAME}" was found in the model`);
            }

            // Now find the index of the image with the id in processed.images
            const imageIndex = processed.images.findIndex((image) => image.id === id);
            if (imageIndex === -1) {
                throw new Error("No image was found assigned to the material slot");
            }

            // Replace the image in the model with the new image
            const editedModel = model;
            if (editedModel.images) {
                editedModel.images = editedModel.images.map((image, index) => {
                    if (index === imageIndex) {
                        return rawImage;
                    }
                    return image;
                });
            }

            return editedModel;
        } catch (e) {
            console.error("Failed to load custom image for model, returning model without image: ", e);
            return model;
        }
    };

    public readonly _testFunctions = {
        getLayerByName: (layerName: string) => {
            const allDeckglLayers = Object.values(this.layers).flat();
            return allDeckglLayers.filter((layer) => layer.props.metaData.layerName === layerName);
        },
        getLayerGeoJsonFeatures: (layerName: string) => {
            const layers = this._testFunctions.getLayerByName(layerName);
            const mapObjects = layers.map((layer) => layer.props.data);
            return mapObjects.map((mo) => mo.map((m) => ({ type: "Feature", properties: m.properties, geometry: { type: "Point", coordinates: m.position } }))).flat();
        },
        getModelDataByLayerName: async (layerName: string) => {
            const layers = this._testFunctions.getLayerByName(layerName);
            const scenegraphPromises = layers.map((modelLayer) => modelLayer.props.scenegraph);
            const scenegraph = await scenegraphPromises[0];
            return scenegraph;
        },
    };

    public destroy(): void {
        if (this.map.__deck) {
            this.map.__deck.finalize();
        }
    }
}
