import { Radio } from "@mui/material";
import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { sanitiseForDOMToken } from "@iventis/utilities";
import { IventisCellEditorParams, RowNetworkStatus } from "../types/data-table.types";

export const RadioButtonCellComponent: FunctionComponent<IventisCellEditorParams> = ({ node, value, colDef, data }) => (
    <RadioButtonContainer>
        <Radio
            onClick={() => node.setDataValue(colDef.field, !value)}
            checked={value ?? false}
            data-testid={sanitiseForDOMToken(`data-table-cell-row-${data?.name}-column-${colDef.headerName}`)}
            disabled={node.data?.networkStatus === RowNetworkStatus.Template}
        />
    </RadioButtonContainer>
);

const RadioButtonContainer = styled.div`
    width: 100%;
    display: flex;
    place-content: center;
`;
