import { StyleType } from "@iventis/domain-model/model/styleType";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { parseRepeatedTimeRangeDataFieldValueToNumber, timeRange, ZERO_TIME_SPAN } from "@iventis/utilities";
import { MapObjectRepeatedTimeRangeValue } from "@iventis/types";
import GeoJSON from "geojson";
import { v4 as uuid } from "uuid";
import {
    getMapObjectNameSystemDatafield,
    getMapObjectLengthSystemDataField,
    getMapObjectRouteTimeSystemDataField,
    getMapObjectAreaSystemDataField,
    getMapObjectCoordinatesSystemDataField,
    getMapObjectQuantityOfModelsOnLineSystemDataField,
} from "@iventis/datafields";
import { AnySupportedGeometry, MapObjectProperties } from "@iventis/map-types";
import { CompositionMapObject, MapCursor } from "../types/internal";
import { LayerStorageScope, MapModuleLayer } from "../types/store-schema";
import { MAP_OBJECT_DEFAULT_NAME, analysisLayerIdPrefix, localSuffix } from "./constants";
import { MapMode } from "../machines/map-machines.types";

export function tileLayerToLocalLayer(layer: MapModuleLayer): MapModuleLayer {
    if (layer.storageScope === LayerStorageScope.LocalOnly) {
        return layer;
    }

    return {
        ...layer,
        id: getLocalLayerID(layer.id),
        source: getLocalLayerID(layer.id),
    };
}

export function getLocalLayerID(layerId: string) {
    if (layerId.startsWith(analysisLayerIdPrefix)) {
        return layerId;
    }
    return `${layerId}_${localSuffix}`;
}

/* Takes the geometries and rotation property from the first set of objects, and the remaining non-rotation properties from the second set of objects */
export function mergeCompositionMapObjectProperties(objectsWithGeometries: CompositionMapObject[], objectsWithProperties: CompositionMapObject[]) {
    return objectsWithGeometries.map(
        (object) =>
            ({
                ...object,
                geojson: {
                    ...object?.geojson,
                    properties: {
                        ...objectsWithProperties.find((c) => c.objectId === object.objectId)?.geojson.properties,
                        rotation: object?.geojson.properties.rotation,
                    },
                },
            } as CompositionMapObject)
    );
}

/**
 *  Gets all the system datafields for a map obect (depends on geometry type) and adds them to the properties of the map object.
 *  If value for that datafield is undefined then set to default value.
 */
export function addSystemDataFieldsToMapObject(styleType: StyleType, properties: { [dataFieldId: string]: unknown } = {}, projectDataFields: DataField[]) {
    const updatedProperties = {};
    const nameDataField = getMapObjectNameSystemDatafield(projectDataFields);
    if (nameDataField == null) {
        throw new Error("Missing system attribute MapObjectName");
    }

    updatedProperties[nameDataField.id] = properties[nameDataField.id] ?? MAP_OBJECT_DEFAULT_NAME;

    if (styleType === StyleType.Line || styleType === StyleType.LineModel) {
        const lengthDataField = getMapObjectLengthSystemDataField(projectDataFields);
        const routeTimeDataField = getMapObjectRouteTimeSystemDataField(projectDataFields);

        if (lengthDataField == null) {
            throw new Error("Missing system attribute MapObjectLength");
        }
        if (routeTimeDataField == null) {
            throw new Error("Missing system attribute RouteTime");
        }

        updatedProperties[lengthDataField.id] = properties[lengthDataField.id] ?? 0;
        updatedProperties[routeTimeDataField.id] = properties[routeTimeDataField.id] ?? ZERO_TIME_SPAN;
    }

    if (styleType === StyleType.Area) {
        const areaDataField = getMapObjectAreaSystemDataField(projectDataFields);

        if (areaDataField == null) {
            throw new Error("Missing system attribute MapObjectArea");
        }

        updatedProperties[areaDataField.id] = properties[areaDataField.id] ?? 0;
    }

    if (styleType === StyleType.Point || styleType === StyleType.Icon || styleType === StyleType.Model) {
        const coordinateDataField = getMapObjectCoordinatesSystemDataField(projectDataFields);

        if (coordinateDataField == null) {
            throw new Error("Missing system attribute Coordinates");
        }

        updatedProperties[coordinateDataField.id] = properties[coordinateDataField.id] ?? "N/A";
    }

    if (styleType === StyleType.LineModel) {
        const quantityDataField = getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields);
        if (quantityDataField == null) {
            throw new Error("Missing system attribute Quantity");
        }
        updatedProperties[quantityDataField.id] = properties[quantityDataField.id] ?? 0;
    }

    return updatedProperties;
}

/** Creates a key value pair of attribute Id and it's default value  */
export function getDefaultAttributeValues(layer: MapModuleLayer) {
    return layer?.dataFields != null
        ? layer.dataFields.reduce<Record<string, string | boolean | number | Date | MapObjectRepeatedTimeRangeValue>>((attributesWithDefaultValues, attribute) => {
              if (attribute.type === DataFieldType.List && attribute.defaultValue?.listItemId != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.listItemId };
              }
              if (attribute.type === DataFieldType.Text && attribute.defaultValue?.valueText != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueText };
              }
              if (attribute.type === DataFieldType.Number && attribute.defaultValue?.valueNumber != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueNumber };
              }
              if (attribute.type === DataFieldType.Date && attribute.defaultValue?.valueDate != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueDate };
              }
              if (attribute.type === DataFieldType.Tickbox && attribute.defaultValue?.valueTickbox != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueTickbox };
              }
              if (attribute.type === DataFieldType.Time && attribute.defaultValue?.valueTime != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueTime };
              }
              if (attribute.type === DataFieldType.TimeRange && attribute.defaultValue?.valueTimeRangeFrom != null && attribute.defaultValue?.valueTimeRangeTo != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: timeRange(attribute.defaultValue?.valueTimeRangeFrom, attribute.defaultValue?.valueTimeRangeTo) };
              }
              if (attribute.type === DataFieldType.Hyperlink && attribute.defaultValue?.valueText != null) {
                  return { ...attributesWithDefaultValues, [attribute.id]: attribute.defaultValue.valueText };
              }
              if (attribute.type === DataFieldType.RepeatedTimeRanges && attribute.defaultValue?.valueRepeatedTimeRanges != null) {
                  const repeatedTimeValues = parseRepeatedTimeRangeDataFieldValueToNumber(attribute.defaultValue.valueRepeatedTimeRanges);
                  return { ...attributesWithDefaultValues, [attribute.id]: repeatedTimeValues.map((v) => ({ ...v, id: uuid() })) };
              }
              return attributesWithDefaultValues;
          }, {})
        : {};
}

/** Is the provided array of objects of length 1 and a route object */
export const isSingleRoute = (objects: { geojson?: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> }[]) =>
    objects?.length === 1 && objects[0]?.geojson?.properties.modeOfTransport != null;

/** Maps the mode to it's default cursor */
export const defaultCursor: Record<MapMode, MapCursor> = {
    loading: MapCursor.LOADING,
    read: MapCursor.READ,
    "edit.default": MapCursor.READ,
    "edit.composition": MapCursor.COMPOSITION,
    areaSelect: MapCursor.READ,
    preview: MapCursor.READ,
    "edit.addComment": MapCursor.COMMENT,
};

export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
