export abstract class Layer<TSource> {
    protected abstract layerId: string;

    protected abstract sourceId: string;

    protected abstract addLayer(): void;

    protected abstract removeLayer(): void;

    protected abstract addSource(): void;

    protected abstract updateSource(source: TSource): void;

    protected abstract removeSource(): void;

    public remove() {
        this.removeLayer();
        this.removeSource();
    }
}
