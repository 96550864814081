import { LineEnd } from "@iventis/domain-model/model/lineEnd";
import { LineJoin } from "@iventis/domain-model/model/lineJoin";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import React, { FunctionComponent, useState } from "react";
import { media, styled } from "@iventis/styles";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { LineType } from "@iventis/domain-model/model/lineType";
import { getDefaultStyleProperty, getStaticAndMappedValues } from "@iventis/map-engine/src/utilities/style-helpers";
import { Content } from "@iventis/translations/content/typed-content";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import {
    colourSelectorComponentCreator,
    ColourSelectorFormulaPreview,
    definedValueSelectorComponentCreator,
    incrementalValueSelectorComponentCreator,
    selectItemsComponentCreator,
    sliderWithTextSelectorCreator,
    StraightLineSvgComponent,
    DashedLineSvgComponent,
    LineEndButtSVGComponent,
    LineEndRoundSVGComponent,
    LineEndSquareSVGComponent,
    LineJoinBevelSVGComponent,
    LineJoinMiterSVGComponent,
    LineJoinRoundSVGComponent,
    ArrowHeaderSVGComponent,
    LineEndSVGComponent,
    LineJoinSVGComponent,
    LineStyleSVGComponent,
    CustomRadioSelector,
    DisabledOverlay,
} from "@iventis/components";
import { dataTestIds } from "@iventis/testing";
import { createStaticStyleValue, getStaticStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { EditStyleItemComponent, StyledEditStyleTitleContainer } from "./edit-style-item";
import { ChangeStyleValue, enumToStringArray } from "./edit-style.helpers";
import StyleContainer from "./style-container";
import CollapsedStyleItemComponent from "./collapsed-style-item";
import { EditStyleProps, ToggleableStyleItemId, ToggleableStyleItems } from "./style.types";
import EditOutlineStyle from "./outline-edit-style";
import EditTextStyle from "./text-edit-style";

const collapsableStyleItems: ToggleableStyleItems[] = [
    { id: ToggleableStyleItemId.OFFSET, name: "Offset line", show: false },
    { id: ToggleableStyleItemId.BLUR, name: "Blur line", show: false },
];

export const LineEditStyleComponent: FunctionComponent<EditStyleProps<LineStyle>> = ({ layerStyle, changeStyleValue, dataFields }) => {
    // Set inital values of toggled styled items, if a default value is used for a toggleable style item then it is not shown
    const [toggleStyleItems, setToggleStyleItems] = useState<ToggleableStyleItems[]>(initialiseToggleableValue(layerStyle));
    const translate = useIventisTranslate();
    const arrowMatchesLine = layerStyle.arrowColourMatchesLine?.staticValue?.staticValue ?? true;

    const handleArrowColourRadioChange = () => {
        const isMatchingLineColour = !arrowMatchesLine;
        if (isMatchingLineColour && layerStyle.colour.extractionMethod === StyleValueExtractionMethod.Static) {
            changeStyleValue("arrowColour", layerStyle.colour || getDefaultStyleProperty(layerStyle.styleType, "colour"));
        } else if (isMatchingLineColour && layerStyle.colour.extractionMethod === StyleValueExtractionMethod.Mapped) {
            changeStyleValue("arrowColour", layerStyle.colour);
        } else {
            const firstLineColourValue = createStaticStyleValue(getStaticAndMappedValues(layerStyle.colour)[0]);
            changeStyleValue("arrowColour", firstLineColourValue ?? getDefaultStyleProperty(layerStyle.styleType, "arrowColour"));
        }

        changeStyleValue("arrowColourMatchesLine", createStaticStyleValue(isMatchingLineColour));
    };

    // when a checkbox of a style item is changed
    const handleStyleItemToggleChange = (id: ToggleableStyleItemId) => {
        // find style item id
        const matchedStyleItem = toggleStyleItems.find((styleItem) => styleItem.id === id);
        // update the state holding the style item toggle values
        const updatedToggleStyleItem: ToggleableStyleItems[] = [
            ...toggleStyleItems.filter((styleItem) => styleItem.id !== id),
            { ...matchedStyleItem, show: !matchedStyleItem.show },
        ];
        // set values of style to the default value as it is always going to be that once a style item is toggled
        changeStyleValue(id, getDefaultStyleProperty(layerStyle.styleType, id) as StyleValue<number>);
        // set state value
        setToggleStyleItems(updatedToggleStyleItem);
    };

    const handleLineColourChange: ChangeStyleValue = (_, value) => {
        changeStyleValue("colour", value as StyleValue<string>);
        if (getStaticStyleValue(layerStyle.arrowColourMatchesLine)) {
            changeStyleValue("arrowColour", value as StyleValue<string>);
        }
    };

    const handleArrowToggle: ChangeStyleValue = (_, value) => {
        changeStyleValue("arrows", value as StyleValue<boolean>);
        // If the arrow is turned on, set the arrow colour to the line colour if arrowColourMatchesLine is true
        if (getStaticStyleValue(layerStyle.arrowColourMatchesLine)) {
            changeStyleValue("arrowColour", layerStyle.colour ?? getDefaultStyleProperty(layerStyle.styleType, "colour"));
        }
    };

    const joinGraphics = {
        Round: <LineJoinRoundSVGComponent />,
        Bevel: <LineJoinBevelSVGComponent />,
        Miter: <LineJoinMiterSVGComponent />,
    };

    const endGraphics = {
        Round: <LineEndRoundSVGComponent />,
        Butt: <LineEndButtSVGComponent />,
        Square: <LineEndSquareSVGComponent />,
    };

    return (
        <>
            <StyleContainer title={translate(Content.map2.styles.line_fill)} icon={<LineStyleSVGComponent />}>
                <StyledStyleItemContainer>
                    {/* COLOUR SELECTOR */}
                    <EditStyleItemComponent
                        styleProperty="colour"
                        value={layerStyle.colour || getDefaultStyleProperty(layerStyle.styleType, "colour")}
                        changeStyleValue={handleLineColourChange}
                        component={colourSelectorComponentCreator()}
                        PreviewComponent={ColourSelectorFormulaPreview}
                        showDataDriven
                        dataFields={dataFields}
                        title={translate(Content.map2.styles.colour)}
                    />
                    {/* OPACITY SLIDER */}
                    <EditStyleItemComponent
                        styleProperty="opacity"
                        value={layerStyle.opacity || getDefaultStyleProperty(layerStyle.styleType, "opacity")}
                        component={sliderWithTextSelectorCreator({
                            minValue: 0,
                            maxValue: 1,
                            increment: 0.05,
                            colour: layerStyle.colour.staticValue.staticValue,
                        })}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.opacity)}
                        isZoomableValue={false}
                    />

                    {/* WIDTH INCREMENTOR */}
                    <EditStyleItemComponent
                        styleProperty="width"
                        value={layerStyle.width || getDefaultStyleProperty(layerStyle.styleType, "width")}
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 0,
                            maxValue: 20,
                            increment: 1,
                            units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                            selectedUnitId: "px",
                            testId: dataTestIds.editLayer.lineWidth,
                        })}
                        title={translate(Content.map2.styles.width)}
                        showHiddenOptions
                    />
                    {/* TYPE OF LINE */}
                    <EditStyleItemComponent
                        styleProperty="type"
                        component={selectItemsComponentCreator({
                            options: [
                                { id: LineType.Solid, name: translate(Content.map2.styles2.solid), component: StraightLineSvgComponent },
                                { id: LineType.Dashed, name: translate(Content.map2.styles2.dashed), component: DashedLineSvgComponent },
                            ],
                            verticalPadding: 0,
                        })}
                        value={layerStyle.type ?? getDefaultStyleProperty(layerStyle.styleType, "type")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles2.dashed)}
                    />
                    {/* BLUR INCREMENTER */}
                    {toggleStyleItems.find((styleItem) => styleItem.id === "blur").show && (
                        <EditStyleItemComponent
                            styleProperty="blur"
                            value={layerStyle.blur || getDefaultStyleProperty(layerStyle.styleType, "blur")}
                            changeStyleValue={changeStyleValue}
                            component={incrementalValueSelectorComponentCreator({
                                minValue: 0,
                                maxValue: 100,
                                increment: 1,
                                units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                                selectedUnitId: "px",
                            })}
                            title="Blur"
                            emitToggleChange={handleStyleItemToggleChange}
                            toggleId={ToggleableStyleItemId.BLUR}
                            showToggle
                            isZoomableValue={false}
                        />
                    )}
                    {/* OFFSET INCREMEMTER */}
                    {toggleStyleItems.find((styleItem) => styleItem.id === "offset").show && (
                        <EditStyleItemComponent
                            styleProperty="offset"
                            value={layerStyle.offset || getDefaultStyleProperty(layerStyle.styleType, "offset")}
                            changeStyleValue={changeStyleValue}
                            component={incrementalValueSelectorComponentCreator({
                                minValue: 0,
                                maxValue: 20,
                                increment: 1,
                                units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                                selectedUnitId: "px",
                            })}
                            title="Offset"
                            emitToggleChange={handleStyleItemToggleChange}
                            toggleId={ToggleableStyleItemId.OFFSET}
                            showToggle
                            isZoomableValue={false}
                        />
                    )}
                </StyledStyleItemContainer>
                {/* UNTOGGLED STYLE ITEMS */}
                <StyledCollapsedStyleItemContainer>
                    {toggleStyleItems.map(
                        (styleItem) =>
                            !styleItem.show && (
                                <CollapsedStyleItemComponent emitCheckboxChange={handleStyleItemToggleChange} name={styleItem.name} id={styleItem.id} key={styleItem.id} />
                            )
                    )}
                </StyledCollapsedStyleItemContainer>
            </StyleContainer>
            <StyleContainer title={translate(Content.map2.styles.join_and_end)} defaultOpen={false}>
                <StyledStyleItemContainer>
                    {/* LINE END PREDEFINED VALUE SELECTOR */}
                    <EditStyleItemComponent
                        styleProperty="end"
                        component={definedValueSelectorComponentCreator({ optionalValues: enumToStringArray(LineEnd).map((value) => ({ value, icon: endGraphics[value] })) })}
                        value={layerStyle.end || getDefaultStyleProperty(layerStyle.styleType, "end")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.end)}
                        bold
                        icon={<LineEndSVGComponent />}
                    />
                    {/* LINE JOIN PREDEFINED VALUE SELECTOR */}
                    <EditStyleItemComponent
                        styleProperty="join"
                        component={definedValueSelectorComponentCreator({ optionalValues: enumToStringArray(LineJoin).map((value) => ({ value, icon: joinGraphics[value] })) })}
                        value={layerStyle.join || getDefaultStyleProperty(layerStyle.styleType, "join")}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.join)}
                        bold
                        icon={<LineJoinSVGComponent />}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <StyleContainer title={translate(Content.map2.styles.arrows)} icon={<ArrowHeaderSVGComponent />} defaultOpen={false} testId="arrows">
                <StyledStyleItemContainer>
                    {/* ARROW ON/OFF */}
                    <EditStyleItemComponent
                        styleProperty="arrows"
                        component={definedValueSelectorComponentCreator<boolean>({
                            optionalValues: [
                                { value: true, label: translate(Content.map2.styles.arrows), testId: "arrows-on" },
                                { value: false, label: translate(Content.common.none), testId: "arrows-off" },
                            ],
                        })}
                        value={layerStyle.arrows}
                        changeStyleValue={handleArrowToggle}
                        title={translate(Content.map2.styles.arrows)}
                    />
                    {/* ARROW SPACING */}
                    <EditStyleItemComponent
                        styleProperty="arrowSpacing"
                        value={
                            layerStyle.arrowSpacing != null && getStaticStyleValueFromMapped(layerStyle.arrowSpacing) !== 0
                                ? layerStyle.arrowSpacing
                                : getDefaultStyleProperty(layerStyle.styleType, "arrowSpacing")
                        }
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 1,
                            maxValue: 200,
                            increment: 10,
                            units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                            selectedUnitId: "px",
                            testId: dataTestIds.editLayer.arrowSpacing,
                        })}
                        title={translate(Content.map2.styles2.arrow_spacing)}
                        isZoomableValue={false}
                        disabled={!getStaticStyleValueFromMapped(layerStyle.arrows)}
                    />

                    {/* ARROW COLOUR */}
                    <StyledArrowColourPickerContainer>
                        <StyledRelativeWrapper>
                            {!getStaticStyleValueFromMapped(layerStyle.arrows) && <DisabledOverlay />}
                            <StyledEditStyleTitleContainer>
                                <span>{translate(Content.map2.styles.colour)}</span>
                            </StyledEditStyleTitleContainer>
                            <CustomRadioSelector
                                options={[
                                    { id: "same-as-line", name: translate(Content.map8.styles.arrows.sameAsLine) },
                                    { id: "custom", name: translate(Content.map8.styles.arrows.custom) },
                                ]}
                                onValueChange={handleArrowColourRadioChange}
                                row
                                defaultValueId={arrowMatchesLine ? "same-as-line" : "custom"}
                                testId="edit-arrow-colour"
                            />
                        </StyledRelativeWrapper>

                        <StyledArrowColours>
                            {arrowMatchesLine && <DisabledOverlay />}
                            {/* COLOUR PICKER */}
                            <EditStyleItemComponent
                                styleProperty="arrowColour"
                                value={createStaticStyleValue(getStaticAndMappedValues(layerStyle.arrowColour)[0]) ?? getDefaultStyleProperty(layerStyle.styleType, "arrowColour")}
                                changeStyleValue={changeStyleValue}
                                component={colourSelectorComponentCreator()}
                                title={translate(Content.map2.styles2.arrow_colour)}
                                disabled={!getStaticStyleValueFromMapped(layerStyle.arrows)}
                                isZoomableValue={false}
                            />
                            {/* ARROW OPACITY */}
                            <EditStyleItemComponent
                                styleProperty="arrowOpacity"
                                value={layerStyle.arrowOpacity ?? getDefaultStyleProperty(layerStyle.styleType, "arrowOpacity")}
                                component={sliderWithTextSelectorCreator({
                                    minValue: 0,
                                    maxValue: 1,
                                    increment: 0.05,
                                    colour:
                                        layerStyle?.arrowColour != null
                                            ? getStaticAndMappedValues(layerStyle.arrowColour)?.[0] ??
                                              getStaticStyleValue(getDefaultStyleProperty(layerStyle.styleType, "arrowColour"))
                                            : getStaticStyleValue(getDefaultStyleProperty(layerStyle.styleType, "arrowColour")),
                                })}
                                changeStyleValue={changeStyleValue}
                                title={translate(Content.map2.styles2.arrow_opacity)}
                                isZoomableValue={false}
                                disabled={!getStaticStyleValueFromMapped(layerStyle.arrows)}
                            />
                        </StyledArrowColours>
                    </StyledArrowColourPickerContainer>
                    {/* ARROW SIZE */}
                    <EditStyleItemComponent
                        styleProperty="arrowSize"
                        value={layerStyle.arrowSize ?? getDefaultStyleProperty(layerStyle.styleType, "arrowSize")}
                        changeStyleValue={changeStyleValue}
                        component={incrementalValueSelectorComponentCreator({
                            minValue: 0,
                            maxValue: 3,
                            increment: 0.1,
                            units: [{ id: "x", name: translate(Content.map3.units.scale) }],
                            selectedUnitId: "x",
                            decimals: 1,
                        })}
                        title={translate(Content.map2.styles2.arrow_size)}
                        disabled={!getStaticStyleValueFromMapped(layerStyle.arrows)}
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <EditOutlineStyle layerStyle={layerStyle} changeStyleValue={changeStyleValue} />
            <EditTextStyle layerStyle={layerStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
        </>
    );
};

// Checks if the style property value is the default value
export function isDefaultValue(style: LineStyle, styleProperty: keyof LineStyle) {
    const defaultValue = getDefaultStyleProperty(style.styleType, styleProperty);
    const stylePropertyValue = style[styleProperty];
    return defaultValue === stylePropertyValue;
}

// creates an array of toggleable values
function initialiseToggleableValue(style: LineStyle) {
    return collapsableStyleItems.map((styleItem) => ({ ...styleItem, show: !isDefaultValue(style, styleItem.id as keyof LineStyle) }));
}

export const StyledCollapsedStyleItemContainer = styled.div`
    display: flex;
`;

export const StyledStyleItemContainer = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    grid-row-gap: 24px;
    grid-column-gap: 30px;
    padding-bottom: 15px;
    ${media.medium} {
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    }
`;

const StyledArrowColourPickerContainer = styled.div`
    grid-column: 1 / span 2;
`;

const StyledArrowColours = styled.div`
    position: relative;
    display: flex;
    gap: 30px;
`;

const StyledRelativeWrapper = styled.div`
    position: relative;
`;
