import {
    FillLayerSpecification,
    SymbolLayerSpecification,
    CircleLayerSpecification,
    LineLayerSpecification,
    CustomLayerInterface,
    FillExtrusionLayerSpecification,
} from "mapbox-gl";

export enum SubLayerType {
    "BASE" = "BASE",
    "EXTRUSION" = "EXTRUSION",
    "POLYGON_TEXT" = "POLYGON_TEXT",
    "LINE_ICON" = "LINE_ICON",
    "LINE_TEXT" = "LINE_TEXT",
    "LINE_OUTLINE" = "LINE_OUTLINE",
    "POLYGON_OUTLINE" = "POLYGON_OUTLINE",
    "POINT_TEXT" = "POINT_TEXT",
    "LINE_MODEL" = "LINE_MODEL",
}

export interface SublayerSignature {
    type: SubLayerType;
    id: string;
}

export interface AggregateLayers {
    [layerId: string]: SublayerSignature[];
}

export type SupportedMapboxLayer =
    | FillLayerSpecification
    | SymbolLayerSpecification
    | CircleLayerSpecification
    | LineLayerSpecification
    | CustomLayerInterface
    | FillExtrusionLayerSpecification;

export interface MapboxlayerWithSublayerType {
    type: SubLayerType;
    id: string;
    layer: SupportedMapboxLayer;
}

export enum LayerSourceLocation {
    Local = "Local",
    Remote = "Remote",
}
