/* eslint-disable react-hooks/rules-of-hooks */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BehaviorSubject } from "rxjs";
import { Checkbox, useTheme } from "@mui/material";
import { inlineTextIconMargin, fontSizes } from "@iventis/styles/src/atomic-rules";
import { customMediaQueryMax, customMediaQueryMin, screenSizeBreakpoints, StyledIconButton, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { NodeComponentProps } from "@iventis/tree-browser/types/component-types";
import React, { FC, useState, useRef, MutableRefObject, FunctionComponent, ReactNode } from "react";

import { User } from "@iventis/domain-model/model/user";
import { sanitiseForDOMToken, useObservableValue } from "@iventis/utilities";
import { UserGroupWithUsers } from "@iventis/domain-model/model/userGroupWithUsers";
import { Permission } from "@iventis/domain-model/model/permission";
import { StyledPopover, UserThumbnail, DisabledOverlay } from "@iventis/components";
import { NewStyledCheckBoxContainer, sharedNodeCss, StyledLibraryNodeProps, TreeBrowserNode } from "@iventis/tree-browser";
import { InteractiveElement } from "@iventis/styles/src/components";
import { ButtonText, Header4 } from "@iventis/styles/src/components/texts";

import { AdminTeamsNode, TreeBrowserNodeWithPerson } from "./types";

type UserNodeComponentType<TNode extends TreeBrowserNode> = FC<{ node: TNode; permission: Permission; expanded: boolean }>;

export const createUserNodeComponent = <TNode extends TreeBrowserNodeWithPerson>({
    showSsoColumn = false, // This will either show or hide the sso column.
    showAdornmentColumn = false, // This will either show or hide the adornment column.
    showOverflowMenuColumn = false, // This will either show or hide the overflow menu column.
    showCheckBoxColumn = true, // This will either show or hide the selection box column.
    enableCheckBox = () => true, // This will either enable or disable the checkbox for the user but it will still be visible.
    onCheckChange,
    checkValuesSubject,
    getOverflowOptions = () => [], // Must have "show overflow menu column" set to true for these options to be visible.
    getAdornment = () => undefined, // Must have "show adornment column" set to true for this to be visible. For example, "Admin" text when used on the project admin page.
}: {
    enableCheckBox?: (personId: string) => boolean;
    showOverflowMenu?: boolean;
    showCheckBoxColumn?: boolean;
    showSsoColumn?: boolean;
    showAdornmentColumn?: boolean;
    showOverflowMenuColumn?: boolean;
    onCheckChange?: (node, value) => void;
    checkValuesSubject?: BehaviorSubject<User[]>;
    getOverflowOptions?: (node: TNode) => { text: string; action: () => void; disabled?: boolean }[];
    getAdornment?: (node: TNode) => React.ReactElement | string;
}): UserNodeComponentType<TNode> => {
    const UserNodeComponent: UserNodeComponentType<TNode> = ({ node }) => {
        const checkedPeople = checkValuesSubject ? useObservableValue(checkValuesSubject) || [] : [];
        const [overflowMenuOpen, setOverflowMenuOpen] = useState(false);
        const theme = useTheme<Theme>();

        const overflowMenuRefSmallDevice = useRef<HTMLDivElement>();
        const overflowMenuRefLargeDevice = useRef<HTMLDivElement>();

        const getOverflowMenuElement = () => {
            // Two overflow menu elements exist; One for each device size (as above)
            if (overflowMenuRefSmallDevice.current == null && overflowMenuRefLargeDevice.current == null) {
                return null;
            }
            if (overflowMenuRefSmallDevice.current.getBoundingClientRect().width !== 0) {
                // If this element is visible, return it (if you're on a large device, the width of this element is 0)
                // This is needed because we don't identify the device size in the JS, only in CSS so this keeps things simple
                return overflowMenuRefSmallDevice.current;
            }
            return overflowMenuRefLargeDevice.current;
        };

        const isChecked = checkedPeople.some((checkedUser) => checkedUser.id === node.person.id);

        const createOverflow = (ref: MutableRefObject<HTMLDivElement>) =>
            getOverflowOptions(node).length > 0 && (
                <div ref={ref}>
                    <InteractiveElement className="end" type="button" onClick={() => setOverflowMenuOpen(true)}>
                        <FontAwesomeIcon icon={["fal", "ellipsis"]} />
                    </InteractiveElement>
                </div>
            );

        return (
            <StyledUserNodeContainerAllDevices
                singleTopRowButton={false}
                className="user-node-container"
                fontWeight={400}
                borderColour={theme.shades.three}
                selected={isChecked}
                emptyNode={false}
                selectionDisabled={!enableCheckBox(node.person.id)}
            >
                {showCheckBoxColumn && (
                    <NewStyledCheckBoxContainer onDoubleClick={(e) => e.stopPropagation()} style={{ flexGrow: "0", flexShrink: "0", aspectRatio: "1/1" }}>
                        <Checkbox
                            className={`${!enableCheckBox(node.person.id) ? "selection-disabled" : "colour-on-focus"}`}
                            size="small"
                            checked={isChecked}
                            onChange={(_, value) => onCheckChange(node, value)}
                            color="default"
                            data-testid={`user-checkbox-${sanitiseForDOMToken(node.person.email)}`}
                            disabled={!enableCheckBox(node.person.id)}
                        />
                        {!enableCheckBox(node.person.id) && <DisabledOverlay />}
                    </NewStyledCheckBoxContainer>
                )}
                <StyledUserNodeContainerLargeDevice ssoColumn={showSsoColumn} overflowColumn={showOverflowMenuColumn} adornmentColumn={showAdornmentColumn}>
                    <StyledColumnInformation className="person-name-column">
                        <UserThumbnail className="user-icon" />
                        <span className="truncate name">{getRenderedNameText(node.person)}</span>
                    </StyledColumnInformation>
                    <StyledColumnInformation className="person-email-column">
                        <PersonContactInformationComponent
                            text={node.person.email}
                            icon={<FontAwesomeIcon icon={{ prefix: "far", iconName: "envelope" }} style={{ marginRight: inlineTextIconMargin }} />}
                        />
                    </StyledColumnInformation>
                    {showSsoColumn && (
                        <SsoIconComponent
                            dataTestId={`person-sso-${sanitiseForDOMToken(node.person.email)}`}
                            className="person-sso-column"
                            userSsoEnabled={node.person.ssoEnabled}
                        />
                    )}
                    <StyledColumnInformation className="person-adornment-column">{getAdornment(node)}</StyledColumnInformation>
                    {showOverflowMenuColumn && <StyledOverflow className="overflow-column">{createOverflow(overflowMenuRefLargeDevice)}</StyledOverflow>}
                </StyledUserNodeContainerLargeDevice>

                <StyledUserNodeContainerSmallDevice>
                    <StyledColumnInformation>
                        <UserThumbnail className="user-icon" />
                        <div className="info">
                            <span className="info-row">
                                <span className="name">{getRenderedNameText(node.person)}</span>
                                {showSsoColumn && (
                                    <SsoIconComponent
                                        dataTestId={`person-sso-${sanitiseForDOMToken(node.person.email)}`}
                                        className="person-sso-column"
                                        userSsoEnabled={node.person?.ssoEnabled}
                                    />
                                )}
                                {showAdornmentColumn && getAdornment && <span> - {getAdornment(node)}</span>}
                            </span>
                            <span className="info-row">{node.person.email}</span>
                        </div>
                    </StyledColumnInformation>
                    {showOverflowMenuColumn && <div className="overflow-column">{createOverflow(overflowMenuRefSmallDevice)}</div>}
                </StyledUserNodeContainerSmallDevice>

                <StyledPopover
                    space={44}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={overflowMenuOpen}
                    anchorEl={getOverflowMenuElement()}
                    onClose={() => setOverflowMenuOpen(false)}
                >
                    {getOverflowOptions(node).map(({ text, action, disabled }) => (
                        <InteractiveElement
                            disabled={disabled}
                            key={text}
                            className="button"
                            type="button"
                            onClick={() => {
                                setOverflowMenuOpen(false);
                                action();
                            }}
                        >
                            <ButtonText>{text}</ButtonText>
                        </InteractiveElement>
                    ))}
                </StyledPopover>
            </StyledUserNodeContainerAllDevices>
        );
    };

    return UserNodeComponent;
};

const PersonContactInformationComponent: FunctionComponent<{ text: string; icon?: ReactNode }> = ({ text, icon }) => (
    <StyledContactInformationContainer>
        {icon}
        <span className="truncate">{text}</span>
    </StyledContactInformationContainer>
);

/**
 * Sso Icon component
 * @param userSSoEnabled - If the user has sso enabled, then show the sso icon
 */
const SsoIconComponent: FunctionComponent<{ userSsoEnabled: boolean; className: string; dataTestId?: string }> = ({ userSsoEnabled, className, dataTestId }) => (
    <FontAwesomeIcon data-testid={dataTestId} className={className} visibility={userSsoEnabled ? "visible" : "hidden"} color="#B6B6B6" icon={["fas", "key"]} />
);

export const getRenderedNameText = (person: User) => `${person.firstName} ${person.lastName}`;

export type TeamNodeComponent = React.FC<NodeComponentProps<AdminTeamsNode> & { node: AdminTeamsNode }>;

export const createTeamNodeComponent = ({
    clickAddPerson,
    clickDeleteTeam,
    expand,
    collapse,
    canAddUser,
}: {
    clickAddPerson: (team: UserGroupWithUsers) => void;
    clickDeleteTeam: (team: UserGroupWithUsers) => void;
    expand: (id: string) => void;
    collapse: (id: string) => void;
    canAddUser: boolean;
}): TeamNodeComponent => {
    const TeamNodeComponent: TeamNodeComponent = ({ node, expanded }) => (
        <StyledTeamContainer>
            <InteractiveElement className="expansion-button" onClick={() => (expanded ? collapse(node.id) : expand(node.id))}>
                <FontAwesomeIcon icon={["fas", expanded ? "caret-down" : "caret-right"]} />
                <Header4>{node.name}</Header4>
            </InteractiveElement>
            <div>
                <StyledIconButton disabled={!canAddUser} onClick={() => clickAddPerson(node.team)}>
                    {!canAddUser && <DisabledOverlay />}
                    <FontAwesomeIcon icon={{ prefix: "far", iconName: "user-plus" }} size="xs" />
                </StyledIconButton>
                <StyledIconButton disabled={!canAddUser} onClick={() => clickDeleteTeam(node.team)}>
                    {!canAddUser && <DisabledOverlay />}
                    <FontAwesomeIcon icon={{ prefix: "far", iconName: "trash" }} size="xs" />
                </StyledIconButton>
            </div>
        </StyledTeamContainer>
    );

    return TeamNodeComponent;
};

const StyledContactInformationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const StyledTeamContainer = styled.div`
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.otherColors.separatorDark};
    margin-left: 10px;

    h4 {
        display: inline-block;
        margin-left: 10px;
    }

    .expansion-button {
        display: flex;
        align-items: center;
    }
`;

const StyledUserNodeContainerAllDevices = styled.div<StyledLibraryNodeProps>`
    display: flex;

    .user-icon {
        width: 28px;
        height: 28px;
    }

    ${sharedNodeCss}

    > * {
        flex-shrink: 1;
        flex-grow: 1;
    }

    .name {
        font-weight: bold;
        font-size: ${fontSizes.treeNodeLarge};
    }
`;

const StyledUserNodeContainerSmallDevice = styled.section`
    display: grid;
    display: grid;
    grid-template-columns: 1fr 10px;
    width: 100%;
    align-items: center;
    gap: 5px;

    ${customMediaQueryMin(screenSizeBreakpoints.medium)} {
        display: none;
    }

    padding: 10px;

    font-size: ${fontSizes.treeNodeMedium};

    white-space: nowrap;

    .info {
        grid-column: 2;
    }

    .end {
        grid-column: 3;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .info-row {
        display: flex;
        gap: 5px;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

// Anything above 768px
const StyledUserNodeContainerLargeDevice = styled.section<{ ssoColumn: boolean; overflowColumn: boolean; adornmentColumn: boolean }>`
    display: grid;
    grid-template-columns:
        [person-name-start] 3fr [person-name-end person-email-start] 2fr [person-email-end person-sso-start]
        ${(props) => (props.ssoColumn ? "0.5fr" : "0fr")} [person-sso-end person-adornment-start]
        ${(props) => (props.adornmentColumn ? "1.5fr" : "0fr")} [person-adornment-end overflow-start]
        ${(props) => (props.overflowColumn ? "0.5fr" : "0fr")} [overflow-end];
    width: 100%;
    align-items: center;
    height: 30px;
    gap: 5px;
    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.lightBorder};

    ${customMediaQueryMax(screenSizeBreakpoints.medium)} {
        display: none;
    }

    padding: 15px;

    font-size: ${fontSizes.treeNodeMedium};

    .person-name-column {
        grid-column-start: person-name-start;
        grid-column-end: person-name-end;
        height: 30px;
    }

    .person-email-column {
        grid-column-start: person-email-start;
        grid-column-end: person-email-end;
    }

    .person-sso-column {
        grid-column-start: person-sso-start;
        grid-column-end: person-sso-end;
    }

    .person-adornment-column {
        grid-column-start: person-adornment-start;
        grid-column-end: person-adornment-end;
    }

    .overflow-column {
        grid-column-start: overflow-start;
        grid-column-end: overflow-end;
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .end {
        width: 32px;
        height: 32px;
    }
`;

const StyledColumnInformation = styled.div`
    display: flex;
    flex-direction: row;
    white-space: normal;
    gap: 5px;
    width: 100%;
    overflow: hidden;
    align-items: center;
`;

const StyledOverflow = styled.div`
    display: flex;
    justify-content: flex-end;
`;
