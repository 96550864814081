/* eslint-disable react/jsx-props-no-spreading */
import { CheckBoxStyled, LoadingComponent, MapperTable } from "@iventis/components";
import { DataField } from "@iventis/domain-model/model/dataField";
import { Body2, formButton, muiInputFormsCSS, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { dataFieldTypes } from "@iventis/datafield-editor/src/data-fields-types.constants";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useIsElementScrollable } from "@iventis/utilities";
import { Button, DialogActions as MuiDialogActions, Autocomplete, TextField, Box } from "@mui/material";
import React, { ReactNode, useReducer, forwardRef, useImperativeHandle } from "react";
import { updateMappedAttributes } from "./layer-geojson-attribute-mapping-state";

export interface LayerGeoJsonAttributeMappingRef {
    /** Updates the properties which can be mapped to the attributes */
    setProperties: (properties: string[]) => void;
}

interface LayerGeoJsonAttributeMappingMappingProps {
    mapLayerAttributes: DataField[];
    properties: string[];
    children: ReactNode;
    onConfirm: (mappedAttributes: { [attributeId: string]: string }, overwriteExistingObjects: boolean) => void;
}

export const LayerGeoJsonAttributeMappingMappingComponent = forwardRef<LayerGeoJsonAttributeMappingRef, LayerGeoJsonAttributeMappingMappingProps>(
    ({ mapLayerAttributes, onConfirm, properties, children }, ref) => {
        const translate = useIventisTranslate();

        const [{ allProperties, mappedAttributesToProperty, overwriteExistingObjects, saving }, update] = useReducer(updateMappedAttributes, {
            allProperties: properties,
            mappedAttributesToProperty: {},
            saving: false,
            overwriteExistingObjects: false,
        });

        const { elementRef, isElementScrollable } = useIsElementScrollable();

        /** When user clicks confirm */
        const handleConfirmClicked = () => {
            onConfirm(mappedAttributesToProperty, overwriteExistingObjects);
            update({ type: "saving", value: true });
        };

        useImperativeHandle(
            ref,
            () => ({
                setProperties: (updatedProperties) => {
                    update({ type: "update-properties", properties: updatedProperties });
                },
            }),
            []
        );

        return (
            <>
                <MappedAttributesContainer ref={elementRef}>
                    {children}
                    <MapperTable
                        scrollable={isElementScrollable}
                        items={mapLayerAttributes}
                        selector={(item) => (
                            <Autocomplete
                                data-testid={`attribute-${item.name}`}
                                multiple={false}
                                filterOptions={(options) => options.filter((option) => !Object.values(mappedAttributesToProperty).includes(option))}
                                options={allProperties}
                                sx={{ width: "100%" }}
                                value={mappedAttributesToProperty[item.id] ?? null}
                                onChange={(_, value) => {
                                    update({
                                        type: "update-mapped-attribute",
                                        attributeId: item.id,
                                        property: value,
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                renderOption={(props, option) =>
                                    (
                                        <Box component="li" {...props} key={option} data-testid={`property-${option}`}>
                                            {option}
                                        </Box>
                                    ) as ReactNode
                                }
                            />
                        )}
                        getItemName={(item) => item.name}
                        getItemId={(item) => item.id}
                        getTypeName={(item) => translate(dataFieldTypes.filter(({ type }) => type === item.type)?.[0]?.name)}
                        itemColumnHeader={translate(Content.map7.importGeoJson.layerAttribute)}
                        typeColumnHeader={translate(Content.map7.importGeoJson.type)}
                        selectorColumnHeader={translate(Content.map7.importGeoJson.fieldToImport)}
                    />
                </MappedAttributesContainer>
                <DialogActions>
                    <div className="overwrite-existing">
                        <CheckBoxStyled
                            checked={overwriteExistingObjects}
                            onChange={() => update({ type: "overwrite-objects", value: !overwriteExistingObjects })}
                            testId="overwrite-existing-checkbox"
                        />
                        <Body2>{translate(Content.map7.importGeoJson.overwriteExisting)}</Body2>
                    </div>
                    <ImportButton variant="contained" onClick={handleConfirmClicked} disabled={saving} data-testid="import-layer-button">
                        <div className="saving-icon">{saving && <LoadingComponent size={20} />}</div>
                        {translate(Content.map7.importGeoJson.importLayer)}
                    </ImportButton>
                </DialogActions>
            </>
        );
    }
);

const MappedAttributesContainer = styled.div`
    ${muiInputFormsCSS}
    width: 600px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    overflow: auto;

    .upload-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px 20px 0px 20px;
    }

    .supported-files {
        color: ${({ theme }: { theme: Theme }) => theme.shades.two};
    }
`;

const DialogActions = styled(MuiDialogActions)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    border-top: 1px solid ${({ theme }: { theme: Theme }) => theme.shades.darkBorder};
    gap: 15px;
    .overwrite-existing {
        display: flex;
        align-items: center;
    }
`;

const ImportButton = styled(Button)`
    height: ${formButton.height};
    width: ${formButton.width};
    position: relative;
    .saving-icon {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
