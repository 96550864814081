import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React from "react";
import { styled } from "@iventis/styles";

export const PermissionsToolTipComponent = () => {
    const translate = useIventisTranslate();

    return (
        <PermissionsToolTipContainer>
            <FontAwesomeIcon icon="pencil-slash" />
            <span>{translate(Content.map10.permissions.noPermission)}</span>
        </PermissionsToolTipContainer>
    );
};

const PermissionsToolTipContainer = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
`;
