import React, { FunctionComponent, useState } from "react";
import { DialogTitle } from "@mui/material";
import { Asset } from "@iventis/domain-model/model/asset";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Content } from "@iventis/translations";
import { EMPTY_GUID, fileToBase64 } from "@iventis/utilities";
import { UploadFileInput } from "../upload-file-input";
import { UploadAndPreviewImageComponent, UploadAndPreviewImageTitle } from "../upload-and-preview-image";
import { StyledCustomDialog } from "../custom-dialog";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";
import { IAssetService } from "./asset-selector.types";
import { AspectRatio } from "../upload-image-with-preview";

const IMAGE_PICKER_QUERY_KEY = "image-picker-image";

export type UploadModelImageData = {
    name: string;
    image: { fileName: string; imageUrl: string; height?: number; width?: number };
};

export interface ImagePickerComponentProps extends ValueSelector<string> {
    assetServices: IAssetService;
    imageUrlGetter: (asset: Asset) => string;
    title: string;
    topText: string;
    maxFileSize?: number;
    aspectRatioLimit?: AspectRatio;
}

interface ImageQueryResult {
    asset: Asset;
    url: string;
}

export const ImagePickerComponent: FunctionComponent<ImagePickerComponentProps> = ({
    value,
    changeValue,
    assetServices,
    imageUrlGetter,
    title,
    topText,
    maxFileSize = 15,
    aspectRatioLimit,
}) => {
    const translate = useIventisTranslate();
    const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
    const [localImage, setLocalImage] = useState<{ imageData: string; imageName: string } | null>(null);

    // Get QueryClient from the context
    const queryClient = useQueryClient();

    // React query hook to get the image from the asset service
    const { data: image } = useQuery<ImageQueryResult>(
        ["image-input-image", value],
        async () => {
            // Need this check for type safety, we know it is a string due to enabled check
            if (typeof value !== "string" || value === EMPTY_GUID) {
                return null;
            }
            const asset = await assetServices.getAsset(value);
            if (asset == null) {
                return null;
            }
            const imageUrl = imageUrlGetter(asset);
            return { asset, url: imageUrl };
        },
        {
            // Only get the image when the dataFieldValue is a string
            enabled: typeof value === "string",
        }
    );

    const imageAdded = image?.asset != null;

    const [uploading, setUploading] = useState(false);
    const [asset, setAsset] = useState<Asset | null>(null);

    const uploadImage = async (file: File) => {
        setUploading(true);
        const { withHeader } = await fileToBase64(file);
        setLocalImage({ imageData: withHeader, imageName: file.name });
        const uploadData: UploadModelImageData = {
            name: file.name,
            image: {
                fileName: file.name,
                imageUrl: withHeader,
            },
        };
        const savedAsset = await assetServices.postModelImage(uploadData);
        setAsset(savedAsset);
        setUploading(false);
    };

    const handleFileDelete = () => {
        changeValue(EMPTY_GUID);
        setLocalImage(null);
        queryClient.invalidateQueries({ queryKey: [IMAGE_PICKER_QUERY_KEY] });
    };

    const handleSave = () => {
        if (asset) {
            changeValue(asset.id);
            queryClient.invalidateQueries({ queryKey: [IMAGE_PICKER_QUERY_KEY] });
            setAsset(null);
        }
        setUploadFileModal(false);
    };

    return (
        <>
            <UploadFileInput
                onInputClick={() => setUploadFileModal(true)}
                onButtonClick={() => (imageAdded ? handleFileDelete() : setUploadFileModal(true))}
                buttonText={!imageAdded ? translate(Content.map6.fileUpload.choose_file) : translate(Content.common.delete)}
                classNames={{ input: "file-upload-input", button: "file-upload-button", container: "file-upload-container" }}
                fileName={image?.asset?.name ?? translate(Content.map7.attributes.noImageSelected)}
                fileAdded={imageAdded}
            />
            <StyledCustomDialog open={uploadFileModal} $overflow="hidden" maxWidth="lg" onClose={() => setUploadFileModal(false)}>
                <DialogTitle>
                    <UploadAndPreviewImageTitle title={title} />
                </DialogTitle>
                <UploadAndPreviewImageComponent
                    imageUrl={localImage?.imageData ?? image?.url}
                    onFileUpload={uploadImage}
                    onClose={() => setUploadFileModal(false)}
                    onSave={() => handleSave()}
                    imageName={localImage?.imageName ?? image?.asset?.name}
                    topText={topText}
                    maxFileSize={maxFileSize}
                    aspectRatioLimit={aspectRatioLimit}
                    saving={uploading}
                    canSave={asset != null}
                />
            </StyledCustomDialog>
        </>
    );
};

export const imageInputComponentCreator: ValueSelectorComponentCreator<ImagePickerComponentProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ImagePickerComponent {...genericProps} {...additionalProps} />
);
