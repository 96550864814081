import { InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import React, { createElement, FunctionComponent } from "react";
import { styled } from "@iventis/styles";
import { Theme as IventisTheme, Theme } from "@emotion/react";
import { inputHeight } from "@iventis/styles/src/atomic-rules";
import { ValueSelector, ValueSelectorComponentCreator } from "./component.types";
import { StyledItemSelect } from "./select-atoms";

interface SelectItemsProps extends ValueSelector<string> {
    options: { id: string; name: string; component?: FunctionComponent; isPlaceholder?: boolean }[];
    verticalPadding?: number;
    isNullable?: boolean;
    ignoreHeight?: boolean;
    disabled?: boolean;
    className?: string;
    testId?: string;
    placeholder?: string;
}

// Options - the different input options when in drop down when input is clicked, component will always overwrite a name
// selectedValue - value which is selected
// verticalPadding - amount of top and bottom padding
// changeValue - a function which handles once a new value has been selected
export const SelectItemsComponent: FunctionComponent<SelectItemsProps> = ({
    options,
    value: selectedValue,
    verticalPadding = 0,
    changeValue,
    ignoreHeight,
    disabled,
    className = "",
    testId,
    placeholder,
}) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme<IventisTheme>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <StyledInputContainer className={className} ignoreHeight={ignoreHeight}>
            {placeholder && (
                <StyledInputLabel $transistionPlaceholder={open || selectedValue?.length > 0} id="select-placeholder">
                    {placeholder}
                </StyledInputLabel>
            )}
            <Select
                value={selectedValue}
                onChange={(event) => changeValue(event.target.value as string)}
                input={<StyledItemSelect className={className} $verticalPadding={`${verticalPadding}px`} $borderColour={theme.shades.two} />}
                disabled={disabled === true}
                data-testid="item-selector"
                data-cy={testId}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                {options.map((option) => (
                    <MenuItem
                        disabled={option.isPlaceholder}
                        style={{ height: inputHeight, display: "flex" }}
                        key={option.id}
                        value={option.id}
                        data-testid={`item-selector-item-${option.id}`}
                    >
                        {option.component ? (
                            createElement(option.component)
                        ) : (
                            <span className="option-name" data-testid={`${testId}-selected-option-name`}>
                                {option.name}
                            </span>
                        )}
                    </MenuItem>
                ))}
            </Select>
        </StyledInputContainer>
    );
};

const StyledInputContainer = styled.div<{ ignoreHeight?: boolean }>`
    width: 100%;
    height: ${(props) => !props.ignoreHeight && "100%"};

    .MuiInputBase-input:focus-visible {
        outline: 1px ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued50} solid;
    }

    .MuiInputBase-root {
        width: 100%;
    }

    .MuiSelect-icon {
        right: 2%;
    }

    .option-name {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    }
`;

const StyledInputLabel = styled(InputLabel)`
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 1;
    padding: 0 5px;
    margin-top: 12px;
    margin-left: 15px;
    background: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};

    ${({ $transistionPlaceholder }: { $transistionPlaceholder: boolean }) => $transistionPlaceholder && "display: none;"}
`;

export const selectItemsComponentCreator: ValueSelectorComponentCreator<SelectItemsProps> = (additionalProps) => (genericProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SelectItemsComponent {...genericProps} {...additionalProps} />
);
