import { styled, gapWithRoomForErrorMessage } from "@iventis/styles";
import { Checkbox as CheckboxMUI } from "@mui/material";

export const ModelInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .selector-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .dimensions-invalid {
        position: absolute;
        color: ${(props) => props.theme.secondaryColors.error};
        height: ${gapWithRoomForErrorMessage};
        bottom: -${gapWithRoomForErrorMessage};
    }
`;

export const ModelVariableSizeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    .checkbox-container {
        display: flex;
        align-items: center;
    }
`;

export const Checkbox = styled(CheckboxMUI)`
    padding: 0px 10px 0px 0px;
`;
