import { AssetType } from "@iventis/domain-model/model/assetType";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { Hierarchy } from "@iventis/domain-model/model/hierarchy";
import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
    createInitialState,
    findNode,
    getNodeNestedLevel,
    NodeComponent,
    NodeServices,
    TreeBrowserComponent,
    TreeBrowserContext,
    TreeBrowserReadOnly,
    useTreeBrowser,
} from "@iventis/tree-browser";
import { EMPTY_GUID, useDelayedConstant, useObservableValue } from "@iventis/utilities";
import { Body2, inputHeight, InteractiveElement, StyledInteractiveArea, styled } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingComponent } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { getAssetSignature } from "@iventis/utilities/src/performance/asset-signature-cache";
import { css } from "@emotion/react";
import { useEditLayerServices } from "./edit-layer-services";
import { StaticCategory } from "./layer-template-selector";

export interface CategoryNode extends AssetCategory {
    type: "Category";
    childNodes: CategoryNode[];
    sourceId: string;
}

export interface CategoryRootNode extends AssetCategory {
    type: "CategoryRoot";
    id: AssetType;
    sourceId: AssetType;
    name: AssetType;
    childNodes: CategoryNode[];
}

/** Converts a hierarchy structure into a treeNode structure */
export const convertHierarchyToTreeBrowserNode = (hierarchy: Hierarchy<AssetCategory>): CategoryNode => {
    const { item, children } = hierarchy;
    return {
        ...item,
        sourceId: item.id,
        type: "Category",
        childNodes: children.map(convertHierarchyToTreeBrowserNode),
    };
};

export const LayerTemplateCategoryTreeBrowser = forwardRef<
    { clearSelection(): void },
    {
        onCategorySelected(category: CategoryNode): void;
        handleStaticCategorySelected(type: StaticCategory): void;
        staticCategorySelected: StaticCategory;
        getTags: (tagType: AssetType) => Promise<Hierarchy<AssetCategory>[]>;
        assetType: AssetType;
        showRecommendedCategories: boolean;
    }
>(({ onCategorySelected, getTags, assetType, handleStaticCategorySelected, staticCategorySelected, showRecommendedCategories }, ref) => {
    const translate = useIventisTranslate();
    const { data: categories, isLoading: isLoadingTags } = useQuery(["tags", assetType], async () => getTags(assetType));

    const nodes = useMemo(() => categories?.map(convertHierarchyToTreeBrowserNode) ?? [], [categories, isLoadingTags]);
    const tree: CategoryRootNode = {
        id: assetType,
        sourceId: assetType,
        name: assetType,
        childNodes: nodes,
        type: "CategoryRoot",
        assetType,
        iconAssetId: "",
    };

    const { value: treeContextValue, isLoading } = useDelayedConstant(
        () =>
            new TreeBrowserReadOnly(createInitialState({ tree, treeId: tree.id, mainNodeId: tree.id, isLoadingTree: false }), categoryServices, {
                fromSelectNodes: (payload) => {
                    const node = typeof payload.nodes[0] === "string" ? findNode(payload.tree.childNodes, payload.nodes[0]) : payload.nodes[0];
                    if (node?.type !== "CategoryRoot" && payload.from === "internal") onCategorySelected(node);
                    return node ? [node.id] : [];
                },
            }),
        !isLoadingTags
    );
    const state = useObservableValue(treeContextValue?.state, treeContextValue?.currentState);

    useImperativeHandle(ref, () => ({
        clearSelection: () => treeContextValue?.toggleSelectNodes([], "external"),
    }));

    return (
        <StyledCategoryContainer>
            <StyledCategoryRow
                data-testid="all-templates-button"
                marginBottom={showRecommendedCategories ? "0px" : "20px"}
                selected={staticCategorySelected === "all"}
                nestedLevel={0}
            >
                <StyledInteractiveArea onClick={() => handleStaticCategorySelected("all")} />
                <FontAwesomeIcon className="category-icon" icon={["fas", "table-cells-large"]} fontSize="14px" />
                <Body2 className="category-name">{translate(Content.map3.layerTemplates.allTemplates)}</Body2>
            </StyledCategoryRow>
            {showRecommendedCategories && (
                <StyledCategoryRow
                    marginBottom="20px"
                    selected={staticCategorySelected === "recommended"}
                    nestedLevel={0}
                    data-cy="layer-category-Chosen for you"
                    data-selected={staticCategorySelected === "recommended"}
                >
                    <StyledInteractiveArea onClick={() => handleStaticCategorySelected("recommended")} />
                    <FontAwesomeIcon className="category-icon" icon={["fas", "thumbs-up"]} fontSize="14px" />
                    <Body2 className="category-name">{translate(Content.map7.layerTemplates.chosenForYou)}</Body2>
                </StyledCategoryRow>
            )}
            <TreeBrowserContext.Provider value={treeContextValue}>
                {isLoading && <LoadingComponent />}
                {!isLoading && <TreeBrowserComponent node={tree} openNodes={state?.expandedNodeIds ?? []} displayRootNode={false} components={{ Category: CategoryRow }} />}
            </TreeBrowserContext.Provider>
        </StyledCategoryContainer>
    );
});

const StyledCategoryContainer = styled.div`
    max-height: 100%;
    min-height: 0;
    overflow-y: auto;
`;

const CategoryRow: NodeComponent<CategoryNode> = ({ node }) => {
    const translate = useIventisTranslate();
    const [{ expandedNodeIds, selectedNodeIds, tree }, treeBrowserContext] = useTreeBrowser(TreeBrowserContext, node.id, "expandedNodeIds", "selectedNodeIds", "tree");
    const expanded = expandedNodeIds.includes(node.id);
    const selected = selectedNodeIds.includes(node.id);
    const levelsDeep = getNodeNestedLevel(tree, node);

    const { assetService } = useEditLayerServices();
    const { data: categoryIcon, isLoading } = useQuery(
        ["asset", node.iconAssetId],
        () => node?.iconAssetId && node.iconAssetId !== EMPTY_GUID && assetService.getAsset(node.iconAssetId)
    );

    const onItemClicked = () => {
        if (selected) {
            treeBrowserContext.toggleExpandNode(node);
        } else {
            treeBrowserContext.expandNode(node);
        }
        treeBrowserContext.toggleSelectNodes([node.id], "internal", true);
    };

    return (
        <StyledCategoryRow
            data-cy={`layer-category-${node.name}`}
            selected={selected}
            nestedLevel={levelsDeep}
            iconUrl={categoryIcon ? getAssetSignature(categoryIcon.assetUrl, categoryIcon.authoritySignature) : undefined}
        >
            <StyledInteractiveArea onClick={onItemClicked} />
            {node.childNodes?.length > 0 && (
                <InteractiveElement className="expand-button" data-cy={`layer-category-expand-${node.name}`} onClick={() => treeBrowserContext.toggleExpandNode(node)}>
                    <FontAwesomeIcon icon={["fas", expanded ? "chevron-down" : "chevron-right"]} fontSize="14px" />
                    <span className="hidden">{translate(Content.common.buttons[expanded ? "collapse" : "expand"])}</span>
                </InteractiveElement>
            )}
            <div className="category-icon">{isLoading && <LoadingComponent size="1rem" />}</div>
            <Body2 className="category-name">{node.name}</Body2>
        </StyledCategoryRow>
    );
};

const StyledCategoryRow = styled.div<{ selected: boolean; nestedLevel: number; iconUrl?: string; marginBottom?: string }>`
    display: flex;
    height: ${inputHeight};
    padding-left: ${(props) => props.nestedLevel * 45}px;
    align-items: center;
    position: relative;
    background-color: ${({ selected, theme }) => (selected ? theme.tertiaryColors.primaryBackground : "transparent")};
    border-left: 3px solid ${({ selected, theme }) => (selected ? theme.tertiaryColors.primary : "transparent")};
    color: ${({ theme, selected }) => (selected ? theme.tertiaryColors.primary : theme.primaryColors.subduedMono)};
    margin-bottom: ${({ marginBottom }) => marginBottom ?? "0px"};

    .expand-button {
        margin-left: 5px;
        width: 14px;
        height: 14px;
        z-index: 200;
    }
    .category-icon {
        width: 14px;
        height: 14px;
        margin: 0 8px;
        ${({ iconUrl, theme, selected }) =>
            iconUrl &&
            css`
                mask-image: url(${iconUrl});
                --webkit-mask-image: url(${iconUrl});
                mask-repeat: no-repeat;
                mask-position: center;
                background-color: ${selected ? theme.tertiaryColors.primary : theme.primaryColors.subduedMono};
            `}
    }

    .category-name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const categoryServices: NodeServices<CategoryRootNode> = {
    addNode: () => null,
    deleteNodes: () => null,
    getNode: () => null,
    getTree: () => null,
    moveNodes: () => null,
    updateNode: () => null,
};
