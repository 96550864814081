import { TreeBrowserNode, TreeBrowserState } from "../types/data-types";
import { findNode, findNodesThatAreAncestors, findNodesThatAreDescendants, getAllAncestorsIdsToSelect, isAllNodesSelected, listIdsOfAllDescendants } from "../tree-operations";

export type TreeBrowserSelection<TNode extends TreeBrowserNode> = {
    fromSelectNodes: (payload: TreeBrowserSelectionPayload<TNode, "select">) => string[];
    fromSelectAll?: (payload: Pick<TreeBrowserState<TNode>, "tree" | "selectedNodeIds">) => string[];
    fromExpandNode: (payload: TreeBrowserSelectionPayload<TNode, "expand">) => string[];
    fromCollapseNode: (payload: TreeBrowserSelectionPayload<TNode, "collapse">) => string[];
    fromMoveNodes: (payload: TreeBrowserSelectionPayload<TNode, "move">) => string[];
    fromDeleteNodes: (payload: TreeBrowserSelectionPayload<TNode, "delete">) => string[];
};

export type TreeBrowserSelectionPayload<TNode extends TreeBrowserNode, TEventType extends "select" | "collapse" | "expand" | "move" | "delete"> = {
    nodes: string[] | TNode[];
    selectedNodeIds: string[];
    mainNodeId: string;
    tree: TNode;
    singleSelectionOnly?: boolean;
    affectedNodeTypes?: TNode["type"][];
} & (TEventType extends "select"
    ? { from: string; ignoreExistingSelection?: boolean; ctrlHeld?: boolean }
    : TEventType extends "delete"
    ? { deletedNodeIds: string[]; from: string }
    : {});

/** The default tree browser selection logic */
export const defaultTreeBrowserSelection = <TNode extends TreeBrowserNode>(): TreeBrowserSelection<TNode> => ({
    fromSelectNodes: (payload): string[] => {
        const { selectedNodeIds, singleSelectionOnly, nodes } = payload;
        if (singleSelectionOnly) {
            if (nodes.length <= 1) {
                // Toggle between main node id and currently selected node id
                const id = typeof nodes[0] === "string" ? nodes[0] : nodes[0]?.id;
                return id == null || id === selectedNodeIds[0] ? [] : [id];
            }
            throw new Error(`You can only add a maximum of one node when in single selection mode`);
        }
        const mainNode = findNode([payload.tree], payload.mainNodeId);
        // We need to know which of the selected nodes are ancestors of the toggled nodes (recursive parents)
        const selectedAncestors = findNodesThatAreAncestors(
            mainNode,
            nodes.map((n) => (typeof n === "string" ? n : n.id)),
            selectedNodeIds
        );
        return [...nodes].reduce((selectedNodes, node) => {
            const fullNode = typeof node === "string" ? findNode([mainNode], node) ?? ({ id: node } as TNode) : node;
            // Get all the descendants of the given nodes
            const descendants = listIdsOfAllDescendants(fullNode);
            if (selectedNodeIds.includes(fullNode.id)) {
                // If we are deselecting, remove the node, all it's descendants and all its selected ancestors
                return selectedNodes.filter((id) => !(id === fullNode.id || selectedAncestors.includes(id) || descendants.includes(id)));
            }
            // Get all the ancestors which are not yet selected but need to be (i.e. if we are selecting a node which completes the full selection of a parents children)
            const ancestorsToSelect = getAllAncestorsIdsToSelect(mainNode, fullNode, selectedNodes);
            // Combine all descendants and ancestors that require selecting
            return [...selectedNodes.filter((id) => !(descendants.includes(id) || id === fullNode.parentId)), fullNode.id, ...descendants, ...ancestorsToSelect];
        }, selectedNodeIds);
    },
    fromExpandNode: ({ tree, nodes, selectedNodeIds, singleSelectionOnly }) => {
        const node = nodes[0];
        const fullNode = typeof node === "string" ? findNode(tree?.childNodes, node) : node;
        return selectedNodeIds.includes(fullNode.id) && !singleSelectionOnly ? [...selectedNodeIds, ...fullNode.childNodes?.map(({ id }) => id)] : selectedNodeIds;
    },
    fromCollapseNode: (payload) => {
        const node = payload.nodes[0];
        const nodeId = typeof node === "string" ? node : node?.id;
        // If the collapsing node isn't selected, make sure we deselect it's descendants
        const nodesToDeselect = findNodesThatAreDescendants(payload.tree, nodeId, payload.selectedNodeIds).filter((id) => id !== nodeId);
        return payload.selectedNodeIds.filter((nodeId) => !nodesToDeselect.includes(nodeId));
    },
    fromMoveNodes: (payload) => {
        const { selectedNodeIds, nodes, tree } = payload;
        if (nodes.length > 0 && [...nodes].every((node) => selectedNodeIds.includes(typeof node === "string" ? node : node.id))) {
            const firstNode = findNode(tree?.childNodes, typeof nodes[0] === "string" ? nodes[0] : nodes[0]?.id);
            const ancestorsToSelect = getAllAncestorsIdsToSelect(tree, firstNode, selectedNodeIds);
            return selectedNodeIds.concat(ancestorsToSelect);
        }
        return selectedNodeIds;
    },
    // Want to deselect everything once we have deleted
    fromDeleteNodes: () => [],
    fromSelectAll: (payload) => {
        const isAllSelected = isAllNodesSelected(payload.tree, payload.selectedNodeIds);
        return isAllSelected ? [] : listIdsOfAllDescendants(payload.tree);
    },
});
