/* eslint-disable camelcase */
export const people = {
    people: "People",
    teams: "Teams",
    surnameAlphabetical: "Surname A-Z",
    teamsAlphabetical: "Teams A-Z",
    addTeamMember: "Add team member",
    addSelectedToTeam: "Add selected to team",
    allPeople: "All people",
    removeTeamLeaderMessage: "This person will no longer be able to edit and add/remove team members.",
    removeTeamLeader: "Remove Team Leader status",
    addTeamLeaderMessage: "This person will be able to edit and add/remove team members.",
    addTeamLeader: "Add Team Leader status",
    add_people: {
        add_user: "Add user",
        invite_people_to_the_project: "Send invites to join the project",
        email: "Email",
        team: "Team",
        separate_comma: "Separate multiple addresses with a comma",
        add_invitee_team: "Add invitee(s) to a team",
        add_more: "Add more",
        project_admin: "Project admin",
        sso: "Enable sso",
    },
    remove_people: {
        remove_user: "Remove user",
        remove_user_title: "Remove this user from the project",
        remove_user_message: "This will disable the selected user's account on the project and they will no longer be able to log in.",
        remove_user_message_continue: "Are you sure you want to continue?",
        remove_people_success: "User successfully removed from project",
    },
    reset_password: {
        reset_password_title: "Send reset password link",
        reset_password_message: "This will send a reset password link to people you've selected.",
        reset_password_message_continue: "Would you like to continue?",
        reset_password_success: "Password rest link successfully sent to selected people",
    },
    sso: {
        title: "Enable SSO",
        confirm: "This will enable SSO for the selected people.",
        continue: "Would you like to continue?",
        success: "SSO successfully enabled for selected people",
    },
    sso_disable: {
        title: "Disable SSO",
        confirm: "The selected users would no longer be able to sign in via Single Sign On, and would need to authenticate using a password.",
        continue: "Would you like to continue?",
        success: "SSO successfully disabled for selected people",
    },
    removeFromTeamMessage1: "This person will no longer be part of the team and will lose any viewing and editing permissions associated with that team.",
    removeFromTeamMessage2: "Are you sure you want to continue?",
    removeFromTeamTitle: "Remove this person from the team",
    removeFromTeamOverflowOption: "Remove from team",
    createANewTeam: "Create a new team",
    nameOfTeam: "Name of team",
    createNewTeam: "Create new team",
    teamLeader: "Team leader",
    toasts: {
        add_team_success: "Created team successfully",
        add_team_error: "Failed to create team",
        invite_success: "Invite(s) sent successfully",
        invite_error: "Failed to send invite because {{ reason }}",
        removeFromTeamSuccess: "Successfully removed from team",
        removeFromTeamError: "Failed to remove from team",
        setTeamLeaderSuccess: "Successfully set team leader status",
        setTeamLeaderError: "Failed to set team leader status",
        deleteTeamSuccess: "Successfully deleted team",
        deleteTeamError: "Failed to delete team",
    },
    amount_teams_selected: "{{ amount }} Teams selected",
    invalid_email_address: "Invalid email address",
    one_or_more_email_not_valid: "One or more emails are not valid",
    select_one_team: "Please select one team or more",
    deleteTeamTitle: "Delete Team",
    deleteTeamMessage: "Are you sure you want to delete the team: {{ team }}?",
} as const;
