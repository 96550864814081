import styled from "@emotion/styled";
import { MultiSelectDropdownComponent } from "@iventis/components";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { SitemapVersion } from "@iventis/domain-model/model/sitemapVersion";
import { Header5, Header3 } from "@iventis/styles";
import { LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { api } from "src/api/api";

interface SelectSitemapVersionsProps {
    instanceName: string;
    projectId: string;
    selectedSitemapVersions: Record<string, string | null>;
    setSelectedSitemapVersion: (sitemapId: string, selectedSitemapVersion: string | null) => void;
}

interface SitemapVersionResponse extends Omit<SitemapVersion, "date"> {
    date: string;
}

interface SitemapResponse extends Omit<Sitemap, "versions"> {
    versions: SitemapVersionResponse[];
}

export function SelectSitemapVersionsComponent({ instanceName, projectId, selectedSitemapVersions, setSelectedSitemapVersion }: SelectSitemapVersionsProps) {
    const { data: sitemaps, isLoading: sitemapsLoading } = useQuery<SitemapResponse[]>(["sitemaps", projectId], async () => {
        const { data: sitemaps } = await api.get<SitemapResponse[]>(`/instances/${instanceName}/projects/${projectId}/sitemaps`);
        sitemaps.forEach((sitemap) => {
            const latestVersion = sitemap.versions.reduce(
                (latestSitemapVersion, sitemapVersion) => (new Date(latestSitemapVersion?.date) > new Date(sitemapVersion.date) ? latestSitemapVersion : sitemapVersion),
                null
            );

            setSelectedSitemapVersion(sitemap.id, latestVersion.id);
        });
        return sitemaps;
    });

    const handleSitemapVersionUpdate = (sitemapId: string, selectedValues: { id: string; name: string }[]): void => {
        const currentSelectedSitemapVersion = selectedSitemapVersions[sitemapId];
        // Replace the existing selected sitemap version with the new selected sitemap version
        const selectedSitemapVersion = selectedValues.find((value) => value.id !== currentSelectedSitemapVersion);
        setSelectedSitemapVersion(sitemapId, selectedSitemapVersion?.id);
    };

    return (
        <>
            {sitemapsLoading && <LinearProgress />}
            <SitemapListContainer>
                <Header3>Select Sitemap Versions</Header3>
                {sitemaps?.map((sitemap) => (
                    <SitemapVersionSelectionContainer key={sitemap.id}>
                        <Header5>{sitemap.name}</Header5>
                        <MultiSelectDropdownComponent
                            values={sitemap.versions}
                            selectedValues={findSitemapVersionLevel(sitemap, selectedSitemapVersions[sitemap.id])}
                            onValuesUpdate={(selectedValues) => handleSitemapVersionUpdate(sitemap.id, selectedValues)}
                            placeHolderText="None selected"
                        />
                    </SitemapVersionSelectionContainer>
                ))}
            </SitemapListContainer>
        </>
    );
}

function findSitemapVersionLevel(sitemap: SitemapResponse, selectedSitemapVersionId: string | null): SitemapVersionResponse[] {
    const foundSitemap = sitemap.versions.find((version) => version.id === selectedSitemapVersionId);
    if (foundSitemap == null) {
        return [];
    }
    return [foundSitemap];
}

const SitemapListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const SitemapVersionSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;
