import React, { FunctionComponent, MouseEventHandler } from "react";
import { styled, StyledIconButton, main } from "@iventis/styles";

import { iconButtonSize } from "@iventis/styles/src/atomic-rules";
import { Checkbox } from "@mui/material";
import { CursorTooltip, SortMethod, SortSelectionComponent } from "@iventis/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FolderBrowserView } from "../types/component-types";

interface TreeBrowserHeaderProps {
    selectAll: () => void;
    selectAllValue: boolean;
    sortMethods: SortMethod[];
    onSortMethodChange: (sortName: string) => void;
    selectedSortMethod: SortMethod;
    hideSelectAll?: boolean;
    children?: React.ReactNode;
    disabledSelection?: boolean;
    disabledSort?: boolean;
}

// Folders, teams, people
export const TreeBrowserHeaderComponent: FunctionComponent<TreeBrowserHeaderProps> = ({
    children,
    selectAll,
    selectAllValue,
    sortMethods,
    onSortMethodChange,
    selectedSortMethod,
    hideSelectAll = false,
    disabledSelection,
    disabledSort,
}) => (
    <StyledTreeBrowserHeaderContainer hideSelectAll={hideSelectAll}>
        {!hideSelectAll && <Checkbox data-cy="select-all" checked={selectAllValue} size="small" onChange={() => selectAll()} disabled={disabledSelection} />}
        {sortMethods.length > 0 ? (
            <StyledSortSelection
                hideSelectAll={hideSelectAll}
                sortMethods={sortMethods}
                selectedSortMethod={selectedSortMethod}
                onSortMethodChange={onSortMethodChange}
                disabled={disabledSort}
                disableUnderline
            />
        ) : (
            <></>
        )}
        <div className="tree-action-container">{children}</div>
    </StyledTreeBrowserHeaderContainer>
);

export const ChangeTreeViewButton: React.FC<{ className?: string; viewType: FolderBrowserView; onChange: (view: FolderBrowserView) => void; disabled: boolean; text: string }> = ({
    className,
    viewType,
    onChange,
    disabled,
    text,
}) => (
    <TreeAction
        className={className}
        text={text}
        onClick={() => onChange(viewType === FolderBrowserView.List ? FolderBrowserView.Tile : FolderBrowserView.List)}
        disabled={disabled}
    >
        {viewType === FolderBrowserView.List && <FontAwesomeIcon size="xs" icon={{ prefix: "far", iconName: "table-cells-large" }} />}
        {viewType === FolderBrowserView.Tile && <FontAwesomeIcon size="xs" icon={{ prefix: "far", iconName: "list" }} />}
        <span className="hidden">{text}</span>
    </TreeAction>
);

const StyledTreeBrowserHeaderContainer = styled.div<{ hideSelectAll?: boolean }>`
    height: ${iconButtonSize};

    border-top: ${main.shades.darkBorder} 1px solid;
    border-bottom: ${main.shades.darkBorder} 1px solid;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .MuiCheckbox-root {
        flex: 0 0 ${iconButtonSize};
        height: 100%;
    }

    .tree-action-container {
        flex-basis: 85%;
        padding-right: 20px;
    }
    .checkbox-container {
        flex: 0 0 ${iconButtonSize};
    }
`;

const StyledSortSelection = styled(SortSelectionComponent)<{ hideSelectAll: boolean }>`
    flex-basis: ${(prop) => (prop.hideSelectAll ? "15%" : "10%")};
    margin-left: ${(prop) => (prop.hideSelectAll ? "13px" : "22px")};
`;

interface StyledTreeActionProps {
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    text?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    dataCy?: string;
    dataTestId?: string;
}

export const TreeAction: React.FC<StyledTreeActionProps> = ({ className, text, onClick, disabled, children, dataCy, dataTestId }) => (
    <CursorTooltip text={text} disabled={disabled}>
        <StyledIconButton data-cy={dataCy} data-testid={dataTestId} className={className} onClick={onClick} disabled={disabled}>
            {children}
            <span className="hidden">{text}</span>
        </StyledIconButton>
    </CursorTooltip>
);

export const StyledTreeActionsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
`;
