import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { borderRadius, InteractiveElement, onHoverCornerButtonCss, onHoverCornerButtonParentCss, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { basicTranslator, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent, memo, PropsWithChildren } from "react";

export type SelectableImageComponentProps = {
    id: string;
    selected: boolean;
    select: (id: string) => void;
    deselect: (id: string) => void;
    image: string;
    name: string;
    width: string;
    height: string;
    translate?: UseIventisTranslate;
    onEditClicked?: (id: string) => void;
};

/** Image component that, when selected, has a pink border around it */
export const SelectableImageComponent: FunctionComponent<PropsWithChildren<SelectableImageComponentProps>> = memo(
    ({ id, selected, select, deselect, image, name, width, height, onEditClicked, translate = basicTranslator, children }) => (
        <StyledPreview width={width} height={height} selected={selected}>
            <InteractiveElement
                className="image-button"
                type="button"
                onClick={() => (selected ? deselect(id) : select(id))}
                data-testid={`image-button-${name}`}
                data-selected={selected}
            >
                <span className="hidden">{translate(Content.common.buttons[selected ? "click_to_deselect" : "click_to_select"], { name })}</span>
                <img src={image} alt={name} className="image-background" />
                {children}
            </InteractiveElement>
            {onEditClicked && (
                <InteractiveElement className="pencil" onClick={() => onEditClicked(id)}>
                    <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                    <span className="hidden">{`${translate(Content.common.buttons.click_to_edit, { name })}`}</span>
                </InteractiveElement>
            )}
        </StyledPreview>
    ),
    (prev, next) =>
        // Optimised so that it won't re-render each time its' sibling's selection changes
        Object.entries(prev).every(([pkey, pval]) => (["select", "deselect"].includes(pkey) ? true : Object.entries(next).find(([nkey]) => nkey === pkey)?.[1] === pval))
);

const StyledPreview = styled.div<{ selected: boolean; width: string; height: string }>`
    position: relative;
    border-radius: ${borderRadius.standard};
    border: ${(props) => (props.selected ? `2px solid ${props.theme.primaryColors.focus}` : "2px solid transparent")};
    box-sizing: content-box;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: 4px;
    .image-button {
        width: 100%;
        height: 100%;
    }

    :hover {
        .pencil {
            ${onHoverCornerButtonParentCss}
        }
    }

    .pencil {
        ${onHoverCornerButtonCss}
    }
    .image-background {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
        object-position: center;
    }
    :hover {
        border-color: ${(props: { theme: Theme }) => props.theme.tertiaryColors.primary};
    }
`;
