/* eslint-disable react/jsx-props-no-spreading */
import { TooltipProps } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { StyledTooltip } from "./tooltips";
import { StyledIcon } from "./tooltips-info-anchor";

type HoverInfoTooltipProps = { component: ReactNode } & Omit<TooltipProps, "children" | "title">;

/** Information icon which when hovered on shows a tooltip */
export function InfoTooltipHoverComponent({ component, ...props }: HoverInfoTooltipProps) {
    const [hovering, setHovering] = useState(false);

    return (
        <StyledTooltip title={component} {...props}>
            <StyledIcon onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} icon={hovering ? ["fas", "circle-info"] : ["far", "circle-info"]} />
        </StyledTooltip>
    );
}
