import { InteractiveElement } from "@iventis/styles";
import React, { ReactNode } from "react";
import { StyledPopover } from "@iventis/components";
import { OverflowOption, TreeBrowserNode } from "../../../types/data-types";

export function LibraryOverflowPopover<TNode extends TreeBrowserNode, TLabel extends string | ReactNode = string>({
    overflowOpen,
    setOverflowOpen,
    overflowOptions,
    overflowButtonRef,
    node,
    withIcons = false,
}: {
    overflowOpen: boolean;
    setOverflowOpen: (bool: boolean) => void;
    overflowOptions: OverflowOption<TNode, TLabel>[];
    overflowButtonRef: React.RefObject<HTMLElement>;
    node: TNode;
    withIcons?: boolean;
}) {
    return (
        <StyledPopover
            space={44}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={overflowOpen}
            anchorEl={overflowButtonRef.current}
            onClose={() => {
                setOverflowOpen(false);
            }}
            $withIcons={withIcons}
        >
            {overflowOptions.map(({ label, selected, key }) => (
                <div key={key ?? label.toString()}>
                    <InteractiveElement
                        data-testid={`overflow-button-${label}`}
                        className="button"
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOverflowOpen(false);
                            selected(node);
                        }}
                    >
                        {label}
                    </InteractiveElement>
                </div>
            ))}
        </StyledPopover>
    );
}
