import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { RowEvent } from "@ag-grid-community/core";
import { styled, Body2, StyledIconButton } from "@iventis/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sanitiseForDOMToken } from "@iventis/utilities";
import { IventisCellRendererParams } from "../types/data-table.types";

export const GroupCellComponent: FunctionComponent<IventisCellRendererParams> = (props) => {
    const {
        node,
        value,
        context: { translate },
    } = props;
    const [expanded, setExpanded] = useState(node.expanded);

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded);

        node.addEventListener("expandedChanged", expandListener);

        return () => {
            node.removeEventListener("expandedChanged", expandListener);
        };
    }, []);

    const onClick = useCallback(
        (e: SyntheticEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
            node.setExpanded(!node.expanded);
        },
        [node]
    );

    return (
        <StyledGroupCell data-testid={sanitiseForDOMToken(`group-row-${node.data?.groupDisplayValue}`)} level={node.level}>
            {node.group && !node.data?.noneGroup && (
                <StyledIconButton onClick={onClick} data-testid={sanitiseForDOMToken(`expand-group-row-${node.data?.groupDisplayValue}`)}>
                    <FontAwesomeIcon fontSize="16px" icon={["far", expanded ? "chevron-down" : "chevron-right"]} />
                    <span className="hidden">{translate(expanded ? "Collapse" : "Expand")}</span>
                </StyledIconButton>
            )}
            <Body2>{node.data?.groupDisplayValue ?? value}</Body2>
        </StyledGroupCell>
    );
};

const StyledGroupCell = styled.div<{ level: number }>`
    padding-left: ${(props) => `${props.level * 15}px`};
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
