/* eslint-disable @typescript-eslint/no-empty-function */
import { Toasts } from "./toast.types";

export const toastsDoNothing: Toasts = {
    error() {},
    info() {},
    success() {},
    remove() {},
    warning() {},
};
