import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { Content } from "@iventis/translations/content/typed-content";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import React, { FunctionComponent } from "react";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { LineStyles } from "@iventis/map-engine/src/utilities/style-helpers";
import { selectItemsComponentCreator } from "@iventis/components";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { EditStyleProps } from "./style.types";
import { ChangeStyleValue } from "./edit-style.helpers";
import { LineEditStyleComponent, StyledStyleItemContainer } from "./line-edit-style";
import { LineModelEditStyleComponent } from "./line-model-edit-style";

export const LineEditStyleDelegator: FunctionComponent<
    {
        onStyleTypeChanged: (styleType: StyleType) => void;
    } & EditStyleProps<LineStyles>
> = ({ layerStyle, onStyleTypeChanged, changeStyleValue, dataFields }) => {
    const translate = useIventisTranslate();
    const changeStyleTypeValue: ChangeStyleValue<LineStyles> = (_, value) => onStyleTypeChanged((value as StyleValue<StyleType>).staticValue.staticValue);
    return (
        <>
            <StyleContainer title={translate(Content.map2.styles2.type_of_line)}>
                <StyledStyleItemContainer>
                    <EditStyleItemComponent
                        styleProperty="styleType"
                        value={createStaticStyleValue(layerStyle.styleType)}
                        changeStyleValue={changeStyleTypeValue}
                        component={selectItemsComponentCreator({
                            testId: "style-type-selector",
                            options: [
                                { id: StyleType.Line, name: translate(StyleType.Line) },
                                { id: StyleType.LineModel, name: translate(Content.map2.styles2[StyleType.LineModel]) },
                            ],
                        })}
                        ignoreTitle
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            {layerStyle.styleType === StyleType.Line && <LineEditStyleComponent layerStyle={layerStyle as LineStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />}
            {layerStyle.styleType === StyleType.LineModel && (
                <LineModelEditStyleComponent layerStyle={layerStyle as LineModelStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
            )}
        </>
    );
};
