import { GeoJSON } from "geojson";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { DeepPartial, OptionalExceptFor, TupleToUnion } from "@iventis/types/useful.types";
/* eslint-disable @typescript-eslint/ban-types */
import { ActorRef } from "xstate";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { AnySupportedGeometry, MapObjectProperties, RouteWaypoint, SelectedMapObject } from "@iventis/map-types";
import { CompositionMapObject, MapCursor, TypedFeature } from "../types/internal";
import { DrawingModifier, DrawingObject, SelectedMapComment } from "../types/store-schema";
import { Midpoint } from "../utilities/midpoint-handles";
import { ContinueDrawingDirection } from "../utilities/continue-drawing";

/*
    DRAWING MACHINE EVENTS
*/

export type DrawingMachineEvents =
    | AddCoordinateDeleteEvent
    | BeginDragEvent
    | BeginObjectDragEvent
    | BeginRotatorDragEvent
    | EndRotationDragEvent
    | EndNodeDragEvent
    | DeleteNodeEvent
    | AppendEvent
    | EnterNodeEvent
    | ClickMidpointEvent
    | DragMidpointEvent
    | EndObjectDraggingEvent
    | EnterMidpointEvent
    | NodeClickedEvent
    | EventsWithNoPayload<"OVER_SELECTED_OBJECT">
    | EventsWithNoPayload<"LEAVE_SELECTED_OBJECT">
    | EventsWithNoPayload<"CLICK_DELETE">
    | EventsWithNoPayload<"CLICK_MIDPOINT">
    | EventsWithNoPayload<"ENTER_DELETE">
    | EventsWithNoPayload<"LEAVE_DELETE">
    | EventsWithNoPayload<"LEAVE_NODE">
    | EventsWithNoPayload<"ENTER_ROTATOR">
    | EventsWithNoPayload<"LEAVE_ROTATOR">
    | EventsWithNoPayload<"LEAVE_MIDPOINT">
    | EventsWithNoPayload<"MOVE_AWAY">
    | EventsWithNoPayload<"OVER_CONTINUE_DRAWING">;

export enum OptionalDrawingBehaviour {
    OBJECT_DRAG = "OBJECT_DRAG",
    CONTINUE_DRAW = "CONTINUE_DRAW",
}

export type AddMidpointEvent = {
    type: "ADD_MIDPOINT";
    payload: { point: Midpoint; dragging: true } | { point: Midpoint; dragging: false; object?: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> };
};
export type RotationHasStarted = { type: "ROTATION_HAS_STARTED"; payload: BeginRotatorDragEvent["payload"] };
export type RotationHasEnded = { type: "ROTATION_HAS_ENDED"; payload: EndRotationDragEvent["payload"] };
export type NodeDragHasEnded = { type: "NODE_DRAG_HAS_ENDED"; payload: EndNodeDragEvent["payload"] };
export type MidpointDragHasEnded = { type: "MIDPOINT_DRAG_HAS_ENDED"; payload: EndNodeDragEvent["payload"] };
export type NodeDragHasStarted = { type: "NODE_DRAG_HAS_STARTED"; payload: BeginDragEvent["payload"] };
export type ObjectDragHasEnded = { type: "OBJECT_DRAG_HAS_ENDED"; payload: EndObjectDraggingEvent["payload"] };

export type DrawingCallbackEvents =
    | AddMidpointEvent
    | RotationHasStarted
    | RotationHasEnded
    | NodeDragHasStarted
    | NodeDragHasEnded
    | ObjectDragHasEnded
    | UpdateDrawingGeometryEvent
    | MidpointDragHasEnded
    | EventsWithNoPayload<"MIDPOINT_DRAG_HAS_STARTED">
    | EventsWithNoPayload<"ENTER_HOVER">
    | EventsWithNoPayload<"EXIT_HOVER">;

export type DrawingMachineContext = {
    defaultCursor: MapCursor;
    /** Behaviours with which you would like the drawing machine to perform, such as object dragging and continue drawing */
    includeBehaviours: OptionalDrawingBehaviour[];
};

export interface Append {
    feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
    waypoints: RouteWaypoint[] | null;
}

export interface AppendEvent {
    type: "APPEND";
    payload: Append;
}

export interface EnterNodeEvent {
    type: "ENTER_NODE";
    payload: AddCoordinateDelete & { cursor?: MapCursor };
}

export interface BeginDrag {
    layerId: string;
    objectId: string;
}

export interface BeginRotatorDragEvent {
    type: "ROTATOR_DRAG_START";
    payload: BeginDrag[];
}

export interface BeginDragEvent {
    type: "NODE_DRAG_START";
    payload: { feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>; cursor: MapCursor };
}

export interface NodeClickedEvent {
    type: "NODE_CLICKED";
    payload: { feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>; point: GeoJSON.Position };
}

export interface BeginObjectDragEvent {
    type: "OBJECT_DRAG_START";
    payload: { lng: number; lat: number };
}

export interface ClickMidpointEvent {
    type: "CLICK_MIDPOINT";
    // Send the object through this payload because it is a dependency of showing the coordinate delete handle
    payload: { point: Midpoint; object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> };
}

export interface DragMidpointEvent {
    type: "DRAG_MIDPOINT";
    payload: { point: Midpoint; createNode: boolean };
}

export interface AddCoordinateDelete {
    coordinate: GeoJSON.Position;
    object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
}
export interface AddCoordinateDeleteEvent {
    type: "MOVE_CLOSE_TO_NODE";
    payload: AddCoordinateDelete;
}

export interface EndObjectDraggingEvent {
    type: "OBJECT_DRAG_END";
    payload: CompositionMapObject[];
}

export interface EndRotationDragEvent {
    type: "DRAG_END";
    payload: CompositionMapObject[];
}

export interface EndNodeDrag {
    transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
    coordinate?: GeoJSON.Position;
}

export interface EndNodeDragEvent {
    type: typeof machineEventTypes.dragEnd;
    payload: EndNodeDrag & { cursor?: MapCursor; isNodeOutSideCadBounds?: boolean };
}

export interface EnterMidpointEvent {
    type: "ENTER_MIDPOINT";
    payload?: { cursor: MapCursor };
}

export type DrawingStates = [
    "closeToNode",
    "closeToNode.default",
    "closeToNode.hoverOverDelete",
    "closeToNode.hoverOverNode",
    "default",
    "hoverOverMidpoint",
    "hoverOverRotator",
    "nodeDragging",
    "midpointDragging",
    "objectDragging",
    "rotating"
];

export type DrawingState = TupleToUnion<DrawingStates>;

/*
    MODE MACHINE EVENTS
*/

export interface SelectObjects {
    selectedMapObjectIds: SelectedMapObject[];
}
export interface SelectObjectsEvent {
    type: "SELECT_OBJECTS";
    payload: { selectedMapObjectIds: SelectedMapObject[]; from: string };
}

export interface SelectLayer {
    layerId: string;
}

export interface SelectLayerEvent {
    type: "SELECT_LAYER";
    payload: SelectLayer;
}

export interface FinishDrawingEvent {
    type: "FINISH";
    payload?: { updateStore?: boolean };
}

export type BeginCompose = {
    objects: OptionalExceptFor<DrawingObject, "layerId", DeepPartial<DrawingObject>>[];
    duplication?: boolean;
    fromCoordinate?: GeoJSON.Position;
    direction?: ContinueDrawingDirection;
};

export type BeginAnalysisCompose = {
    objects: OptionalExceptFor<DrawingObject, "layerId">[];
    fromCoordinate?: GeoJSON.Position;
    direction?: ContinueDrawingDirection;
};

export interface BeginComposeEvent {
    type: "COMPOSE" | "DRAG_END" | "MOVE_AWAY" | "CONTINUE_DRAWING";
    payload: BeginCompose;
}

export interface BeginAnalysisComposeEvent {
    type: "COMPOSE_ANALYSIS" | "DRAG_END" | "MOVE_AWAY" | "CONTINUE_DRAWING";
    payload: BeginAnalysisCompose;
}

export type ModeContext = {
    drawingMachineRef: ActorRef<DrawingMachineEvents> | undefined;
    defaultCursor: MapCursor;
    layerPermissions: { [layerId: string]: Authorisation };
};

export type RefreshAttributeListItems = {
    type: "REFRESH_ATTRIBUTE_LIST_ITEMS";
    payload: { layerId: string; attributeId: string };
};

export type UpdateModeOfTransport = {
    type: "UPDATE_MODE_OF_TRANSPORT";
    payload: { modeOfTransport: ModeOfTransport };
};

export type UpdateRouteWaypoints = {
    type: "UPDATE_ROUTE_WAYPOINTS";
    payload: { waypoints: RouteWaypoint[] };
};

export type SelectableComment = Omit<SelectedMapComment, "canvasCoordinates"> & { position: GeoJSON.Position };

export type SelectCommentEvent = {
    type: "SELECT_COMMENT";
    payload: SelectableComment;
    from: string;
    focus?: boolean;
};

export type DeleteCommentEvent = {
    type: "DELETE_COMMENT";
    commentId: string;
};

export type ExitComments = {
    type: "EXIT_COMMENTS";
    keepCommentsMachineAlive: boolean;
};

export type SetDrawingModifierEvent = {
    type: "SET_DRAWING_MODIFIER";
    payload: DrawingModifier;
};

export type EnterAreaSelect = {
    type: "ENTER_AREA_SELECT";
    payload: BeginCompose;
};

export type CancelDrawingEvent = {
    type: "CANCEL_DRAWING";
    payload?: { fallbackGeometry?: { feature: AnySupportedGeometry; waypoints: RouteWaypoint[] | null }; updateStore?: boolean };
};

/** Use these events for external mode events */
export type ModeEvents =
    | BeginComposeEvent
    | SelectObjectsEvent
    | SelectLayerEvent
    | FinishDrawingEvent
    | UpdateDrawingGeometryEvent
    | DeleteNodeEvent
    | BeginAnalysisComposeEvent
    | DeleteObjectEvent
    | SetLayerPermissions
    | RefreshAttributeListItems
    | UpdateModeOfTransport
    | UpdateRouteWaypoints
    | SelectCommentEvent
    | DeleteCommentEvent
    | ExitComments
    | EnterAreaSelect
    | CancelDrawingEvent
    | SetDrawingModifierEvent
    | EventsWithNoPayload<"TOGGLE_COMMENTS">
    | EventsWithNoPayload<"LOADED">
    | EventsWithNoPayload<"PREVIEW">
    | EventsWithNoPayload<"COPY_OBJECTS">
    | EventsWithNoPayload<"ZOOM_IN">
    | EventsWithNoPayload<"ZOOM_OUT">
    | EventsWithNoPayload<"SET_PITCH">
    | EventsWithNoPayload<"RESET_POSITION">
    | EventsWithNoPayload<"EXIT_ANALYSIS">
    | EventsWithNoPayload<"CONTINUE_DRAWING">
    | EventsWithNoPayload<"ADD_MOBILE_COMMENT">
    | EventsWithNoPayload<"CONFIRM_COMMENT_MOVE">
    | EventsWithNoPayload<"CANCEL_COMMENT_MOVE">
    | EventsWithNoPayload<"ENTER_OBJECT_SELECT">
    | EventsWithNoPayload<"ENTER_READ">;

/** Use these for internal mode events */
export type ModeMachineEventsWithDrawingCallbacks = ModeEvents | DrawingCallbackEvents;

export type MapModes = ["loading", "read", "edit.default", "edit.composition", "areaSelect", "preview", "edit.addComment"];

export type MapMode = TupleToUnion<MapModes>;

export const modeMachineEvents = {
    loaded: "LOADED",
    compose: "COMPOSE",
    selectObjects: "SELECT_OBJECTS",
    finish: "FINISH",
    selectLayer: "SELECT_LAYER",
    preview: "PREVIEW",
    copyObjects: "COPY_OBJECTS",
    zoomIn: "ZOOM_IN",
    zoomOut: "ZOOM_OUT",
    setPitch: "SET_PITCH",
    resetPosition: "RESET_POSITION",
    exitAnalysis: "EXIT_ANALYSIS",
    continueDrawing: "CONTINUE_DRAWING",
    composeAnalysis: "COMPOSE_ANALYSIS",
    deleteObject: "DELETE_OBJECT",
    setLayerPermission: "SET_LAYER_PERMISSIONS",
    refreshAttributeListItems: "REFRESH_ATTRIBUTE_LIST_ITEMS",
    updateModeOfTransport: "UPDATE_MODE_OF_TRANSPORT",
    updateRouteWaypoints: "UPDATE_ROUTE_WAYPOINTS",
    toggleComments: "TOGGLE_COMMENTS",
    exitComments: "EXIT_COMMENTS",
    selectComment: "SELECT_COMMENT",
    deleteComment: "DELETE_COMMENT",
    addCommentInMobile: "ADD_MOBILE_COMMENT",
    confirmCommentMove: "CONFIRM_COMMENT_MOVE",
    cancelCommentMove: "CANCEL_COMMENT_MOVE",
    enterAreaSelect: "ENTER_AREA_SELECT",
    enterObjectSelect: "ENTER_OBJECT_SELECT",
    cancelDrawing: "CANCEL_DRAWING",
    updateDrawingGeometry: "UPDATE_GEOMETRY",
    enterRead: "ENTER_READ",
    setDrawingModifier: "SET_DRAWING_MODIFIER",
} as const;

/*
    SHARED MACHINE TYPES
*/

export const machineEventTypes = {
    // Mode events
    ...modeMachineEvents,

    // Drawing Events
    clickDelete: "CLICK_DELETE",
    clickMidpoint: "CLICK_MIDPOINT",
    dragMidpoint: "DRAG_MIDPOINT",
    nodeDragStart: "NODE_DRAG_START",
    overSelectedObject: "OVER_SELECTED_OBJECT",
    leaveSelectedObject: "LEAVE_SELECTED_OBJECT",
    objectDragStart: "OBJECT_DRAG_START",
    rotatorDragStart: "ROTATOR_DRAG_START",
    moveCloseToNode: "MOVE_CLOSE_TO_NODE",
    dragEnd: "DRAG_END",
    objectDragEnd: "OBJECT_DRAG_END",
    moveAway: "MOVE_AWAY",
    enterDelete: "ENTER_DELETE",
    leaveDelete: "LEAVE_DELETE",
    enterNode: "ENTER_NODE",
    leaveNode: "LEAVE_NODE",
    enterMidpoint: "ENTER_MIDPOINT",
    leaveMidpoint: "LEAVE_MIDPOINT",
    enterRotator: "ENTER_ROTATOR",
    leaveRotator: "LEAVE_ROTATOR",
    append: "APPEND",
    overContinueDrawing: "OVER_CONTINUE_DRAWING",
    updateDrawingGeometry: "UPDATE_GEOMETRY",
    nodeClicked: "NODE_CLICKED",
} as const;

export interface DeleteObjectEvent {
    type: "DELETE_OBJECT";
    payload: { updateStore: boolean };
}

export interface SetLayerPermissions {
    type: "SET_LAYER_PERMISSIONS";
    payload: { [layerId: string]: Authorisation };
}

export type EventsWithNoPayload<T> = { type: T };

export interface DeleteNode {
    coordinate: GeoJSON.Position;
}
export interface ObjectPayload {
    objectIds: string[];
}
export interface DeleteNodeEvent {
    type: "CLICK_DELETE" | "DELETE_NODE";
    payload: DeleteNode;
}

export interface UpdateDrawingGeometryEvent {
    type: "UPDATE_GEOMETRY";
    payload: { features: TypedFeature[]; updateStore: boolean };
}

export type MachineTypes = Pick<ModeEvents, "type">["type"];
