import { InputAdornment, Skeleton, TextField } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton } from "@iventis/styles";

export const CopyableTextPreview: React.FC<{ fieldLabel: string; text?: string; onSuccessfulCopy?: () => void; className?: string; testId?: string }> = ({
    fieldLabel,
    text,
    onSuccessfulCopy,
    className,
    testId,
}) => (
    <>
        <StyledFieldLabel>{fieldLabel}</StyledFieldLabel>
        {text == null && (
            <Skeleton variant="rounded" animation="wave" width="100%">
                <TextField variant="outlined" value="loading link" />
            </Skeleton>
        )}
        {text != null && (
            <TextField
                className={className}
                variant="outlined"
                value={text}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <StyledIconButton onClick={() => navigator.clipboard.writeText(text).then(onSuccessfulCopy)}>
                                <FontAwesomeIcon size="xs" icon={["fas", "copy"]} />
                            </StyledIconButton>
                        </InputAdornment>
                    ),
                }}
                data-cy={testId}
            />
        )}
    </>
);
