import { DataField } from "@iventis/domain-model/model/dataField";
import { AssetPickerComponent, AssetPreviewButton, AutocompleteWithLoading, CustomDialog, FormButtonsComponent } from "@iventis/components";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { DialogActions as DialogActionsMUI, DialogContent as DialogContentMUI, DialogTitle as DialogTitleMUI, Divider } from "@mui/material";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import { ModelSizingComponent } from "@iventis/components/src/model-sizing-component";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { Body1, Body2, Header3, formPadding, muiInputFormsCSS, sectionalMargin, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { dimensionValidation } from "@iventis/components/src/model-sizing-component-state";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { getModelQueryKey, useAssetSignatureCache } from "@iventis/utilities";
import { Asset } from "@iventis/domain-model/model/asset";
import { useQuery } from "@tanstack/react-query";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { Model } from "@iventis/domain-model/model/model";
import { ModelLayerStyle } from "@iventis/map-engine";
import { getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { AttributeSelector } from "./create-formula";
import { ListItemToDimension, convertListItemsToModelListItem, convertModelListItemsToStyleValues } from "./model-attribute-style-helpers";
import { EditStyleContext } from "./edit-style-context";
import { ModelWithThumbnail } from "./model-edit-style";
import { MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED, useListItemsForFormula } from "./data-driven-styles.helpers";

export type ModelAttributeBasedModalStatus = "dimensions" | "model" | "hidden";

export const ModelAttributeStyles = ({
    dataFields,
    onClose,
    onConfirm,
    style,
    selectedDataFieldId,
    modelsWithThumbnails,
    type,
    getModel,
}: {
    dataFields: DataField[];
    onClose: () => void;
    onConfirm: (values: { height: StyleValue<number>; length: StyleValue<number>; width: StyleValue<number> }) => void;
    style: ModelLayerStyle;
    selectedDataFieldId?: string;
    modelsWithThumbnails: ModelWithThumbnail[];
    type: ModelAttributeBasedModalStatus;
    getModel?: (modelId: string) => Promise<Model>;
}) => {
    const translate = useIventisTranslate();

    const [selectedDataField, setSelectedDataField] = useState<DataField | null>(dataFields.find((df) => df.id === selectedDataFieldId));
    // Contains datafield list item id to a width, height and length combination
    const [dataFieldIdToDimensionMap, setDataFieldIdToDimensionMap] = useState<ListItemToDimension>(convertListItemsToModelListItem(style, modelsWithThumbnails, type === "model"));

    const listOnlyDataFields = useMemo(() => dataFields.filter((df) => df.type === DataFieldType.List), [dataFields]);

    const selectedDataFieldChange = (dataField: DataField) => {
        setSelectedDataField(dataField);
        setDataFieldIdToDimensionMap(convertListItemsToModelListItem(style, modelsWithThumbnails, type === "model"));
    };

    const [showModelSelector, setShowModelSelector] = useState<string>(null);

    const handleOnConfirm = () => {
        onConfirm(convertModelListItemsToStyleValues(dataFieldIdToDimensionMap, selectedDataField.id, type === "model"));
    };

    const handleUpdatedModel = (model: ModelWithThumbnail, listItemId: string) => {
        setDataFieldIdToDimensionMap({
            ...dataFieldIdToDimensionMap,
            [listItemId]: {
                modelId: model.id,
                modelName: model.name,
                thumbnailUrl: model.thumbnailUrl,
                dimensions: !(model.height === 0 || model.height == null),
                height: model.height ?? 0,
                width: model.width ?? 0,
                length: model.length ?? 0,
            },
        });
        setShowModelSelector(null);
    };

    // Given an array of list items, if the list item has no mapped value present, it will add the default static value as its' mapped value ready for the user to go and edit
    const addListItemsToStyleMapping = (listItems: DataFieldListItem[]) => {
        const modelId = getStaticStyleValueFromMapped(style.model);
        const model = modelsWithThumbnails.find((model) => model.id === modelId);
        setDataFieldIdToDimensionMap(
            listItems.reduce(
                (acc, listItem) =>
                    acc[listItem.id] == null
                        ? // If no list item exists in the style already, set it to the default
                          {
                              ...acc,
                              [listItem.id]: {
                                  modelId,
                                  height: getStaticStyleValueFromMapped(style.height),
                                  width: getStaticStyleValueFromMapped(style.width),
                                  length: getStaticStyleValueFromMapped(style.length),
                                  modelName: model.name,
                                  thumbnailUrl: model.thumbnailUrl,
                                  dimensions: !(model?.height == null || model?.height === 0),
                              },
                          }
                        : acc,
                dataFieldIdToDimensionMap
            )
        );
    };

    const { data: listItems, isLoading: isLoadingListItems } = useListItemsForFormula(selectedDataField, addListItemsToStyleMapping);

    return (
        <CustomDialog open maxWidth="lg" onClose={onClose}>
            {showModelSelector != null ? (
                <ModelAttributePicker
                    close={() => setShowModelSelector(null)}
                    selectedModelId={modelsWithThumbnails[0]?.thumbnailAssetId}
                    handleModelUpdate={(model) => handleUpdatedModel(model, showModelSelector)}
                    getModel={getModel}
                />
            ) : (
                <ModelAttributeStyleValues
                    dataFieldIdToDimensionMap={dataFieldIdToDimensionMap}
                    setDataFieldIdToDimensionMap={setDataFieldIdToDimensionMap}
                    type={type}
                    handleConfirm={handleOnConfirm}
                    onClose={onClose}
                    setShowModelSelector={(listItemId) => setShowModelSelector(listItemId)}
                    loading={isLoadingListItems}
                    selectedDataField={selectedDataField}
                    listItems={listItems}
                >
                    <AttributeSelector
                        dataFields={listOnlyDataFields}
                        selectedDataField={selectedDataField}
                        setDataField={selectedDataFieldChange}
                        label={type === "model" ? translate(Content.map6.styles.models.determineModel) : translate(Content.map6.styles.models.determineDimensions)}
                    />
                    {!isLoadingListItems && listItems.length > MAX_LIST_ITEMS_BEFORE_SEARCH_REQUIRED && (
                        <AutocompleteWithLoading
                            placeholder={translate(Content.map.data_fields.data_driven_formula.searchListItem)}
                            label={translate(Content.map.data_fields.data_driven_formula.addListItem)}
                            loadingOptions={false}
                            onChange={(listItem) => addListItemsToStyleMapping([listItem])}
                            options={listItems != null ? listItems.filter((l) => dataFieldIdToDimensionMap[l.id] == null) : []}
                            value={null}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            className="listItemSearch"
                        />
                    )}
                </ModelAttributeStyleValues>
            )}
        </CustomDialog>
    );
};

const ModelAttributePicker = ({
    close,
    selectedModelId,
    handleModelUpdate,
    getModel,
}: {
    close: () => void;
    selectedModelId: string;
    handleModelUpdate: (asset: ModelWithThumbnail) => void;
    getModel?: (modelId: string) => Promise<Model>;
}) => {
    const imageGetter = useAssetSignatureCache();
    const { assetService, getModels } = useContext(EditStyleContext);
    const translate = useIventisTranslate();
    const { data: models } = useQuery([getModelQueryKey], () => getModels());

    const onAssetSelected = async (modelThumbnail: Asset) => {
        const model = models.find((model) => model.thumbnailAssetId === modelThumbnail.id);
        handleModelUpdate({ ...model, thumbnailUrl: imageGetter(modelThumbnail.assetUrl, modelThumbnail.authoritySignature) });
    };

    return (
        <AssetPickerComponent
            assetType={AssetType.Model}
            currentAssetId={selectedModelId}
            onAssetSelected={onAssetSelected}
            imageUrlGetter={(asset) => imageGetter(asset.assetUrl, asset.authoritySignature)}
            selectorDescription={translate(Content.map2.styles2.choose_model)}
            close={() => close()}
            getAsset={assetService.getAsset}
            getAssetTags={assetService.getAssetTags}
            getAssetsByType={assetService.getAssetsByType}
            suppressInfiniteFetching={!assetService.assetPagination}
            postIcon={assetService.postIcon}
            patchIcon={assetService.patchIcon}
            postModel={assetService.postModel}
            canEditAsset={assetService.canEditAsset}
            deleteAsset={assetService.deleteAsset}
            hideAssetActions={assetService.hideAssetSelectorActions}
            getModel={getModel}
            updateModel={assetService.updateModel}
        />
    );
};

export const ModelAttributeStyleValues = ({
    dataFieldIdToDimensionMap,
    setDataFieldIdToDimensionMap,
    type,
    handleConfirm,
    onClose,
    setShowModelSelector,
    loading,
    selectedDataField,
    children,
    listItems,
}: PropsWithChildren<{
    dataFieldIdToDimensionMap: ListItemToDimension;
    setDataFieldIdToDimensionMap: (value: ListItemToDimension) => void;
    selectedDataField: DataField;
    type: ModelAttributeBasedModalStatus;
    onClose: () => void;
    handleConfirm: () => void;
    setShowModelSelector: (listItemId: string) => void;
    loading: boolean;
    listItems: DataFieldListItem[];
}>) => {
    const translate = useIventisTranslate();

    const handleValueChanged = (value: number, listItemId: string, property: "height" | "width" | "length") => {
        setDataFieldIdToDimensionMap({ ...dataFieldIdToDimensionMap, [listItemId]: { ...dataFieldIdToDimensionMap[listItemId], [property]: value } });
    };

    const isValid = useMemo(
        () =>
            Object.values(dataFieldIdToDimensionMap).every((value) =>
                value.dimensions ? dimensionValidation({ height: value.height, length: value.length, width: value.width }) : true
            ) && selectedDataField != null,
        [dataFieldIdToDimensionMap, selectedDataField]
    );

    return (
        <>
            {/* Component is a non-typed prop for MUI DialogTitle - https://stackoverflow.com/questions/70441304/how-do-i-make-the-mui-dialog-title-an-h1-element-so-the-modal-is-accessible */}
            <DialogTitle {...{ component: "div" }}>
                <Header3>{translate(Content.map.data_fields.data_driven_formula.title)}</Header3>
                {type === "model" ? (
                    <Body2>{translate(Content.map6.styles.models.attributeModel)}</Body2>
                ) : (
                    <Body2>{translate(Content.map6.styles.models.attributeDimensions)}</Body2>
                )}
            </DialogTitle>
            <Divider />
            <ModelAttributeBasedDimensionContainer type={type}>
                {children}
                {/* List all list items with a set of dimensions and at the bottom have the default value */}
                {selectedDataField != null && !loading && (
                    <div className="attribute-row-container">
                        {Object.entries(dataFieldIdToDimensionMap)
                            .sort(([listItemId]) => (listItemId === "static" ? 1 : -1))
                            .reduce((acc, [listItemId, value]) => {
                                const listItem =
                                    listItemId === "static"
                                        ? ({ id: listItemId, name: translate(Content.map2.data_driven.any_others) } as DataFieldListItem)
                                        : listItems.find((value) => value.id === listItemId);
                                if (listItem == null) {
                                    return acc;
                                }
                                return [
                                    ...acc,
                                    <div className="attribute-row" data-testid="model-attribute-row" key={listItemId}>
                                        <Body1 className="list-attribute-name">{listItem.name}</Body1>
                                        {type === "model" && (
                                            <AssetPreviewButton
                                                className="model-preview-button"
                                                img={value.thumbnailUrl}
                                                name={value.modelName}
                                                onClick={() => setShowModelSelector(listItemId)}
                                                selectorDescription={translate(Content.map2.styles2.choose_model)}
                                            />
                                        )}
                                        {(type === "dimensions" || value?.dimensions) && (
                                            <ModelSizingComponent values={value} onValueChanged={(property, value) => handleValueChanged(value, listItemId, property)} />
                                        )}
                                    </div>,
                                ];
                            }, [])}
                    </div>
                )}
            </ModelAttributeBasedDimensionContainer>
            <Divider />
            <DialogActions>
                <FormButtonsComponent
                    handleSubmit={handleConfirm}
                    submitButtonText={<span data-testid="formula-submit-button">{translate(Content.common.buttons.submit)}</span>}
                    secondaryButtons={[{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: onClose }]}
                    disableSubmit={() => !isValid}
                />
            </DialogActions>
        </>
    );
};

export const DialogTitle = styled(DialogTitleMUI)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .subtitle {
        ${({ theme }: { theme: Theme }) => theme.shades.two}
    }
`;

const ModelAttributeBasedDimensionContainer = styled(DialogContentMUI)<{ type: ModelAttributeBasedModalStatus }>`
    ${muiInputFormsCSS};
    scrollbar-gutter: stable both-edges;
    display: flex;
    flex-direction: column;
    width: ${({ type }) => (type === "model" ? "800px" : "700px")};
    height: 500px;
    padding: ${formPadding};
    // By default MUI dialog has a padding override, so need to explicitly set padding for top
    padding-top: ${formPadding};
    gap: ${sectionalMargin};

    .attribute-row-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 30px;
    }

    .attribute-row {
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: ${({ type }) => (type === "model" ? "flex-start" : "space-between")};
        gap: 30px;
    }

    .list-attribute-name {
        width: 100px;
        max-height: 100%;
        overflow: hidden;
    }

    .model-preview-button {
        width: 150px;
        height: auto;
    }
`;

const DialogActions = styled(DialogActionsMUI)`
    margin: 20px;
`;
