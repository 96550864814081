import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { Content } from "@iventis/translations/content/typed-content";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import React, { FunctionComponent } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { PointStyles } from "@iventis/map-engine/src/utilities/style-helpers";
import { selectItemsComponentCreator } from "@iventis/components";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { EditStyleProps } from "./style.types";
import { ChangeStyleValue } from "./edit-style.helpers";
import { IconEditStyleComponent } from "./icon-edit-style";
import { StyledStyleItemContainer } from "./line-edit-style";
import ModelEditStyleComponent from "./model-edit-style";
import { PointEditStyleComponent } from "./point-edit-style";

export const PointEditStyleDelegator: FunctionComponent<
    {
        onStyleTypeChanged: (styleType: StyleType) => void;
    } & EditStyleProps<PointStyles>
> = ({ layerStyle, onStyleTypeChanged, changeStyleValue, dataFields }) => {
    const translate = useIventisTranslate();
    const changeStyleTypeValue: ChangeStyleValue<PointStyles> = (_, value) => onStyleTypeChanged((value as StyleValue<StyleType>).staticValue.staticValue);
    return (
        <>
            <StyleContainer title={translate(Content.map2.styles.type_of_point)}>
                <StyledStyleItemContainer>
                    <EditStyleItemComponent
                        styleProperty="styleType"
                        value={createStaticStyleValue(layerStyle.styleType)}
                        changeStyleValue={changeStyleTypeValue}
                        component={selectItemsComponentCreator({
                            testId: "style-type-selector",
                            options: [
                                { id: StyleType.Point, name: translate(StyleType.Point) },
                                { id: StyleType.Icon, name: translate(StyleType.Icon) },
                                { id: StyleType.Model, name: translate(StyleType.Model) },
                            ],
                        })}
                        ignoreTitle
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            {layerStyle.styleType === StyleType.Icon && <IconEditStyleComponent layerStyle={layerStyle as IconStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />}
            {layerStyle.styleType === StyleType.Point && (
                <PointEditStyleComponent layerStyle={layerStyle as PointStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
            )}
            {layerStyle.styleType === StyleType.Model && (
                <ModelEditStyleComponent layerStyle={layerStyle as ModelStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
            )}
        </>
    );
};
