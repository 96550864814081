import { FeatureCollection, LineString, Point, Polygon } from "geojson";

export enum ValidationError {
    None = "None",
    InvalidJson = "InvalidJson",
    InvalidGPX = "InvalidGPX",
    InvalidParsedGPX = "InvalidParsedGPX",
    InvalidKML = "InvalidKML",
    InvalidParsedKML = "InvalidParsedKML",
    TooLarge = "TooLarge",
    InvalidGeoJson = "InvalidGeoJson",
    InvalidLayerType = "InvalidLayerType",
    InvalidCoordinates = "InvalidCoordinates",
    InvalidCoordinateSystem = "InvalidCoordinateSystem",
    GeometryTypeNotHandled = "GeometryTypeNotHandled",
}

interface SuccessParsedJson {
    error: ValidationError.None;
    parsedJson: Object;
}
interface ErrorParsedJson {
    error: ValidationError.InvalidJson;
    parsedJson: undefined;
}

export type ParsedJsonResponse = SuccessParsedJson | ErrorParsedJson;
interface ErrorParsedGPX {
    error: ValidationError.InvalidGPX;
    features: undefined;
    filename: string;
}

interface SuccessParsedGPX {
    error: ValidationError.None;
    features: Object;
    filename: string;
}

export type ParsedGPXResponse = SuccessParsedGPX | ErrorParsedGPX;

interface SuccessParsedGeoJson {
    error: ValidationError.None;
    parsedGeoJson: FeatureCollection<Point | LineString | Polygon>;
}

interface ErrorParsedGeoJson {
    error: ValidationError.InvalidGeoJson;
    parsedGeoJson: undefined;
}

export type ParsedGeoJsonResponse = SuccessParsedGeoJson | ErrorParsedGeoJson;

export interface SuccessValidation {
    error: ValidationError.None;
    features: FeatureCollection<LineString | Point | Polygon>;
    importFeatureType?: never;
    fileName: string;
}

export interface ErrorValidationFeatureType {
    error: ValidationError.InvalidLayerType;
    importFeatureType: "Point" | "Line" | "Area" | "Unknown Type";
}

export interface ErrorValidation {
    error:
        | ValidationError.InvalidJson
        | ValidationError.InvalidGPX
        | ValidationError.InvalidParsedGPX
        | ValidationError.TooLarge
        | ValidationError.InvalidGeoJson
        | ValidationError.InvalidCoordinates
        | ValidationError.GeometryTypeNotHandled
        | ValidationError.InvalidCoordinateSystem
        | ValidationError.InvalidKML
        | ValidationError.InvalidParsedKML;
}

export type ValidationResponse = SuccessValidation | ErrorValidationFeatureType | ErrorValidation;

export type CoordinateReferenceSystem = { type: "name"; properties: { name: string } };
