import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Asset } from "@iventis/domain-model/model/asset";
import { useTreeBrowser } from "@iventis/tree-browser";
import { toast } from "@iventis/toasts/src/toast";
import { downloadJson, getAssetSignature } from "@iventis/utilities";
import { AdminMapNode, AdminSpatialLibraryNode } from "./tree-browser-types";
import { BasicSpatialNode } from "./basic-spatial-node";
import { TreeBrowserProjectsContext } from "./project.helpers";
import { api } from "../api/api";

export interface SpatialLibraryNodeProps {
    node: AdminSpatialLibraryNode;
}

interface MapNodeProps {
    node: AdminMapNode;
}

/** Total amount of time we allow for an export to take place - 5 Minutes */
const TOTAL_EXPORT_WAIT_TIME = 300000;

/** Attempt to download the file again every - 10 Seconds */
const RETRY_ATTEMPT = 10000;

const AMOUNT_OF_RETRIES = TOTAL_EXPORT_WAIT_TIME / RETRY_ATTEMPT;

export const MapNodeComponent = ({ node }: MapNodeProps) => {
    const [{ tree }] = useTreeBrowser(TreeBrowserProjectsContext, node.id, "tree");

    /** Attempt to download json file for export */
    const handleDownloadJson = async (asset: Asset, retry = 0) => {
        const success = await downloadJson(getAssetSignature(asset.assetUrl, asset.authoritySignature), asset.name);
        if (!success) {
            await handleDownloadJsonRetry(asset, retry);
        }
    };

    /** Attempt to download the JSON file again every 10 seconds */
    const handleDownloadJsonRetry = async (asset: Asset, retry: number) => {
        // If under amount of retries, try again
        if (retry < AMOUNT_OF_RETRIES) {
            toast.info({
                title: "Alert",
                message: `Export is not ready yet, we will try again in ${RETRY_ATTEMPT / 1000} seconds`,
            });
            // Wait for set period of time and attempt to download again
            await new Promise((resolve) => setTimeout(resolve, RETRY_ATTEMPT));
            await handleDownloadJson(asset, retry + 1);
        } else {
            // If over amount of retries, show error and don't retry
            toast.error({ title: "Alert", message: "There is an error exporting the map" });
        }
    };

    return (
        <BasicSpatialNode
            node={node}
            allowExpand={false}
            icon={<FontAwesomeIcon icon={{ prefix: "far", iconName: "map" }} className="icon" size="lg" />}
            overflowOptions={[
                {
                    label: "Export map",
                    selected: async () => {
                        const asset = (await api.post<Asset>(`instances/${tree.id}/projects/${node.projectId}/maps/${node.id}/exports`)).data;
                        toast.info({ title: "Export has started", message: "Export has started and you will be notified when it is complete" });
                        // As we do not have web sockets implemented, and the map will likely not be ready straight away, we wait 10 seconds
                        await new Promise((resolve) => setTimeout(resolve, RETRY_ATTEMPT));
                        toast.success({
                            title: "Export has completed",
                            message: "Export has completed successfully, click the button below to download the file",
                            button: { icon: ["fas", "download"], label: "Download" },
                            onClick: () => handleDownloadJson(asset),
                        });
                    },
                    key: "export-map",
                },
            ]}
        />
    );
};
