/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from "react";
import { Autocomplete, AutocompleteRenderGroupParams, InputProps, TextField, TextFieldProps, UseAutocompleteProps } from "@mui/material";
import { inlineTextIconMargin } from "@iventis/styles/src/atomic-rules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";

export const extractValue = (value: any, property = "id") => {
    if (value == null) {
        return null;
    }
    if (typeof value === "string" || typeof value === "number") {
        return value;
    }

    return value[property];
};

export interface AutocompleteWithLoadingProps<T = any> {
    label?: string | ReactNode;
    placeholder?: string;
    helperText?: string;
    value: T | null;
    options: T[];
    onChange: (value: T) => void;
    className?: string;
    loadingOptions: boolean;
    disabled?: boolean;
    error?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    renderOption?: (props: React.HTMLAttributes<unknown>, option: T) => React.ReactNode;
    renderAdornment?: (option: T) => React.ReactNode;
    getOptionLabel: (option: T) => string;
    getOptionSelected?: (option: T, selected: T) => boolean;
    style?: React.CSSProperties;
    disableClearable?: boolean;
    autoFocus?: boolean;
    disablePortal?: boolean;
    variant?: TextFieldProps["variant"];
    id?: string;
    filterOptions?: UseAutocompleteProps<T, false, boolean, false>["filterOptions"];
    groupBy?: (option: T) => string;
    renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
    getOptionDisabled?: (p: T) => boolean;
    blurOnSelect?: boolean;
    dataTestId?: string;
}
export function AutocompleteWithLoading<T extends any>({
    label,
    placeholder,
    helperText,
    onChange,
    className,
    loadingOptions,
    disabled = false,
    options,
    error,
    value,
    renderOption,
    renderAdornment,
    getOptionLabel,
    getOptionSelected = (option: T, value: T) => extractValue(option) === extractValue(value),
    onBlur,
    style,
    disableClearable = false,
    autoFocus = false,
    disablePortal,
    variant = "outlined",
    id,
    filterOptions,
    groupBy,
    renderGroup,
    getOptionDisabled,
    blurOnSelect = false,
    dataTestId,
}: React.PropsWithChildren<AutocompleteWithLoadingProps<T>>) {
    return (
        <Autocomplete<T, false, boolean>
            groupBy={groupBy}
            renderGroup={renderGroup}
            style={style}
            className={className}
            id={id}
            disablePortal={disablePortal}
            disabled={loadingOptions || disabled}
            value={value ?? null}
            autoComplete
            autoHighlight
            openOnFocus
            filterOptions={filterOptions}
            disableClearable={disableClearable}
            handleHomeEndKeys
            onBlur={onBlur}
            options={options}
            blurOnSelect={blurOnSelect}
            renderOption={renderOption ? (props, option) => renderOption(props, option) : null}
            getOptionLabel={(option: T) => (getOptionLabel ? getOptionLabel(option) : null)}
            isOptionEqualToValue={getOptionSelected}
            onChange={(_, value: T) => onChange(value)}
            getOptionDisabled={getOptionDisabled}
            renderInput={(params) => (
                <>
                    {label && (typeof label === "string" ? <StyledFieldLabel>{label}</StyledFieldLabel> : label)}
                    <TextField
                        {...params}
                        InputProps={
                            ({
                                ...params.InputProps,
                                ...((loadingOptions && {
                                    startAdornment: <FontAwesomeIcon icon={["fas", "circle-notch"]} spin style={{ marginRight: inlineTextIconMargin }} />,
                                }) || {
                                    startAdornment: renderAdornment ? renderAdornment(value) : null,
                                }),
                                "data-testid": dataTestId,
                            } as unknown) as Partial<InputProps>
                        }
                        variant={variant}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        fullWidth
                        error={!!error}
                        helperText={helperText}
                    />
                </>
            )}
        />
    );
}
