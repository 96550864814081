import { IventisExportedGroup } from "@iventis/domain-model/model/iventisExportedGroup";
import { DataField } from "@iventis/domain-model/model/dataField";
import { v4 as uuid } from "uuid";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { replaceDataFieldAndMapObjectIdsForLayer } from "./map-json-datafields";
import { ImportIventisMap, ImportMapApiFunctions, MapImportLayer } from "./map-json-types";
import { MissingAsset, replaceAssetsInMap } from "./map-json-import-assets";
import { ensureLayerStylesAreValid } from "./map-json-import-layer-styles";

type UpdatedIdsMap = { [previousId: string]: string };

/** Parses layer and groups, giving them updated ids and parent ids */
export async function replaceImportMapLayerAndGroupIds(
    importedMap: ImportIventisMap,
    newMapId: string,
    missingAssets: MissingAsset[],
    projectDataFields: DataField[],
    sitemaps: Sitemap[],
    selectedSitemapVersions: Record<string, string>,
    apiFunctions: ImportMapApiFunctions
) {
    const { layers, groups } = importedMap;
    // Create a key value pair for each layer with old and new id
    const updatedLayerIds: UpdatedIdsMap = {};
    layers.forEach((layer) => {
        updatedLayerIds[layer.id] = uuid();
    });

    // Create a key value pair for each group with old and new id
    const updatedGroupIds: UpdatedIdsMap = {};
    groups.forEach((group) => {
        updatedGroupIds[group.id] = uuid();
    });

    const updatedMapId = { [importedMap.id]: newMapId };

    const assetReplacedLayers = replaceAssetsInMap(layers, missingAssets);
    const updatedLayerWithNewIds = replaceImportedLayerIds(assetReplacedLayers, updatedLayerIds, updatedGroupIds, updatedMapId);
    const layersWithValidStyles = ensureLayerStylesAreValid(updatedLayerWithNewIds);
    const updatedLayersWithNewDataFields = await Promise.all(
        layersWithValidStyles.map((layer) => replaceDataFieldAndMapObjectIdsForLayer(layer, projectDataFields, sitemaps, selectedSitemapVersions, apiFunctions))
    );

    // Parse the layer and groups
    return { layers: updatedLayersWithNewDataFields, groups: replaceGroupIdsAndParentIds(groups, updatedGroupIds, updatedMapId) };
}

/** Replaces id and parentId of each layer with updated id */
function replaceImportedLayerIds(layers: MapImportLayer[], updatedLayerIds: UpdatedIdsMap, updatedGroupIds: UpdatedIdsMap, updatedMapId: UpdatedIdsMap): MapImportLayer[] {
    // For now map the assets and data fields to blank arrays
    return layers.map((layer) => ({
        ...layer,
        id: updatedLayerIds[layer.id],
        parentId: updatedGroupIds[layer.parentId] ?? updatedMapId[layer.parentId],
    }));
}

/** Replaces id and parentId of each group with updated id */
function replaceGroupIdsAndParentIds(groups: IventisExportedGroup[], updatedGroupIds: UpdatedIdsMap, updatedMapId: UpdatedIdsMap): IventisExportedGroup[] {
    return groups.map((group) => ({ ...group, id: updatedGroupIds[group.id], parentId: updatedGroupIds[group.parentId] ?? updatedMapId[group.parentId] }));
}

/** Converts a MapImportLayer to our domain model MapLayer. Does NOT convert data fields */
export function mapImportLayerToMapLayer(importLayer: MapImportLayer): MapLayer {
    const mapLayer = {
        id: importLayer.id,
        name: importLayer.name,
        sidebarOrder: importLayer.sidebarOrder,
        visible: importLayer.visible,
        styleType: importLayer.styleType,
        dataFields: [],
    } as MapLayer;

    switch (importLayer.styleType) {
        case StyleType.Area:
            mapLayer.areaStyle = importLayer.style as AreaStyle;
            break;
        case StyleType.Line:
            mapLayer.lineStyle = importLayer.style as LineStyle;
            break;
        case StyleType.Point:
            mapLayer.pointStyle = importLayer.style as PointStyle;
            break;
        case StyleType.Icon:
            mapLayer.iconStyle = importLayer.style as IconStyle;
            break;
        case StyleType.Model:
            mapLayer.modelStyle = importLayer.style as ModelStyle;
            break;
        case StyleType.LineModel:
            mapLayer.lineModelStyle = importLayer.style as LineModelStyle;
            break;
        default:
            throw new Error(`Unknown style type`);
    }

    return mapLayer;
}
