import { FormWizardTemplate, ValueSelector, ValueSelectorComponentCreator, CustomRadioSelector, CustomDialog } from "@iventis/components";
import TextValueInputComponent from "@iventis/components/src/selectors/text-value-input";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { ZoomValue } from "@iventis/domain-model/model/zoomValue";
import { Body2, gapWithRoomForErrorMessage, Header5, HorizontalGap, media, styled } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useThrottledCallback } from "@iventis/utilities";
import { Button, Checkbox } from "@mui/material";
import React, { FunctionComponent, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { defaultMappedZoomValues, defaultValue } from "@iventis/map-engine/src/utilities/default-style-values";
import { PredefinedZoomLevels } from "@iventis/map-engine/src/bridge/constants";

interface ZoomOptionsModalProps {
    component: ReturnType<ValueSelectorComponentCreator<ValueSelector<unknown>>>;
    changeValue: (newValue: ZoomableValue<unknown>) => void;
    value: ZoomableValue<unknown>;
    onClose: () => void;
    open: boolean;
    showHiddenOptions?: boolean;
}

interface ZoomLevelData {
    id: string;
    zoomLevel: number;
    label?: React.ReactNode;
    value: unknown;
    hidden: boolean;
    lockHidden: boolean;
}

const ZoomOptionsModal: FunctionComponent<ZoomOptionsModalProps> = ({ component, changeValue, value, open, onClose, showHiddenOptions = false }) => {
    const hasPredefinedZoomLevels = useMemo(
        () => Object.values(PredefinedZoomLevels).every((predefinedZoom) => Object.keys(value.mappedZoomValues).includes(`${predefinedZoom}`)),
        [value.mappedZoomValues]
    );

    const [zoomType, setZoomType] = useState(value.userDefinedZoomOptions || !hasPredefinedZoomLevels ? "custom" : "static");

    const translate = useIventisTranslate();

    const predefinedLabelMap = {
        [PredefinedZoomLevels.max]: <Body2>{translate(Content.map3.zoomableValues.max_level)}</Body2>,
        [PredefinedZoomLevels.street]: <Body2>{translate(Content.map3.zoomableValues.street_level)}</Body2>,
        [PredefinedZoomLevels.city]: <Body2>{translate(Content.map3.zoomableValues.city_level)}</Body2>,
        [PredefinedZoomLevels.country]: <Body2>{translate(Content.map3.zoomableValues.country_level)}</Body2>,
        [PredefinedZoomLevels.world]: <Body2>{translate(Content.map3.zoomableValues.world_level)}</Body2>,
    };

    const generateZoomLevelData = (mappedZoomValues: Record<number, ZoomValue<unknown>>, userDefined: boolean = value.userDefinedZoomOptions || !hasPredefinedZoomLevels) =>
        Object.entries(mappedZoomValues).map(
            (entry) =>
                ({
                    id: uuid(),
                    zoomLevel: Number.parseFloat(entry[0]),
                    value: entry[1].value,
                    hidden: showHiddenOptions ? entry[1].hidden : false,
                    label: userDefined ? undefined : predefinedLabelMap[Number.parseFloat(entry[0])],
                    lockHidden: false,
                } as ZoomLevelData),
            []
        );

    const [zoomLevelDataWithChanges, setZoomLevelDataWithChanges] = useState<ZoomLevelData[]>(
        validateHidden(generateZoomLevelData(value.mappedZoomValues ?? defaultMappedZoomValues(0)))
    );

    const valueSelectors = useMemo(
        () =>
            zoomLevelDataWithChanges.map((zoomLevelData) => (
                <ZoomLevelAdjustmentComponent
                    testid={`zoom-modal-adjustment-component-${zoomLevelData.zoomLevel}`}
                    component={component}
                    zoomLevelData={zoomLevelData}
                    changeValue={(newValue: ZoomLevelData) => {
                        const newData = zoomLevelDataWithChanges.map((data) => {
                            if (data === zoomLevelData) {
                                return newValue;
                            }
                            return data;
                        });
                        setZoomLevelDataWithChanges(validateHidden(newData));
                    }}
                    key={zoomLevelData.id}
                    showHiddenOption={showHiddenOptions}
                />
            )),
        [zoomLevelDataWithChanges]
    );

    return (
        <CustomDialog open={open} data-testid="zoom-modal-container" onClose={onClose}>
            <FormWizardTemplate
                currentStage={0}
                stages={[
                    {
                        isValid: zoomLevelDataWithChanges?.length > 0,
                        primaryButtonText: translate(Content.common.buttons.confirm),
                        primaryButtonCallback: () => {
                            changeValue({
                                ...value,
                                extractionMethod: ZoomableValueExtractionMethod.Continuous,
                                userDefinedZoomOptions: zoomType === "custom",
                                mappedZoomValues: zoomLevelDataWithChanges.reduce(
                                    (cum, value) => ({ ...cum, [value.zoomLevel]: { value: value.value, hidden: value.hidden } }),
                                    {}
                                ),
                            });
                            onClose();
                        },
                        submitButtonDataCy: "zoom-level-modal-submit-button",
                        secondaryButtons: [
                            {
                                buttonText: translate(Content.common.buttons.cancel),
                                onButtonPressed: () => {
                                    onClose();
                                },
                            },
                        ],
                    },
                ]}
                title={translate(Content.map3.zoomableValues.edit_zoom_levels)}
            >
                <>
                    <Header5>{translate(Content.map3.zoomableValues.zoom_options)}</Header5>
                    <StyledSelectorContainer data-testid="zoom-options-radio-selector">
                        <StyledRadioSelector
                            options={[
                                { id: "static", name: translate(Content.map3.zoomableValues.predefined) },
                                { id: "custom", name: translate(Content.map3.zoomableValues.custom) },
                            ]}
                            onValueChange={(event, newZoomType) => {
                                setZoomType(newZoomType);

                                if (newZoomType === "static") {
                                    if (value.userDefinedZoomOptions || !hasPredefinedZoomLevels) {
                                        // If we have selected to use predefined zoom values but we were using custom zoom values,
                                        // we want to generate new zoom values based on the default zoom levels
                                        setZoomLevelDataWithChanges(generateZoomLevelData(defaultMappedZoomValues(value.staticValue as number | string | boolean), false));
                                    } else {
                                        // If we have selected to use predefined zoom values and we were not using custom zoom values,
                                        // Then use the value's mapped zoom values if it isn't undefined, always fall back to the default
                                        setZoomLevelDataWithChanges(
                                            generateZoomLevelData(value.mappedZoomValues ?? defaultMappedZoomValues(value.staticValue as number | string | boolean), false)
                                        );
                                    }
                                } else if (newZoomType === "custom") {
                                    if (value.userDefinedZoomOptions || !hasPredefinedZoomLevels) {
                                        // If we have selected to use custom zoom values and we were using custom zoom values,
                                        // Then use the value's mapped zoom values if it isn't undefined, always fall back to the default
                                        setZoomLevelDataWithChanges(
                                            generateZoomLevelData(value.mappedZoomValues ?? defaultMappedZoomValues(value.staticValue as number | string | boolean), true)
                                        );
                                    } else {
                                        // If we have selected to use custom zoom values but we were not using custom zoom values,
                                        // Then wipe all zoom values to let the user add their own
                                        setZoomLevelDataWithChanges([]);
                                    }
                                }
                            }}
                            row
                            defaultValueId={zoomType}
                        />
                    </StyledSelectorContainer>
                    <Header5>{translate(Content.map3.zoomableValues.zoom_levels)}</Header5>
                    {valueSelectors}
                    {zoomType === "custom" && (
                        <>
                            <HorizontalGap height={gapWithRoomForErrorMessage} />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    const newZoomLevelData: ZoomLevelData = {
                                        id: uuid(),
                                        zoomLevel: 0,
                                        value: value.staticValue,
                                        hidden: false,
                                        lockHidden: false,
                                    };
                                    setZoomLevelDataWithChanges([...zoomLevelDataWithChanges, newZoomLevelData]);
                                }}
                                data-testid="add-zoom-level-button"
                            >
                                {translate(Content.map3.zoomableValues.add_zoom_level)}
                            </Button>
                        </>
                    )}
                </>
            </FormWizardTemplate>
        </CustomDialog>
    );
};

interface ZoomLevelAdjustmentComponentProps {
    component: ReturnType<ValueSelectorComponentCreator<ValueSelector<unknown>>>;
    zoomLevelData: ZoomLevelData;
    changeValue: (newData: ZoomLevelData) => void;
    throttleMs?: number;
    className?: string;
    testid?: string;
    showHiddenOption?: boolean;
}

const ZoomLevelAdjustmentComponent: FunctionComponent<ZoomLevelAdjustmentComponentProps> = ({
    component,
    zoomLevelData,
    changeValue,
    throttleMs = 200,
    className,
    testid,
    showHiddenOption = false,
}) => {
    const translate = useIventisTranslate();

    const throttle = useThrottledCallback(changeValue, throttleMs);

    const handleChange = (newValue: ZoomLevelData) => {
        throttle(newValue);
    };

    return (
        <StyledZoomSelectorContainer className={className} data-testid={testid}>
            {zoomLevelData.label || (
                <StyledInput
                    inputValue={zoomLevelData.zoomLevel}
                    minValue={0}
                    maxValue={22}
                    emitValueChange={(output) => {
                        handleChange({ ...zoomLevelData, zoomLevel: output });
                    }}
                />
            )}
            <div>
                {component({
                    value: zoomLevelData.value,
                    changeValue: (value: unknown) => {
                        handleChange({ ...zoomLevelData, value });
                    },
                    disabled: zoomLevelData.hidden,
                    className: "value-selector-component",
                })}
            </div>
            {showHiddenOption && (
                <RowContainer>
                    <Checkbox
                        checked={zoomLevelData.hidden}
                        onClick={() => {
                            const newValue = !zoomLevelData.hidden;
                            handleChange({ ...zoomLevelData, hidden: newValue, value: newValue ? defaultValue(typeof zoomLevelData.value) : zoomLevelData.value });
                        }}
                        disabled={zoomLevelData.lockHidden}
                    />
                    <Body2>{translate(Content.map3.zoomableValues.hide)}</Body2>
                </RowContainer>
            )}
        </StyledZoomSelectorContainer>
    );
};

const StyledRadioSelector = styled(CustomRadioSelector)`
    flex-wrap: nowrap;
    width: 100%;
`;

const StyledSelectorContainer = styled.div`
    width: 100%;
`;

const StyledZoomSelectorContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 50% 25%;
    box-sizing: border-box;
    column-gap: 10px;
    align-items: center;
    margin: 10px 0px 10px 0;
    width: 100%;

    :nth-child(1) {
        grid-column: 0;
    }

    :nth-child(2) {
        grid-column: 1;
    }

    :nth-child(3) {
        grid-column: 2;
    }

    .value-selector-component {
        justify-content: center;
    }

    ${media.medium} {
        width: 400px;
    }
`;

const StyledInput = styled(TextValueInputComponent)`
    .adornment {
        display: none;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

/** Makes all zoom levels that are lower than the highest zoom level that is hidden also hidden. It also locks them */
function validateHidden(data: ZoomLevelData[]) {
    // get the highest zoom level that is hidden
    const highestHidden = Math.max(...data.reduce((cum, { hidden, zoomLevel }) => (hidden ? [...cum, zoomLevel] : cum), [0] as number[]));

    return data.map((zoomData) => {
        if (zoomData.zoomLevel < highestHidden) {
            return {
                ...zoomData,
                hidden: true,
                lockHidden: true,
            };
        }
        return {
            ...zoomData,
            lockHidden: false,
        };
    });
}

export default ZoomOptionsModal;
