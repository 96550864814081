import { FillLayerSpecification, FillExtrusionLayerSpecification, LineLayerSpecification, SymbolLayerSpecification, CircleLayerSpecification, LayerSpecification } from "mapbox-gl";

type IventisMapboxLayer = FillLayerSpecification | FillExtrusionLayerSpecification | LineLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification;

interface MapboxLayerMetaData {
    name?: string;
    type?: "base" | "arrows" | "text" | "outline" | null;
}

export type IventisMapboxTestLayer = IventisMapboxLayer & { metadata?: MapboxLayerMetaData };

export function isIventisTestLayer(layer: LayerSpecification): layer is IventisMapboxLayer & { metadata: MapboxLayerMetaData } {
    if (layer == null || layer.metadata == null || typeof layer.metadata !== "object") {
        return false;
    }

    const metadata = layer.metadata as Record<string, unknown>;
    const hasName = typeof metadata.name === "string";
    return hasName;
}
