import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, SxProps, TextField } from "@mui/material";
import { fontSizes, inputHeight, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import React, { FunctionComponent, useMemo } from "react";
import { CloseButton } from "@iventis/components";
import { closeButton } from "@iventis/styles/src/atomic-rules";

interface SearchBarProps {
    placeHolderText: string;
    debounceMs?: number;
    className?: string;
    fontSize?: keyof typeof fontSizes;
    autoFocus?: boolean;
    loading?: boolean;
    value: string;
    onValueChange: (value: string) => void;
    disabled?: boolean;
    testid?: string;
    sx?: SxProps;
    onFocus?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void;
}

export const SearchBarComponent: FunctionComponent<SearchBarProps> = ({
    placeHolderText,
    onValueChange,
    value,
    className,
    fontSize = "medium",
    autoFocus = false,
    loading = false,
    disabled = false,
    testid,
    sx,
    onFocus,
}) => {
    const [focused, setFocused] = React.useState(autoFocus);

    const icon = useMemo(() => {
        if (loading) {
            return <FontAwesomeIcon icon={{ prefix: "fas", iconName: "circle-notch" }} className="icon fa-spin" />;
        }
        if (value !== "") {
            if (focused) {
                return <CloseButton onMouseDown={() => onValueChange("")} className="clear-text" />;
            }
            return null;
        }
        return <FontAwesomeIcon icon={{ prefix: "far", iconName: "magnifying-glass" }} className="icon" />;
    }, [loading, value, focused]);

    const onRenderCheckAutofocus = (element: HTMLInputElement) => {
        // According to MUI the autofocus is not reliable and therefore we need to manually focus the input
        if (autoFocus && element != null) {
            // https://github.com/mui/material-ui/issues/7247
            element.getElementsByTagName("input")[0].focus();
        }
    };

    return (
        <StyledTextField
            data-testid={testid}
            value={value ?? ""}
            placeholder={placeHolderText}
            variant="outlined"
            size="small"
            onChange={(event) => onValueChange(event.target.value)}
            className={className}
            disabled={disabled}
            onFocus={(e) => {
                setFocused(true);
                onFocus?.(e);
            }}
            onBlur={() => setFocused(false)}
            sx={sx}
            ref={onRenderCheckAutofocus}
            InputProps={{
                disabled,
                style: {
                    fontSize: fontSizes[fontSize],
                },
                endAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
            }}
        />
    );
};

const StyledTextField = styled(TextField)`
    width: 250px;

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }: { theme: Theme }) => theme.secondaryColors.lightGrey};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
    }

    &:focus-within .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
    }

    .MuiInputBase-root {
        background: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
        height: ${inputHeight};
    }

    .icon {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
    }

    .clear-text {
        position: relative;
        // Ensures the clear text button is in the same location as other icons
        width: ${closeButton.width};
        height: ${closeButton.height};
    }
`;
