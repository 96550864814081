import { MultiSelectDropdownComponent } from "@iventis/components";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { EMPTY_GUID } from "@iventis/utilities";
import React, { useEffect, useState } from "react";
import { createMultiStyleValue } from "@iventis/layer-style-helpers";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { StyledEditStyleItemContainer, StyledEditStyleTitleContainer } from "./edit-style-item";
import { ChangeStyleValue } from "./edit-style.helpers";
import { createDataDrivenStyleValue } from "./data-driven-styles.helpers";

const allowedDataFieldTypes = [DataFieldType.List, DataFieldType.Text, DataFieldType.Number, DataFieldType.Time, DataFieldType.TimeRange, DataFieldType.What3Words];

type EditDataDrivenStyleItemProps = {
    dataFields: DataField[];
    changeStyleValue?: ChangeStyleValue;
    value: StyleValue<string>;
    disabled?: boolean;
};

function ensureMultiStyleValue(styleValue: StyleValue<string>): StyleValue<string> {
    if (styleValue.extractionMethod === "Multi") {
        return styleValue;
    }
    const multiStyleValue = createMultiStyleValue<string>(EMPTY_GUID);
    multiStyleValue.multiValues = [styleValue];
    return multiStyleValue;
}

function getStyleForDataField(dataField: DataField) {
    if (allowedDataFieldTypes.some((t) => t === dataField.type) === false) {
        throw Error(`Attribute type ${dataField.type} is not supported for data driven text content`);
    }
    const newStyleValue = createDataDrivenStyleValue(
        dataField,
        {
            extractionMethod: ZoomableValueExtractionMethod.Static,
            staticValue: EMPTY_GUID,
            mappedZoomValues: {},
            unitType: Units.None,
        },
        dataField.type === DataFieldType.List ? [] : null
    );
    return newStyleValue;
}

/**
 * Component for setting or removing a data-driven style value for textContent.
 */
export function EditDataDrivenTextContent({ dataFields, value, changeStyleValue, disabled = false }: EditDataDrivenStyleItemProps) {
    const translate = useIventisTranslate();
    const [selectedValue, setSelectedValue] = useState<StyleValue<string>>(ensureMultiStyleValue(value));

    // This useEffect is used to ensure that the map object name is always selected if there is no other data field selected.
    // This is required for new layers
    useEffect(() => {
        if (selectedValue?.multiValues?.length === 0 && dataFields.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectName) != null) {
            changeValues([dataFields.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectName)]);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedValue != null) {
            changeValues(dataFields.filter((df) => selectedValue.multiValues.some((v) => v.dataFieldId === df.id)));
        }
    }, [dataFields]);

    const changeValues = (values: DataField[]) => {
        const newlySelected = values.filter((v) => !selectedValue.multiValues.some((sv) => sv.dataFieldId === v.id));
        const newStyles = [];
        newlySelected.forEach((dataField) => {
            newStyles.push(getStyleForDataField(dataField));
        });
        const removed = selectedValue.multiValues.filter((v) => !values.some((df) => df.id === v.dataFieldId));
        const changedStyleValues = [...selectedValue.multiValues, ...newStyles].filter((v) => !removed.some((r) => r.dataFieldId === v.dataFieldId));
        const newValue = { ...selectedValue, multiValues: changedStyleValues };
        setSelectedValue(newValue);
        changeStyleValue("textContent", newValue);
    };

    return (
        <StyledEditStyleItemContainer>
            <StyledEditStyleTitleContainer>
                <div id="title">{translate(Content.map2.styles.text_content)}</div>
            </StyledEditStyleTitleContainer>
            <MultiSelectDropdownComponent
                values={[...dataFields?.filter((df) => allowedDataFieldTypes.includes(df.type))]}
                disabled={disabled}
                selectedValues={dataFields.filter((df) => selectedValue.multiValues.some((v) => v.dataFieldId === df.id))}
                onValuesUpdate={changeValues}
                maximumValueSelected={3}
                minimumValueSelected={1}
                dataTestId="layer-text-content-selector"
            />
        </StyledEditStyleItemContainer>
    );
}
