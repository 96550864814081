/* eslint-disable @typescript-eslint/no-explicit-any */
import { bytesToMegaBytes, convertGPXToGeoJSON, fileToString, isObject } from "@iventis/utilities";
import { styleTypeToGeoJSONGeometryType } from "@iventis/map-engine/src/utilities/geojson-helpers";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { FeatureCollection, Point, LineString, Position, Polygon, Feature } from "geojson";
import { GPXOption } from "@iventis/types";
import JSZip from "jszip";
import { KmlToGeojson } from "./kml-to-geojson";
import { MAX_SIZE_MB, validCoordinateSystemNames } from "./layer-geojson-validation-constants";
import {
    ValidationError,
    ParsedJsonResponse,
    ParsedGeoJsonResponse,
    ValidationResponse,
    ErrorValidationFeatureType,
    CoordinateReferenceSystem,
} from "./layer-geojson-validation-types";

/**
 * Validates that a file input (json) is valid for importing to a layer
 *
 * Will return either valid geojson or an reason for why there was an errors
 */
export async function validateLayerGeojsonInput(file: File, styleType: StyleType): Promise<ValidationResponse> {
    let jsonString: string;
    try {
        // Convert file to a string
        jsonString = await fileToString(file);
    } catch (err) {
        return { error: ValidationError.InvalidJson };
    }

    // Convert string to json
    const { error: jsonError, parsedJson } = parseJsonFile(jsonString);

    if (jsonError === ValidationError.InvalidJson) {
        return { error: ValidationError.InvalidJson };
    }

    // Ensure size is below 10mb
    if (!isSizeOfJsonFileValid(file)) {
        return { error: ValidationError.TooLarge };
    }

    if (!validateCoordinateSystem(parseJsonFile)) {
        return { error: ValidationError.InvalidCoordinateSystem };
    }
    // Validate the geojson (not geometry)
    const { error: geoJsonError, parsedGeoJson: validGeojson } = isValidGeoJson(parsedJson);

    if (geoJsonError === ValidationError.InvalidGeoJson) {
        return { error: ValidationError.InvalidGeoJson };
    }

    // Validate the geometry
    const geometryError = isFeatureGeometryValid(validGeojson, styleType);
    if (geometryError !== ValidationError.None) {
        if (geometryError === ValidationError.InvalidLayerType) {
            return { error: ValidationError.InvalidLayerType, importFeatureType: parsedGeoJsonToIventisType(validGeojson) };
        }
        return { error: geometryError };
    }

    const parsedGeoJson = parseGeojsonCoordinates(validGeojson);

    // Geojson has passed all validation and is valid
    return { features: parsedGeoJson, error: ValidationError.None, fileName: file.name };
}

export async function validateGPXFile(file: File, gpxType: GPXOption) {
    let validationResult: ValidationResponse;
    const result = await convertGPXToGeoJSON(file, gpxType);
    if (result == null || result.featureCollection.features.length === 0) {
        validationResult = {
            error: ValidationError.InvalidGPX,
        };
    } else {
        const isValidJson = isValidGeoJson(result.featureCollection);
        const isValidGeometry = isFeatureGeometryValid(result.featureCollection, StyleType.Line);
        if (isValidJson?.error === ValidationError.None && isValidGeometry === ValidationError.None) {
            validationResult = {
                error: ValidationError.None,
                features: result.featureCollection,
                fileName: file.name,
            };
        } else {
            validationResult = {
                error: ValidationError.InvalidParsedGPX,
            };
        }
    }
    return { validationResult, options: result.options, selectedOption: result.selectedOption } as const;
}

async function convertKMZToKML(file: File): Promise<File> {
    const zip = new JSZip();
    const kmzData = await zip.loadAsync(file);
    const kmlFile = kmzData.file(/\.kml$/i)[0];
    if (!kmlFile) {
        throw new Error("No KML file found in KMZ archive");
    }
    const kmlData = await kmlFile.async("blob");
    return new File([kmlData], kmlFile.name, { type: "application/vnd.google-earth.kml+xml" });
}

export async function validateKMLFile(file: File, styleType: StyleType) {
    const geoType = styleTypeToGeoJSONGeometryType(styleType);
    let kmlFile = file;
    let validationResult: ValidationResponse;
    if (file.name.endsWith(".kmz")) {
        try {
            kmlFile = await convertKMZToKML(file);
        } catch (err) {
            return { validationResult: { error: ValidationError.InvalidKML }, folders: [] } as const;
        }
    }
    const kmlToGeojson = new KmlToGeojson();
    const kmlString = await fileToString(kmlFile);
    const { folders, geojson: result } = kmlToGeojson.parse(kmlString);

    // if no map objects of this layer type are found, return error
    if (result.features.length > 0 && result.features.find((feature) => feature.geometry.type === geoType) == null) {
        validationResult = {
            error: ValidationError.InvalidLayerType,
            importFeatureType: parsedGeoJsonToIventisType(result),
        };
        return { validationResult, folders } as const;
    }

    if (result == null || result.features?.length === 0) {
        validationResult = {
            error: ValidationError.InvalidKML,
        };
    } else {
        const isValidJson = isValidGeoJson(result);
        if (isValidJson?.error === ValidationError.None) {
            // filter other styles
            const filteredResults = { type: result.type, features: [...result.features.filter((feature) => feature.geometry.type === geoType)] };
            const isValidGeometry = isFeatureGeometryValid(filteredResults, styleType);

            if (isValidGeometry === ValidationError.None) {
                validationResult = {
                    error: ValidationError.None,
                    features: filteredResults,
                    fileName: kmlFile.name,
                };
            } else {
                validationResult = {
                    error: ValidationError.InvalidParsedKML,
                };
            }
        } else {
            validationResult = {
                error: ValidationError.InvalidParsedKML,
            };
        }
    }
    return { validationResult, folders } as const;
}

const parsedGeoJsonToIventisType = (featureCollection: FeatureCollection): ErrorValidationFeatureType["importFeatureType"] => {
    const feature = featureCollection.features[0];
    switch (feature?.geometry?.type) {
        case "Point":
            return "Point";
        case "LineString":
            return "Line";
        case "Polygon":
            return "Area";
        default:
            return "Unknown Type";
    }
};
/** Attempts to parse string to json */
export function parseJsonFile(jsonString: string): ParsedJsonResponse {
    try {
        const parsedJson = JSON.parse(jsonString);
        return { error: ValidationError.None, parsedJson };
    } catch (e) {
        return { error: ValidationError.InvalidJson, parsedJson: undefined };
    }
}

export function isSizeOfJsonFileValid(file: File, maxSizeMb = MAX_SIZE_MB) {
    const megaBytes = bytesToMegaBytes(file.size);
    return megaBytes < maxSizeMb;
}

/**
 * Validates that we support the coordinate reference system
 *
 * If there is no coordinate reference system we assume it is in the correct formats
 */
export function validateCoordinateSystem(jsonObject: any) {
    // Check "crs" is a property if not then return valid (assume correct format)
    if (!("crs" in jsonObject && typeof jsonObject.crs === "object")) {
        return true;
    }

    // Ensure crs property is of type we want
    if (!crsTypeGuard(jsonObject.crs)) {
        return false;
    }

    // Ensure it is a coordinate system we support
    return validCoordinateSystemNames.includes(jsonObject.crs.properties.name);
}

function crsTypeGuard(crs: any): crs is CoordinateReferenceSystem {
    if (typeof crs !== "object") {
        return false;
    }

    if (!("type" in crs && crs.type === "name")) {
        return false;
    }

    if (!("properties" in crs && typeof crs.properties === "object")) {
        return false;
    }

    if (!("name" in crs.properties && typeof crs.properties.name === "string")) {
        return false;
    }

    return true;
}

/**
 * Validates the geojson ensuring it is a feature collection
 *
 * Validates that each feature inside the feature collection, does not validate feature's geometry
 */
export function isValidGeoJson(jsonObject: any): ParsedGeoJsonResponse {
    if (!featureCollectionTypeGuard(jsonObject)) {
        return { error: ValidationError.InvalidGeoJson, parsedGeoJson: undefined };
    }
    return { error: ValidationError.None, parsedGeoJson: jsonObject };
}

function featureCollectionTypeGuard(jsonObject: any): jsonObject is FeatureCollection<LineString | Point | Polygon> {
    if (!("type" in jsonObject && jsonObject.type === "FeatureCollection")) {
        return false;
    }

    if (!("features" in jsonObject && Array.isArray(jsonObject.features) && jsonObject.features.length > 0)) {
        return false;
    }

    if (!jsonObject.features.every((feature) => "type" in feature && feature.type === "Feature" && "properties" in feature && isObject(feature.properties))) {
        return false;
    }

    if (!jsonObject.features.every((feature) => "geometry" in feature && "coordinates" in feature.geometry && "type" in feature.geometry)) {
        return false;
    }

    return true;
}

/**
 * Cycles through each feature and ensure geometry is valid
 *
 * If an error is found stop validating and return the error
 */
export function isFeatureGeometryValid(featureCollection: FeatureCollection, styleType: StyleType) {
    let error: ValidationError = ValidationError.None;

    for (let index = 0; index < featureCollection.features.length; index += 1) {
        const feature = featureCollection.features[index];
        error = validateGeometry(feature, styleType);
        if (error !== ValidationError.None) {
            break;
        }
    }

    return error;
}

/**
 * Matches validation function with the feature geometry type
 */
function validateGeometry(feature: Feature, styleType: StyleType) {
    switch (feature.geometry.type) {
        case "Point":
            return validatePointGeometry(feature.geometry, styleType);
        case "LineString":
            return validateLineGeometry(feature.geometry, styleType);
        case "Polygon":
            return validateAreaGeometry(feature.geometry, styleType);
        default:
            return ValidationError.GeometryTypeNotHandled;
    }
}

/** Validation for point geometry */
function validatePointGeometry(geometry: Point, styleType: StyleType): ValidationError {
    // Checks style type is point based styles
    const isLayerTypeCorrect = styleType === StyleType.Icon || styleType === StyleType.Point || styleType === StyleType.Model;
    if (!isLayerTypeCorrect) {
        return ValidationError.InvalidLayerType;
    }
    // Check coordinates are valid (correct array length, that each coordinate is a number etc.)
    if (!isCoordinatesValid(geometry.coordinates)) {
        return ValidationError.InvalidCoordinates;
    }
    return ValidationError.None;
}

/** Validation for line geometry */
function validateLineGeometry(geometry: LineString, styleType: StyleType): ValidationError {
    // Check the style type is line based styles
    const isLayerTypeCorrect = styleType === StyleType.Line || styleType === StyleType.LineModel;
    if (!isLayerTypeCorrect) {
        return ValidationError.InvalidLayerType;
    }

    // Check coordinates array is an array and has at least three points
    const isCoordinatesArrayValid = Array.isArray(geometry.coordinates) && geometry.coordinates.length >= 2;
    if (!isCoordinatesArrayValid) {
        return ValidationError.InvalidCoordinates;
    }

    // Check coordinates have length of 3 or more and that they are valid (correct array length, that each coordinate is a number etc.)
    if (!geometry.coordinates.every(isCoordinatesValid)) {
        return ValidationError.InvalidCoordinates;
    }
    return ValidationError.None;
}

/** Validation for area geometry */
export function validateAreaGeometry(geometry: Polygon, styleType: StyleType): ValidationError {
    // Check the style type is area

    if (styleType !== StyleType.Area) {
        return ValidationError.InvalidLayerType;
    }
    // Check outer array is an array and length of one or greater (valid geojson can have more than one element in this array but we don't support it)
    const isOuterArrayValid = Array.isArray(geometry?.coordinates) && geometry?.coordinates.length >= 1;

    if (!isOuterArrayValid) {
        return ValidationError.InvalidCoordinates;
    }

    const coordinates = geometry.coordinates[0];
    // Check inner array is an array and length of is 4 or more
    const isInnerArrayValid = Array.isArray(coordinates) && coordinates.length >= 4;

    if (!isInnerArrayValid) {
        return ValidationError.InvalidCoordinates;
    }

    const firstCoordinates = coordinates[0];
    const lastCoordinates = coordinates[coordinates.length - 1];
    // Polygons need first and last coordinates to be equal
    const lastAndFirstCoordinateEqual = firstCoordinates[0] === lastCoordinates[0] && firstCoordinates[1] === lastCoordinates[1];
    // Check coordinates are valid (correct array length, that each coordinate is a number etc.)
    const isAreaCoordinatesValid = lastAndFirstCoordinateEqual && coordinates.every(isCoordinatesValid);
    if (!isAreaCoordinatesValid) {
        return ValidationError.InvalidCoordinates;
    }

    return ValidationError.None;
}

/** Checks each coordinates is length of 2 and both coordinate are valid numbers */
function isCoordinatesValid(coordinates: Position) {
    return Array.isArray(coordinates) && (coordinates.length === 2 || coordinates.length === 3) && coordinates.every((c) => typeof c === "number" && !Number.isNaN(c));
}

function parseGeojsonCoordinates(geojson: FeatureCollection<LineString | Point | Polygon>): FeatureCollection<LineString | Point | Polygon> {
    const features: FeatureCollection<LineString | Point | Polygon>["features"] = geojson.features.map((feature) => {
        switch (feature.geometry.type) {
            case "Point":
                return { ...feature, geometry: { ...feature.geometry, coordinates: feature.geometry.coordinates.slice(0, 2) } };
            case "LineString":
                return { ...feature, geometry: { ...feature.geometry, coordinates: feature.geometry.coordinates.map((coordinate) => coordinate.slice(0, 2)) } };
            case "Polygon":
                return { ...feature, geometry: { ...feature.geometry, coordinates: [feature.geometry.coordinates[0].map((coordinate) => coordinate.slice(0, 2))] } };
            default:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                throw new Error(`${feature.geometry.type} geometry type not handled`);
        }
    });
    return { type: "FeatureCollection", features };
}
