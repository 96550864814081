import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DataFieldListItemPropertyType } from "@iventis/domain-model/model/dataFieldListItemPropertyType";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { Content } from "@iventis/translations";
import { WHAT3WORDS_ATTRIBUTE_NAME, What3WordsLogo } from "@iventis/what3words";
import React, { ReactNode } from "react";

export const LIST_ITEMS_QUERY_KEY = "list-items";

export const LIST_ITEM_QUERY_KEY = "list-item";

export const dataFieldTypes: { type: DataFieldType; name: string; icon: IconProp | ReactNode; filterFromCreation?: boolean }[] = [
    { type: DataFieldType.Text, name: Content.map.data_fields.types.Text, icon: ["fal", "text"] },
    { type: DataFieldType.List, name: Content.map.data_fields.types.List, icon: ["fal", "list"] },
    { type: DataFieldType.Number, name: Content.map.data_fields.types.Number, icon: ["fal", "hashtag"] },
    { type: DataFieldType.Date, name: Content.map.data_fields.types.Date, icon: ["fal", "calendar"] },
    { type: DataFieldType.Tickbox, name: Content.map.data_fields.types.Tickbox, icon: ["fal", "check"] },
    { type: DataFieldType.Time, name: Content.map.data_fields.types.Time, icon: ["fal", "timer"] },
    { type: DataFieldType.TimeRange, name: Content.map.data_fields.types.TimeRange, icon: ["fal", "clock"] },
    {
        type: DataFieldType.What3Words,
        name: WHAT3WORDS_ATTRIBUTE_NAME,
        icon: <What3WordsLogo />,
        filterFromCreation: true,
    },
    { type: DataFieldType.Hyperlink, name: Content.map.data_fields.types.Hyperlink, icon: ["fal", "link"] },
    { type: DataFieldType.Image, name: Content.map.data_fields.types.Image, icon: ["fal", "image"] },
    { type: DataFieldType.RepeatedTimeRanges, name: Content.map8.dates.attributeName, icon: ["fal", "clock"] },
    { type: undefined, name: "", icon: undefined },
];

export const listItemTypes: { type: DataFieldListItemPropertyType; name: string }[] = [
    { type: DataFieldListItemPropertyType.Text, name: Content.map.data_fields.types.Text },
    { type: DataFieldListItemPropertyType.Number, name: Content.map.data_fields.types.Number },
];

export const emptyDataField: DataField = {
    name: "",
    customerIdentifierName: "",
    allowedMetadataProperties: [],
    type: DataFieldType.List,
    listValues: [],
    projectId: undefined,
    hierarchyMetadataPropertyName: "",
    showInApi: false,
    apiNameSingular: "",
    apiNamePlural: "",
    listItemProperties: [],
    listItemRelationships: [],
    id: undefined,
    order: 0,
    defaultValue: {
        valueText: undefined,
        dataFieldId: undefined,
        listItemId: undefined,
        valueDate: undefined,
        valueNumber: undefined,
        valueTickbox: undefined,
        valueRepeatedTimeRanges: undefined,
    },
};
