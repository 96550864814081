import { AnyFeature } from "@iventis/map-types";
import { DrawingModifier } from "../types/store-schema";
import { ObjectSnapping } from "./objects-snapping";

/** Union of drawing modifier states. Expect only one to be defined at one time */
export type DrawingModifierState<TFeature extends AnyFeature = AnyFeature> = { snapping: ObjectSnapping<TFeature>; none: undefined } | { snapping: undefined; none: undefined }; // Add any other modifiers here

/** Maps a drawing modifier type to its' corresponding class */
export type DrawingModifierCreators<TFeature extends AnyFeature = AnyFeature> = { snapping: () => ObjectSnapping<TFeature> };

/** If no drawing modifier is applied, this should be the default state */
export const emptyDrawingModifiers = { snapping: undefined, none: undefined };

export abstract class ModifyableDrawingTool<TFeature extends AnyFeature = AnyFeature> {
    protected drawingModifier: DrawingModifierState<TFeature>;

    constructor(drawingModifier: DrawingModifier, private createDrawingModifier: DrawingModifierCreators<TFeature>) {
        this.setDrawingModifier(drawingModifier);
    }

    protected getLatestTranslation(): TFeature[] {
        return this.getDrawingModifier()?.latestTranslation;
    }

    protected getDrawingModifier() {
        return Object.values(this.drawingModifier).find((modifier) => modifier != null);
    }

    /** Tries to modify the argument if applicable. Returns argument if no modification applicable */
    protected tryModify(newTranslation: TFeature[]) {
        const drawingModifier = this.getDrawingModifier();

        let translation = newTranslation;

        if (drawingModifier) {
            translation = drawingModifier.modify(translation);
        }
        return translation;
    }

    public setDrawingModifier(drawingModifier: DrawingModifier) {
        this.drawingModifier = { ...emptyDrawingModifiers, [drawingModifier]: this.createDrawingModifier[drawingModifier]?.() };
    }

    public destroy() {
        this.drawingModifier = emptyDrawingModifiers;
    }
}
