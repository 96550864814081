import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import { useMachine } from "@xstate/react";
import { StyleType } from "@iventis/domain-model/model/styleType";
import React, { FunctionComponent, useRef } from "react";
import { styled } from "@iventis/styles";
import { useDataFieldServices } from "@iventis/datafield-editor";
import PreviewContainerComponent, { PreviewContainerCallbackRefs } from "./preview-container";
import { layerThumbnailGeneratorMachine } from "./layer-thumbnail-generator-machine";
import { ModelThumbnailGenerator } from "./model-layer-thumbnail-generator";
import { IStyleService } from "./edit-layer-services";

export interface LayerThumbnailGeneratorServices extends IStyleService {
    setLayerThumbnail: (previewDataUrl: string) => Promise<void>;
}

/** Place anywhere you want to generate a thumbnail for your layer */
export const LayerThumbnailGenerator: FunctionComponent<{ layer: MapLayer; services: LayerThumbnailGeneratorServices; onDone: () => void }> = ({ layer, services, onDone }) => {
    const previewRef = useRef<PreviewContainerCallbackRefs>();
    const { dataFieldListItemsService } = useDataFieldServices();

    const [, send] = useMachine(layerThumbnailGeneratorMachine, {
        actions: {
            onDone,
        },
        services: {
            takeSnapshot: async () => {
                const url = await previewRef.current.takeSnapshot();
                return url;
            },
            updateThumbnail: async (_, event) => {
                await services.setLayerThumbnail(event.data);
            },
        },
    });

    return (
        <StyledContainer>
            {layer.styleType === StyleType.Model || layer.styleType === StyleType.LineModel ? (
                <ModelThumbnailGenerator ref={previewRef} layer={layer} services={services} onCanvasReady={() => send("MAP_LOADED")} />
            ) : (
                <PreviewContainerComponent
                    ref={previewRef}
                    layerStyle={getLayerStyle(layer)}
                    className="style-preview-small"
                    datafields={layer.dataFields}
                    assetGetter={services.assetGetter}
                    modelGetter={services.modelGetter}
                    imageCompressUrlGetter={services.imageCompressor}
                    isAssetSdf={services.isAssetSdf}
                    onMapLoaded={() => send("MAP_LOADED")}
                    getAttributeListItems={dataFieldListItemsService.getDataFieldListItems}
                    bustCacheIds={services.bustCacheIds}
                />
            )}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    visibility: hidden;
`;
