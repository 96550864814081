import { load } from "@loaders.gl/core";
import { GLTFLoader, postProcessGLTF } from "@loaders.gl/gltf";
import { CUSTOM_IMAGE_MATERIAL_SLOT_NAME } from "./model-material-name-constants";

export const validateModelCanHaveCustomImage: (modelFile: File) => Promise<boolean> = async (modelFile: File) => {
    const rawModel = await load(modelFile, GLTFLoader);
    const processed = postProcessGLTF(rawModel);

    // Find the material slot and ensure it has a texture we can replace
    return processed.materials.some((material) => material.name.includes(CUSTOM_IMAGE_MATERIAL_SLOT_NAME) && material?.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.id);
};
