import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AutocompleteWithLoading,
    ConfirmationDialogComponent,
    DisabledOverlay,
    EditStyleLimitMessage,
    FileUploadComponent,
    FileUploadImagePreviewComponent,
    FormWizardTemplate,
    MultipleValueTextInput,
    FormWizardTitle,
} from "@iventis/components";
import {
    StyledFieldLabel,
    screenSizeBreakpoints,
    borderRadius,
    formGap,
    media,
    inlineTextIconMargin,
    iconButtonSize,
    InteractiveElement,
    inputMarginBottom,
    muiInputFormsCSS,
    FillSpace,
    styled,
} from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { v4 as uuid } from "uuid";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Box, IconButton, LinearProgress, useTheme } from "@mui/material";
import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState, useTransition } from "react";
import {
    fileToBase64,
    getFilteredDataFieldTypesForLayer,
    isValidUuid,
    replaceItemInArray,
    sortDatafieldsByOrder,
    sortDataFieldsOnDelete,
    useConstant,
    useDebouncer,
    useFunctionality,
    useWindowSize,
} from "@iventis/utilities";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { FormWizardEditableTitle } from "@iventis/components/src/form-wizard-editable-title";
import { ExtractRefFromComponent } from "@iventis/types/useful.types";
import {
    ChipsErrorBoundary,
    DataFieldConfigContext,
    DataFieldsChips,
    DataFieldsChipsProps,
    DataFieldServicesContext,
    IDataFieldServices,
    useDataFieldServices,
} from "@iventis/datafield-editor";
import { DataField } from "@iventis/domain-model/model/dataField";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { IventisErrorBoundary } from "@iventis/error-boundaries";
import { setLayerStyle } from "@iventis/map-engine/src/utilities/layer.helpers";
import { getLayerStyle } from "@iventis/layer-style-helpers/src/get-layer-style-helpers";
import {
    styleTypeToLayerProperties,
    stripStyleValuesWithRemovedDataFieldReferences,
    stripStyleValuesWithRemovedListItemReference,
    isStyleValid,
    isLayerStyleValid,
} from "@iventis/map-engine/src/utilities/style-helpers";
import { incrementMonitoringCount } from "@iventis/observability-and-monitoring";
import { MetricName } from "@iventis/domain-model/model/metricName";
import { UnionOfStyles } from "@iventis/map-engine/src/types/store-schema";
import { isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { updateLayerDataFieldsOnStyleTypeChange } from "@iventis/datafields";
import { isUsingZoomableStyleValue, isValidZoomableStyleValue } from "@iventis/layer-style-helpers/src/style-value-zoomable-value-helpers";
import { useEditLayerServices } from "./edit-layer-services";
import { EditStyleHeaderComponent, EditStyleComponent } from "./edit-style";
import { EditStyleContext } from "./edit-style-context";
import { LayerTourContext, tourLayerAttributesClassName, tourLayerNameClassName } from "./layer-tour";
import PreviewContainerComponent, { PreviewContainerCallbackRefs } from "./preview-container";
import { AttributeTour, AttributeTourContext } from "./attribute-tour";
import { getStyleDefaultValue, createLayerStyleChangesSideEffects, hasLayerChanged, hasCategoriesChanged } from "./edit-layer-helpers";
import { EditLayerMapObjectToolTipEditorComponent } from "./edit-layer-map-object-tooltip-editor";

interface ImagePreviewState {
    fileName: string;
    imageUrl: string;
}

export const EditStyleWizard: FunctionComponent<{
    categoryOptions?: { categories: string[]; getExistingCategories: () => Promise<string[]> };
    allowImageUpload?: boolean;
    initialLayer: MapLayer;
    canUserEdit: boolean;
    navigateToProjectSettings?: () => void;
    close: () => void;
    templateImage?: string;
    back: () => void;
    mapName: string;
    showMapObjectDataFieldSelectionForTooltip: boolean;
}> = ({
    categoryOptions,
    allowImageUpload = false,
    initialLayer,
    canUserEdit,
    navigateToProjectSettings,
    close,
    mapName,
    templateImage,
    back,
    showMapObjectDataFieldSelectionForTooltip,
}) => {
    /*
        Globals
    */
    const { screenWidth } = useWindowSize();
    const translate = useIventisTranslate();
    const functionality = useFunctionality();
    const queryClient = useQueryClient();
    const { assetService, styleService: editStyleService, layerService } = useEditLayerServices();
    const { dataFieldsService, dataFieldListItemsService } = useDataFieldServices();

    /*
        Local state
    */
    const [savedLayer, setSavedLayer] = useState(initialLayer); // Layer that has been saved to the backend
    const [latestLayer, setLayer] = useState(savedLayer); // Layer that is local and reactive
    const [showStyle, setShowStyle] = useState(true);
    const [showAddAttributeTour, setShowAddAttributeTour] = useState(false);
    const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
    const [layerTemplateCategories, setTemplateCategories] = useState<string[]>([]);
    const previewRef = useRef<PreviewContainerCallbackRefs>(null);
    const editLayerTitleRef = useRef<ExtractRefFromComponent<typeof FormWizardEditableTitle>>(null);
    const [thumbnailPreview, setThumbnailPreview] = useState<ImagePreviewState>({ fileName: "template image", imageUrl: templateImage });
    // If we're going to create a new layer, we must only generate the layer id once (hence useConstant)
    const layerId = useConstant(() => (initialLayer.id?.length ? initialLayer.id : uuid()));

    /*
        Queries
    */
    const { data: existingLayerTemplateCategories, isFetching: isLoadingCategories } = useQuery({
        queryKey: ["template-categories", initialLayer.id],
        queryFn: async () => {
            const options = await categoryOptions.getExistingCategories();
            setTemplateCategories(options);
            return options;
        },
        enabled: categoryOptions?.getExistingCategories != null && initialLayer?.id != null,
        initialData: [],
    });
    const { isFetching: isLoadingStyle } = useQuery({
        // We use the initial layer only here because we don't want to refetch after we create (the useMutation handles that)
        queryKey: ["style", initialLayer.id ?? "new", initialLayer.styleType],
        enabled: !(Object.keys(getLayerStyle(initialLayer)).length > 0),
        queryFn: async () => {
            const { styleType } = latestLayer;
            const layerStyle = await getLayerStyleForStyleType(styleType);
            setPartialLayer({ [styleTypeToLayerProperties[styleType]]: layerStyle, styleType });
            return null;
        },
    });

    /*
        Mutations
    */
    const { isLoading: isUpdatingSyle, mutateAsync: updateStyleType } = useMutation({
        mutationFn: async (newStyleType: StyleType) => {
            const projectDataFields = await editStyleService.getProjectDataFields();
            const updatedDataFields = updateLayerDataFieldsOnStyleTypeChange(newStyleType, latestLayer, projectDataFields);
            let layerStyle = latestLayer[styleTypeToLayerProperties[newStyleType]];
            if (!isStyleValid(layerStyle)) {
                layerStyle = await getLayerStyleForStyleType(newStyleType);
            }
            setPartialLayer({ [styleTypeToLayerProperties[newStyleType]]: layerStyle, styleType: newStyleType, dataFields: updatedDataFields });
        },
    });
    const { isLoading: isSavingLayer, mutateAsync: saveLayer } = useMutation({
        mutationFn: async (_layer?: MapLayer) => {
            const layerToSave = _layer ?? latestLayer;
            if (latestLayer.id == null) {
                setLayer({ ...layerToSave, id: layerId });
                const newLayer = { ...layerToSave, id: layerId };
                await layerService.postLayer(newLayer, thumbnailPreview?.imageUrl, layerTemplateCategories);

                // If MapObjectName order is 1 then the order has not changed when creating a layer
                if (newLayer.dataFields?.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectName).order !== 1) {
                    for (let i = 0; i < newLayer.dataFields.length; i += 1) {
                        await dataFieldsService.putDataField({ ...newLayer.dataFields[i] }, newLayer.id);
                    }
                }
                setSavedLayer(newLayer);
                saveDebounceRef.current.cancel();
            } else {
                await layerService.putLayer(layerToSave, thumbnailPreview?.imageUrl, layerTemplateCategories);
                setSavedLayer(layerToSave);
            }
            if (hasCategoriesChanged(existingLayerTemplateCategories, layerTemplateCategories)) {
                queryClient.setQueryData(["template-categories", initialLayer.id], layerTemplateCategories);
            }
        },
    });

    /*
        Derived state
    */
    const orderedDataFields = useMemo(() => sortDatafieldsByOrder(latestLayer.dataFields), [latestLayer.dataFields]);
    const existsOnServer = isValidUuid(savedLayer.id);
    // No memoisation here because it depends on almost everything and doesn't cost much computation
    const canSave = (() => {
        // If we are in some loading state or the user has no permission, block saving
        if (!canUserEdit || isUpdatingSyle || isLoadingCategories || isLoadingStyle) {
            return false;
        }

        const layerStyle = getLayerStyle(latestLayer);

        // Check that all values are valid if they are determined by zoom level
        const isValidZoomableValues = Object.values(layerStyle).every((styleValue) => {
            if (isUsingZoomableStyleValue(styleValue)) {
                return isValidZoomableStyleValue(styleValue);
            }
            return true;
        });

        if (!isValidZoomableValues) {
            return false;
        }

        // If the layer is not defined, block saving
        const isDefined = latestLayer.styleType != null && typeof layerStyle === "object" && latestLayer.name !== undefined;
        if (!isDefined) {
            return false;
        }
        // If it does not exist on the server, all saving
        if (!existsOnServer) {
            return true;
        }

        // If the latest layer is different from the saved layer, allow saving
        const isDifferent = hasLayerChanged(savedLayer, latestLayer, existingLayerTemplateCategories, layerTemplateCategories, templateImage, thumbnailPreview?.imageUrl);
        return isDifferent;
    })();

    // Save the layer, debounced
    // Debounce needs to be a ref, otherwise the reference to the debounced function is lost
    // And you cannot debounce it again or flush
    const saveDebounceRef = useDebouncer(saveLayer, 10000);
    useEffect(
        () => () => {
            // Cancel when we unmount
            saveDebounceRef.current.cancel();
        },
        []
    );

    /*
        Functions
    */
    /** Performs a patch-like update on the layer, only updating those properties that are provided (node: uses a debouncer of 10 seconds so if left idle for a while it will save automatically) */
    const setPartialLayer = (updator: Partial<MapLayer> | ((layer: MapLayer) => Partial<MapLayer>), forceSave = false) =>
        setLayer((layer) => {
            const partialLayer = typeof updator === "function" ? updator(layer) : updator;

            if (layer.styleType === StyleType.Model || layer.styleType === StyleType.LineModel) {
                if (partialLayer.modelStyle?.customColour != null || partialLayer.lineModelStyle?.customColour != null) {
                    incrementMonitoringCount({ name: MetricName.MODEL_COLOUR_CHANGES });
                }

                if (partialLayer.modelStyle?.customImage != null || partialLayer.lineModelStyle?.customImage != null) {
                    incrementMonitoringCount({ name: MetricName.MODEL_IMAGE_CHANGES });
                }
            }

            const newLayer = {
                ...layer,
                ...partialLayer,
                iconStyle: { ...layer.iconStyle, ...partialLayer.iconStyle },
                pointStyle: { ...layer.pointStyle, ...partialLayer.pointStyle },
                areaStyle: { ...layer.areaStyle, ...partialLayer.areaStyle },
                lineModelStyle: { ...layer.lineModelStyle, ...partialLayer.lineModelStyle },
                modelStyle: { ...layer.modelStyle, ...partialLayer.modelStyle },
                lineStyle: { ...layer.lineStyle, ...partialLayer.lineStyle },
            };
            saveDebounceRef.current.cancel();
            if (forceSave) {
                saveLayer(newLayer);
            } else {
                saveDebounceRef.current(newLayer);
            }
            return newLayer;
        });
    const getLayerStyleForStyleType = async (styleType: StyleType) => {
        let layerStyle: UnionOfStyles;
        if (latestLayer.id !== undefined) {
            layerStyle = await editStyleService.getLayerStyle(latestLayer.id, latestLayer.mapId, styleType);
        }
        if (!isStyleValid(layerStyle)) {
            layerStyle = await getStyleDefaultValue(styleType, editStyleService.getModels, assetService.getAssetsByType, queryClient);
        }
        return layerStyle;
    };
    const onStylePropertyChanged = (property: string, value: StyleValue<unknown>) => {
        const styleTypeKey = styleTypeToLayerProperties[latestLayer.styleType];
        setPartialLayer({
            [styleTypeKey]: {
                ...createLayerStyleChangesSideEffects(latestLayer.styleType, property, value),
                [property]: value,
            },
        });
    };
    const onImageSelected = async (setState: (value: React.SetStateAction<ImagePreviewState>) => void, file?: File) => {
        if (!file) {
            setState(undefined);
            return;
        }
        const { withHeader } = await fileToBase64(file);
        setState({ fileName: file.name, imageUrl: withHeader });
    };

    /** We intercept the deletion of list items here so we can remove any references to the list items found inside the layers' style */
    const dataFieldsServiceInterceptor: IDataFieldServices = useMemo(
        () => ({
            dataFieldsService: {
                ...dataFieldsService,
                postDataField: async (dataField) => {
                    const orderedDatafield = { ...dataField, order: orderedDataFields.length + 1 };
                    setPartialLayer((layer) => ({ ...layer, dataFields: [...layer.dataFields, orderedDatafield] }), true);
                    await dataFieldsService.postDataField(orderedDatafield, layerId);
                },
                putDataField: async (dataField) => {
                    if (existsOnServer) {
                        setPartialLayer((layer) => {
                            // Ensure the dataField has all of it's properties
                            const originalDataField = layer.dataFields.find((df) => df.id === dataField.id);
                            const updatedDataField: DataField = { ...originalDataField, ...dataField };
                            return { ...layer, dataFields: replaceItemInArray(layer.dataFields, updatedDataField) };
                        });
                        await dataFieldsService.putDataField(dataField, layerId);
                    }
                },
                deleteDataField: async (dataFieldId: string, layerId: string) => {
                    setPartialLayer((layer) => {
                        const newDataFields = sortDataFieldsOnDelete(layer.dataFields, dataFieldId);
                        return {
                            ...stripStyleValuesWithRemovedDataFieldReferences(layer, dataFieldId),
                            dataFields: newDataFields,
                            tooltipDataFieldIds: layer.tooltipDataFieldIds?.filter((id) => id !== dataFieldId),
                        };
                    }, true);
                    // Delete the data field
                    await dataFieldsService.deleteDataField(dataFieldId, layerId);
                },
            },
            dataFieldListItemsService: {
                ...dataFieldListItemsService,
                deleteDataFieldListItem: async (listItemId: string, dataFieldId: string, layerId: string) => {
                    setPartialLayer((layer) => stripStyleValuesWithRemovedListItemReference(layer, listItemId), true);
                    await dataFieldListItemsService.deleteDataFieldListItem(listItemId, dataFieldId, layerId);
                },
            },
        }),
        [dataFieldsService, dataFieldListItemsService, setPartialLayer]
    );

    /** When user picks new style type */
    const handleStyleTypeChange = async (styleType: StyleType) => {
        const style = getLayerStyle({ ...latestLayer, styleType });
        // Ensure the updated style is valid
        if (!isStyleValid(style)) {
            // If not valid populate with default values
            const style = await getStyleDefaultValue(styleType, editStyleService.getModels, assetService.getAssetsByType, queryClient);
            const layer = setLayerStyle(latestLayer, style);
            setPartialLayer(layer);
        } else {
            setPartialLayer({ styleType });
        }
    };

    const isLoading = isLoadingStyle || isUpdatingSyle || !isLayerStyleValid(latestLayer);

    return (
        <AttributeTour running={showAddAttributeTour} onFinish={() => setShowAddAttributeTour(false)}>
            <FormWizardTemplate
                currentStage={0}
                stages={[
                    {
                        isValid: canSave && !isSavingLayer,
                        primaryButtonCallback: async () => {
                            await saveLayer(latestLayer);
                        },
                        submitButtonDataCy: "save-layer-button",
                        primaryButtonText: translate(Content.common.buttons.save),
                        submittingText: translate(Content.common.saving),
                        submittedText: translate(Content.common.saved),
                        showLoadingSpinner: isSavingLayer,
                        isSubmitted: !isSavingLayer && !canSave,
                        secondaryButtons: [
                            {
                                buttonDisabled: isSavingLayer,
                                onButtonPressed: existsOnServer ? close : back,
                                buttonText: translate(Content.common.buttons[existsOnServer ? "close" : "back"]),
                            },
                        ],
                    },
                ]}
                title={
                    <StyledTitleContainer>
                        <FormWizardTitle
                            header={mapName}
                            title={
                                <FormWizardEditableTitle
                                    ref={editLayerTitleRef}
                                    entityName={latestLayer.name}
                                    fallbackTitle={latestLayer.name}
                                    updateNameOnEntity={(name) => name !== savedLayer.name && setPartialLayer({ name })}
                                    disableNameEdits={!canUserEdit}
                                    className={tourLayerNameClassName}
                                    icon={<FontAwesomeIcon style={{ marginRight: inlineTextIconMargin }} icon={["far", "layer-group"]} />}
                                />
                            }
                            customTitle
                        />
                        <FillSpace />
                        <IconButton sx={{ marginRight: "14px", width: iconButtonSize, height: iconButtonSize }} type="button" onClick={close}>
                            <FontAwesomeIcon icon={["far", "xmark"]} className="xmark" />
                        </IconButton>
                    </StyledTitleContainer>
                }
                modalTestId="edit-layer-wizard"
            >
                <ConfirmationDialogComponent
                    show={showDiscardConfirmation}
                    handleConfirm={navigateToProjectSettings}
                    handleCancel={() => setShowDiscardConfirmation(false)}
                    title={translate(Content.map5.edit_layer.discard_title)}
                    message={translate(Content.map5.edit_layer.discard_message)}
                    confirmText={translate(Content.common.yes)}
                    cancelText={translate(Content.common.no)}
                />
                <StyledContent data-testid="edit-style-wizard">
                    <StyleTypeEditor layer={latestLayer} layerExistsOnServer={latestLayer.id?.length > 0} setStyleType={handleStyleTypeChange} />
                    {categoryOptions != null && (
                        <div>
                            <MultipleValueTextInput
                                values={categoryOptions.categories}
                                selectedValues={layerTemplateCategories}
                                title={translate("Categories")}
                                placeholder={translate("Search for categories")}
                                onChange={(value) => setTemplateCategories(value)}
                                disabled={isLoadingCategories}
                                sectionalMargin={false}
                            />
                        </div>
                    )}
                    {allowImageUpload && (
                        <ImageUploadersContainer>
                            <div>
                                <StyledFieldLabel>{translate("Upload preview image")}</StyledFieldLabel>
                                <FileUploadComponent
                                    PreviewComponent={FileUploadImagePreviewComponent}
                                    previewImageClassName="layer-template-image-preview"
                                    removeRequested={() => onImageSelected(setThumbnailPreview)}
                                    loading={false}
                                    fileName={thumbnailPreview?.fileName}
                                    fileThumbnailUrl={thumbnailPreview?.imageUrl}
                                    uploadFile={(value) => onImageSelected(setThumbnailPreview, value)}
                                    uploadButtonText={translate("Select file")}
                                    removeImageText={translate("Remove image")}
                                    persistFileSelectInput
                                />
                            </div>
                        </ImageUploadersContainer>
                    )}
                    <div className={tourLayerAttributesClassName}>
                        <div className="icon-label">
                            <FontAwesomeIcon icon={["fas", "tag"]} size="xs" />
                            <StyledFieldLabel>{translate(Content.map.data_fields.title)}</StyledFieldLabel>
                        </div>
                        <ChipsErrorBoundary toast={null}>
                            <DataFieldConfigContext.Provider value={{ resourceId: latestLayer.id, allowSetDefaultValue: true, validateListItem: () => true }}>
                                <DataFieldServicesContext.Provider value={dataFieldsServiceInterceptor}>
                                    <DataFieldChipWrapper
                                        dataFields={orderedDataFields}
                                        getProjectDataFields={editStyleService.getProjectDataFields}
                                        allowDeletion
                                        navigateToProjectSettings={() => (canSave ? setShowDiscardConfirmation(true) : navigateToProjectSettings())}
                                        allowEdit={canUserEdit}
                                        allowCreate={canUserEdit}
                                        onOpen={() => saveLayer(undefined)}
                                        onDragEnd={(dataFields) => {
                                            if (!existsOnServer) saveLayer({ ...latestLayer, dataFields });
                                        }}
                                        filteredDataFieldTypes={getFilteredDataFieldTypesForLayer(latestLayer.styleType)}
                                        layerLoaded={!!savedLayer?.id}
                                        addButtonTestId="style-editor-add-attribute-button"
                                        disableAddButton={isLoading}
                                    />
                                </DataFieldServicesContext.Provider>
                            </DataFieldConfigContext.Provider>
                        </ChipsErrorBoundary>
                    </div>
                    {showMapObjectDataFieldSelectionForTooltip && (
                        <EditLayerMapObjectToolTipEditorComponent
                            layer={latestLayer}
                            setDataFields={(tooltipDataFieldIds) => setPartialLayer({ tooltipDataFieldIds })}
                            disabled={!canUserEdit}
                        />
                    )}
                    {!functionality.customLayer && <EditStyleLimitMessage />}
                    <div>
                        <div className="icon-label">
                            <FontAwesomeIcon icon="palette" size="xs" />
                            <StyledFieldLabel>{translate(Content.map.add_layer.styles)}</StyledFieldLabel>
                        </div>
                        <StyledOptionsContainer>
                            <IventisErrorBoundary>
                                {isLoading && (
                                    // Make sure the user is aware the style is loading, by displaying a progress bar
                                    <>
                                        <LinearProgress style={{ width: "100%", position: "absolute", top: 0, left: 0, borderRadius: "20px" }} />
                                        <DisabledOverlay />
                                    </>
                                )}
                                {!(isLoadingStyle || isUpdatingSyle || !isLayerStyleValid(latestLayer)) && (
                                    <>
                                        {screenWidth >= screenSizeBreakpoints.extraExtraSmall && (
                                            <PreviewContainerComponent
                                                ref={previewRef}
                                                layerStyle={getLayerStyle(latestLayer)}
                                                className={showStyle ? "style-preview" : "style-preview-small"}
                                                datafields={latestLayer.dataFields}
                                                assetGetter={editStyleService.assetGetter}
                                                modelGetter={editStyleService.modelGetter}
                                                imageCompressUrlGetter={editStyleService.imageCompressor}
                                                isAssetSdf={editStyleService.isAssetSdf}
                                                getAttributeListItems={dataFieldListItemsService.getDataFieldListItems}
                                                bustCacheIds={editStyleService.bustCacheIds}
                                                assetRefreshObserver={editStyleService.refreshAssetEvent}
                                                cypressWindowName="IVENTIS_LAYER_STYLE_PREVIEW_ENGINE_TESTING_FUNCTIONS"
                                            />
                                        )}
                                        {/* TODO: Replace name="style" with layer style name once implemented */}
                                        <EditStyleHeaderComponent
                                            previewRef={previewRef}
                                            style={getLayerStyle(latestLayer)}
                                            name="style"
                                            show={showStyle}
                                            setShow={setShowStyle}
                                            datafields={latestLayer.dataFields}
                                            editStyleService={editStyleService}
                                            imageCompressUrlGetter={editStyleService.imageCompressor}
                                        >
                                            <EditStyleContext.Provider
                                                value={{
                                                    setShowAddAttributeTour: (value) => setShowAddAttributeTour(value),
                                                    canUserEdit: canUserEdit && functionality.customLayer,
                                                    assetService,
                                                    getModels: editStyleService.getModels,
                                                    getProjectDataFields: editStyleService.getProjectDataFields,
                                                    onAssetRefresh: editStyleService.refreshAssetEvent,
                                                }}
                                            >
                                                <EditStyleComponent layer={latestLayer} onChange={onStylePropertyChanged} onStyleTypeChanged={updateStyleType} />
                                            </EditStyleContext.Provider>
                                        </EditStyleHeaderComponent>
                                    </>
                                )}
                            </IventisErrorBoundary>
                        </StyledOptionsContainer>
                    </div>
                </StyledContent>
            </FormWizardTemplate>
        </AttributeTour>
    );
};

export const StyleTypeEditor: FunctionComponent<{ layer: MapLayer; layerExistsOnServer: boolean; setStyleType: (styleType: StyleType) => void }> = ({
    layer,
    layerExistsOnServer,
    setStyleType,
}) => {
    const translate = useIventisTranslate();
    const functionality = useFunctionality();
    const theme = useTheme<Theme>();
    const [, startTransition] = useTransition();

    const [displayStyleType, setDisplayStyleType] = useState(layer.styleType);
    const updateStyleType = (styleType: StyleType) => {
        setDisplayStyleType(styleType);
        startTransition(() => {
            setStyleType(styleType);
        });
    };
    return (
        <AutocompleteWithLoading
            value={displayStyleType || layer.styleType}
            options={Object.values(StyleType)}
            label={translate(Content.map.add_layer.type_of_layer)}
            onChange={updateStyleType}
            loadingOptions={false}
            getOptionLabel={(opt) => translate(Content.map2.styles2[opt])}
            disabled={layerExistsOnServer}
            dataTestId="style-type-selector"
            renderOption={(props, option) => (
                <StyledLayerTypeItem key={option}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Box {...props} key={option} width="100%">
                        {translate(Content.map2.styles2[option])}
                    </Box>
                    {!functionality.layer3DTypes && isModelLayer({ styleType: option }) && (
                        <>
                            <DisabledOverlay />
                            <InteractiveElement className="upgrade-button" onClick={() => functionality.open?.("layer3DTypes")}>
                                <FontAwesomeIcon icon={["far", "circle-up"]} color={theme.primaryColors.focus} className="icon" />
                            </InteractiveElement>
                        </>
                    )}
                </StyledLayerTypeItem>
            )}
        />
    );
};

const DataFieldChipWrapper: FunctionComponent<DataFieldsChipsProps> = (props) => {
    const { ref: layerTourRef } = useContext(LayerTourContext);
    const { ref: attributeTourRef } = useContext(AttributeTourContext);
    return (
        <DataFieldsChips
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            onOpen={() => {
                if (layerTourRef?.current?.isRunning()) {
                    layerTourRef.current.minimiseTour();
                }
                if (attributeTourRef?.current?.isRunning()) {
                    attributeTourRef.current.minimiseTour();
                }
                props?.onOpen?.();
            }}
            onClose={() => {
                if (layerTourRef?.current?.isRunning() && !attributeTourRef?.current?.isRunning()) {
                    layerTourRef.current.maximiseTour();
                    layerTourRef.current.nextStep();
                }
                if (attributeTourRef?.current?.isRunning()) {
                    attributeTourRef.current.maximiseTour();
                    attributeTourRef.current.nextStep();
                }
                props?.onClose?.();
            }}
        />
    );
};

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${muiInputFormsCSS}

    .icon-label {
        margin-bottom: 10px;
    }

    .layer-template-image-preview {
        margin-top: ${inputMarginBottom};
        .image-preview {
            /* Matches the browser-rendered width of the layer template preview */
            height: 84px;
            width: 196px;
            object-fit: cover;
            object-position: top;
        }
    }

    .icon-label svg {
        padding-right: 5px;
        display: inline;
    }
    .icon-label label {
        display: inline;
    }
`;

const StyledLayerTypeItem = styled.div`
    position: relative;
    display: flex;
    .upgrade-button {
        position: absolute;
        right: 0;
        top: 0;
        width: ${iconButtonSize};
        height: ${iconButtonSize};
        z-index: 1000;
    }
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        margin-right: 10px;
    }
    .xmark {
        width: 18px;
        height: 18px;
        margin-right: 0;
    }
`;

const StyledOptionsContainer = styled.div`
    display: flex;
    border: ${({ theme }: { theme: Theme }) => `1px solid ${theme.shades.three}`};
    border-radius: ${borderRadius.standard};
    padding: ${formGap};
    justify-content: stretch;
    position: relative;

    .style-preview {
        width: 72px;
        height: 72px;
        ${media.small} {
            width: 80px;
            height: 80px;
        }
    }

    .style-preview-small {
        width: 72px;
        height: 72px;
    }
`;

const ImageUploadersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 50%);
`;
