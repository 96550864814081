import React, { FunctionComponent, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InteractiveElement } from "@iventis/styles/src/components";
import { styled, Header5, zIndexes } from "@iventis/styles";

import { IconButton } from "@mui/material";
import { sanitiseForDOMToken } from "@iventis/utilities";
import { HeaderCellComponentProps, RowModel } from "../types/data-table.types";
import { getSortComponent } from "./sort";
import { InsertRowAboveComponent } from "./insert-row-above";

/**
 * Used as headerComponent for data table columns
 */
export const HeaderCellComponent: FunctionComponent<HeaderCellComponentProps> = ({ ...props }) => {
    const { enableMenu, column, showColumnMenu, api, columnApi, displayName } = props;
    const headerRef = useRef(null);
    const filterActive = column.isFilterActive();
    const rowModelType = api.getModel().getType() as RowModel;

    return (
        <>
            <StyledHeaderCell ref={headerRef}>
                {getSortComponent(rowModelType, props)}
                {enableMenu && (
                    <InteractiveElement
                        className="icon iventis-filter-icon"
                        name="filter"
                        data-testid="filter-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            showColumnMenu(headerRef.current);
                        }}
                    >
                        <FontAwesomeIcon size="sm" icon={[filterActive ? "fas" : "far", "filter"]} />
                    </InteractiveElement>
                )}
                {"Cypress" in window && column.getColDef().enableRowGroup && (
                    <IconButton data-testid={sanitiseForDOMToken(`group-column-${displayName}-button`)} onClick={() => columnApi.addRowGroupColumn(column.getColId())}>
                        <InsertRowAboveComponent fill="black" />
                    </IconButton>
                )}
            </StyledHeaderCell>
        </>
    );
};

export const GroupHeaderCellComponent: FunctionComponent<HeaderCellComponentProps> = ({ context }) => {
    const { translate } = context;
    return (
        <StyledHeaderCell>
            <Header5>{translate("Group")}</Header5>
        </StyledHeaderCell>
    );
};

const StyledHeaderCell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 10;
    padding-left: 18px;
    padding-right: 18px;
    .asc {
        margin-top: 6px;
    }
    .desc {
        margin-bottom: 6px;
    }
    .icon {
        margin-left: 15px;
    }
    .iventis-filter-icon {
        z-index: ${zIndexes.dataTable.columnFilter};
    }
`;
