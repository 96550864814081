import { DataFieldValueTypes } from "@iventis/types";
import { DataFieldValueV2Extended } from "./datafield-value-types";

/**
 * Converts the array of datafield values into a key value pair, where the key is the datafield id and the value is the datafield value
 */
export function parseDataFieldValueToKeyValuePair(datafieldValues: DataFieldValueV2Extended[]): Record<string, DataFieldValueTypes> {
    const extractedDataFieldValues: Record<string, DataFieldValueTypes> = {};
    datafieldValues.forEach((dfv) => {
        // Extract the dataFieldId from the datafield value
        const { dataFieldId } = dfv;
        if (dataFieldId != null) {
            // Find the value which is not null or undefined
            let value = null;
            Object.entries(dfv).forEach(([propertyName, dataFieldValue]) => {
                if (propertyName !== "dataFieldId" && dataFieldValue !== null && dataFieldValue !== undefined) {
                    value = dataFieldValue;
                }
            });
            extractedDataFieldValues[dataFieldId] = value;
        }
    });
    return extractedDataFieldValues;
}
