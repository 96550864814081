import { Body2 } from "@iventis/styles";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { StyledFieldLabel } from "@iventis/styles/src/components/forms";
import { CUSTOM_IMAGE_MODEL_SUPPORT_URL, fileToBase64, useConstant } from "@iventis/utilities";
import { useQuery } from "@tanstack/react-query";
import { Model } from "@iventis/domain-model/model/model";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { DisabledOverlay, FileUploadComponent, FormWizardTemplate, InputWithChipsCss } from "../index";
import { StyledUploadDialog, StyledThumbnailUploadContainer, StyledUploadContainer, ThumbnailUploadPreview } from "./upload-shared-styles";
import { ModelVariableSize, UploadModelVariableSizeComponent } from "./upload-model-variable-size";
import { dimensionValidation } from "./model-sizing-component-state";
import { FromGapComponent } from "./form-gap";
import { CustomImageValue, UploadModelCustomImageComponent } from "./upload-model-custom-image";
import { CustomColourValue, UploadModelCustomColourComponent } from "./upload-model-custom-colour";

export const acceptedModelFileTypes = ["glb", "gltf"];

export type UploadModelData = {
    modelId?: string;
    thumbnailId?: string;
    name: string;
    thumbnail: { fileName: string; imageUrl: string; changed?: boolean };
    model: { fileName: string; modelUrl: string | null; error?: string };
    categories: string[];
} & ModelVariableSize &
    CustomImageValue &
    CustomColourValue;

/** Returns true for all accepted 3D model file types */
export const isModelFileType = (fileName) => acceptedModelFileTypes.includes(fileName.substring(fileName.lastIndexOf(".") + 1));

export const UploadAssetModal: FunctionComponent<{
    open: boolean;
    close: () => void;
    children: ReactNode;
    minModalDimensions?: { width: string; height: string };
}> = ({ open, close, children, minModalDimensions }) => (
    <StyledUploadDialog open={open} onClose={close} $minModalDimensions={minModalDimensions}>
        {open && children}
    </StyledUploadDialog>
);

export const UploadModelForm: FunctionComponent<{
    close: () => void;
    uploadRemotely: (updated: UploadModelData, original: UploadModelData) => Promise<Model>;
    categories?: string[];
    existingId?: string;
    getExistingModel?: (id: string) => Promise<UploadModelData>;
    setSelectedId?: (id: string) => void;
    customImageOnModelValidator?: (modelFile: File) => Promise<boolean>;
}> = ({ close, uploadRemotely, existingId, getExistingModel, categories, setSelectedId, customImageOnModelValidator }) => {
    const translate = useIventisTranslate();
    const [state, setState] = useState<"idle" | "loading" | "error">("idle");

    const defaultData = useConstant<UploadModelData>(() => ({
        name: null,
        thumbnail: { fileName: null, imageUrl: null },
        categories: [],
        model: { fileName: null, modelUrl: null },
        variableSizeModel: false,
        size: { height: 0, width: 0, length: 0 },
        customImage: false,
        aspectRatio: { height: 0, width: 0 },
        customColour: false,
    }));

    // Form data
    const [data, _setData] = useState<UploadModelData>(defaultData);

    const [existingData, setExistingData] = useState<UploadModelData | null>();

    const { isLoading, isFetching } = useQuery(["get-existing-asset", existingId], async () => (existingId ? getExistingModel?.(existingId) : Promise.resolve(defaultData)), {
        onSuccess: (model) => {
            // If values are null (never been set) then set them to 0
            const height = model.size.height ?? 0;
            const width = model.size.width ?? 0;
            const length = model.size.length ?? 0;
            const aspectRatioWidth = model?.aspectRatio?.width ?? 0;
            const aspectRatioHeight = model?.aspectRatio?.height ?? 0;

            const updatedModel = {
                ...model,
                variableSizeModel: height !== 0,
                size: { height, width, length },
                aspectRatio: { height: aspectRatioHeight, width: aspectRatioWidth },
            };
            setExistingData(updatedModel);
            _setData(updatedModel);
        },
    });

    // Update form data
    const setData = <TField extends keyof UploadModelData, TValue extends UploadModelData[TField]>(field: TField, value: TValue) => _setData((d) => ({ ...d, [field]: value }));

    // Upload image to local state
    const uploadThumbnailLocally = async (file: File) => {
        const { withHeader } = await fileToBase64(file);
        setData("thumbnail", { fileName: file.name, imageUrl: withHeader, changed: true });
        setState("idle");
    };

    const [customImageMaterialValid, setCustomImageMaterialValid] = useState(true);

    // Upload image to local state
    const uploadModelLocally = async (file: File) => {
        if (!isModelFileType(file.name)) {
            setData("model", { fileName: null, modelUrl: null, error: `Must be of type: ${acceptedModelFileTypes.join(", ")}` });
            return;
        }
        const { withHeader } = await fileToBase64(file);
        setData("model", { fileName: file.name, modelUrl: withHeader });

        const canHaveCustomImage = await customImageOnModelValidator(file);
        setCustomImageMaterialValid(canHaveCustomImage);

        setState("idle");
    };

    // Upload model to remote storage
    const uploadModelRemotely = async () => {
        setState("loading");
        let err = false;
        try {
            const res = await uploadRemotely(data, existingData);
            if (setSelectedId) setSelectedId(res?.thumbnailAssetId);
        } catch {
            err = true;
            setState("error");
        }
        if (!err) {
            close();
        }
    };

    const validationCheck = () => {
        // Check that all the properties are populated
        const isValid =
            data.name?.length > 0 &&
            data.thumbnail.fileName?.length > 0 &&
            data.thumbnail.imageUrl?.length > 0 &&
            data.model.fileName?.length > 0 &&
            (customImageMaterialValid || !data.customImage) &&
            (data.variableSizeModel ? dimensionValidation(data.size) : true);

        // If editing then check that updated data is different to existing
        if (existingId) {
            return isValid && JSON.stringify(data) !== JSON.stringify(existingData);
        }

        // If not editing ensure model is populated
        return isValid && data.model.modelUrl?.length > 0;
    };

    return (
        <FormWizardTemplate
            stages={[
                {
                    isValid: validationCheck(),
                    primaryButtonText: translate(Content.common.buttons.confirm),
                    primaryButtonCallback: uploadModelRemotely,
                    secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: close }],
                    submitButtonDataCy: "confirm-model-button",
                },
            ]}
            isSubmitting={state === "loading"}
            currentStage={0}
            title={existingId == null ? translate(Content.map8.upload_asset.upload_model) : translate(Content.map8.upload_asset.update_model)}
        >
            {(isLoading || isFetching) && <LinearProgress style={{ position: "absolute", width: "100%", top: "0px", left: "0px" }} />}
            {(isLoading || isFetching) && <DisabledOverlay testId="upload-model-disabled-overlay" />}
            <StyledFieldLabel id="name">{translate(Content.map8.upload_asset.name, { assetType: translate(Content.map8.upload_asset.model) })}</StyledFieldLabel>
            <TextField
                type="text"
                aria-labelledby="name"
                name="name"
                variant="outlined"
                value={data.name || ""}
                onChange={(e) => setData("name", e.target.value)}
                data-testid="upload-asset-name"
            />
            <FromGapComponent />
            {categories && (
                <>
                    <StyledFieldLabel id="name">{translate(Content.map8.upload_asset.categories)}</StyledFieldLabel>
                    <InputWithChipsCss>
                        <Autocomplete<string, true>
                            multiple
                            options={categories}
                            onChange={(_, value) => setData("categories", value)}
                            value={data.categories}
                            renderInput={(params) => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    placeholder={translate(Content.map8.upload_asset.search_for_categories)}
                                />
                            )}
                            data-testid="asset-categories-selector"
                        />
                    </InputWithChipsCss>
                    <FromGapComponent />
                </>
            )}
            <StyledFieldLabel id="select-model">{translate(Content.map8.upload_asset.select_asset, { assetType: translate(Content.map8.upload_asset.model) })}</StyledFieldLabel>
            <StyledUploadContainer>
                <FileUploadComponent
                    PreviewComponent={() => null}
                    removeRequested={() => null}
                    loading={false}
                    fileName={data.model.fileName}
                    fileThumbnailUrl={null}
                    uploadFile={uploadModelLocally}
                    uploadButtonText={translate("Select file")}
                    removeImageText={null}
                    className="file-upload"
                    persistFileSelectInput
                    ariaLabelledBy="select-model"
                    maxFileSize={3}
                    dataTestIdButton="upload-model-button"
                    dataTestIdInput="upload-model-input"
                />
            </StyledUploadContainer>
            {data.model.error?.length > 0 && (
                <>
                    <Body2 style={{ color: "red", marginTop: "5px" }}>
                        {`${translate(Content.map8.upload_asset.file_end)} ${acceptedModelFileTypes.map((t) => `.${t}`).join(` ${translate(Content.map8.upload_asset.or)} `)}`}
                    </Body2>
                    <FromGapComponent />
                </>
            )}
            {customImageMaterialValid === false && data?.customImage && (
                <>
                    <Body2 style={{ color: "red", marginTop: "5px" }}>
                        {translate(Content.map8.upload_asset.not_support_custom_image)}{" "}
                        <a href={CUSTOM_IMAGE_MODEL_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                            {translate(Content.common.here)}
                        </a>
                        .
                    </Body2>
                    <FromGapComponent />
                </>
            )}
            <StyledFieldLabel id="select-thumbnail">{translate(Content.map8.upload_asset.select_thumbnail)}</StyledFieldLabel>
            <StyledThumbnailUploadContainer>
                <FileUploadComponent
                    PreviewComponent={ThumbnailUploadPreview}
                    removeRequested={() => null}
                    loading={false}
                    fileName={data.thumbnail.fileName}
                    fileThumbnailUrl={data.thumbnail.imageUrl}
                    uploadFile={uploadThumbnailLocally}
                    uploadButtonText={translate(Content.map8.upload_asset.select_file)}
                    removeImageText={null}
                    inputAccept="image/*"
                    className="file-upload"
                    persistFileSelectInput
                    ariaLabelledBy="select-thumbnail"
                    maxFileSize={1}
                    dataTestIdButton="upload-model-thumbnail-button"
                    dataTestIdInput="upload-model-thumbnail-input"
                />
            </StyledThumbnailUploadContainer>
            <FromGapComponent />
            <UploadModelVariableSizeComponent onChange={setData} values={data} />
            <FromGapComponent />
            <UploadModelCustomImageComponent onChange={setData} values={data} />
            <UploadModelCustomColourComponent onChange={setData} value={data} />
            {state === "error" && (
                <>
                    <FromGapComponent />
                    <Body2 style={{ color: "red" }}>{translate(Content.map8.upload_asset.something_went_wrong)}</Body2>
                </>
            )}
        </FormWizardTemplate>
    );
};
