import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapboxEngineData, MapEngineStyle, MapState, Source } from "@iventis/map-engine/src/types/store-schema";
import { localSuffix, previewLayerId } from "@iventis/map-engine/src/bridge/constants";
import { main } from "@iventis/styles";
import { mapFontStackUrl } from "@iventis/map-engine/src/map-fonts";
import { initialState } from "@iventis/map-engine/src/state/map-state.initial-values";

const baseMapStyle: MapEngineStyle = {
    version: 8,
    name: "Preview Map",
    sources: {
        [`${previewLayerId}_${localSuffix}`]: {
            type: "geojson",
            data: {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [],
                },
                properties: {},
            },
        },
    },
    layers: [
        {
            type: "background",
            id: "preview-background",
            paint: {
                "background-color": main.shades.four,
            },
        },
    ],
    glyphs: mapFontStackUrl,
    sprite: "",
};

export const baseMapStylePreviewSnapshot: MapEngineStyle = {
    ...baseMapStyle,
    layers: [
        {
            type: "background",
            id: "preview-background",
            paint: {
                "background-color": main.secondaryColors.blank,
            },
        },
    ],
};

export const initialMapState: MapState<MapboxEngineData> = {
    ...initialState.mapModule,
    position: {
        value: {
            lng: 0,
            lat: 0,
            zoom: 15,
            bearing: 0,
            pitch: 0,
            source: Source.HOST,
        },
        stamp: "",
    },
    engineSpecificData: {
        styles: {
            value: { [AssetType.MapBackground]: baseMapStyle, [AssetType.SiteMap]: [] },
            stamp: "",
        },
    },
    layers: {
        value: [],
        stamp: "",
    },
};
