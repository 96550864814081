import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body1 } from "@iventis/styles/src/components/texts";
import React, { useState } from "react";
import { styled } from "@iventis/styles";
import { ClickAwayListenerToolTip, InfoTooltip } from "./tooltips";

/**
 * An info icon that display a tooltip on click
 *
 * @param {string} title - Adds a title to the tooltip
 * @param {string | React.ReactNode} content - Text or elements to display in the tooltip
 */
export const InfoIcon: React.FC<{
    /** Title of tooltip */
    title: string;
    /** Content of tooltip. Can be text or a react element */
    content: string | React.ReactNode;
}> = ({ title, content }) => {
    const [open, setOpen] = useState(false);
    const [hovering, setHovering] = useState(false);

    const onClose = () => setOpen(false);

    return (
        <ClickAwayListenerToolTip onClickAway={onClose}>
            <InfoTooltip title={title} text={typeof content === "string" ? <Body1>text</Body1> : content} open={open} onClose={onClose}>
                <StyledIcon
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    onClick={() => setOpen(!open)}
                    icon={open || hovering ? ["fas", "circle-info"] : ["far", "circle-info"]}
                />
            </InfoTooltip>
        </ClickAwayListenerToolTip>
    );
};

export const TooltipContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    padding-left: 5px;
    cursor: pointer;
`;
