import React, { BaseSyntheticEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ICellEditor } from "@ag-grid-community/core";
import { Input } from "@mui/material";
import { styled, narrowPadding } from "@iventis/styles";

import { Theme } from "@emotion/react";
import { sanitiseForDOMToken } from "@iventis/utilities";
import { IventisCellEditorParams, RowNetworkStatus } from "../types/data-table.types";
import { createInitialNumberState } from "../lib/grid.helpers";

/**
 * Used as cellEditor for data table column of type "number"
 */
export const NumberCellEditor = forwardRef<ICellEditor, IventisCellEditorParams>((props, ref) => {
    const { node, required, column } = props;
    const initialState = createInitialNumberState(props);
    const convertInput = (value: string | number) => (value === undefined || value === "" ? undefined : Number(value));
    const [value, setValue] = useState(convertInput(initialState.value));

    const refInput = useRef(null);

    useEffect(() => {
        // get ref from React component
        const eInput = refInput.current;
        eInput.focus();
        if (eInput.value?.length > 0) {
            eInput.select();
        }
    }, []);

    useImperativeHandle(ref, () => ({
        getValue() {
            return value;
        },
        isCancelBeforeStart() {
            // If the field is not required or has a value, while we are pending creation, make this cell uneditable
            const blockEditsUntilCreatedOnServer = (!required || props.value != null) && node.data.networkStatus === RowNetworkStatus.PendingCreation;
            return !node.data.canEdit || blockEditsUntilCreatedOnServer;
        },
        isCancelAfterEnd() {
            return convertInput(props.value) === value || value === undefined;
        },
    }));

    return (
        <StyledInput
            type="number"
            inputRef={refInput}
            value={value ?? ""}
            onChange={(event: BaseSyntheticEvent) => setValue(convertInput(event.target.value))}
            error={value === undefined}
            inputProps={{ "data-testid": sanitiseForDOMToken(`data-table-Number-cell-editor-row-${node.data?.name}-column-${column.getColDef().headerName}`) }}
        />
    );
});

const StyledInput = styled(Input)`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }: { theme: Theme }) => theme.dataTableTheme.background};
    padding-left: ${narrowPadding};
`;
