import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import { point, polygon, Position } from "@turf/helpers";
import rhumbBearing from "@turf/rhumb-bearing";
import GeoJSON from "geojson";
import { AnySupportedGeometry, MapObjectProperties } from "@iventis/map-types";
import { coordinateHandleLayerId } from "../bridge/constants";
import { FixedShapeSupportedGeometry, Listener, MoveEvent, TypedFeature } from "../types/internal";
import {
    createRectangleFromTwoPoints,
    ensure360Bearing,
    findBearingOfRectangle,
    geometryToPointGeometry,
    getCoordinateList,
    getIndexClosestCoordinate,
    removeLoopClosingCoordinate,
    replaceCoordinate,
    updateCoordinates,
} from "./geojson-helpers";
import { Midpoint } from "./midpoint-handles";
import { DrawingModifier } from "../types/store-schema";
import { DrawingModifierCreators, DrawingModifierState, ModifyableDrawingTool } from "./drawing-modifiers";

export enum CoordinateHandleEvent {
    START_DRAG = "START_DRAG",
    DRAG = "DRAG",
    RELEASE = "RELEASE",
    NEAR_HANDLE = "NEAR_HANDLE",
    DESTROY = "DESTROY",
    ENTER = "ENTER",
    LEAVE = "LEAVE",
}

interface ListenerPattern {
    [CoordinateHandleEvent.START_DRAG]: ((point: GeoJSON.Point, cursorBearing?: number) => void)[];
    [CoordinateHandleEvent.ENTER]: ((point: GeoJSON.Point, cursorBearing?: number) => void)[];
    [CoordinateHandleEvent.LEAVE]: (() => void)[];
    [CoordinateHandleEvent.DRAG]: ((transformation: GeoJSON.Feature, newCoordinate: { lng: number; lat: number }) => void)[];
    [CoordinateHandleEvent.RELEASE]: ((payload: { transformation: GeoJSON.Feature; coordinate: GeoJSON.Position; cursorBearing?: number; isDragging?: boolean }) => void)[];
    [CoordinateHandleEvent.NEAR_HANDLE]: ((point: GeoJSON.Point) => void)[];
    [CoordinateHandleEvent.DESTROY]: (() => void)[];
}

export class CoordinateHandles extends ModifyableDrawingTool {
    private listeners: ListenerPattern = {
        [CoordinateHandleEvent.START_DRAG]: [],
        [CoordinateHandleEvent.ENTER]: [],
        [CoordinateHandleEvent.LEAVE]: [],
        [CoordinateHandleEvent.DRAG]: [],
        [CoordinateHandleEvent.RELEASE]: [],
        [CoordinateHandleEvent.NEAR_HANDLE]: [],
        [CoordinateHandleEvent.DESTROY]: [],
    };

    private nearHandleListener: Listener;

    private mouseDownHandleListener: Listener;

    private mouseDragListener: Listener;

    private mouseUpListener: Listener;

    private mouseMoveListener: Listener;

    private onContentUnderMouseChangedListener: Listener;

    private enteredHandle: boolean;

    private coordinateGeometry: GeoJSON.FeatureCollection<GeoJSON.Point>;

    protected drawingModifier: DrawingModifierState;

    protected getDrawingModifier: () => DrawingModifierState[DrawingModifier];

    public setDrawingModifier: (drawingModifier: DrawingModifier) => void;

    constructor(
        private object: TypedFeature,
        public operations: {
            onMouseMove: (callback: (e: MoveEvent) => void, radius?: number) => Listener;
            onMouseMoveNearHandle: (callback: (point: GeoJSON.Point) => void) => Listener;
            onMouseDownHandle: (callback: (point: GeoJSON.Point) => void) => Listener;
            onMouseUp: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onContentUnderMouseChanged: (callback: (e: MoveEvent) => void) => Listener;
            setHandleGeometry: (geometry: GeoJSON.FeatureCollection<GeoJSON.Point>) => void;
        },
        drawingModifier: DrawingModifier,
        createDrawingModifier: DrawingModifierCreators,
        alreadyDraggingPoint?: Midpoint
    ) {
        super(drawingModifier, createDrawingModifier);

        this.coordinateGeometry = getCoordinateGeometry(object);
        this.operations.setHandleGeometry(this.coordinateGeometry);

        let dragStarted = false;

        const contentUnderMouseChanged = (event) => {
            if (dragStarted) {
                return;
            }

            const coordinateHandleObject = event.objects.find((object) => object.properties.layerid === coordinateHandleLayerId) as GeoJSON.Feature<GeoJSON.Point>;

            if (coordinateHandleObject && !this.enteredHandle) {
                this.enteredHandle = true;

                let cursorBearing;

                if (this.object.properties.fixedShape != null) {
                    cursorBearing = this.getCursorBearing(coordinateHandleObject);
                }

                this.listeners.ENTER.forEach((listener) => listener(coordinateHandleObject.geometry, cursorBearing));
            } else if (this.enteredHandle && !event.objects.some((object) => object.properties.layerid === coordinateHandleLayerId)) {
                this.enteredHandle = false;
                this.listeners.LEAVE.forEach((listener) => listener());
            }
        };

        const dragFunction = (startingPoint: GeoJSON.Point) => {
            dragStarted = false;

            this.mouseUpListener = this.operations.onMouseUp((mousePosition) => {
                this.mouseUpListener.remove();
                this.mouseUpListener = undefined;
                this.mouseDragListener.remove();

                const transformation = dragStarted ? this.finalTranslation(startingPoint.coordinates, mousePosition) : this.object;
                let cursorBearing;
                if (this.object.properties.fixedShape != null) {
                    cursorBearing = this.getCursorBearing(point([mousePosition.lng, mousePosition.lat]));
                }
                this.listeners[CoordinateHandleEvent.RELEASE].forEach((listener) =>
                    listener({ transformation, coordinate: [mousePosition.lng, mousePosition.lat], cursorBearing, isDragging: dragStarted })
                );
            });

            this.mouseDragListener = this.operations.onMouseMove((mousePosition) => {
                const [lng, lat] = this.tryModify([point([mousePosition.lng, mousePosition.lat], { id: object.properties.id })])[0].geometry.coordinates as Position;

                if (!dragStarted) {
                    this.removeHandles();
                    dragStarted = true;

                    let cursorBearing;
                    if (this.object.properties.fixedShape != null) {
                        cursorBearing = this.getCursorBearing(point([lng, lat]));
                    }
                    this.listeners[CoordinateHandleEvent.START_DRAG].forEach((listener) => listener(startingPoint, cursorBearing));
                }

                const transformation = this.getTransformation(startingPoint.coordinates, { lng, lat });
                this.listeners[CoordinateHandleEvent.DRAG].forEach((listener) => listener(transformation, { lng, lat }));
            });
        };

        if (alreadyDraggingPoint) {
            dragFunction(alreadyDraggingPoint.geometry);
        }

        this.mouseMoveListener = this.operations.onMouseMove(contentUnderMouseChanged);

        this.onContentUnderMouseChangedListener = this.operations.onContentUnderMouseChanged(contentUnderMouseChanged);

        this.nearHandleListener = this.operations.onMouseMoveNearHandle((p) => {
            const indexOfNearest = getIndexClosestCoordinate(this.coordinateGeometry.features, point(p.coordinates), (a) => a.geometry.coordinates);
            this.listeners[CoordinateHandleEvent.NEAR_HANDLE].forEach((listener) => listener(this.coordinateGeometry.features[indexOfNearest].geometry));
        });

        this.mouseDownHandleListener = this.operations.onMouseDownHandle((point: GeoJSON.Point) => {
            dragFunction(point);
        });
    }

    getTransformation(originalCoordinate: GeoJSON.Position, newCoordinate: { lng: number; lat: number }) {
        // If object is a fixed rectangle, then we must resize instead of just replacing the coordinate.
        // However, if only 1 point exists, it means the user is moving the anchor, so we skip the rectangle resize
        if (this.object.properties.fixedShape === FixedShape.Rectangle && getCoordinateList(this.object.geometry as FixedShapeSupportedGeometry).length === 5) {
            const newCoord = [newCoordinate.lng, newCoordinate.lat];
            const fakePolygon =
                this.object.geometry.type === "Polygon"
                    ? this.object.geometry
                    : this.object.geometry.type === "LineString"
                    ? polygon([this.object.geometry.coordinates]).geometry
                    : null;
            if (fakePolygon == null) {
                throw new Error("Points cannot be fixed shapes");
            }
            // Remove the closing coordinate
            const uniqueCoords = removeLoopClosingCoordinate(fakePolygon).coordinates;
            // Find the target coordinate's index
            const newCoordIndex = getIndexClosestCoordinate(uniqueCoords, originalCoordinate, (a) => a);
            // Fixed coordinate in a rectangle will always be 2 indeces away from target
            const anchorCoord = uniqueCoords[(newCoordIndex + 2) % uniqueCoords.length];
            // Update the geoometry
            const newCoords = createRectangleFromTwoPoints(anchorCoord, newCoord, findBearingOfRectangle(this.object.geometry as FixedShapeSupportedGeometry));
            const transformedFeature: GeoJSON.Feature<FixedShapeSupportedGeometry, MapObjectProperties> = {
                ...this.object,
                geometry: updateCoordinates(this.object.geometry as FixedShapeSupportedGeometry, newCoords),
            };
            return transformedFeature;
        }
        const transformedFeature: TypedFeature = {
            ...this.object,
            geometry: replaceCoordinate(this.object.geometry, originalCoordinate, [newCoordinate.lng, newCoordinate.lat]),
        };

        return transformedFeature;
    }

    private finalTranslation(...[originalCoordinate, mousePosition]: Parameters<CoordinateHandles["getTransformation"]>) {
        const latestTranslation = this.getLatestTranslation()?.[0];
        let newCoordinate = mousePosition;
        if (latestTranslation?.geometry.type === "Point") {
            newCoordinate = { lng: latestTranslation.geometry.coordinates[0], lat: latestTranslation.geometry.coordinates[1] };
        }
        return this.getTransformation(originalCoordinate, newCoordinate);
    }

    removeHandles() {
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });
        this.mouseDownHandleListener.remove();
    }

    public on<E extends CoordinateHandleEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }

    private getCursorBearing(coordinateHandleObject: GeoJSON.Feature<GeoJSON.Point>) {
        if (this.coordinateGeometry.features.length === 1) {
            return undefined;
        }
        const indexOfHovered = getIndexClosestCoordinate(this.coordinateGeometry.features, coordinateHandleObject, (a) => a.geometry.coordinates);
        const [left, right] = [
            this.coordinateGeometry.features[(indexOfHovered + 1) % 4],
            this.coordinateGeometry.features[indexOfHovered === 0 ? this.coordinateGeometry.features.length - 1 : (indexOfHovered - 1) % 4],
        ];
        const bearingLeft = ensure360Bearing(rhumbBearing(coordinateHandleObject, left));
        const bearingRight = ensure360Bearing(rhumbBearing(coordinateHandleObject, right));
        const outerBearing = bearingLeft < bearingRight ? bearingRight : bearingLeft;
        const diff = Math.abs(bearingLeft - bearingRight);
        const cursorBearing = outerBearing - diff / 2;
        return cursorBearing;
    }

    destroy() {
        super.destroy();
        this.operations.setHandleGeometry({
            type: "FeatureCollection",
            features: [],
        });
        this.mouseMoveListener?.remove();
        this.nearHandleListener?.remove();
        this.mouseUpListener?.remove();
        this.mouseDownHandleListener?.remove();
        this.mouseDragListener?.remove();
        this.onContentUnderMouseChangedListener?.remove();
        this.listeners[CoordinateHandleEvent.DESTROY].forEach((listener) => listener());
    }
}

export function getCoordinateGeometry(object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>): GeoJSON.FeatureCollection<GeoJSON.Point> {
    if (object === undefined) {
        return {
            type: "FeatureCollection",
            features: [],
        };
    }
    const pointGeometry = geometryToPointGeometry(object);

    return pointGeometry;
}
